import { useLocalObservable, useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import ItemsStore from './ItemStore';
import { Configuration, ItemsApi } from '../api-client';
import { AuthContext } from './Auth/auth.provider';
import useStore from '../helpers/useStore';

interface EscalationsProviderProps {
  children: ReactNode;
}

export const ItemsContext = createContext<ItemsStore | null>(null);

export const ItemsProvider = ({ children }: EscalationsProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore;

 
  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  const apiClient = new ItemsApi(config);


  const store = useLocalStore(() => new ItemsStore(apiClient, AuthenticationStore));

  store.apiClient = apiClient;

  return (
    <ItemsContext.Provider value={store}>
      {children}
    </ItemsContext.Provider>
  );
};
