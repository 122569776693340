// NotificationStore.js
import { makeObservable, observable, action } from "mobx";

class NotificationStore {
  @observable
  message = "";
  
  @observable
  type = ""; // Default type is "info"

  @observable
  error = "";

  @observable
  stackTrace = "";

  @observable
  statusCode = 0;

  @observable
  timestamp = "";

  @action
  showMessage(message:any, type:any, error:any = undefined) {
    this.message = message;
    this.type = type; // Update the type along with the message
    this.error = error?.response?.data?.message || "";
    this.stackTrace = error?.response?.data?.stack || "";
    this.statusCode = error?.response?.data?.statusCode || 0;
    this.timestamp = error?.response?.data?.timestamp || "";
    console.log("Notification:", message, "Type:", type, "Error:", error);
  }
}

const notificationStore = new NotificationStore();
makeObservable(notificationStore);

export default notificationStore;
