// AppointmentHeader.tsx
import React from "react";
import { FC } from "react";
import { Typography, Grid, Box } from "@mui/material";
import { formatDateUI } from "../../helpers/dateFormat";

interface AppointmentHeaderProps {
  patientDetails: any,
  rxDate: string | undefined | null;
  single: boolean
  price: number | undefined;
  tarifRx?: string | undefined | null;
}

const AppointmentHeader: FC<AppointmentHeaderProps> = ({ patientDetails, rxDate, single, price, tarifRx}) => {

  const date = new Date();
  const day = String(date.getDate()).padStart(2, '0');
  const monthNames = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  const month = monthNames[date.getMonth()]; // Get the month abbreviation
  const year = date.getFullYear();
  const todayDate = `${day} ${month} ${year}`;

  const {firstName, lastName} = patientDetails;

  let rezeptDate = "";
  if(rxDate){
    const cleanedDateString = formatDateUI(rxDate)
    const parts: string[] = cleanedDateString.split(" ");
    rezeptDate = parts.slice(1).join(" "); 
  }
  else{
    rezeptDate = " ";
  }

  // const rezeptDate = formatDateUI(startDate)
  // const parts: string[] = rezeptDate.split(" ");
  // const cleanedDateString: string = parts.slice(1).join(" ");



  return (
    <>

      <Grid container>
        <div style={{ flex: 1 }}>
          <Grid container justifyContent="space-between"> 
          <Box>
          <Typography sx={{fontSize: '12px'}}  align="left">
            <strong>Ausdruck:</strong> {todayDate}
          </Typography>
          </Box>
            <Box>
          <Typography sx={{fontSize: '12px'}} align="right">
            <strong>Name:</strong>{" " + firstName + " " + lastName}
          </Typography>
          </Box>
          </Grid>
          <Box>
          <Typography sx={{fontSize: '12px'}} align="left">
            <strong>Rezept:</strong> {rezeptDate}
          </Typography>
          </Box>
        </div>
      </Grid>
      <Grid container>
      <div style={{ flex: 1, textAlign: 'left'}}>
          <Box>
          <Typography sx={{fontSize: '12px'}} align="left">
          Sollten Sie verhindert sein, sagen Sie Ihre Termine bitte mindestens 24 Stunden vorher ab.
          Andernfalls müssen wir Ihnen ausgefallene Termin privat in Rechung stellen. 
        </Typography>

        {single && tarifRx !== "Privat" && price! > 0?
        <Typography sx={{fontSize: '12px'}} align="left">
          Für dieses Rezept ist eine Zuzahlung von <strong>{price!.toFixed(2).replace('.', ',')}€</strong> erforderlich.
        </Typography>
    : ""
    }
        
          </Box>
        </div>
        
      </Grid>
    </>
  );
};

export default AppointmentHeader;
