// NavigationStore.js
import { makeObservable } from "mobx";




class NavigationStore {
  navigateToPatient(patientId:any) {
    const route = this.generateRoute(patientId);
    window.history.replaceState("","",route)
    return route;
  }

  generateRoute(patientId:any, prescriptionId?:any) {
   
       const baseRoute=window.location.href // Fetch the base route from URL parameter or default to '/patient'
        let route = `/patient?pId=${patientId}`;
        if (prescriptionId) {
          route += `&&prescription?rxId=${prescriptionId}`;
        }
        return route;
      
  }
}

const navigationStore = new NavigationStore();
makeObservable(navigationStore);

export default navigationStore;
