import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";
import Controls from "../../../../atoms/Controls";
import { RxSeriesDto } from "../../../../api-client";
import { mobiliTheme } from "../../../../themes/mobiliTheme";

interface RxSeriesSelectorProps {
  data: RxSeriesDto[];
  selectedRow: any;
  handleRowClick: (rx: any) => void;
  // handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement>, rx: any) => void;
}

const RxSeriesSelector: React.FC<RxSeriesSelectorProps> = ({
  data,
  selectedRow,
  handleRowClick,
  // handleMoreButtonClick,
}) => {
  return (
    <List component="nav" aria-label="therapy list">
      {data.map((series, index) => (
        <ListItem key={index} sx={{ padding: "4px 0px", width: "100%" }}>
          <ListItemButton
            onClick={() => handleRowClick(series)}
            selected={selectedRow?.id === series.id}
            sx={{ padding: "0px" }}
          >
            <Paper
              sx={{
                padding: "8px",
                display: "flex",
                flex: "1",
                alignItems: "center",
                backgroundColor:
                  selectedRow?.id === series.id
                    ? "secondary.light"
                    : mobiliTheme.lowLevelContainerBackground,
                boxShadow: 3, // Correct usage of boxShadow for shadow effects
              }}
            >
              <Typography sx={{ flexShrink: 0 }}>{series.label}</Typography>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 0fr)",
                  padding: "0px 4px",
                  marginLeft: "8px",
                }}
              >
                {series?.therapyRxs &&
                  series.therapyRxs?.map((therapy: any, index: number) => {
                    const therapyId = therapy.therapy.abbreviation;
                    return (
                      <Tooltip title={therapyId} key={therapy.id}>
                        <Controls.Chip
                          sx={{
                            fontSize: "10px",
                            border: "1px solid black",
                            backgroundColor: "white",
                          }}
                          label={
                            "(" + therapy.rx.rxNumber + ") " + therapy.amount + " x " + therapyId
                          }
                        />
                      </Tooltip>
                    );
                  })}
              </div>
            </Paper>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};

export default RxSeriesSelector;
