import React from 'react';
import { Card, CardContent, Typography, Grid, CardHeader, Tooltip, Avatar } from '@mui/material';
import { NoteDto } from '../../../api-client';

interface NotesViewProps {
  notes: NoteDto[];
}

const NotesView: React.FC<NotesViewProps> = ({ notes }) => {
  return (
    <Grid container spacing={2}>
      
      {notes.map((card, index) => (
        <Grid item xs={12} key={card.id || index}>
          <Card
            style={{
              border: '0px solid #007bff',
              display: 'flex',
              alignItems: 'center',
            }}
          >
              
            <CardContent style={{ flex: 1 }}>
              <Tooltip title={card.text || 'Keine Notiz'}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    width: '250px',
                  }}
                >
                  {card.text || 'Keine Notiz'}
                </Typography>
              </Tooltip>
            </CardContent>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', padding: 8 }}>
              <Tooltip title={card.updatedByUser ? card.updatedByUser.lastName : card.createdByUser?.lastName}>
                <Avatar sx={{ width: 40, height: 35, marginRight: '8px' }}>
                  {card.updatedByUser ? card.updatedByUser.nickName : card.createdByUser?.nickName}
                </Avatar>
              </Tooltip>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default NotesView;
