import React, { useEffect, useState, useCallback } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableStructure from "./TableStructure";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { Grid, Typography } from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import useStore from "../../helpers/useStore";
import { TransactionsContext } from "../../stores/Transactions/Transactions.provider";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import IconButton from "@mui/material/IconButton";
import { observer } from "mobx-react";
import SearchBox from "./SearchBox";
import {
  BaseTransactionDetailsDto,
  GetCashDetailsDto,
  GetRechnungDetailsDto,
} from "../../api-client";
import { Button } from "@mui/material";
import { TopLevelPaper } from "../../themes/StyledComponents";
// import debounce from 'lodash.debounce';

const commonStyles = {
  bgcolor: "background.paper",
  border: 0,
  my: 1,
};

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
    fontWeight: "bold",
  },
}));

interface LedgerTableProps<T> {
  transactionType: "cash" | "ec" | "lastschrift" | "rechnung";
}

export type TransactionDetailsDto =
  | GetCashDetailsDto
  | BaseTransactionDetailsDto
  | GetRechnungDetailsDto;

const LedgerTableComponent = <T extends TransactionDetailsDto>({
  transactionType,
}: LedgerTableProps<T>) => {
  const TransactionsStore = useStore(TransactionsContext);

  const {
    getCashTransactionsBetweenDates,
    getEcCashTransactionsBetweenDates,
    getLastSchriftTransactionsBetweenDates,
    getRechnungTransactionsBetweenDates,
    getCashTransactionsWithSearch,
    getEcCashTransactionsWithSearch,
    getLastSchriftTransactionsWithSearch,
    getRechnungTransactionsWithSearch,
    lastUpdate,
  } = TransactionsStore;

  const [data, setData] = useState<T[]>([]);
  //useState<GetRechnungDetailsDto[]>([]);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState<Dayjs | null>(dayjs());
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs());
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  ////////////////////////////////////////////////// Debounce Method ////////////////////////////////////////

  // const fetchData = useCallback(async (searchTerm: string) => {
  //     try {
  //         if (startDate && endDate) {
  //             let response;
  //             if (searchTerm.trim() !== '') {
  //                 response = await getTransactionsWithSearch(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), searchTerm);
  //             } else {
  //                 response = await getTransactionsBetweenDates(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  //             }
  //             setData(response);
  //         }
  //     } catch (error) {
  //         console.log("No Transactions Found", error);
  //     }
  // }, [startDate, endDate, getTransactionsBetweenDates, getTransactionsWithSearch]);

  // const debouncedFetchData = useCallback(debounce(fetchData, 300), [fetchData]);

  // useEffect(() => {
  //     debouncedFetchData(search);
  // }, [search, debouncedFetchData, lastUpdate]);

  ///////////////////////////////////////////// Semi-Optimized Version /////////////////////////////////////

  // const fetchData = useCallback(async () => {
  //     try {
  //         if (startDate && endDate) {
  //             let response;
  //             if (search.trim() !== '') {
  //                 response = await getRechnungTransactionsWithSearch(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'), search);
  //             } else {
  //                 response = await getRechnungTransactionsBetweenDates(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD'));
  //             }
  //             setData(response);
  //         }
  //     } catch (error) {
  //         console.log("No Transactions Found", error);
  //     }
  // }, [startDate, endDate, search, getRechnungTransactionsBetweenDates, getRechnungTransactionsWithSearch]);

  // useEffect(() => {
  //     fetchData();
  // }, [fetchData, lastUpdate]);

  const fetchData = useCallback(async () => {
    try {
      if (startDate && endDate) {
        let response;
        if (search.trim() !== "") {
          switch (transactionType) {
            case "cash":
              response = await getCashTransactionsWithSearch(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD"),
                search
              );
              break;
            case "ec":
              response = await getEcCashTransactionsWithSearch(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD"),
                search
              );
              break;
            case "lastschrift":
              response = await getLastSchriftTransactionsWithSearch(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD"),
                search
              );
              break;
            case "rechnung":
              response = await getRechnungTransactionsWithSearch(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD"),
                search
              );
              break;
            default:
              throw new Error("Invalid transaction type");
          }
        } else {
          switch (transactionType) {
            case "cash":
              response = await getCashTransactionsBetweenDates(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD")
              );
              break;
            case "ec":
              response = await getEcCashTransactionsBetweenDates(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD")
              );
              break;
            case "lastschrift":
              response = await getLastSchriftTransactionsBetweenDates(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD")
              );
              break;
            case "rechnung":
              response = await getRechnungTransactionsBetweenDates(
                startDate.format("YYYY-MM-DD"),
                endDate.format("YYYY-MM-DD")
              );
              break;
            default:
              throw new Error("Invalid transaction type");
          }
        }
        setData(response as T[]);
      }
    } catch (error) {
      console.log("No Transactions Found", error);
    }
  }, [
    startDate,
    endDate,
    search,
    transactionType,
    getCashTransactionsBetweenDates,
    getEcCashTransactionsBetweenDates,
    getLastSchriftTransactionsBetweenDates,
    getRechnungTransactionsBetweenDates,
    getCashTransactionsWithSearch,
    getEcCashTransactionsWithSearch,
    getLastSchriftTransactionsWithSearch,
    getRechnungTransactionsWithSearch,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, lastUpdate]);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePreviousDay = () => {
    if (startDate && endDate) {
      setStartDate(startDate.subtract(1, "day"));
      setEndDate(startDate.subtract(1, "day"));
      setPage(0);
    }
  };

  const handleNextDay = () => {
    if (startDate && endDate) {
      setStartDate(startDate.add(1, "day"));
      setEndDate(startDate.add(1, "day"));
      setPage(0);
    }
  };

  const isNextDayDisabled = startDate
    ? startDate.isAfter(dayjs(), "day") || startDate.isSame(dayjs(), "day")
    : false;

  return (
    <TopLevelPaper sx={{ borderRadius: "0 0 12px 12px",}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container alignItems="left" justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Grid>
                <SearchBox searchString={search} setSearchString={setSearch} />
              </Grid>
              <Grid >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => fetchData()}
                  sx={{ mx: 1.2, py: 1.2}}
                >
                  Neu
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ paddingLeft: 40 }}>
            <Typography
              sx={{
                textTransform: "uppercase",
                fontWeight: "bold",
                fontSize: "h4.fontSize",
                color: "white",
                fontFamily: "merriweather-light",
              }}
            >
              Kasse
            </Typography>
          </Grid>
          <Grid item sx={{my: -1}}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={handlePreviousDay} size="large" color="secondary">
                  <KeyboardArrowLeftIcon fontSize="inherit" />
                </IconButton>
                <IconButton
                  onClick={handleNextDay}
                  disabled={isNextDayDisabled}
                  size="large"
                  color="secondary"
                >
                  <KeyboardArrowRightIcon fontSize="inherit" />
                </IconButton>
              </Grid>
              <Grid sx={{ mx: 1 }}>
                <DatePicker
                  label={"Startdatum"}
                  value={startDate}
                  onChange={setStartDate}
                  maxDate={endDate || dayjs()}
                />
              </Grid>
              <Grid>
                <DatePicker
                  label={"Enddatum"}
                  value={endDate}
                  onChange={setEndDate}
                  minDate={startDate || dayjs()}
                  maxDate={dayjs()}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Box sx={{ ...commonStyles, maxHeight: "70vh", display: "flex", flexDirection: "column", borderRadius: "12px"}}>
          <TableContainer component={Paper} sx={{ flex: 1, overflow: "auto" }}>
            {data.length > 0 ? (
              <Table stickyHeader sx={{ minWidth: 650 }} size="small">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="left">Transaktion Nummer</StyledTableCell>
                    <StyledTableCell align="left">RX Nummer</StyledTableCell>
                    <StyledTableCell align="left">Patientenname</StyledTableCell>
                    <StyledTableCell align="left">Motiv</StyledTableCell>
                    <StyledTableCell align="left">Typ</StyledTableCell>
                    <StyledTableCell align="left">Gesamtpreis (€)</StyledTableCell>
                    <StyledTableCell align="left">Erstellungsdatum</StyledTableCell>
                    <StyledTableCell align="left">Erstellt von</StyledTableCell>
                    <StyledTableCell align="left">Kontoname</StyledTableCell>
                    <StyledTableCell align="left">Exportiert</StyledTableCell>
                    <StyledTableCell align="left">T-Konten</StyledTableCell>
                    {transactionType === "rechnung" && (
                      <>
                        <StyledTableCell align="left">Zahlungsstatus</StyledTableCell>
                        <StyledTableCell align="left">Weitere Informationen</StyledTableCell>
                        <StyledTableCell align="left">Cloud Link</StyledTableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableStructure
                  rows={data}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  transactionType={transactionType}
                />
              </Table>
            ) : (
              <Typography sx={{ margin: 2 }}>
                Für die ausgewählten Daten wurden keine Transaktionen gefunden.
              </Typography>
            )}
          </TableContainer>
        </Box>
      </LocalizationProvider>
    </TopLevelPaper>
  );
};

const LedgerTable = observer(LedgerTableComponent);
export default LedgerTable;
