import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
// import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@mui/lab';

import DateFnsUtils from "@date-io/date-fns";
import styled from "styled-components";
import { TimeSlotDto } from "../../api-client";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import useStore from "../../helpers/useStore";
import { AccessTime, Save } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

interface PatientAvailabilityProps {
  patientId: number;
  blockedData: TimeSlotDto[];
  setblockedData: any;
  readOnly?: boolean;
  patientAvailrefresh?:any;
  setPatientAvailrefresh?:any;
}

const StyledTable = styled(Table)`
  width: 100% !important;
  border-collapse: collapse !important;

  & th,
  & td {
    border: 1px solid #dcdcdc !important;
    padding: 7px !important;
    text-align: left !important;
  }
`;

const CustomInput = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PatientAvailability: React.FC<PatientAvailabilityProps> = ({
  patientId,
  blockedData,
  setblockedData,
  readOnly,
  patientAvailrefresh,
  setPatientAvailrefresh
}) => {
  const AppointmentStore = useStore(AppointmentContext);
  const { saveBlockedSlotForPatient, getBlockedSlotsForPatient, deleteBlockedSlot } =
    AppointmentStore;

  // const [data, setData] = useState<TimeSlotDto[]>([]);

  const fetchData = async () => {
    try {
      const fetchedData = await getBlockedSlotsForPatient(patientId);
      setblockedData(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patientAvailrefresh]);

  const handleAddRow = () => {
    const newId = blockedData.length + 1;
    const newRow: TimeSlotDto = {
      //   id: newId.toString() || '',
      start: new Date().toISOString(),
      end: new Date().toISOString(),
      type: "PatientUnvailable",
    };

    setblockedData([...blockedData, newRow]);
  };

  // ...

  const handleCellChange = (field: string, value: any, index: number) => {
    const updatedData = [...blockedData];
    const row = updatedData[index];

    // Convert the custom input value to a Date object
    const parsedValue = new Date(value);

    if (!isNaN(parsedValue.getTime())) {
      if (field === "start.date") {
        const newStartDate = new Date(row?.start);
        newStartDate.setFullYear(parsedValue.getFullYear());
        newStartDate.setMonth(parsedValue.getMonth());
        newStartDate.setDate(parsedValue.getDate());
        row.start = newStartDate.toISOString();
      } else if (field === "start.time") {
        const newStartTime = new Date(row.start);
        newStartTime.setHours(parsedValue.getHours());
        newStartTime.setMinutes(parsedValue.getMinutes());
        row.start = newStartTime.toISOString();
      } else if (field === "end.date") {
        const newEndDate = new Date(row.end);
        newEndDate.setFullYear(parsedValue.getFullYear());
        newEndDate.setMonth(parsedValue.getMonth());
        newEndDate.setDate(parsedValue.getDate());
        row.end = newEndDate.toISOString();
      } else if (field === "end.time") {
        const newEndTime = new Date(row.end);
        newEndTime.setHours(parsedValue.getHours());
        newEndTime.setMinutes(parsedValue.getMinutes());
        row.end = newEndTime.toISOString();
      }
      setblockedData(updatedData);
    } else {
      console.error("Invalid time value");
    }
  };

  // ...

  const handleDelete = async (index: any) => {
    console.log("Index", index, blockedData);
    const deletedRow = blockedData[index];

    // Check if the row has an ID (i.e., it has already been added and saved)
    if (deletedRow.id) {
      // Call your API to delete the row by its ID
      try {
        await deleteBlockedSlot(deletedRow.id);
        if(setPatientAvailrefresh){
          // Toggle the value of patientAvailrefresh
       setPatientAvailrefresh((prevValue:any) => !prevValue);}
        // fetchData();
      } catch (error) {
        console.error("Error deleting row:", error);
        return;
      }
    }

    // If it's a new row or if it was successfully deleted, update the data
    const updatedData = [...blockedData];
    updatedData.splice(index, 1);
    setblockedData(updatedData);
  };

  const handleOk = async () => {
    try {
      const updatedData = blockedData.map((item) => {
        return {
          id: item.id ? item.id : undefined,
          start: new Date(item.start).toISOString(),
          end: new Date(item.end).toISOString(),
          type: "PatientUnvailable",
        };
      });

     await saveBlockedSlotForPatient(patientId, updatedData);
     if(setPatientAvailrefresh){
     // Toggle the value of patientAvailrefresh
     setPatientAvailrefresh((prevValue:any) => !prevValue);}
 
      // fetchData();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  console.log("NewRowDtaa", blockedData);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper} sx={{ maxWidth: "100%", overflow: "hidden" }}>
            <StyledTable>
              <TableHead>
                <TableRow>
                  <th>Startdatum</th>
                  <th>Startzeit</th>
                  <th>Enddatum</th>
                  <th>Endzeit</th>
                  <th>Aktionen</th>
                </TableRow>
              </TableHead>
              <TableBody>
                {blockedData?.map((row, index) => (
                  <TableRow key={row.id}>
                    <td>
                      <CustomInput>
                        <DatePicker
                          // cancelLabel=""
                          // okLabel=""
                          // autoOk={true}
                          value={new Date(row.start)}
                          onChange={(date: any) => handleCellChange("start.date", date, index)}
                          format="dd.MM.yyyy"
                        />
                      </CustomInput>
                    </td>
                    <td>
                      <CustomInput>
                        <TimePicker
                          // KeyboardButtonProps={{
                          //   'aria-label': 'change time',
                          // }}
                          // cancelLabel=""
                          // okLabel=""
                          // autoOk={true}
                          // keyboardIcon={<AccessTime />}
                          value={new Date(row.start)}
                          onChange={(time: any) => handleCellChange("start.time", time, index)}
                          ampm={false}
                        />
                      </CustomInput>
                    </td>
                    <td>
                      <CustomInput>
                        <DatePicker
                          // cancelLabel=""
                          // okLabel=""
                          // autoOk={true}
                          value={new Date(row.end)}
                          onChange={(date: any) => handleCellChange("end.date", date, index)}
                          format="dd.MM.yyyy"
                        />
                      </CustomInput>
                    </td>
                    <td>
                      <CustomInput>
                        <TimePicker
                          // keyboardIcon={<AccessTime />}
                          value={new Date(row.end)}
                          onChange={(time: any) => handleCellChange("end.time", time, index)}
                          ampm={false}
                        />
                      </CustomInput>
                    </td>
                    <td>
                      {!readOnly && ( // Show the delete button if not in read-only mode
                        <DeleteIcon onClick={() => handleDelete(index)} />
                      )}
                    </td>
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
            {!readOnly && ( // Show the "Add Row" button if not in read-only mode
              <Box display="flex" justifyContent="flex-end" p={1}>
                <Button
                  //   variant="contained"
                  //   color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleAddRow}
                >
                  Neu
                </Button>
              </Box>
            )}
          </TableContainer>
        </Grid>
      </Grid>
      <Grid item xs={12} mt={2} sx={{border:"0px solid red"}} display="flex" justifyContent="flex-end">
        {!readOnly && ( // Show the "Save" button if not in read-only mode
          <Button variant="contained" color="primary" startIcon={<Save />} onClick={handleOk}>
            Speichern
          </Button>
        )}
      </Grid>
    </LocalizationProvider>
  );
};

export default PatientAvailability;
