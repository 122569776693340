import { Box, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { AppointmentDto } from "../../../api-client";
import { FC } from "react";
import { formatDateUI } from "../../../helpers/dateFormat";

interface BodyProps {
    patientAppointments: AppointmentDto[],
    selectedRow: any
    patientDetails: any
    totalPrice: number
}

const Body: FC<BodyProps> = ({patientAppointments, selectedRow, patientDetails, totalPrice}) => {

    const doctorName = selectedRow.doctor?.contactInfo?.firstNames + " " + selectedRow.doctor?.contactInfo?.lastName;
    const creationDate = formatDateUI(selectedRow.date) ;
    const patientName = patientDetails.firstName + " " + patientDetails.lastName;
    const salutation = patientDetails.salutation;

    const filteredRx = patientAppointments.filter((item)=>{
        return item.start
    })
    return (
        <div>
            <Typography variant="body2">Guten Tag, {salutation} {patientName}</Typography>
            <p>Ich erlaube mir folgende Leistungen Zu berechnen: <br />
            Behandlungen aufgrund der Verordnung von {doctorName} vom {creationDate} mit Diagnose {selectedRow.icdCodeEntries.map((entry: any, index: number) => (
                    <span key={index}>
                        {entry.userText} - {entry.description}
                        {index < selectedRow.icdCodeEntries.length - 1 && ', '}
                    </span>
                ))} für {patientName} an folgenden Terminen:
            </p>
            <Typography variant="body2">
                {filteredRx.map((item, index) => (
                    <span key={index}>
                        {formatDateUI(item.start)}
                        {index < filteredRx.length - 1 && ', '}
                    </span>
                ))}
            </Typography>

            <TableContainer sx={{ marginTop: '40px' , marginBottom: '30px', }}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', whiteSpace: 'nowrap' }} align="left">
                            Anzahl
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', whiteSpace: 'nowrap' }} align="left">
                            Art der Behandlung
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', whiteSpace: 'nowrap'}} align="left">
                            Einzelpreis €
                        </TableCell>
                        <TableCell sx={{ fontWeight: 'bold', fontSize: '16px', whiteSpace: 'nowrap' }} align="left">
                            Gesamtpreis €
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedRow.therapyRxs.map((entry: any, index: number) => (
                        <TableRow key={index} sx={{ borderBottom: 'none' }}>
                            <TableCell sx={{ fontSize: '16px', borderBottom: 'none', whiteSpace: 'nowrap'}} align="center">
                                {entry.amount}
                            </TableCell>
                            <TableCell sx={{ fontSize: '16px', borderBottom: 'none'}} align="left">
                                {entry.therapy.description}
                            </TableCell>
                            <TableCell sx={{ fontSize: '16px', borderBottom: 'none', whiteSpace: 'nowrap' }} align="right">
                            {Number(entry.therapy.price).toFixed(2).replace('.', ',')}
                            </TableCell>
                            <TableCell sx={{ fontSize: '16px', borderBottom: 'none', whiteSpace: 'nowrap' }} align="right">
                            {(entry.amount * entry.therapy.price).toFixed(2).replace('.', ',')}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: '20px', marginBottom: '10px' }}>
            <Grid container>
            <Grid item xs={3} display="flex" alignItems="left">
                <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>
                Rechnungsbetrag €
                </Typography>
            </Grid>
            <Grid item xs={6}></Grid> {/* Empty grid item to create space */}
            <Grid item xs={3} display="flex" justifyContent="flex-end" alignItems="left">
                <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black', marginRight: '15px' }}>
                {totalPrice.toFixed(2).replace('.', ',')}
                </Typography>
            </Grid>
            </Grid>
        </Box>
        </TableContainer>
        </div>
    );
}

export default Body;