import { attentionColors } from "../themes/mobiliTheme";
import {generateColor} from "./generateColor";

export const backgroundColourCheck=(differenceInWeeksValue:number)=>{
    let backgroundColor = "";
    if (differenceInWeeksValue <= 1) {
      backgroundColor = attentionColors.customInfoPrimary;
    } else if (differenceInWeeksValue <= 2) {
      backgroundColor = attentionColors.lowPriority; // Add your color for <= 2 weeks
    } else if (differenceInWeeksValue <= 3) {
      backgroundColor = attentionColors.highPriority; // Add your color for <= 3 weeks
    } else {
      backgroundColor = attentionColors.immediateAttention; // Add your color for > 3 weeks
    }
    console.log("backgroundColourCheck", backgroundColor,differenceInWeeksValue);
    return backgroundColor;
  }

  

  const rxNumberColorMap: { [key: string]: string } = {};

export const getColorForRxNumber = (rxNumber: string) => {
  // Check if the color for this Rx number is already assigned
  if (rxNumberColorMap[rxNumber]) {
    return rxNumberColorMap[rxNumber];
  }

  // If not assigned, generate a new color using the generateColor function
  const newColor = generateColor(rxNumber);
  rxNumberColorMap[rxNumber] = newColor;
  console.log("NEWCOLO", newColor);
  return newColor;
};