import React from "react";
import { FC } from "react";
import { Typography, Grid, Divider } from "@mui/material";

interface FooterProps {
  address: string;
  phone: string;
}

const Footer: FC<FooterProps> = ({ address, phone }) => {
  return (
    <footer style={{ minHeight: '50px' }}>
      <Divider variant="middle" />
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 1 }}>
          <Typography sx={{fontSize: '8px'}} align="left">
            {address}
          </Typography>
        </div>
        <div>
          <Typography sx={{fontSize: '8px'}} align="right">
            {phone}
          </Typography>
        </div>
      </Grid>
    </footer>
  );
};

export default Footer;
