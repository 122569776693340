import React from "react";
import { FormLabel, Checkbox as MuiCheckbox } from "@mui/material/";
import { InputLabel, FormControl, FormControlLabel } from "@mui/material";
import { fontSize } from "@mui/system";

const Checkbox = (props: any) => {
  const { name, label, value, onChange, disabled,style } = props;

  const convertToDefEventPara = (name: any, value: any) => ({
    target: {
      name,
      value,
    },
  });

  return (
    <FormControl size="small">
      <FormControlLabel
        control={
          <MuiCheckbox
            sx={style?style:{ padding: "0px", paddingLeft: "1px" }}
            name={name}
            checked={value}
            disabled={disabled}
            onChange={(e) => onChange(convertToDefEventPara(name, e.target.checked))}
          />
        }
        label={
          <FormLabel>
            {label}
          </FormLabel>
        }
      />
    </FormControl>
  );
};
export default Checkbox;
