import React, { createRef, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from '@fullcalendar/multimonth'
import { DateSelectArg, DateSpanApi, EventApi, EventClickArg } from '@fullcalendar/core';
import { Event } from "../../../../molecules/Calendar/Calendar.type";
import interactionPlugin from "@fullcalendar/interaction";
import { Box, Button } from '@mui/material';
import AbsenceDialog from '../../../../molecules/Calendar/AbsenceDialog';
import { CalendarContext } from '../../../../stores/Calendar/calendar.provider';
import { AppointmentDto, LeaveOfAbsenceDto } from '../../../../api-client';
import useStore from '../../../../helpers/useStore';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import AddIcon from '@mui/icons-material/Add';
import { mobiliTheme } from '../../../../themes/mobiliTheme';

interface UserCalendarProps {
  userData: any;
}

const UserCalendar: React.FC<UserCalendarProps> = ({ userData }) => {

  let eventGuid = 0;
  const momentRange = extendMoment(Moment);
  const calendarRef = createRef<FullCalendar>();
  const date = moment(new Date()).format("YYYY-MM-DD");
  const [event, setEvent] = useState<Event>();
  const [events, setEvents] = useState<Event[]>([]);
  const [appointments, setAppointments] = useState<Event[]>([]);
  const [absences, setAbsences] = useState<Event[]>([]);
  const [isAddAbsenceDialogOpen, setAddAbsenceDialogOpen] = useState(false);
  const CalendarStore = useStore(CalendarContext);
  const { getUserAbsences, getNextAppointmentsByUserId } = CalendarStore;

  const createEventId = () => {
    return String(eventGuid++);
  };

  useEffect(() => {
    fetchEvents();

  }, []);

  const getDatesBetween = (start: moment.Moment, end: moment.Moment) => {
    const range = momentRange.range(start, end);
    const dates = Array.from(range.by('day'));
    return dates;
  };

  const fetchEvents = async () => {
    const events: Event[] = [];
    try {
      const userId = userData.id;
      const leaveOfAbsences = await getUserAbsences(userId);
      
      const absenceEvents = leaveOfAbsences.map((absence: LeaveOfAbsenceDto) => {
        let event: Event = {
          id: createEventId(),// should be a unique id
          title: absence.loAType || "",
          start: moment(absence.start!).format('YYYY-MM-DD'),
          end: moment(absence.end!).format('YYYY-MM-DDT01:00:00'),
          type: absence.loAType,
          color: mobiliTheme.palette.primary.dark
        };
        return event;
      });
      setAbsences(absenceEvents);
      console.log("absences", absenceEvents);
      events.push(...absenceEvents);

      const appointments = await getNextAppointmentsByUserId(userId);
      console.log("appointments", appointments);

      const uniqueAppointments: Event[] = [];
      const appsEvents = appointments.map((app: AppointmentDto) => {
        let event: Event = {
          id: createEventId(),// should be a unique id
          title: "Termine vergeben",
          start: moment(app.start).format('YYYY-MM-DD'),
          end: moment(app.end).format('YYYY-MM-DD'),
          display: "background",
          color: mobiliTheme.palette.primary.light
        };
        const isDuplicate = uniqueAppointments.some((uniqueApp: Event) => {
          return (
              uniqueApp.start === event.start &&
              uniqueApp.end === event.end
          );
        });
        if (!isDuplicate) {
            uniqueAppointments.push(event);
        }
        return event;
      });
      setAppointments(appsEvents);
      console.log("appointments", uniqueAppointments);
      events.push(...uniqueAppointments);

      console.log("events", events);
      setEvents(events);

    } catch (error) {
      console.log("error", error);
    }
  }

  const handleDateSelect = (selectInfo: DateSelectArg) => {    
    let calendarApi = selectInfo.view.calendar;
    const event: Event = {
      id: createEventId(),
      title: "Urlaub",
      start: moment(selectInfo.start).format('YYYY-MM-DD'),
      end: moment(selectInfo.end).add(-1, 'day').format('YYYY-MM-DD'),
    };
    // calendarApi.addEvent(event);
    setEvent(event);
    setAddAbsenceDialogOpen(true);
    console.log("event added to calendar", event);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const event : Event = {
      id: clickInfo.event.id,
      title: clickInfo.event.title,
      start: moment(clickInfo.event.start).format('YYYY-MM-DD'),
      end: moment(clickInfo.event.end).format('YYYY-MM-DD'),
      type: clickInfo.event.extendedProps.type
    };
    setEvent(event);
    setAddAbsenceDialogOpen(true);
    console.log("event clicked", event);
  };
  
  const handleOpenAddAbsenceDialog = () => {
    setAddAbsenceDialogOpen(true);
  };

  const handleEvents = (events: EventApi[]) => {

  };

  const onAbsenceDialogClose = () => {
    setAddAbsenceDialogOpen(false);
  }

  const onAbsenceDialogConfirm = () => {
    setAddAbsenceDialogOpen(false);
    fetchEvents();
  }

  const handleSelectAllow = (selectInfo: DateSpanApi) => {

    var selectedStartDate = selectInfo.start;
    var selectedEndDate = selectInfo.end;

    // Check if any disabled dates fall within the selected range
    var isDisabled = events.map(event => 
      event.display !== 'background' &&
      getDatesBetween(moment(event.start), moment(event.end))).flat()
      .some(function (disabled) {
        var disabledDate = new Date(disabled.toString());
        return (
          selectedStartDate <= disabledDate &&
          selectedEndDate > disabledDate
        );
      });

    return !isDisabled;
  }
  
  return (
    <>
      <Box display={'flex'} alignItems={'center'}>
        {userData.firstName} {userData.lastName} ({userData.nickName})
        <Button onClick={handleOpenAddAbsenceDialog} startIcon={<AddIcon/>}>Abwesenheit Hinzufügen</Button>
      </Box>
      {isAddAbsenceDialogOpen && 
      <AbsenceDialog
        leaveOfAbsence = {{
          user: {id: userData.id}, 
          start: event?.start,
          end: event?.end,
          loAType: event?.type || "vacation",
        }}
        open={isAddAbsenceDialogOpen}
        event={event!}
        onClose={onAbsenceDialogClose}
        title={"Abwesenheit Hinzufügen"}
        onConfirm={onAbsenceDialogConfirm}
        blockedDays={absences.map(event => getDatesBetween(moment(event.start), moment(event.end))).flat()}
        highlightDays={appointments.map(app => moment(app.start))}
      />}
      <FullCalendar
        ref={calendarRef}
        locale={"de"}
        displayEventTime={false}
        initialDate={date}
        schedulerLicenseKey="0067980715-fcs-1697454050"
        plugins={[
          dayGridPlugin,
          multiMonthPlugin,
          interactionPlugin
        ]}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridWeek,dayGridMonth,multiMonthYear",
        }}
        titleFormat={{ year: "numeric", month: "long" }}
        buttonText={{
          today: "Heute",
          month: "Monat",
          year: "Jahr",
          week: "Woche",
          day: "Tag",
          list: "Liste"
        }}
        initialView="dayGridMonth"
        firstDay={1}
        editable={true}
        selectable={true}
        selectConstraint={{ start: date }}
        selectMirror={true}
        dayMaxEvents={true}
        droppable={true}
        allDaySlot={true}
        events={events}
        selectAllow={handleSelectAllow}
        select={handleDateSelect}
        eventClick={handleEventClick}
        eventsSet={handleEvents} // called after events are initialized/added/changed/removed
      />
    </>
  )
}

export default UserCalendar