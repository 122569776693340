import { action, makeObservable, observable } from "mobx";
import { IPromiseBasedObservable, fromPromise } from "mobx-utils";
import { AppointmentDto, CalendarApi, RxDto, TimeSlotDto, LeaveOfAbsenceDto, EventTimeSlotDto, ContinuousAppointmentDto, ContinuousEventTimeSlotDto } from "../../api-client";
import AuthStore from "../Auth/authStore";
import notificationStore from "../Notification/notificationStore";

export default class CalendarStore {
  public apiClient: CalendarApi;
  public authStore: AuthStore;

  public constructor(apiClient: CalendarApi,authStore: AuthStore) {
    this.apiClient = apiClient;
    this.authStore = authStore;
  }
  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @action
  public getAppointment = async (appointmentId: string) => {
    try {
      const { data } = (await this.apiClient.getAppointmentForCalendar(
        appointmentId
      )) as any;
      return data || [];
    }catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen des Termins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public getUsersByLocation = async (locationId: string) => {
    try {
      const { data } = (await this.apiClient.getUsersByLocationForCalendar(locationId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der Benutzer nach Standort";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public getPatientsByAppointmentDate = async (date: string) => {
    try {
      const { data } = (await this.apiClient.getPatientsByAppointmentDateForCalendar(date)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der Patienten nach Termin";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public createTimeslot= async (body: TimeSlotDto) => {
    try {
      const { data } = (await this.apiClient.postTimeslot(body)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Erstellen des Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public createLeaveOfAbsence = async (leaveOfAbsence: LeaveOfAbsenceDto) => {
    try {
      const { data } = (await this.apiClient.postLeaveOfAbsence(leaveOfAbsence)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Erstellen der Abwesenheit";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public createEventTimeSlot = async (eventTimeSlot: EventTimeSlotDto) => {
    try {
      const { data } = (await this.apiClient.postEventTimeslot(eventTimeSlot)) as any;
      return data || [];
    }catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Erstellen des Ereignis-Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public createContinuousEventTimeSlot = async (eventTimeSlot: ContinuousEventTimeSlotDto) => {
    try {
      const { data } = (await this.apiClient.postContinuousEventTimeslot(eventTimeSlot)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Erstellen des kontinuierlichen Ereignis-Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public saveAppointment = async (id: string, appointment: AppointmentDto) => {
    try {
      const { data } = (await this.apiClient.updateAppointment(
        id,
        appointment
      )) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Speichern des Termins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  public updateTimeSlot = async (id:string, timeSlot:TimeSlotDto):Promise<TimeSlotDto> => {
    try {
      const { data } = (await this.apiClient.updateTimeSlot(id, timeSlot)) as any;
      return data as TimeSlotDto;
    } catch (error: any) {
      console.error("Error updating time slot:", error);
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Speichern des Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
      return {} as TimeSlotDto;
    }
  }

  public updateContinuousTimeSlot = async (id:string, timeSlot:TimeSlotDto):Promise<TimeSlotDto> => {
    try {
      const { data } = (await this.apiClient.updateContinuousTimeSlot(id, timeSlot)) as any;
      return data as TimeSlotDto;
    }  catch (error: any) {
      console.error("Error updating continuous time slot:", error);
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Speichern des kontinuierlichen Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
      return {} as TimeSlotDto;
    }
  }

  @action
  public getAppointmentsByDateAndLocation = async (date: string, locationId: string, weeklyWorkDay: boolean = false) => {
    try {
      const { data } = (await this.apiClient.getAppointmentsByDateAndLocationForCalendar(date, locationId, weeklyWorkDay)) as any;
      return data || [];
    }  catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der Termine nach Datum und Standort";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  };

  @action
  public getUnscheduledPatientAppointments = async (patientId: string) => {
    try {
      const { data } = (await this.apiClient.getUnscheduledAppointmentsForPatient(patientId)) as any;
      return data || [];
    }  catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der nicht geplanten Termine des Patienten";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action 
  public getUnscheduledContinuousAppointments = async (patientId: string) => {
    try {
      const { data } = (await this.apiClient.getUnscheduledContinuousAppointmentsForPatient(patientId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der nicht geplanten kontinuierlichen Termine des Patienten";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public getContinuousAppointmentsByLocation = async (locationId: string) => {
    try {
      const { data } = (await this.apiClient.getContinuousAppointmentsByLocation(locationId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der kontinuierlichen Termine nach Standort";
      notificationStore.showMessage(errorMessage, "error", error);
      console.error("Error fetching continuous appointments:", error);
    }
  }

  @action
  public getContinuousAppointmentsForPatient = async (patientId: string) => {
    try {
      const { data } = (await this.apiClient.getContinuousAppointmentsForPatientCalendar(patientId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der kontinuierlichen Termine für den Patienten";
      notificationStore.showMessage(errorMessage, "error", error);
      console.error("Error fetching continuous appointments:", error);
    }
  }

  @action 
  public getContinuousAppointmentsForUser = async (userId: string) => {
    try {
      const { data } = (await this.apiClient.getContinuousAppointmentsForUserCalendar(userId)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der kontinuierlichen Termine für den Benutzer";
      notificationStore.showMessage(errorMessage, "error", error);
      console.error("Error fetching continuous appointments:", error);
    }
  }

  @action
  public getContinuousAppointment = async (id: string) => {
    try {
      const { data } = (await this.apiClient.getContinuousAppointment(id)) as any;
      return data || [];
    } catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen des kontinuierlichen Termins";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action
  public deleteTimeslot = async (timeslotId: string) => {
    try {
      const { data } = (await this.apiClient.deleteTimeslot(timeslotId)) as any;
      return data || [];
    }  catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Löschen des Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  @action deleteContinuousTimeslot = async (timeslotId: string) => {
    try {
      const { data } = (await this.apiClient.deleteContinuousTimeSlot(timeslotId)) as any;
      return data || [];
    }catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Löschen des kontinuierlichen Zeitfensters";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  public therapistSick = async (userId: string, date: Date):Promise<number|undefined>=> {
    try {
      // const { data } = (await this.apiClient.therapistSick(userId, date.toUTCString(),date.toUTCString())) as any;
      return 1 as number ;
    } catch (error:any) {
      this.resourceRequest = fromPromise.reject(error);
      console.log(error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Krankmeldung";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }
  public getRxForTimeslot = async (timeslotId: string):Promise<RxDto> => {
    try {
      const { data } = (await this.apiClient.getRxForTimeSlot(timeslotId)) as any;
      return data ;
    } catch (error) {
      this.resourceRequest = fromPromise.reject(error);
      notificationStore.showMessage("Fehler beim Laden des Rezepts", "error", error);
      return {} as RxDto;
    }
  }

  public userAbsence = async (userId: string, leaveOfAbsence: LeaveOfAbsenceDto):Promise<LeaveOfAbsenceDto|undefined> => {
    try {
      const { data } = (await this.apiClient.userAbsence(userId, leaveOfAbsence)) as any;
      return data as LeaveOfAbsenceDto;
    }
    catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      console.log(error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Abwesenheit Hinzufügen";
      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

  public getUserAbsences = async (userId: string):Promise<LeaveOfAbsenceDto[]> => {
    try {
      const { data } = (await this.apiClient.getUserAbsences(userId)) as any;
      return data as LeaveOfAbsenceDto[];
    }
    catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      console.log(error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Abwesenheit";
      notificationStore.showMessage(errorMessage, "error");
      return [];
    }
  }

  public getNextAppointmentsByUserId = async (userId: string):Promise<AppointmentDto[]> => {
    try {
      const { data } = (await this.apiClient.getNextAppointmentsByUserId(userId)) as any;
      return data as AppointmentDto[];
    }
    catch (error: any) {
      this.resourceRequest = fromPromise.reject(error);
      console.log(error);
      const errorMessage = error.response?.data?.message || "Fehler beim Abrufen der nächsten Termine";
      notificationStore.showMessage(errorMessage, "error", error);
      return [];
    }
  }

}


