import React from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { mobiliTheme, therapyColors } from "../themes/mobiliTheme";
import { Box, Tooltip } from "@mui/material";

const GenericChip = ({ label, onDelete, sx, ...props }: any) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      sx={
        sx
          ? sx
          : {
              width: "100%",
              wordWrap: "break-word",
              border: "3px solid blue", // Add a border for debugging
              padding: "0px",
            }
      }
      {...props}
    />
  );
};


export const SmallChip = ({  sx, ...props }: any) => {
  let bgColor: string = mobiliTheme.topLevelBackground;
  const defaultSx = {
    width: "100%",
    wordWrap: "break-word",
    border: "0px solid blue", // Add a border for debugging
    padding: "0px",
    fontSize: "0.6em",
    borderRadius: ".9em",
    backgroundColor: bgColor,
    "& .MuiChip-label": {
      // Targeting the label specifically
      padding: "6px", // Adjust the padding as needed
    },
  }

  return (
      <Chip
        sx={
          sx
            ? { ...defaultSx, ...sx }
            : defaultSx
        }
        {...props}
      />
  );
};


export const TherapyChip = ({ therapyName, amount, sx, ...props }: any) => {
  const colorMap = therapyColors as any;

  let bgColor: string = colorMap[therapyName] || "grey";

  const defaultSx = {
    backgroundColor: bgColor,
  }

  return (
    <Tooltip title={therapyName}>
      <Box>
      <SmallChip
        label={amount ? `${amount} ${therapyName}` : therapyName}
        sx={
          sx
            ? { ...defaultSx, ...sx }
            : defaultSx
        }
        {...props}
      /></Box></Tooltip>
   
  );
};

// const GenericChipList = ({ chips }:any) => {
//   return (
//     <Stack direction="row" spacing={1}>
//       {chips.map((chip:any, index:any) => (
//         <GenericChip
//           key={index}

//           label={chip.label}
//           onDelete={chip.onDelete ? () => chip.onDelete(index) : undefined}
//           {...chip.props}
//         />
//       ))}
//     </Stack>
//   );
// };

export default GenericChip;
