import React from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { FC } from "react";

interface SearchBoxProps{
  searchString: string;
  setSearchString:(value: string) => void;
}

const SearchBox:FC<SearchBoxProps> = ({ searchString, setSearchString }) => {
  return (
      <Paper
          component="form"
          sx={{ display: 'flex', alignItems: 'center', width: 200, py:0.6 }}
          elevation={2}
      >
          <InputBase
              sx={{ ml: 1, flex: 1}}
              placeholder="Suchen"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
              inputProps={{ 'aria-label': 'search transaction' }}
          />
      </Paper>
  );
}

export default SearchBox;