import React from "react";
import { Card, CardHeader, CardContent, Theme, SxProps } from "@mui/material";
import { mobiliTheme } from "../themes/mobiliTheme";

interface FlatCardProps {
  title: string | React.ReactNode;
  subtitle?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>; // Add this line to allow sx props
  sxContent?: SxProps<Theme>; // Add this line to allow sx props
  fullHeight?: boolean;
}

const FlatCard: React.FC<FlatCardProps> = ({
  title,
  subtitle,
  children,
  sx,
  sxContent,
  fullHeight,
}) => {
  return (
    <Card
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        border: "none",
        height: fullHeight ? "100%" : "auto",
        display: "flex",
        flexDirection: "column",
        padding: 0,
        ...sx, // Spread the sx prop to allow custom styles to be injected
      }}
    >
      <CardHeader
        title={title}
        subheader={subtitle}
        sx={{
          textAlign: "left",
          pb: 0,
          backgroundColor: "transparent !important", // Overrides any other styles
          ".MuiCardHeader-title": {
            backgroundColor: "transparent !important", // Specific override for the title
          },
          ".MuiCardHeader-subheader": {
            backgroundColor: "transparent !important", // Specific override for the subheader
          },
        }}
      />
      <CardContent
        sx={{
          flex: 1,
          // backgroundColor: mobiliTheme.lowLevelContainerBackground,
          backgroundColor: "white",
          border: "1px solid black",
          borderRadius: 2, // theme.shape.borderRadius defaults to 4px; 1 represents 4px when using the `sx` prop.
          p: 2,
          // height: fullHeight ? '100%' : 'auto',
          ...sxContent, // Spread the sxContent prop to allow custom styles to be
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default FlatCard;
