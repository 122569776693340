import React, { useEffect, useState } from "react";
import {  FormControl, FormHelperText, IconButton, InputLabel, Typography, TextField as MuiTextField, Autocomplete } from "@mui/material";
import { Close } from "@mui/icons-material";


const Select = (props: any) => {
  const formControlStyle = {
    // width: "500px",
    // marginBottom: "1px",
    // height:"50px",
    // marginTop: "0px",
 
  };

  const {
    name,
    label,
    value,
    error = null,
    required,
    onChange,
    options = [],
    disabled,
    id,
    handleSearchCancel,
    style,
    onBlur,
    placeholder,
    showOptionLabel = false,
  } = props;
  
  const [selectedValue, setSelectedValue] = useState("");

  const handleCancelIconClick = () => {
    setSelectedValue(""); // Clear the selected value
    handleSearchCancel(); // Call the handleSearchCancel function
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    const updatedEvent = {
      target: {
        name,
        value: selectedValue,
      },
    };
    setSelectedValue(selectedValue); // Update the selectedValue state
    onChange(updatedEvent);
  };

  
  
  const handleKeyDown = (event:any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const inputValue = event.target.value.trim();
      const filteredOptions = options.filter((option:any) =>
      option.value?.toLowerCase().includes(inputValue?.toLowerCase()) ||
      option.label.toLowerCase().includes(inputValue?.toLowerCase())
      );
      if (filteredOptions?.length === 1) {
        const selectedOption = filteredOptions[0];
        const event = {
          target: {
            name,
            label: selectedOption.label,
            value: selectedOption.value,
            id: selectedOption.id,
          },
        };
        setSelectedValue(selectedOption.value);
        onChange(event);
      }
    }
  };
  

  
  
  useEffect(() => {
    // Update the selectedValue state whenever the value prop changes, but only if the id prop is not present
    if (!id && value!=="" || !(options?.find((option:any) => option.id === value && option.value!==value)) ){
      setSelectedValue(value);
    }
  }, [value, id]);
  

const valueSelected=selectedValue;
const selectedOption = options?.find((option:any) => option.value === valueSelected);

  return (
    <FormControl sx={{display : "flex"}} style={style?style:formControlStyle} {...(error && { error: true })}>
      <Autocomplete
       onBlur={(event:any) => {
        if(!onBlur){
        
        const inputValue = event.target.value.trim();
        console.log('onBlur',inputValue)
        const matchedOption = options.find((option:any) =>
          option.value.toLowerCase() === inputValue.toLowerCase()
        );
        if (!matchedOption) {
          // Clear the selected value if no option matches the typed text
          setSelectedValue(" ");
        }}
      }}
      componentsProps={style ? { popper: { sx: { flexGrow:1 } } } : {}}
       style={{ paddingRight: '1px',margin:"0px"  }}
        options={options}
        getOptionLabel={(option: any) =>
          showOptionLabel
            ? option.label
            :
          option.value !== option.label && option.value !==null
            ? option.value + " (" + option.label + ")"
            : option.label
        }
        isOptionEqualToValue={(option:any, value:any) => option.value === value.value}
        renderInput={(params) => (
          <MuiTextField
            {...params}
            onKeyDown={handleKeyDown}
            sx={{margin:"0px"}}
            label={label?
              <>
                {label}
                {required && (
                  <Typography component="span" variant="body2" color="error" style={{  }}>
                 
                    * 
                 {error && <div style={{ color: 'red' }}>{error}</div>}
                  </Typography>
                )}
              </>:""
               
            }
            variant="standard"
            name={name}
            value={valueSelected} // Use selectedValue instead of value to display the selected value in the UI
            placeholder={placeholder}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              ...params.inputProps,
              value: showOptionLabel ? selectedOption?.label : valueSelected || "", // Use selectedValue to display the selected value in the input
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {!handleSearchCancel ? params.InputProps.endAdornment : ""}
                  {valueSelected !== "" && handleSearchCancel && (
                    <IconButton onClick={handleCancelIconClick} style={{alignContent:"end"}}>
                      <Close />
              </IconButton>
                  )}
                </>
              ),
            }}
          />
        )}

        onChange={(_, newValue) => {
          const event = {
            target: {
              name,
              label:newValue ? newValue.label : "",
              value: newValue ? newValue.value : "",
              id: newValue ? newValue.id : "",
            },
          };
          setSelectedValue(newValue ? newValue.value : ""); // Update the selectedValue state
          onChange(event);
        }}
        disabled={disabled}
      />
      {/* {error && <FormHelperText>{error}</FormHelperText>} */}
    </FormControl>
  );
};

export default Select;
