import { format } from "date-fns";
import { de } from "date-fns/locale";
// 3 letter month abbreviations in german:
const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"];

const daysOfWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];

export const formatTime = (timestamp: string) => {
  const date = new Date(timestamp);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};

export const formatDateUI = (date: any, continous?: boolean | undefined) => {
  const inputDate = new Date(date);
  const day = String(inputDate.getUTCDate()).padStart(2, "0");
  const year = inputDate.getUTCFullYear() - 2000;
  // const month = months[inputDate.getUTCMonth()];
  const month = inputDate.getUTCMonth() + 1;
  const dayOfWeek = daysOfWeek[inputDate.getUTCDay()];

  if (continous) {
    return dayOfWeek;
  } else {
    return `${dayOfWeek} ${day}.${month}.${year}`;
  }
};

const formatDate = (date: any) => {
  if (typeof date === "string") {
    // Check if the date is in "yyyy-mm-dd" format
    if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    }
    // If it's not in the expected format, you may need additional parsing logic
    // Handle other date formats here
  } else if (date instanceof Date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  return "Invalid Date";
};
export default formatDate;
