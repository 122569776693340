import React, { useEffect, useState } from "react";
import WeeklyCalendar from "../../../../molecules/Calendar/WeeklyCalendar";

import { UserContext } from "../../../../stores/User/User.provider";
import useStore from "../../../../helpers/useStore";
import notificationStore from "../../../../stores/Notification/notificationStore";
import { WeeklyWorkDayDto, WorkPlanDto } from "../../../../api-client";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";

import { Event } from "../../../../molecules/Calendar/Calendar.type";
import moment, * as Moments from "moment";

import { extendMoment } from "moment-range";
import WeeklyWorkDays from "./WeeklyWorkDays";
import WorkPlans from "./WorkPlans";
import { DateSelectArg } from "@fullcalendar/core";
import { mobiliTheme } from "../../../../themes/mobiliTheme";
import { TopLevelPaper } from "../../../../themes/StyledComponents";
const momentRange = extendMoment(Moments);
interface WeeklyUserCalendarProps {
  userData: any;
}

const WeeklyUserCalendar: React.FC<WeeklyUserCalendarProps> = ({ userData }) => {
  let eventGuid = 0;
  moment.locale("de");

  const [workPlanOutdated, setWorkPlanOutdated] = useState<boolean>(false);
  const [workPlanStatusLoading, setWorkPlanStatusLoading] = useState<boolean>(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [startDate, setStartDate] = useState<Moments.Moment | null>(moment());
  const [endDate, setEndDate] = useState<Moments.Moment | null>(moment());
  const [range, setRange] = useState<Moments.Moment[] | null>();
  const formattedStartDate = startDate?.format("DD MMM YYYY");
  const formattedEndDate = endDate?.format("DD MMM YYYY");
  const [workPlanId, setWorkPlanId] = useState<number>();
  const [weeklyWorkDayKey, setWeeklyWorkDayKey] = useState<number>(0);
  const UserStore = useStore(UserContext);
  const {
    getWeeklyWorkDaysByWorkPlan,
    createWeeklyWorkDay,
    updateWeeklyWorkDay,
    fetchIsWorkPlanOutdated,
    fillSlotsForWorkPlan,
  } = UserStore;

  const createEventId = () => {
    return String(eventGuid++);
  };

  const fetchWorkPlanStatus = async (workPlanId: number) => {
    setWorkPlanStatusLoading(true);
    const isOutdated = await fetchIsWorkPlanOutdated(workPlanId);
    setWorkPlanOutdated(isOutdated);
    setWorkPlanStatusLoading(false);
  };

  const initializeWorkPlan = async (workPlanId: number) => {
    setWorkPlanStatusLoading(true);
    await fillSlotsForWorkPlan(workPlanId);
    notificationStore.showMessage("Arbeitsplan erfolgreich initialisiert", "success");
    fetchWorkPlanStatus(workPlanId);
  };

  const fetchEvents = async (workPlanId: number) => {
    const weeklyWorkDays = await getWeeklyWorkDaysByWorkPlan(userData.id, workPlanId);
    const events = weeklyWorkDays.map((workDay: WeeklyWorkDayDto) => {
      let event: Event = {
        id: workDay.id!.toString(),
        title: workDay.isBreak ? "Pause" : "Arbeitszeit",
        start: workDay.startTime!,
        end: workDay.endTime,
        startTime: moment(workDay.startTime).format("HH:mm:ss"),
        endTime: moment(workDay.endTime).format("HH:mm:ss"),
        daysOfWeek: [workDay.dayOfWeek?.toString()!],
        color: workDay.isBreak
          ? mobiliTheme.palette.info.light
          : mobiliTheme.palette.secondary.main,
      };
      return event;
    });
    setEvents(events);
    console.log("events", events);
  };

  const setRangeDates = () => {
    if (startDate && endDate) {
      const range = momentRange.range(startDate!, endDate!);
      const dates: any = Array.from(range.by("day"));
      setRange(dates);
    }
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moments.Moment | null;
    endDate: Moments.Moment | null;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setRangeDates();
    // handleInputChange("from", startDate?.format("YYYY-MM-DD"));
    // handleInputChange("to", endDate?.format("YYYY-MM-DD"));
  };

  const handleWorkPlanClick = (workPlan: WorkPlanDto) => {
    let workPlanId = workPlan.id!;
    setWorkPlanId(workPlanId);
    fetchEvents(workPlanId);
    console.log("workPlan clicked", workPlan);
  };

  const renderCalendar = () => {
    fetchEvents(workPlanId!);
    if (workPlanId) {
      fetchWorkPlanStatus(workPlanId);
    }
  };

  const handleWorkPlanDelete = (workPlan: WorkPlanDto) => {
    setWorkPlanId(undefined);
    setEvents([]);
    console.log("workPlan deleted", workPlan);
  };

  const handleCreateEvent = async (event: DateSelectArg) => {
    if (!workPlanId) {
      notificationStore.showMessage("Bitte wähle einen Arbeitsplan aus", "error");
      return;
    }
    console.log("create event clicked", event);
    const weeklyWorkDay: WeeklyWorkDayDto = {
      workPlan: { id: workPlanId },
      dayOfWeek: new Date(event.start).getDay(),
      startTime: new Date(event.start).toISOString(),
      endTime: new Date(event.end)?.toISOString(),
      employeeId: userData.id,
    };
    const result = await createWeeklyWorkDay(weeklyWorkDay);
    if (result) {
      renderCalendar();
      setWeeklyWorkDayKey(weeklyWorkDayKey + 1); // to refresh the weekly work days
      notificationStore.showMessage("Arbeitszeit erfolgreich hinzugefügt", "success");
    }
    fetchIsWorkPlanOutdated(workPlanId);
  };

  const handleChangeEvent = async (event: Event) => {
    if (!workPlanId) {
      notificationStore.showMessage("Bitte wähle einen Arbeitsplan aus", "error");
      return;
    }

    const weeklyWorkDay: WeeklyWorkDayDto = {
      id: parseInt(event.id!),
      workPlan: { id: workPlanId },
      dayOfWeek: new Date(event.start).getDay(),
      startTime: new Date(event.start).toISOString(),
      endTime: new Date(event.end!)?.toISOString(),
      employeeId: userData.id,
    };
    console.log("update weeklyWorkDay", weeklyWorkDay);
    const result = await updateWeeklyWorkDay(weeklyWorkDay);
    console.log("update result", result);
    if (result) {
      setWeeklyWorkDayKey(weeklyWorkDayKey + 1); // to refresh the weekly work days
      notificationStore.showMessage("Arbeitszeit erfolgreich aktualisiert", "success");
    }
    fetchIsWorkPlanOutdated(workPlanId);
  };

  useEffect(() => {
    if (workPlanId) {
      fetchWorkPlanStatus(workPlanId);
    }
  }, [workPlanId]);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={2}>
          <Grid item sx={{ textAlign: "left" }}>
            <WorkPlans
              user={userData}
              onItemClick={handleWorkPlanClick}
              onDeleted={handleWorkPlanDelete}
            />
          </Grid>
          <Grid item sx={{ textAlign: "left" }}>
            {workPlanId && (
              <WeeklyWorkDays
                key={weeklyWorkDayKey}
                user={userData}
                workPlanId={workPlanId}
                onItemChange={renderCalendar}
              />
            )}
          </Grid>
          <Grid item sx={{ textAlign: "left" }}>
            {workPlanId && (
              <>
                {workPlanOutdated ? (
                  <>
                    <Typography>
                      {workPlanStatusLoading
                        ? "wird überprüft"
                        : "Arbeitsplan muss initialsiert werden"}
                    </Typography>
                    <Button
                      onClick={() => initializeWorkPlan(workPlanId)}
                      disabled={workPlanStatusLoading}
                      variant="contained"
                      color="primary"
                    >
                      {workPlanStatusLoading ? <CircularProgress size={24} /> : "Initialisieren"}
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography>
                      {workPlanStatusLoading ? "wird überprüft" : "Arbeitsplan ist startklar"}
                    </Typography>
                    <Button disabled variant="contained">
                      Initialisieren
                    </Button>
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      {workPlanId && (
        <TopLevelPaper>
          <WeeklyCalendar
            events={events}
            // onDatesSet={() => {}}
            onCreateEvent={handleCreateEvent}
            onChangeEvent={handleChangeEvent}
            // onRemoveEvent={() => {}}
            // onOutdated={() => {}}
          />
        </TopLevelPaper>
      )}
    </>
  );
};

export default WeeklyUserCalendar;
