import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, OutlinedInput, TextField, Toolbar } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { CreateDoctorDto } from '../../api-client';
import useStore from '../../helpers/useStore';
import { DoctorContext } from '../../stores/Doctor/doctor.provider';
import Controls from '../../atoms/Controls';
import WarningDialog from '../../atoms/WarningDialog';
import { TopLevelPaper } from '../../themes/StyledComponents';


// Function to fetch doctor information based on search query (you need to implement this)
// const fetchDoctorInfo = async (searchQuery) => {
//   // Make an API call or query the database to fetch doctor information based on searchQuery
//   // Return the doctor information if found, or null if not found
// };
interface DoctorDataEntryDialogProps {
  handleCloseAddEditDialog: (data?: any) => void;
    openAddEditDialog:boolean;
    doctorOptions:any;
    selectedLANR: string | null;
    selectedBSNR: string | null;

  }

const DoctorDialog:React.FC<DoctorDataEntryDialogProps> = ({openAddEditDialog,handleCloseAddEditDialog,doctorOptions,selectedLANR,selectedBSNR}) => {
  const [searchQuery, setSearchQuery] = useState('');

  const DoctorStore = useStore(DoctorContext);
  const { addUpdateDoctor, searchDoctor } = DoctorStore;
  const [optionSelected, setOptionSelected] = useState(false); // New state to track option selection
  const [unsavedChangesWarning,setShowunsavedChangesWarning]=useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to track unsaved changes
  const handleCloseDoctor = () => {
    if (unsavedChanges) {
      // If there are unsaved changes, set a state to open the warning dialog
      setShowunsavedChangesWarning(true);
    } else {
      handleCloseAddEditDialog()
    }
  };
  
  const handleUnsavedChangesWarning = () => {

    handleDoctorSubmit()
   
  };
  
  const handleCloseUnsavedChangesWarning = () => {
    handleCloseAddEditDialog();
    // If user cancels and chooses not to continue, close the warning dialog
    setShowunsavedChangesWarning(false);
  };
  const handleDoctorSubmit = async (event?: any) => {
    if(event){
    event.preventDefault(); }// Prevent default form submission behavior
    try {
    const response=  await addUpdateDoctor(doctorForm);
setUnsavedChanges(false);
      
    // Close the dialog and send the data back to parent component
    if(response){
      handleCloseAddEditDialog(response);}
      // handleCloseAddEditDialog();
      setDoctorFormValues({
        number: 0,
        clinicNumber: 0,
        contactInfo: {
          salutation:"",
          firstNames: "",
          lastName: "",
          phone1: "",
          fax: "",
          mobile: "",
          addressLine1: "",
          city: "",
          zip: "",
          email: "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };
   // Set default values for the text fields when doctorForm is empty
   const defaultDoctorFormValues: CreateDoctorDto = {
    number: selectedLANR as unknown as number || 0,
    clinicNumber: selectedBSNR as unknown as number || 0,
    contactInfo: {
      salutation:"",
      firstNames: "",
      lastName: "",
      phone1: "",
      fax: "",
      mobile: "",
      addressLine1: "",
      city: "",
      zip: "",
      email: "",
    },
  }; 

  const [doctorForm, setDoctorFormValues] = useState<CreateDoctorDto>(defaultDoctorFormValues);
  const hasMounted = useRef(false); 
  const fetchDoctorInfo = async (searchQuery:any) => {
    try {
     const searchDto=  {doctorNumber: searchQuery?.match(/^\w+/)?.[0] || "",
        lastName: searchQuery?.match(/\(([^)]+)\)/)?.[1] || "",}
      const response = await searchDoctor(searchDto);
      return response;
    } catch (error) {
      console.error(error);
      return null;
    }
  };
  useEffect(() => {
    if (hasMounted.current) {
      setUnsavedChanges(true);
    } else {
      hasMounted.current = true;
    }
  }, [doctorForm]);

  const handleDoctorSearch = async () => {
    try {
      const doctorInfo = await fetchDoctorInfo(searchQuery);
      if (doctorInfo) {
        setDoctorFormValues(doctorInfo); // Assuming doctorInfo has the same shape as CreateDoctorDto
      } else {
        // Handle doctor not found case if needed
        console.log('Doctor not found.');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const doctorData = doctorOptions.map(
    (doctor: any) => `${doctor.number} (${doctor.contactInfo.lastName})`
  );
const handleSelectChange = (event: any) => {
    if(event.target.value){
    setSearchQuery(event.target.value);

    // Check if the entered value matches any option in the doctorData array
    const enteredValue = event.target.value.trim();
    const optionExists = doctorData.some((option:any) => option.toLowerCase() === enteredValue.toLowerCase());

    setOptionSelected(optionExists); // Set optionSelected to true if the entered value matches an option
    }
    else{
        setDoctorFormValues(defaultDoctorFormValues);
    }
  };

  // Add useEffect block to call handleDoctorSearch when optionSelected is true
  useEffect(() => {
    if (optionSelected) {
      handleDoctorSearch();
      setOptionSelected(false); // Reset optionSelected to false after calling handleDoctorSearch
    }
  }, [optionSelected]);

    // Add useEffect to fetch and populate doctor data based on selectedBSNR
    useEffect(() => {
      if (selectedBSNR) {
        const selectedDoctor = doctorOptions.find(
          (doctor: any) => doctor.clinicNumber===Number(selectedBSNR)
        );
  
        if (selectedDoctor) {
          const selectedContactInfo = {
            tel: selectedDoctor.contactInfo?.phone1 || '',
            fax: selectedDoctor.contactInfo?.fax  || '',
            addressLine1: selectedDoctor.contactInfo?.addressLine1  || '',
            city: selectedDoctor.contactInfo?.city  || '',
            zip: selectedDoctor.contactInfo?.zip  || '',
            email: selectedDoctor.contactInfo?.email  || '',
          };
          setDoctorFormValues({
           ...doctorForm,
            clinicNumber: selectedDoctor.clinicNumber,
            contactInfo: {
              ...doctorForm.contactInfo, // Keep existing properties
              ...selectedContactInfo,    // Add the selectedContactInfo properties
            },
          });
        }
      }
    }, [selectedBSNR, doctorOptions]);
  const handleCancelSearch = () => {
    setSearchQuery(''); // Reset the searchQuery to empty string
    setDoctorFormValues(defaultDoctorFormValues); // Clear the search results
  };
  return (
    <Dialog open={openAddEditDialog} onClose={handleCloseDoctor} > 
      <DialogTitle>Hinzufügen/Bearbeiten Neu Arzt</DialogTitle>
      <DialogContent>
      <Toolbar sx={{ width: '500px' }}>
          <Controls.Select
            label="Arzt suchen"
            value={searchQuery}
            onChange={handleSelectChange}
            options={doctorData.map((option: any) => {
              return {
                label: option,
                value: option,
              };
            })}
            handleSearchCancel={handleCancelSearch}
          />
          {/* <Button onClick={handleDoctorSearch}>Search Doctor</Button> */}
        </Toolbar>
        <DialogContent>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            label="LANR"
                            value={doctorForm.number}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                number: event.target.value as any,
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          <TextField
                            label="BSNR"
                            value={doctorForm.clinicNumber|| ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                clinicNumber: event.target.value as any,
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                           <Controls.Select
                  name="salutation"
                  label="Anrede"
                  value={doctorForm.contactInfo?.salutation || ''}
                  onChange={(event:any) =>
                    setDoctorFormValues({
                      ...doctorForm,
                      contactInfo: {
                        ...doctorForm.contactInfo,
                        salutation: event.target.value,
                      },
                    })
                  }
                  style={{width: "230px",  border:"1px solid #cccc",height:"60px",padding:"3px"}}
                  options={[
                    { label: "No Title", value: null },
                    { label: "Frau", value: "Frau" },
                    { label: "Herr", value: "Herr" },
                    { label: "Frau Dr.", value: "Frau Dr." },
                    { label: "Herr Dr.", value: "Herr Dr." },
                    { label: "Frau Dr. med.", value: "Frau Dr. med." },
                    { label: "Herr Dr. med.", value: "Herr Dr. med." },
                    { label: "Frau Prof. Dr.", value: "Frau Prof. Dr." },
                    { label: "Herr Prof. Dr.", value: "Herr Prof. Dr." },
                    { label: "Frau Prof. Dr. med.", value: "Frau Prof. Dr. med." },
                    { label: "Herr Prof. Dr. med.", value: "Herr Prof. Dr. med." }
                  ]}
                  onBlur={true}
                />
                          <TextField
                            label="Vorname"
                            value={doctorForm.contactInfo?.firstNames || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  firstNames: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />

                          <TextField
                            label="Nachname"
                            value={doctorForm.contactInfo?.lastName || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  lastName: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          {/* Additional fields for contact information */}
                          <TextField
                            label="Telefon"
                            value={doctorForm.contactInfo?.phone1 || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  phone1: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          <TextField
                            label="Fax"
                            value={doctorForm.contactInfo?.fax || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  fax: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                        </Grid>
                        {/* Second column */}
                        <Grid item xs={6}>
                          <TextField
                            label="Mobil"
                            value={doctorForm.contactInfo?.mobile || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  mobile: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          <TextField
                            label="Anschrift Zeile 1"
                            value={doctorForm.contactInfo?.addressLine1 || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  addressLine1: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          {/* <TextField label="Address Line 2"  value={doctorForm.contactInfo.addressLine2}
                        onChange={(event) =>
                          setDoctorFormValues({
                            ...doctorForm,
                            contactInfo: {
                              ...doctorForm.contactInfo,
                              addressLine2: event.target.value,
                            },
                          })
                        }/> */}
                          <TextField
                            label="Ort"
                            value={doctorForm.contactInfo?.city || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  city: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          <TextField
                            label="PLZ"
                            value={doctorForm.contactInfo?.zip || ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  zip: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          <TextField
                            label="Email"
                            value={doctorForm.contactInfo?.email|| ''}
                            onChange={(event) =>
                              setDoctorFormValues({
                                ...doctorForm,
                                contactInfo: {
                                  ...doctorForm.contactInfo,
                                  email: event.target.value,
                                },
                              })
                            }
                            fullWidth
                            margin="normal"
                            InputProps={{
                              style: {
                                height: '50px', // Adjust the height as needed
                              },
                            }}
                          />
                          {/* </Box> */}
                        </Grid>
                      </Grid>
                    </DialogContent>
      </DialogContent>
      <DialogActions>
        <Button  variant="text" onClick={handleCloseDoctor}>Abbrechen</Button>
        <Button onClick={handleDoctorSubmit} color="primary" variant="contained">
          Speichern
        </Button>
      </DialogActions>
      {unsavedChangesWarning && (
      <WarningDialog
        open={true} // Ensure the warning dialog is open
        onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
        onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
        title="Änderungen speichern?"
        content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
        isConfirmSave

      />
    )}
    </Dialog>
  );
};
export default DoctorDialog;
