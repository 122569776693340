import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './App';
import { AuthProvider } from './stores/Auth/auth.provider';
import { UserProvider } from './stores/User/User.provider';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { MasterTablesProvider } from './stores/MasterTables/MasterTables.provider';
import { TicketsStoreProvider } from './stores/Ticketing/tickets.provider';



ReactDOM.render(<BrowserRouter><AuthProvider><UserProvider><MasterTablesProvider><App/></MasterTablesProvider></UserProvider></AuthProvider></BrowserRouter>,document.getElementById('root'));


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
