import { therapyColors } from "../themes/mobiliTheme";

const colors = [
  '#e67e22',
  '#9b59b6',
  '#ff6f61',
  '#1abc9c',
  '#d35400',
  '#16a085',
  '#d3b0da',
  '#c0392b',
  '#27ae60',
  '#f39c12',
  '#d35400',
  '#8e44ad',
  '#f368e0',
  '#7f8c8d',
  '#34495e',
  '#e84393',
  '#3498db',
  '#e74c3c',
  '#2ecc71',
  '#f1c40f',
  
];

export function generateColor(name: string) {
  // if (name.length !== 3) {
  //   return 'default';
  // }


  const hash = hashCode(name);
  const colorIndex = hash % colors.length;
  return colors[colorIndex];
}


export function mapTherapyToColor(therapyAbbr: string) {
  const colorMap = therapyColors as any;
  let bgColor: string = colorMap[therapyAbbr] || "grey";
  return bgColor;
}

function hashCode(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return Math.abs(hash);
}


function getColorMap(keys: string[]): Map<string, string> {
  const map = new Map<string, string>();
  keys.forEach((key,i) => {
    map.set(key, colors[i % colors.length]);
  });
  return map;
}


