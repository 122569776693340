import React from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import { AccessTime, Boy, Girl, Receipt } from "@mui/icons-material";
import { format } from "date-fns";
import FlatCard from "../../../atoms/FlatCard";

const chipStyles = {
  height: "auto",
  margin: "1px",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    padding: "2px",
    minWidth: "60px",
  },
};

const Involvements = ({ ticketData }: any) => {
  console.log(
    "ticketData",
    ticketData,
    ticketData.rxId,
    ticketData.rxNumber,
    ticketData.patientId,
    ticketData.appointmentId
  );
  let content = null;

  if (ticketData) {
    content = (
      <>
        {ticketData.patientId && (
          <Chip
            sx={{
              chipStyles,
            }}
            label={
              <>
                {ticketData?.salutation === "Frau" ? <Girl /> : <Boy />}
                {/* <Typography variant="body1" fontSize="12px" > */}
                {`${ticketData?.patient?.firstName} ${ticketData?.patient?.lastName}`}
                {/* </Typography> */}
              </>
            }
          />
        )}
        {ticketData.rxId && (
          <Chip
            sx={chipStyles}
            label={
              <>
                <Receipt />
                {ticketData?.rx?.rxNumber}
              </>
            }
          />
        )}
        {ticketData.appointmentId && (
          <>
            {ticketData.appointment?.start && (
              <Chip
                sx={chipStyles}
                label={
                  <>
                    <AccessTime /> {/* Include the AccessTimeIcon */}
                    {format(new Date(ticketData.appointment?.start), "dd.MM.yyyy HH:mm:ss")}
                  </>
                }
              />
            )}

            {ticketData.appointment?.timeSlots &&
              Object.keys(ticketData.appointment.timeSlots)
                .filter((key) => ticketData.appointment.timeSlots[key].therapyRx) // Filter out non-Rx properties
                .map((key: string, index: number) => (
                  <Chip
                    key={index}
                    sx={chipStyles}
                    avatar={
                      ticketData.appointment.timeSlots[key].user && (
                        <Avatar
                          sx={{
                            width: 32,
                            height: 32,
                            fontSize: "0.8rem",
                            color: "white !important",
                          }}
                        >
                          {ticketData.appointment.timeSlots[key].user.nickName ||
                            `${
                              ticketData.appointment.timeSlots[key].user.firstName?.charAt(0) || ""
                            }${
                              ticketData.appointment.timeSlots[key].user.lastName?.charAt(0) || ""
                            }`}
                        </Avatar>
                      )
                    }
                    label={` ${ticketData.appointment.timeSlots[key].therapyRx.rx.rxNumber}     ${ticketData.appointment.timeSlots[key].therapyRx.therapy.abbreviation}`}
                  />
                ))}
            {ticketData.appointment?.end && (
              <Chip
                sx={chipStyles}
                label={
                  <>
                    <AccessTime /> {/* Include the AccessTimeIcon */}
                    {format(new Date(ticketData.appointment?.end), "dd.MM.yyyy HH:mm:ss")}
                  </>
                }
              />
            )}
          </>
        )}
      </>
    );
  }
  console.log("content", content);
  return (
    <FlatCard
      title="Betroffen"
      sx={{
        minHeight: "100px",
        width: "100%",
        flexGrow: "1",
        overflow: "auto", // Enable overflow scrolling
      }}
    >
      {content ? content : ""}
    </FlatCard>
  );
};

export default Involvements;
