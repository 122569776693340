import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import FlatCard from "../../atoms/FlatCard";
import Controls from "../../atoms/Controls";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import { AddressDto, AppointmentDto, ContactInfoDTO, PatientDto } from "../../api-client";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import useStore from "../../helpers/useStore";
import { AddressDtoValidation } from "./PatientPage";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import notificationStore from "../../stores/Notification/notificationStore";
import { Label } from "@mui/icons-material";
import TerminView, { AppointmentCardView } from "../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../PatientOverview/TerminView";
import { LabelItem } from "../../molecules/FlexList";
import { ExpandMore, ExpandLess } from "@mui/icons-material";

interface PatientAddressProps {
  patientId: string;
  addresses: AddressDto[] | undefined;
  addressesFormValidationErrors: (AddressDtoValidation | null)[] | undefined;
  onChange: (address: AddressDto[]) => void;
}
export interface PatientAddressRef {
  addAddressFormError: (errorObj: Record<string, string>) => void;
}

interface AddressFormProps {
  address: AddressDto;
  addressFormValidationErrors: AddressDtoValidation | null | undefined;
  onChange: (address: AddressDto, addressIndex: number) => void;
  addressIndex: number;
}
interface AddressFlagOption {
  key: keyof AddressDto;
  label: string;
}
const addressFlagOptions: AddressFlagOption[] = [
  { key: "isPrimary", label: "Meldeadresse" },
  { key: "isAlternativeBilling", label: "Rechnungsaddresse" },
  { key: "isVisit", label: "Anfahrtsaddresse" },
];

const AddressForm = ({
  address,
  onChange,
  addressIndex,
  addressFormValidationErrors,
}: AddressFormProps) => {
  const [expanded, setExpanded] = useState(false);

  const handleContactInfoChange = (key: string, value: string) => {
    const updatedContactInfo = { ...address.contactInfo, [key]: value } as ContactInfoDTO;
    onChange({ ...address, contactInfo: updatedContactInfo }, addressIndex);
  };

  const toggleCollapse = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (address.isAlternativeBilling && !address.isPrimary) {
      setExpanded(true);
    } else {
      setExpanded(false);
    }
  }, [address.isAlternativeBilling, address.isPrimary]);

  return (
    <Grid container spacing={2}>
      {/* Row 1 */}
      <Grid item xs={6}>
        <Controls.Input
          label="Straße und Hausnummer"
          name="street"
          value={address?.contactInfo?.addressLine1}
          onChange={(e: any) => handleContactInfoChange("addressLine1", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.addressLine1}
        />
      </Grid>
      <Grid item xs={3}>
        <Controls.Input
          label="PLZ"
          name="zip"
          value={address?.contactInfo?.zip}
          onChange={(e: any) => handleContactInfoChange("zip", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.zip}
        />
      </Grid>
      <Grid item xs={3}>
        <Controls.Input
          label="Ort"
          name="city"
          value={address?.contactInfo?.city}
          onChange={(e: any) => handleContactInfoChange("city", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.city}
        />
      </Grid>

      {/* Row 2 */}
      <Grid item xs={3}>
        <Controls.Input
          label="Mobil"
          name="mobile"
          value={address?.contactInfo?.mobile}
          onChange={(e: any) => handleContactInfoChange("mobile", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.mobile}
        />
      </Grid>
      <Grid item xs={3}>
        <Controls.Input
          label="Telefon 1"
          name="phone1"
          value={address?.contactInfo?.phone1}
          onChange={(e: any) => handleContactInfoChange("phone1", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.phone1}
        />
      </Grid>
      <Grid item xs={6}>
        <Controls.Input
          label="E-mail"
          name="email"
          value={address?.contactInfo?.email}
          onChange={(e: any) => handleContactInfoChange("email", e.target.value)}
          style={{ borderBottom: "2px solid grey", paddingLeft: "5px", width: "100%" }}
          error={addressFormValidationErrors?.contactInfo?.email}
        />
      </Grid>

      {/* Collapsible Row Toggle */}
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <IconButton onClick={toggleCollapse}>
          <ExpandMore
            style={{
              transition: "transform 0.3s",
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </IconButton>
      </Grid>
      {/* Collapsible Content */}
      <Grid item xs={12}>
        <Collapse in={expanded} timeout="auto" unmountOnExit style={{ width: "100%" }}>
          <Grid container spacing={2}>
            {/* Row 3 */}
            <Grid item xs={4}>
              <Controls.Select
                name="salutation"
                label="Anrede"
                value={address?.contactInfo?.salutation}
                onChange={(e: any) => handleContactInfoChange("salutation", e.target.value)}
                options={[
                  { label: "No Title", value: null },
                  { label: "Frau", value: "Frau" },
                  { label: "Herr", value: "Herr" },
                  { label: "Frau Dr.", value: "Frau Dr." },
                  { label: "Herr Dr.", value: "Herr Dr." },
                  { label: "Frau Dr. med.", value: "Frau Dr. med." },
                  { label: "Herr Dr. med.", value: "Herr Dr. med." },
                  { label: "Frau Prof. Dr.", value: "Frau Prof. Dr." },
                  { label: "Herr Prof. Dr.", value: "Herr Prof. Dr." },
                  { label: "Frau Prof. Dr. med.", value: "Frau Prof. Dr. med." },
                  { label: "Herr Prof. Dr. med.", value: "Herr Prof. Dr. med." },
                ]}
                onBlur={true}
                style={{
                  borderBottom: "2px solid grey",
                  paddingLeft: "5px",
                }}
                required
                error={addressFormValidationErrors?.contactInfo?.salutation}
              />
            </Grid>

            <Grid item xs={4}>
              <Controls.Input
                name="nachname"
                label="Nachname"
                value={address?.contactInfo?.lastName}
                onChange={(e: any) => handleContactInfoChange("lastName", e.target.value)}
                style={{
                  borderBottom: "2px solid grey",
                  paddingLeft: "5px",
                  width: "100%",
                }}
                error={addressFormValidationErrors?.contactInfo?.lastName}
              />
            </Grid>
            <Grid item xs={4}>
              <Controls.Input
                name="firstname"
                label="Vorname"
                value={address?.contactInfo?.firstNames}
                onChange={(e: any) => handleContactInfoChange("firstNames", e.target.value)}
                style={{
                  borderBottom: "2px solid grey",
                  paddingLeft: "5px",
                  width: "100%",
                }}
                error={addressFormValidationErrors?.contactInfo?.firstNames}
              />
            </Grid>

            {/* Row 4 */}
            <Grid item xs={6}>
              <Controls.Input
                label="Anschrift Zeile 2"
                name="Addressline2"
                value={address?.contactInfo?.addressLine2}
                onChange={(e: any) => handleContactInfoChange("addressLine2", e.target.value)}
                style={{
                  borderBottom: "2px solid grey",
                  paddingLeft: "5px",
                  width: "100%",
                }}
                error={addressFormValidationErrors?.contactInfo?.addressLine2}
              />
            </Grid>
            <Grid item xs={3}>
              <Controls.Input
                label="Fax"
                name="fax"
                value={address?.contactInfo?.fax}
                onChange={(e: any) => handleContactInfoChange("fax", e.target.value)}
                style={{
                  borderBottom: "2px solid grey",
                  paddingLeft: "5px",
                }}
                error={addressFormValidationErrors?.contactInfo?.fax}
              />
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};
const PatientAddress = forwardRef<PatientAddressRef, PatientAddressProps>(
  ({ patientId, addresses, onChange, addressesFormValidationErrors }: PatientAddressProps, ref) => {
    const AppointmentStore = useStore(AppointmentContext);
    const { getAppointmentsForPatient, getContinuousAppointmentsForPatient } = AppointmentStore;

    const PatientsStore = useStore(PatientsContext);
    const { deletePatientAddres } = PatientsStore;

    const [keys, setKeys] = useState<string[]>([]);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedAddressId, setSelectedAddressId] = useState<string>();
    const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>();
    const [appointmentsOfAddress, setAppointmentsOfAdress] = useState<AppointmentDto[]>();
    const [dataRefreshFlag, setDataRefreshFlag] = useState(null);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const fetchAppointments = async (addressId: string) => {
      try {
        var appointments: AppointmentDto[] = [];
        //Fetch Normal Temin
        const normalTermin = await getAppointmentsForPatient(patientId, addressId);

        //Fetch Continuous Termin
        const continuousTermin = await getContinuousAppointmentsForPatient(patientId, addressId);
        if (normalTermin) appointments = [...normalTermin];
        if (continuousTermin) appointments = [...appointments, ...continuousTermin];

        setAppointmentsOfAdress(appointments);
      } catch (error) {
        console.log("Error fetching appointments for patient", error);
      }
    };

    useEffect(() => {
      if (addresses?.length) {
        // Generate new keys whenever the length of the addresses array changes
        const newKeys = addresses?.map(() => Math.random().toString(36).substring(2, 15));
        setKeys(newKeys);
      }
    }, [addresses?.length]);

    useEffect(() => {
      if (dataRefreshFlag != null && selectedAddressId) {
        fetchAppointments(selectedAddressId);
      }
    }, [dataRefreshFlag]);

    // Expose functions to the parent
    useImperativeHandle(ref, () => ({
      addAddressFormError(errorObj: Record<string, string>) {
        console.log("Adding AddressFormError");
      },
    }));

    const onChangeAddress = (updatedAddress: AddressDto, addressIndex: number) => {
      // setAddressesArr((prevAddresses) =>
      //   prevAddresses.map((address) => {
      //     if (address.id === updatedAddress.id) {
      //       return updatedAddress;
      //     }
      //     return address;
      //   })
      // );

      let updatedAdressesArr = addresses?.map((address, index) => {
        if ((updatedAddress.id && updatedAddress.id === address.id) || addressIndex == index) {
          return updatedAddress;
        }
        return address;
      });

      if (updatedAdressesArr?.length) onChange(updatedAdressesArr);
    };

    const onCheckboxChange = (
      addressId: string,
      addressIndex: number,
      key: string,
      value: boolean
    ) => {
      let addressesArr = addresses?.concat();
      if (!addressesArr?.length) return;
      // return console.log(key, value);

      if (value) {
        //mark check and validate and assign checkboxes
        addressesArr = addressesArr.map((addressObj, index) => {
          if ((addressId && addressId === addressObj.id) || addressIndex == index) {
            addressObj = { ...addressObj, [key]: value };
          } else {
            addressObj = { ...addressObj, [key]: !value };
          }
          return addressObj;
        });
        onChange(addressesArr);
      } else {
        //mark uncheck the key in address
        let address = addresses?.filter(
          (adObj, index) => (addressId && adObj.id === addressId) || addressIndex == index
        )[0];
        if (address) {
          const updatedAddress = { ...address, [key]: value };
          addressesArr[addressIndex] = updatedAddress;
          onChange(addressesArr);
        }
      }
      // console.log("AddressArrCheckbox", addressesArr);
    };

    const onDeleteAddress = async () => {
      //console.log("Deleting addressId", selectedAddressId);

      try {
        if (selectedAddressId) {
          //Add loading
          setDeleteLoading(true);
          //Delete request to database
          await deletePatientAddres(selectedAddressId);
        }
        console.log("Addresses Length", addresses, selectedAddressId, addresses?.length);

        if (addresses?.length) {
          let filteredAddresses = addresses?.filter((addObj, index) => {
            if (selectedAddressId) {
              return addObj.id != selectedAddressId;
            } else {
              return selectedAddressIndex != index;
            }
          });

          onChange(filteredAddresses);
        }

        setShowDeleteModal(false);
        setDeleteLoading(false);
      } catch (error) {
        console.log("Error on deleting address", error);
        setDeleteLoading(false);
      }
    };

    const onDeleteClick = (addressId: string, addressIndex: number) => {
      if (!addressId) {
        setShowDeleteModal(true);
        setSelectedAddressIndex(addressIndex);
        setSelectedAddressId(undefined);
        return;
      }
      setSelectedAddressId(addressId);
      setSelectedAddressIndex(undefined);
      if (patientId) {
        setShowDeleteModal(true);
        fetchAppointments(addressId);
      } else {
        notificationStore.showMessage("patienId is null", "error");
      }
    };

    return (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            gap: "10px",
          }}
        >
          {addresses?.map((address, addressIndex) => {
            const uniqueKey = keys[addressIndex] || `new-${addressIndex}`;
            return (
              <div
                style={{
                  width: "100%",
                  maxWidth: "750px",
                  flexGrow: 1,
                }}
              >
                <FlatCard title="" fullHeight>
                  <div style={{ position: "relative" }}>
                    <Button
                      style={{ position: "absolute", right: 0 }}
                      size="small"
                      onClick={() => onDeleteClick(address.id!, addressIndex)}
                    >
                      löschen
                    </Button>
                  </div>

                  {addressFlagOptions.map((addFlagOptObj) => (
                    <Controls.Checkbox
                      key={`${uniqueKey}-${addFlagOptObj.key}`}
                      name={addFlagOptObj.key}
                      label={addFlagOptObj.label}
                      value={address[addFlagOptObj.key]}
                      onChange={(e: any) =>
                        onCheckboxChange(
                          address.id!,
                          addressIndex,
                          addFlagOptObj.key,
                          e.target.value
                        )
                      }
                    />
                  ))}

                  <Box
                    sx={{
                      width: "100%",
                      typography: "body1",
                      border: (theme) => `2px solid ${theme.palette.primary.light}`,
                      alignContent: "stretch",
                      padding: "10px",
                    }}
                  >
                    <AddressForm
                      key={uniqueKey}
                      address={address}
                      onChange={onChangeAddress}
                      addressIndex={addressIndex}
                      addressFormValidationErrors={
                        address.id
                          ? addressesFormValidationErrors?.filter((obj) => obj?.id == address.id)[0]
                          : addressesFormValidationErrors?.[addressIndex]
                      }
                    />
                  </Box>
                </FlatCard>
              </div>
            );
          })}
        </div>

        <Dialog
          maxWidth={selectedAddressId && appointmentsOfAddress?.length ? "lg" : "sm"}
          fullWidth
          open={showDeleteModal}
          style={{ width: "100%" }}
          onClose={() => setShowDeleteModal(false)}
        >
          <DialogTitle>Are you sure you want to delete the address?</DialogTitle>
          {selectedAddressId && appointmentsOfAddress?.length ? (
            <DialogContent style={{ paddingTop: "8px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <h3 style={{ color: "red" }}>
                    Diese Adresse kann nicht gelöscht werden, weil sie von (
                    {`${appointmentsOfAddress?.length}`}) Terminen verwendet wird. Um fortzufahren,
                    müssen andere Adressen im Terminbearbeitungsmenü für die jeweiligen Termine
                    ausgewählt werden.
                  </h3>
                </Grid>

                <Grid item xs={12} md={8}>
                  {appointmentsOfAddress?.map((appointment: any, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <AppointmentCardView
                          appointment={appointment}
                          //notes={appoitmentNotes.filter((note) => note.appointmentId === appointment.id)}
                          appointments={appointmentsOfAddress}
                          onAddNotes={(event: any) => {}}
                          patientId={patientId}
                          dataRefreshFlag={dataRefreshFlag}
                          setDataRefreshFlag={setDataRefreshFlag}
                        />
                      </React.Fragment>
                    );
                  })}
                </Grid>
              </Grid>
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button variant="text" onClick={() => setShowDeleteModal(false)}>
              Abbrechen
            </Button>
            <Button
              onClick={() => onDeleteAddress()}
              disabled={
                selectedAddressId || deleteLoading ? Boolean(appointmentsOfAddress?.length) : false
              }
              variant="contained"
            >
              Löschen
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);

export default PatientAddress;
