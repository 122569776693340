import React, { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";
import {
  Button,
  TextField,
  Container,
  Grid,
  Typography,
  Divider,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Autocomplete,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
  Modal,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Radio,
  makeStyles,
  styled,
} from "@mui/material";
import { attentionColors, mobiliTheme } from "../../themes/mobiliTheme";
import { Add, Check, CheckBox, PersonSearch } from "@mui/icons-material";
import Controls from "../../atoms/Controls";
import TextArea from "../../atoms/TextArea";
import { observer } from "mobx-react";
import { initialValues } from "../pages/Patient/initialValues";
import useStore from "../../helpers/useStore";
import { MasterTablesContext } from "../../stores/MasterTables/MasterTables.provider";
import { DoctorContext } from "../../stores/Doctor/doctor.provider";
import { RxStoreContext } from "../../stores/Rx/rx.provider";
import { RxDto } from "../../api-client";
import { v4 as uuidv4 } from "uuid";
import notificationStore from "../../stores/Notification/notificationStore";
import { UserContext } from "../../stores/User/User.provider";
import DoctorDialog from "../PatientDataEntryForm/Doctor";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import ValidationCardRxInfoPage from "../../molecules/ValidationCardRxInfoPage";
import FlatCard from "../../atoms/FlatCard";
import classes from "./Verordnung13.module.css";

interface DiagnoseGroup {
  a: string;
  b: string;
  c: string;
  d: string;
}

interface Verordnung13Props {
  rxFormData: any;
  patientValues: any;
  handleData: any;
  masterTablesData: any;
  readOnlyView?: boolean;
  setUnsavedChanges?: any;
}

// Define the type for the methods exposed to the parent component
export interface VerordnungHandle {
  handleGenericSubmit: any;
}

const ScalableDiv = styled("div")(({ theme }) => ({
  transformOrigin: "top left",
  [theme.breakpoints.down("sm")]: {
    transform: "scale(0.6)",
  },
  [theme.breakpoints.between("sm", "xl")]: {
    transform: "scale(0.65)",
  },
  [theme.breakpoints.up("xl")]: {
    transform: "scale(0.85)",
  },
}));

const ScalableComponentSimple = ({ children }: any) => {
  return <ScalableDiv>{children}</ScalableDiv>;
};

const Verordnung13 = observer(forwardRef<VerordnungHandle, Verordnung13Props>(({ rxFormData, patientValues, handleData, masterTablesData, readOnlyView,setUnsavedChanges }, ref) => {
  // const { rxFormData, patientValues, handleData, masterTablesData, readOnlyView } = props;
    const masterTablesStore = useStore(MasterTablesContext);
    const UserStore = useStore(UserContext);
    const patientStore = useStore(PatientsContext);
    const rxStore = useStore(RxStoreContext);
    const {savedSeriesForPatient}=rxStore;
    const { searchPatient } = patientStore;
    const { user } = UserStore;
    const { getICD,getTarifGroupIds } = masterTablesStore;
    const [selectedDiagnoseGroup, setSelectedDiagnoseGroup] = useState<DiagnoseGroup | null>(null);
    const RxStore = useStore(RxStoreContext);
    const { saveRxs } = RxStore;
    const [loading, setLoading] = useState(false);
      const tarifGroupsMasterData = getTarifGroupIds();
      //For payer
      const insurer = masterTablesData
        .filter((obj: any) => "insurer" in obj)
        .map((obj: any) => obj.insurer);
      const [selectedPayer, setSelectedPayer] = useState(null);
      const [selectedChipCard, setSelectedChipCard] = useState<string | null>(null);
      const diagnoseGroups = masterTablesData
        .filter((obj: any) => "diagnosisGroups" in obj)
        .map((obj: any) => obj.diagnosisGroups);

      const therapyOptions = masterTablesData
        .filter((obj: any) => "therapy" in obj)
        .map((obj: any) => obj.therapy);
      const rxType = masterTablesData
        .filter((obj: any) => "rxType" in obj) // Filter objects that have the insuranceStatus property
        .map((obj: any) => obj.rxType);
      //  therapyOptions= therapyOptions[0].filter(
      //       (therapyObj: any) =>

      //         therapyObj.duration
      //     )

      //For Doctor
      const [selectedDoctor, setSelectedDoctor] = useState(null);
      const [selectedBSNR, setSelectedBSNR] = useState<string | null>(null);
      const [selectedLANR, setSelectedLANR] = useState<string | null>(null);
      const DoctorStore = useStore(DoctorContext);
      const { doctorOptions } = DoctorStore;
      const [isSelectActive, setIsSelectActive] = useState(false);
      const [isAddEditDialogOpen, setAddEditDialogOpen] = useState(false);

      const account = masterTablesData
        .filter((obj: any) => "account" in obj)
        .map((obj: any) => obj.account);

      const handlePersonSearchClick = () => {
        setIsSelectActive(!isSelectActive); // Activate the Select dropdown
      };
      const handleDoctorChange = async (event: React.ChangeEvent<HTMLSelectElement>) => {
        setUnsavedChanges(true);
        const selectedValue: any = event.target.value;
        setSelectedDoctor(selectedValue);
        // Autopopulate bsnr based on doctor selection
        const selectedDoctor: any = doctorOptions.find(
          (doctor: any) => doctor?.contactInfo.lastName === selectedValue
        );

        if (selectedDoctor) {
          setFormData((prevFormValues: any) => ({
            ...prevFormValues,
            doctorNumber: selectedDoctor.number,
            practiceNumber: selectedDoctor.clinicNumber,
            doctorData: selectedDoctor,
          }));
          setSelectedBSNR(selectedDoctor.clinicNumber);
          setSelectedLANR(selectedDoctor.number);
        }

        // Filter doctor options based on selectedValue
        const filteredOptions: any = doctorOptions.filter(
          (doctor: any) => doctor?.contactInfo?.lastName === selectedValue
        );
        if (filteredOptions?.length > 0) {
          // If filteredOptions has a value, set it as the fetchedData
          // setFetchedData(filteredOptions[0]);
          // Update receiptForm with selected doctor
          // if (!isEditing) {
          setFormData({
            ...formData,
            doctorId: filteredOptions[0].id,
            doctorNumber: filteredOptions[0].number,
            practiceNumber: filteredOptions[0].clinicNumber,
            doctorData: filteredOptions[0],
          });
          // } else {
          //   setFormValues((prevFormValues: any) => ({
          //     ...prevFormValues,
          //     rxs: prevFormValues.rxs.map((rx: any) => ({
          //       ...rx,
          //       doctorId: filteredOptions[0].id,
          //       practiceNumber: filteredOptions[0].clinicNumber,
          //       doctorNumber: filteredOptions[0].number,
          //     })),
          //   }));
          // }
        } else {
          // setFetchedData(null);
          setFormData({
            ...formData,
            doctorId: "",
            doctorNumber: "",
            practiceNumber: "",
          });
        }
      };

      const initialrxValues = {
        Receiptpayer: "",
        firstName: "",
        lastName: "",
        address: "",
        dob: "",

      status: "",
      signatureStatus: false,
      insuranceStatus: "",
      chipcard: "",
      tarifGroupId:"",
      insureeNumber: "",
      rxtype: "",
      accountId: "",

      isPerpetual: false ,

      doctorId: "",
      practiceNumber: "",
      doctorNumber: "",
      date: "",
      isCopaymentExempted: false,
      isAccident: false,
      isBVG: false,
      icdCodeEntries: [{ userText: "" }, { userText: "" }],
      diagnosisText: "",
      diagnosegroup: "" || null,
      a: "",
      b: "",
      c: "",
      d: "",
      hasIndividualSymptoms: false,
      therapyRxs: [
        {
          id: "",
          amount: "",
          frequency: { id: "", text: "", prefferedValue: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled:false,
          doubledTreatmentIds:[],
          rxSeries:{label:""}
        },
        {
          id: "",
          amount: "",
          frequency: { id: "", text: "", prefferedValue: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled:false,
          doubledTreatmentIds:[],
          rxSeries:{label:""}
        },
        {
          id: "",
          amount: "",
          frequency: { id: "", text: "", prefferedValue: "" },
          therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
          isDoubled:false,
          doubledTreatmentIds:[],
          rxSeries:{label:""}
        },
      ],
      visitType: "",
      therapyReport: false,
      urgent: false,
      symptoms: "",
      therapyFrequency: "",
      amount: "",
      additionalNotes: "",
      doctorData: {} as any,
      duplicate: false,
    };

      const [formData, setFormData] = useState(initialrxValues);
      const [icdCodeEntries, setIcdCodeEntries] = useState(initialrxValues.icdCodeEntries);


      // useEffect(() => {
      //   if (rxFormData) {
      //     // Populate the form data with values from rxFormData
      //     setFormData(rxFormData);
      //   } else {
      //     // Use empty values for form data
      //    setFormData(initialrxValues)
      //   }
      // }, [rxFormData]);
      // Initialize with two input boxes

      // Expose the submitForm function to the parent component
      useImperativeHandle(ref, () => ({
        handleGenericSubmit,
      }));

      useEffect(() => {
        const fetchData = async () => {
          console.log("RxFormdata and Patient Value", rxFormData, " and", patientValues.rxs);
          if (rxFormData && rxFormData.rxNumber) {
            rxFormData = patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id);
            console.log(
              "After checking RxFormdata and Patient Value",
              rxFormData,
              " and",
              patientValues.rxs
            );
            let rxs: [] = [];
            let icdCodeEntries;

          if (rxFormData.icdCodeEntries && rxFormData.icdCodeEntries.length > 0) {
            if (rxFormData.icdCodeEntries.length === 1) {
              icdCodeEntries = [...rxFormData.icdCodeEntries, { userText: "" }];
            } else {
              icdCodeEntries = rxFormData.icdCodeEntries;
            }
          } else {
            icdCodeEntries = [{ userText: "" }, { userText: "" }];
          }
          console.log("RxFormdata TherapyRXS", rxFormData.therapyRxs);
          rxFormData.therapyRxs = rxFormData?.therapyRxs?.flatMap((therapy: any) => {
            // Extract therapyRxs from each rx, or default to an empty array
            // Map therapyRxs to the desired format
            console.log("Therapy Log DATA", therapy, therapy.frequency);
            return {
              id: therapy.id,
              date: rxFormData.date,
              therapy: therapy.therapy.id
                ? therapy.therapy
                : {
                    id: therapy?.therapy,
                    abbreviation: therapy?.therapy,
                    description:
                      therapyOptions[0]?.find(
                        (therapyObj: any) => therapyObj.id === therapy?.therapy
                      ).description || "",
                  },
              amount: therapy.amount,
              frequency: therapy.frequency?therapy.frequency.id
                ? therapy.frequency
                : { id: therapy?.frequency, text: therapy?.frequency }:null,
              isDoubled:therapy.isDoubled,
              doubledTreatmentIds:therapy.doubledTreatmentIds,
              rxSeries:therapy.rxSeries
            };
          });

          const initialTherapyRxs = Array.from({ length: 3 }, (_, index) => {
            if (index < rxFormData.therapyRxs.length) {
              // If index is within the length of formData.therapyRxs, use existing data
              return rxFormData.therapyRxs[index];
            } else {
              // Otherwise, add an empty row
              return {
                id: uuidv4(),
                amount: "",
                frequency: { id: "", text: "", prefferedValue: "" },
                therapy: { id: "", HMP4: "", price: "", abbreviation: "", description: "" },
                isDoubled:false,
                doubledTreatmentIds:[],
                // rxSeries:{}
              };
            }
          });
          // Update form data based on fetched data
          const updatedFormData = {
            ...rxFormData,
            firstName: patientValues.firstName || "",
            lastName: patientValues.lastName || "",
            dob: patientValues.dob || "",

            insureeNumber:rxFormData.insureeNumber || patientValues.insuranceNo,
            insuranceStatus:rxFormData.insuranceStatus || patientValues.status,
              // Receiptpayer: rxFormData.payer.insurer || "",
              Receiptpayer:
                insurer[0]?.find((option: any) => option.id === rxFormData.Receiptpayer)?.insurer ||
                rxFormData.payer?.insurer,
              chipcard: rxFormData.chipcard || rxFormData.payer?.ik,
              doctorId:
                doctorOptions?.find((option: any) => option.id === rxFormData.doctorId)?.contactInfo
                  .lastName || rxFormData.doctor?.contactInfo?.lastName,
              diagnosegroup:
                diagnoseGroups[0]?.find((option: any) => option.id === rxFormData.diagnosegroup)
                  ?.code || rxFormData.diagnosisGroup?.code,
              rxtype:
                rxType[0]?.find((option: any) => option.id === rxFormData.rxtype)?.text ||
                rxFormData.rxtype?.text,
              doctorData:
                (doctorOptions as any).find(
                  (option: any) =>
                    option.contactInfo.lastName === rxFormData.doctorId ||
                    option.id === rxFormData.doctorId
                ) || rxFormData.doctor,
              accountId:
                account[0]?.find((option: any) => option.id === rxFormData.accountId)?.name ||
                rxFormData.account?.name,
              status: rxFormData.status || "",
              signatureStatus: rxFormData.signatureStatus || "",
              therapyRxs: initialTherapyRxs,
              // rxtype:rxFormData.rxtype?.id || "",
              // accountId:rxFormData.account?.id || "",
              // chipcard: rxFormData.payer?.id || "",
              // doctorId: rxFormData.doctor?.contactInfo?.lastName || "",
              bsnr: rxFormData.practiceNumber || "",
              // diagnosegroup: rxFormData.diagnosisGroup?.code || "",
              icdCodeEntries: icdCodeEntries,
            };

            // Update form data state
            setFormData(updatedFormData);

            // Optionally, return the updated form data
            return updatedFormData;
          }
          // Make API request to fetch patient data based on patient ID or any other identifier
          else {
            setFormData(initialrxValues);
          }
        };
        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [rxFormData, patientValues?.rxs]);
      useEffect(() => {
        if (formData.diagnosegroup) {
          const diagnoseGroupIsId = diagnoseGroups[0].some(
            (group: any) => group.id === formData.diagnosegroup
          );
          // Find the matching diagnose group based on the selected code
          const selectedDiagnoseGroup = diagnoseGroupIsId
            ? diagnoseGroups[0].find((group: any) => group.id === formData.diagnosegroup)
            : diagnoseGroups[0].find((group: any) => group.code === formData.diagnosegroup);

          if (selectedDiagnoseGroup) {
            // Generate symptoms based on selected checkboxes
            let selectedSymptoms = "";
            if (formData.a) selectedSymptoms += selectedDiagnoseGroup.a + " ";
            if (formData.b) selectedSymptoms += selectedDiagnoseGroup.b + " ";
            if (formData.c) selectedSymptoms += selectedDiagnoseGroup.c + " ";
            if (formData.d) selectedSymptoms += selectedDiagnoseGroup.d + " ";
            setSelectedDiagnoseGroup(selectedDiagnoseGroup);
            setFormData((prevValues: any) => ({
              ...prevValues,
              a: selectedDiagnoseGroup.a ? prevValues.a : false,
              b: selectedDiagnoseGroup.b ? prevValues.b : false,
              c: selectedDiagnoseGroup.c ? prevValues.c : false,
              d: selectedDiagnoseGroup.d ? prevValues.d : false,
              symptoms: selectedSymptoms.trim(),
            }));
          }
        }
      }, [formData.diagnosegroup, formData.a, formData.b, formData.c, formData.d]);

      const handleICDCodeEntrySubmit = async (icdCode: any, index: number) => {
        try {
          const icdObject: any = await getICD(icdCode.userText);
          if (icdObject) {
            // If the API call is successful and returns an icdObject, update the icdCodeEntries state with the normCode
            setFormData((prevValues: any) => {
              const updatedICDCodeEntries = prevValues.icdCodeEntries.map(
                (icdEntry: any, i: number) => {
                  return i === index
                    ? { id: icdCode?.id || "", userText: icdCode.userText }
                    : icdEntry;
                }
              );

              const concatenatedDiagnosisText = prevValues.diagnosisText
                ? `${prevValues.diagnosisText} ${icdObject.text}` // Append the new icdObject.text to the existing diagnosisText
                : icdObject.text;
              return {
                ...prevValues,
                icdCodeEntries: updatedICDCodeEntries,
                diagnosisText: concatenatedDiagnosisText.trim(), // Set diagnosisText to the concatenated value
              };
            });
          }
        } catch (error) {
          console.error("Error fetching ICD object:", error);
        }
      };

      const handleChange = (event: any, index?: any) => {
        const { name, value } = event.target;
        const updatedTherapyRxs: any = [...formData.therapyRxs];
        updatedTherapyRxs[index][name.split(".")[1]] = value;
        setFormData({ ...formData, therapyRxs: updatedTherapyRxs });
      };
      const handleOpenAddEditDialog = (lanr: string | null, bsnr: string | null) => {
        setSelectedLANR(lanr);
        setSelectedBSNR(bsnr);
        setAddEditDialogOpen(true);
      };

      const handleCloseAddEditDialog = (data: any) => {
        // Handle doctor data, LANR, and BSNR passed back from DoctorDialog
        setAddEditDialogOpen(false);

        // Here you can perform further actions with the received data
        if (data && "number" in data) {
          const { number, clinicNumber, contactInfo, id } = data;

          // Update states or perform other operations based on the received data
          setSelectedLANR(number);
          setSelectedBSNR(clinicNumber);
          setSelectedDoctor(contactInfo?.lastName);
          setFormData((prevFormValues: any) => ({
            ...prevFormValues,
            doctorNumber: number,
            practiceNumber: clinicNumber,
            doctorId: id,
            doctorData: data,
          }));

          // For example, you can update doctor form values if needed
          // setDoctorFormValues(doctorData);
        }
      };
      const handleAddInput = () => {
        setIcdCodeEntries([...icdCodeEntries, { userText: "" }]);

        // Update the formValues state as well to reflect the new entry
        setFormData((prevValues: any) => ({
          ...prevValues,
          icdCodeEntries: [...prevValues.icdCodeEntries, { userText: "" }],
        }));
      };
      const [isHausbesuchSelected, setHausbesuchSelected] = useState<boolean | null>(null);
      const [popupOpen, setPopupOpen] = useState(false);

      useEffect(() => {
        if (formData.visitType) {
          if (formData.visitType === "Haus" || formData.visitType === "Heim") {
            setHausbesuchSelected(true);
          } else {
            setHausbesuchSelected(false);
          }
        }
      }, [formData.visitType]);

      const handleCheckboxChange = (event: any) => {
        const hausbesuch = event.target.value === "yes";
        setHausbesuchSelected(hausbesuch);

        if (hausbesuch) {
          handleInputChange({
            target: {
              name: "visitType",
              value: "",
            } as EventTarget & HTMLInputElement,
          } as React.ChangeEvent<HTMLInputElement>);
          setPopupOpen(true);
        } else {
          handleInputChange({
            target: {
              name: "visitType",
              value: "Praxis",
            } as EventTarget & HTMLInputElement,
          } as React.ChangeEvent<HTMLInputElement>);
        }
      };

      const handlePopupClose = () => {
        setHausbesuchSelected(false);
        handleInputChange({
          target: {
            name: "visitType",
            value: "Praxis",
          } as EventTarget & HTMLInputElement,
        } as React.ChangeEvent<HTMLInputElement>);
        setPopupOpen(false);
      };

      const handlePopupSelect = (event: any) => {
        const visitType = event.target.value;
        handleInputChange({
          target: {
            name: "visitType",
            value: visitType,
          } as EventTarget & HTMLInputElement,
        } as React.ChangeEvent<HTMLInputElement>);
        setHausbesuchSelected(true);
        setPopupOpen(false);
      };

      const handleInputChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        index?: any,
        idValue?: string
      ) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { name, value, checked, type, id } = event.target;
        setUnsavedChanges(true);

      const inputValue = type === "checkbox" ? checked : value;

      if (name === "date" && formData.isPerpetual && value) {
        setFormData((prevValues: any) => ({
          ...prevValues,
          unvirtualize: true,
        }));
      }

      if (name === "therapy") {
        const updatedTherapyRxs = [...formData.therapyRxs];
        const selectedTherapy = therapyOptions[0]?.find(
          (therapyObj: any) => therapyObj.abbreviation === value
        );
if(selectedTherapy){
        updatedTherapyRxs[index].therapy.id = selectedTherapy.id;
        updatedTherapyRxs[index].therapy.abbreviation = value;
        updatedTherapyRxs[index].therapy.description = selectedTherapy?.description || "";
        updatedTherapyRxs[index].therapy.price = selectedTherapy?.price || "";
        updatedTherapyRxs[index].frequency =
          selectedTherapy.frequency || formData.therapyRxs[0].frequency;

        setFormData((prevFormData: any) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
      }}
      // Check if the input name is "amount"
      if (name === "amount") {
        // Update therapyRxs based on rowIndex and therapyId
        const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
          if (index === index && therapy.id === idValue) {
            return {
              ...therapy,
              amount: value, // Update the amount property with the new value
              frequency: therapy.frequency || formData.therapyRxs[0].frequency,
            };
          }
          return therapy;
        });

        // Update the formData state with the updated therapyRxs
        setFormData((prevFormData) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
      }
      
      if (name === "rxSeries") {
        // Update therapyRxs based on rowIndex and therapyId
        const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
          if (index === index && therapy.id === idValue) {
            return {
              ...therapy,
              rxSeries:{id:id,label:value}
            };
          }
          return therapy;
        });

          // Update the formData state with the updated therapyRxs
          setFormData((prevFormData) => ({
            ...prevFormData,
            therapyRxs: updatedTherapyRxs,
          }));
        }
        if (name === "therapyFrequency") {
          // Update the frequency for the specific therapy row
          const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
            return {
              ...therapy,
              frequency: {
                id: value, // Update the frequency id
                text: value, // Update the frequency text
              },
            };
          });

          // Update the formData state with the updated therapyRxs
          setFormData((prevFormData) => ({
            ...prevFormData,
            therapyRxs: updatedTherapyRxs,
          }));
        }

        if (name.startsWith("icdCodeEntries")) {
          const inputElement = document.querySelector(`[name="${name}"]`) as HTMLInputElement | HTMLTextAreaElement;
          const cursorPosition = inputElement?.selectionStart || 0;
          let updatedValue = value;
          let newCursorPosition = cursorPosition; // Initialize new cursor position
        
          // Check if the length is at least 4 and if a period needs to be inserted
          if (value.length >= 4) {
            // Check if the period already exists at the correct position
            if (!value.includes(".")) {
              // Insert a period after the third character if it's not already there
              updatedValue = value.slice(0, 3) + "." + value.slice(3);
        
              // Adjust cursor position if a period was inserted before the original cursor position
              if (cursorPosition > 3) {
                newCursorPosition = cursorPosition + 1; // Move cursor one position forward
              }
            }
          }
        
          // Update the form values with the correctly formatted value
          setFormData((prevValues: any) => ({
            ...prevValues,
            icdCodeEntries: prevValues.icdCodeEntries.map((icdEntry: any, i: number) => {
              return i === index
                ? { id: idValue || undefined, userText: updatedValue }
                : { id: icdEntry?.id, userText: icdEntry?.userText || "" };
            }),
          }));
        
          // Restore cursor position after the update
          setTimeout(() => {
            if (inputElement) {
              inputElement.selectionStart = inputElement.selectionEnd = newCursorPosition;
            }
          }, 0);
        }
        

        if (name === "rxtype") {
          setFormData((prevValues) => ({
            ...prevValues,
            diagnosegroup: null, // Clear diagnosegroup when rxtype changes
          }));
          filterDiagnoseGroups(value);
        }

        // if (name === "doctorNumber") {
        //   // Autopopulate doctor and bsnr based on LANR selection
        //   const selectedDoctor: any = doctorOptions.find(
        //     (doctor: any) => doctor.number === Number(value)
        //   );
        //   // setFetchedData(selectedDoctor);
        //   console.log("UZZHHHH", selectedDoctor);
        //   if (selectedDoctor) {
        //     setFormData((prevFormValues: any) => ({
        //       ...prevFormValues,
        //       doctorId: selectedDoctor.id,
        //       practiceNumber: selectedDoctor.clinicNumber,
        //       doctorData:selectedDoctor
        //     }));
        //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
        //     setSelectedBSNR(selectedDoctor.clinicNumber);
        //   }
        // }
        if (name === "hasIndividualSymptoms") {
          // When the checkbox is selected, clear symptom codes and text
          // if (inputValue ) {
          setFormData((prevValues: any) => ({
            ...prevValues,
            a: false, // Clear symptom code
            b: false,
            c: false,
            d: false,
            symptoms: "",
            hasIndividualSymptoms: inputValue, // Clear symptoms text
          }));
          // }
        } else if (
          (name === "a" || name === "b" || name === "c" || name === "d") &&
          formData?.hasIndividualSymptoms
        ) {
          // If the checkbox is selected, and symptom code is changed, uncheck the checkbox
          setFormData((prevValues: any) => ({
            ...prevValues,
            hasIndividualSymptoms: false,
            [name]: inputValue,
          }));
        } else {
          console.log("InputValue", inputValue, id);
          // Update other form fields based on their names
          setFormData((prevValues: any) => ({
            ...prevValues,
            [name]: id ? id : inputValue,
          }));
        }

        // if (name === "doctorNumber") {
        //   // Autopopulate doctor and bsnr based on LANR selection
        //   const selectedDoctor: any = doctorOptions.find(
        //     (doctor: any) => doctor.number === Number(value)
        //   );
        //   setFetchedData(selectedDoctor);
        //   console.log("UZZHHHH", selectedDoctor);
        //   if (selectedDoctor) {
        //     setFormData((prevFormValues: any) => ({
        //       ...prevFormValues,
        //       doctorId: selectedDoctor.id,
        //       practiceNumber: selectedDoctor.clinicNumber,
        //     }));
        //     setSelectedDoctor(selectedDoctor.contactInfo.lastName);
        //     setSelectedBSNR(selectedDoctor.clinicNumber);
        //   }
        // }
        if (insurer[0]) {
          if (name === "Receiptpayer") {
            // Autopopulate doctor and bsnr based on LANR selection

            const selectedPayer: any = insurer[0].find(
              (option: any) => option.id === id || option.ik === value
            );
            console.log("UZZHHHH", selectedPayer);
            if (selectedPayer) {
              setFormData((prevFormValues: any) => ({
                ...prevFormValues,
                chipcard: selectedPayer?.ik,
                tarifGroupId: selectedPayer?.tarifGroup?.id,
              }));
              setSelectedChipCard(selectedPayer.ik);
              console.log("selectedPayer", selectedPayer.insurer);
            }
          }

          if (name === "chipcard") {
            const selectedPayer: any = insurer[0].find((option: any) => option.ik === value);
            console.log("UZZHHHH", selectedPayer);
            if (selectedPayer) {
              setFormData((prevFormValues: any) => ({
                ...prevFormValues,
                Receiptpayer: selectedPayer.id,
                tarifGroupId: selectedPayer?.tarifGroup?.id,
              }));

              setSelectedPayer(selectedPayer.insurer);
              console.log("selectedPayer", selectedPayer.insurer);
            }
          }
        }
      };

      const handleGenericSubmit = async (event: any) => {
        if (event) {
          setLoading(false);
          event.preventDefault(); // Prevent default form submission behavior
        }
        try {
          setLoading(true);
          const updatedRxValues: any = {
            ...formData,
            id: patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)?.id || "",
            rxNumber: patientValues?.rxs.find((rx: any) => rx.id === rxFormData.id)?.rxNumber || "",
            doctor: formData.doctorData,
            accountId:
              account[0].find((option: any) => option.name === formData.accountId)?.id ||
              formData.accountId,
            rxtype:
              rxType[0].find((option: any) => option.text === formData.rxtype)?.id ||
              formData.rxtype,
            diagnosegroup:
              diagnoseGroups[0].find((option: any) => option.code === formData.diagnosegroup)?.id ||
              formData.diagnosegroup,
            doctorId:
              (doctorOptions as any).find(
                (option: any) => option.contactInfo.lastName === formData.doctorId
              )?.id || formData.doctorId,

            Receiptpayer:
              insurer[0].find((option: any) => option.insurer === formData.Receiptpayer)?.id ||
              formData.Receiptpayer,
            // therapyRxs:
            //   formData.therapyRxs
            //     ?.filter((therapy: any) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
            //     .map((therapy: any) => ({

            //       frequency: therapy.frequency || "",

            //     })) || [],

            therapyRxs: (formData.therapyRxs || []) // Ensure formData.therapyRxs is an array or provide an empty array
              .filter((therapy) => therapy.therapy.id !== "") // Filter out entries without therapy.therapyId or therapy.id
              .map((therapy: any) => {
                if (!therapy.frequency || !therapy.therapy || !therapy.amount) {
                  throw new Error(
                    "Bitte fügen Sie die Behandlung hinzu, um das Rezept zu speichern/bearbeiten (Therapie, Menge, Häufigkeit sind Pflichtfelder) und deaktivieren Sie auch die Zelle nach der Bearbeitung!"
                  );
                }
                const insurer = masterTablesData
                  .filter((obj: any) => "insurer" in obj)
                  .map((obj: any) => obj.insurer); // Extract the insurer array

              const payer = insurer[0].find((option: any) => option.insurer === formData.Receiptpayer)?.id ||
              formData.Receiptpayer
              const insurerInfo = insurer[0]?.filter((obj: any) => obj.id === payer)[0]?.tarifGroup?.id;//To check with Nafees why is it used
              // const therapyName = therapy.therapy.abbreviation.toUpperCase();
              // console.log("TherapyName SEEEEEEEEEEEEEe", therapyName);

              
        
              return {
                ...therapy,
                therapy:{
                  ...therapy.therapy,
                  id: `${insurerInfo || formData?.tarifGroupId}_${therapy.therapy.abbreviation}`,
                },
                amount:therapy.amount,
                id: therapy.therapyId || therapy.id,
                frequency: therapy.frequency || "",
              };
            })
        };
        // // Handle form submission logic here
        // const updatedFormData = {

          //   patient:{
          //     ...patientValues,
          //   firstName: formData.patient?.firstName || "",
          //   lastName: formData.patient?.lastName || "",
          //   dob: formData.patient?.dob || ""},
          //   rxs: updatedRxValues
          // };
          const updatedFormData = {
            ...patientValues,
            rxs: patientValues?.rxs.map((rx: any) =>
              rx.id === rxFormData.id ? updatedRxValues : rx
            ),
          };
          console.log("Final782848", updatedRxValues);

          console.log("Final", formData, rxFormData, updatedFormData);
          const response = await saveRxs(patientValues.id, [updatedRxValues] as unknown as RxDto[]); // Use updatedFormData instead of formData
          handleData(updatedFormData);
          setFormData(updatedRxValues);
          setUnsavedChanges(false);
          await searchPatient({ id: patientValues.id });
        } catch (error: any) {
          console.error("Error saving Rx:", error);
          notificationStore.showMessage(
            error.message || "Fehler beim Speichern des Rezepts",
            "error",
            error
          );
        } finally {
          // Set loading back to false when the API call is complete
          setLoading(false);
        }
      };

      const [therapyRxs, setTherapyRxs] = useState(formData.therapyRxs); // State to manage therapyRxs array
      const componentRef = useRef(null);
      const handleDuplicateRow = (index: number, event: React.ChangeEvent<HTMLInputElement>,idValue?:string) => {
        const { value } = event.target;
        console.log("handleDuplicateRow called", { index, value }, event);
        setUnsavedChanges(true);


        const updatedTherapyRxs = formData.therapyRxs.map((therapy: any, index: number) => {
          // Check if the current therapy matches the ID we're updating
          if (therapy.id === idValue) {
            // Validate the amount
            if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
              notificationStore.showMessage(
                "Bitte fügen Sie einen gültigen Betrag hinzu.",
                "warning"
              );
              // Return the therapy as is to avoid changes
              return therapy;
            }
        
            // Temporarily update the therapy object
            const updatedTherapy = {
              ...therapy,
              isDoubled: value as any,
              doubledTreatmentIds: (
                therapy.doubledTreatmentIds && therapy.doubledTreatmentIds.length > 0
                  ? therapy.doubledTreatmentIds
                  : [uuidv4()]
              ) as any,
              amount: therapy.amount
            };
        
            // Check for duplicate therapies
            const isDuplicate = formData.therapyRxs.some((othertherapy, otherIndex) => {
              if (otherIndex !== index) {
                // Compare relevant properties
                return updatedTherapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
              }
              return false;
            });
        
            if (isDuplicate) {
              notificationStore.showMessage(
                "Die Behandlung ist bereits beendet, so dass kein Duplikat zulässig ist.",
                "warning"
              );
              // Return the therapy as is to avoid changes
              return therapy;
            }
        
            // Return the updated therapy if all conditions are met
            return updatedTherapy;
          }
        
          // Return the therapy as is if the ID doesn't match
          return therapy;
        });
        
        // Update the formData state with the updated therapyRxs
        setFormData((prevFormData) => ({
          ...prevFormData,
          therapyRxs: updatedTherapyRxs,
        }));
        

      
        // setFormData((prevFormData) => {
        //   const updatedTherapyRxs = [...prevFormData.therapyRxs];
        //   const therapy = updatedTherapyRxs[index];

        //   if (therapy) {
        //     // Validate the amount
        //     if (!therapy.amount || (therapy.amount as unknown as number) % 2 !== 0) {
        //       notificationStore.showMessage(
        //         "Bitte fügen Sie einen gültigen Betrag hinzu.",
        //         "warning"
        //       );
        //       return prevFormData; // Return the previous state without changes
        //     }
        //     // Check for duplicate therapies (you need to define your comparison logic)
        //     const isDuplicate = updatedTherapyRxs.some((othertherapy, otherIndex) => {
        //       if (otherIndex !== index) {
        //         // Compare relevant properties
        //         return therapy.therapy.abbreviation === othertherapy.therapy.abbreviation;
        //       }
        //       return false;
        //     });
        //     if (isDuplicate) {
        //       // Show an alert for duplicate therapies
        //       notificationStore.showMessage(
        //         "Die Behandlung ist bereit<s beendet, so dass kein Duplikat zulässig ist.",
        //         "warning"
        //       );
        //       return prevFormData;
        //     }

        //     therapy.isDoubled = value as any;
        //     // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        //     (therapy.doubledTreatmentIds = (
        //       therapy.doubledTreatmentIds && therapy.doubledTreatmentIds.length > 0
        //         ? therapy.doubledTreatmentIds
        //         : [uuidv4()]
        //     ) as any),
        //       (therapy.amount = therapy.amount);
        //   }

        //   return {
        //     ...prevFormData,
        //     therapyRxs: updatedTherapyRxs,
        //   };
        // });
      };
      

      const [filteredDiagnoseGroups, setFilteredDiagnoseGroups] = useState([]);

      const filterDiagnoseGroups = (rxtype: any) => {
        const filtered = diagnoseGroups[0].filter((option: any) => option.RxType?.id === rxtype);
        setFilteredDiagnoseGroups(filtered);
      };

      useEffect(() => {
        filterDiagnoseGroups(formData.rxtype);
      }, [formData.rxtype]);

      console.log("formData", formData);
      return (
        <Grid
          container
          direction={"row"}
          spacing={2}
          xs={12}
          sx={{ paddingTop: "0px", paddingLeft: "4px", border: "0px dashed blue" }}
        >
          {!readOnlyView ? (
            <Grid item xs={12} md={5} sx={{ border: "0px dashed green" }}>
              <FlatCard title={`Rezept ${rxFormData?.rxNumber || ""}`}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="rxtype"
                      label="Rezept-typ"
                      value={formData?.rxtype}
                      onChange={handleInputChange}
                      options={rxType[0]?.map((option: any) => ({
                        label: option?.text.toString(),
                        value: option?.text.toString(),
                        id: option?.id.toString(),
                      }))}
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="accountId"
                      label="Mandant"
                      value={formData?.accountId}
                      onChange={handleInputChange}
                      options={user?.location?.accounts?.map((option: any) => ({
                        label: option?.name?.toString(),
                        value: option?.name?.toString(),
                        id: option?.id?.toString(),
                      }))}
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="status"
                      label="Rezeptverbleib"
                      value={formData?.status}
                      onChange={handleInputChange}
                      options={[
                        {
                          label: "noch nicht erstellt",
                          value: "noch nicht erstellt",
                        },
                        { label: "beim Patient", value: "beim Patient" },
                        { label: "beim Therapeut", value: "beim Therapeut" },
                        { label: "vorhanden", value: "vorhanden" },
                      ]}
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="tarifGroupId"
                      label="Tarif Group"
                      id={1}
                      value={formData?.tarifGroupId}
                      onChange={handleInputChange}
                      options={
                        tarifGroupsMasterData
                          ? tarifGroupsMasterData.map((value: any) => ({
                              label: value.toString(),
                              value: value.toString(),
                            }))
                          : []
                      }
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>                 
                </Grid>
              </FlatCard>

              <FlatCard title="Arzt">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controls.Select
                      name="doctor"
                      label="Arzt aus der Liste wählen"
                      id={1}
                      value={selectedDoctor || formData.doctorId}
                      onChange={handleDoctorChange}
                      options={doctorOptions?.map((doctor: any) => ({
                        label: doctor.contactInfo.lastName.toString(),
                        value: doctor.contactInfo.lastName.toString(),
                        id: doctor.id.toString(),
                      }))}
                      disabled={readOnlyView}
                      style={{
                        width: "100%",
                        marginTop: "0px",
                        marginBottom: "0px",
                        fontSize: "10px",
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      onClick={() => handleOpenAddEditDialog(selectedLANR, selectedBSNR)}
                      disabled={readOnlyView}
                    >
                      Arzt hinzufügen / bearbeiten
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Controls.RadioGroup
                      label={
                        <Typography sx={{ textAlign: "left" }}>
                          Arztunterschrift vorhanden
                        </Typography>
                      }
                      name="signatureStatus"
                      value={formData?.signatureStatus}
                      options={[
                        { id: "nicht kontrolliert", label: "nicht kontrolliert" },
                        { id: "vorhanden", label: "vorhanden" },
                        { id: "fehlend", label: "fehlend" },
                      ]}
                      disabled={readOnlyView}
                      onChange={handleInputChange}
                      sx={{
                        width: "100%",
                        paddingLeft: "1rem",
                        textAlign: "left",
                        fontSize: "0.7rem",
                      }}
                    />
                  </Grid>
                </Grid>

                {isAddEditDialogOpen && (
                  <DoctorDialog
                    openAddEditDialog={isAddEditDialogOpen}
                    handleCloseAddEditDialog={handleCloseAddEditDialog}
                    doctorOptions={doctorOptions}
                    selectedLANR={formData.doctorNumber}
                    selectedBSNR={formData.practiceNumber}
                  />
                )}
              </FlatCard>

              <FlatCard title="Art der Besuch">
                <Controls.RadioGroup
                  // disabled={readOnlyView}
                  label=""
                  name="visitType"
                  value={formData.visitType}
                  options={[
                    { id: "Praxis", label: "Praxis" },
                    { id: "Haus", label: "Haus" },
                    { id: "Heim", label: "Heim" },
                  ]}
                  onChange={(event: any) => {
                    handleInputChange(event);
                    setHausbesuchSelected(
                      event.target.value === "Haus" || event.target.value === "Heim"
                    );
                  }}
                />
              </FlatCard>
              <FlatCard title=" Heilmittel nach Maßgabe des Kataloges" sx={{width:"500px"}}>

                    <Table
                      sx={{
                        borderCollapse: "collapse",
                        width: "100%",
                        border: "1px solid #ccc",
                        background: "white",
                        paddingTop:"10px"
                      
                      }}
                    >
                      <TableBody
                        sx={{ maxHeight: "50px", overflowY: "scroll" }}
                      >
                        <TableCell>Heilmittel</TableCell>
                        <TableCell></TableCell>
                        <TableCell>Menge</TableCell>
                        <TableCell>Series</TableCell>
                        {formData?.therapyRxs.map((therapy, rowIndex) => (
                          <TableRow
                            key={rowIndex}
                            // style={{ border: "1px solid red", height: "1px" }}
                          >
                            <TableCell
                              sx={{
                                padding: "0px",
                                width: "50px",
                                height: "1px",
                              }}
                            >
                              <Controls.Select
                                disabled={readOnlyView}
                                name="therapy"
                                label=""
                                value={therapy.therapy.abbreviation}
                                onChange={(event: any) => handleInputChange(event, rowIndex)}
                                // options={therapyOptions[0]
                                //   ?.filter((therapyObj: any) => therapyObj.duration)
                                //   ?.map((therapy: any) => ({
                                //     label: therapy?.abbreviation?.toString(),
                                //     value: therapy?.abbreviation?.toString(),
                                //   }))}
                                options={therapyOptions[0]
                                  ?.filter((therapyObj: any) => therapyObj.duration)
                                  ?.reduce((acc: any[], therapy: any) => {
                                    const abbreviation = therapy?.abbreviation?.toString();
                                    if (abbreviation && !acc.some(option => option.value === abbreviation)) {
                                      acc.push({
                                        label: abbreviation,
                                        value: abbreviation,
                                      });
                                    }
                                    return acc;
                                  }, [])}
                                style={{
                                  width: "100px",
                                  marginTop: "0px",
                                  marginBottom: "0px",
                                  fontSize: "10px",
                                }}
                              />
                            </TableCell>
                            {/* <TableCell
                              sx={{
                                padding: "0px",
                                direction: "column",
                                height: "1px",
                              }}
                            >
                              <Typography sx={{ fontSize: "10px" }}>
                                {therapy.therapy.description}
                              </Typography>
                            </TableCell> */}
                            <TableCell
                              sx={{
                                padding: "0px",
                                width: "10px",
                                height: "1px",
                              }}
                            >
                              <Controls.Checkbox
                                name="doubled"
                                value={therapy.isDoubled}
                                label={
                                  <Typography sx={{ fontSize: "10px" }}>
                                    Doppelbehandlung
                                  </Typography>
                                }
                                disabled={
                                  !(
                                    therapy.therapy.abbreviation
                                  ) || readOnlyView
                                }
                                onChange={(event: any) =>{
                                  handleDuplicateRow(
                                    rowIndex,
                                    event,
                                    therapy.id
                                  );
                                  
                                }}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0px",
                                width: "50px",
                                height: "1px",
                                borderLeft: "1px solid #ccc",
                              }}
                            >
                              <Controls.Input
                                name="amount"
                                disabled={readOnlyView}
                                label=""
                                value={therapy.amount}
                                onChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => {
                                  handleInputChange(
                                    event,
                                    rowIndex,
                                    therapy.id
                                  );
                                }} // Pass rowIndex to handleInputChange
                                style={{ width: "100%", margin: "0px" }}
                              />
                              </TableCell>
                              <TableCell
                              sx={{
                                padding: "0px",
                                width: "80px",
                                height: "1px",
                                borderLeft: "1px solid #ccc",
                              }}
                            >
                               <Controls.Select
        name="rxSeries"
        // label="Rezeptverbleib"
        id={1}
        style={{ width: "100%", margin: "0px" }}
        value={therapy.rxSeries?.label}
        onChange={(event:any) =>  handleInputChange(event,rowIndex, therapy.id)}
        options={savedSeriesForPatient.map((option:any) => ({
          label: option.label,
          value: option.label,
          id:option.id
        }))}/>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                
                  </FlatCard>

              {/* <Controls.Input
                name="practiceNumber"
                label="BSNR"
                value={selectedBSNR || formData?.practiceNumber}
                onChange={handleInputChange}
              /> */}
            </Grid>
          ) : (
            <Grid item xs={12} md={5} sx={{ border: "0px dashed green" }}>
              <FlatCard title="Allgemein" sx={{ marginBottom: "2px" }}>
                <Grid container spacing={0} justifyContent="space-between">
                  {/* First Row */}
                  <Grid item xs={5}>
                    <Typography textAlign="left" sx={{ fontSize: "0.8em" }}>
                      Rezept-Typ:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controls.Chip
                      label={formData.rxtype || "-"}
                      sx={{ width: "100%", fontSize: "0.7rem" }}
                    />
                  </Grid>

                  {/* Second Row */}
                  <Grid item xs={5}>
                    <Typography textAlign="left" style={{ fontSize: "0.8rem" }}>
                      Account:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controls.Chip
                      label={formData.accountId || "-"}
                      sx={{ width: "100%", fontSize: "0.7rem",padding:"0px" }}
                    />
                  </Grid>

                  {/* Third Row */}
                  <Grid item xs={5}>
                    <Typography textAlign="left"  style={{ fontSize: "0.8rem" }}>
                      Rx Location:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Controls.Chip
                      label={formData.visitType || "-"}
                      sx={{ width: "100%", fontSize: "0.7rem" }}
                    />
                  </Grid>
                </Grid>
              </FlatCard>

              <FlatCard
                title="Arzt"
                sx={{ paddingTop: "5px", marginBottom: "8px", textAlign: "left" }}
              >
                <Typography variant="body2" sx={{ fontSize: "0.8rem" }}>
                  {`${formData?.doctorData?.contactInfo?.salutation || "Dr."} ${
                    formData?.doctorData?.contactInfo?.firstNames || ""
                  } ${formData?.doctorData?.contactInfo?.lastName || ""}`}
                  <br />
                  {`Address:${formData?.doctorData?.contactInfo?.addressLine1 || ""}`}

                  {`${formData?.doctorData?.contactInfo?.addressLine2 || ""}`}
                  <br />
                  {`Tel: ${formData?.doctorData?.doctor?.contactInfo?.phone1 || ""}`}
                  <br />
                  {`LANR: ${formData?.doctorData?.doctor?.number || ""}`}
                  <br />
                  {`BSNR: ${formData?.doctorData?.doctor?.clinicNumber || ""}`}
                </Typography>
              </FlatCard>
             {rxFormData &&  <FlatCard title="Validierung" sx={{ marginBottom: "8px" }}>
                <ValidationCardRxInfoPage patientData={patientValues} selectedRx={rxFormData} />
              </FlatCard>}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            md={7}
            sx={{ display: "flex", border: "0px solid red", borderColor: "transparent" }}
          >
            {" "}
            {/* transparent border color is a hack because i couldnt figure out any way to add top padding*/}
            <ScalableComponentSimple occupiedHeight={readOnlyView ? 0.8 : 1}>
              <Container
                sx={{ border: "0px solid yellow" }}
                className={classes.container}
                ref={componentRef}
              >
                {/* Small box */}
                <form onSubmit={handleGenericSubmit} className={classes.form}>
                  <Grid container spacing={1}>
                    <Grid item sx={{ border: "0px solid green" }}>
                      <Grid container spacing={1} sx={{ flexGrow: 1 }}>
                        {/* Checkboxes */}
                        {/* Checkbox on the left */}
                        <Grid item xs={1}>
                          <Grid item xs={3}>
                            <div style={{ position: "relative" }}>
                              <Controls.Checkbox
                                style={{
                                  position: "absolute",
                                  zIndex: 2,
                                  left: -10,
                                  top: -20,
                                  "&:not(.Mui-checked)": {
                                    // Define the styles for the unchecked state here
                                    color: "rgba(128, 128, 128, 0.2)",
                                  },
                                  "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                                }}
                                disabled={readOnlyView}
                                name="isCopaymentExempted"
                                value={formData?.isCopaymentExempted}
                                onChange={handleInputChange}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 3,
                                  top: 10,
                                  left: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none", // Make the label not clickable
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "9px",
                                    color: attentionColors.immediateAttention,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Zuzah- lungs- frei
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ position: "relative" }}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                style={{
                                  position: "absolute",
                                  zIndex: 2,
                                  left: -10,
                                  top: 10,
                                  "&:not(.Mui-checked)": {
                                    // Define the styles for the unchecked state here
                                    color: "rgba(128, 128, 128, 0.2)",
                                  },

                                  "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                                }}
                                name="isCopaymentExempted"
                                value={!formData?.isCopaymentExempted}
                                onChange={handleInputChange}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 3,
                                  top: 40,
                                  left: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none", // Make the label not clickable
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "9px",
                                    color: attentionColors.immediateAttention,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Zuzah- lungs- pflicht
                                </span>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={3}>
                            <div style={{ position: "relative" }}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                style={{
                                  position: "absolute",
                                  zIndex: 2,
                                  left: -10,
                                  top: 40,
                                  "&:not(.Mui-checked)": {
                                    // Define the styles for the unchecked state here
                                    color: "rgba(128, 128, 128, 0.2)",
                                  },
                                  "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                                }}
                                name="isAccident"
                                value={formData?.isAccident}
                                onChange={handleInputChange}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 3,
                                  top: 70,
                                  left: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none", // Make the label not clickable
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "9px",
                                    color: attentionColors.immediateAttention,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Unfall- folgen
                                </span>
                              </div>
                            </div>
                          </Grid>

                          <Grid item xs={3}>
                            <div style={{ position: "relative" }}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                style={{
                                  position: "absolute",
                                  zIndex: 2,
                                  left: -10,
                                  top: 70,
                                  "&:not(.Mui-checked)": {
                                    // Define the styles for the unchecked state here
                                    color: "rgba(128, 128, 128, 0.2)",
                                  },
                                  "& .MuiSvgIcon-root": { fontSize: 50 }, // Adjust the size of the checkbox icon
                                }}
                                name="isBVG"
                                value={formData?.isBVG}
                                onChange={handleInputChange}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  zIndex: 3,
                                  top: 100,
                                  left: 20,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "100%",
                                  height: "100%",
                                  pointerEvents: "none", // Make the label not clickable
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "9px",
                                    color: attentionColors.immediateAttention,
                                    fontWeight: "bold",
                                  }}
                                >
                                  BVG
                                </span>
                              </div>
                            </div>
                          </Grid>
                        </Grid>

                        {/* Form content */}
                        <Grid item xs={6}>
                          <div className={classes.box}>
                            {/* <Grid container spacing={2}> */}

                            <Grid item xs={12}>
                              <Typography className={classes.typography}>
                                Krankenkasse bzw. Kostenträger
                              </Typography>
                              <div style={{ height: "30px" }}>
                                <Controls.Select
                                  disabled={readOnlyView}
                                  name="Receiptpayer"
                                  label=""
                                  id={1}
                                  value={selectedPayer || formData.Receiptpayer}
                                  onChange={handleInputChange}
                                  options={insurer[0]?.map((option: any) => ({
                                    label: option.ik.toString(),
                                    value: option.insurer.toString(),
                                    id: option.id.toString(),
                                  }))}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                />
                              </div>
                              <Divider
                                sx={{
                                  flexGrow: 1,
                                  background: attentionColors.immediateAttention,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography className={classes.typography}>
                                Name, Vorname des Versicherten
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name=""
                                    label=""
                                    value={formData?.lastName}
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name=""
                                    label=""
                                    value={formData?.firstName}
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography className={classes.typography}>geb . am</Typography>
                                  <Controls.DatePicker
                                    name=""
                                    label=""
                                    value={formData.dob}
                                    onChange={handleInputChange}
                                    disabled
                                    variant="standard"
                                    style={{
                                      width: "100%",
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "10px",
                                      "& .MuiInputBase-input": {
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: "0px",
                                      },
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider
                                sx={{
                                  flexGrow: 1,
                                  background: attentionColors.immediateAttention,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={1} alignItems="center">
                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>
                                    Kostenträgerkennung
                                  </Typography>
                                  <div style={{ height: "30px" }}>
                                    <Controls.Select
                                      disabled={readOnlyView}
                                      name="chipcard"
                                      label=""
                                      id={1}
                                      value={selectedChipCard || formData?.chipcard}
                                      onChange={handleInputChange}
                                      options={insurer[0]?.map((insurer: any) => ({
                                        label: insurer.insurer.toString(),
                                        value: insurer.ik.toString(),
                                      }))}
                                      style={{
                                        width: "100%",
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        fontSize: "10px",
                                      }}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>
                                    Versicherten-Nr.
                                  </Typography>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name="insureeNumber"
                                    label=""
                                    value={formData?.insureeNumber}
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>Status</Typography>
                                  <Controls.Select
                                    disabled={readOnlyView}
                                    name="insuranceStatus"
                                    label=""
                                    value={formData?.insuranceStatus}
                                    onChange={handleInputChange}
                                    options={[
                                      { label: "10000", value: "10000" },
                                      { label: "30000", value: "30000" },
                                      { label: "50000", value: "50000" },
                                    ]}
                                    style={{
                                      width: "100%",
                                      marginTop: "0px",
                                      marginBottom: "0px",
                                      fontSize: "10px",
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Divider
                                sx={{
                                  flexGrow: 1,
                                  background: attentionColors.immediateAttention,
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>
                                    Betriebsstätten-Nr.
                                  </Typography>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name="practiceNumber"
                                    label=""
                                    value={selectedBSNR || formData?.practiceNumber}
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>

                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>Arzt-Nr.</Typography>
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name="doctorNumber"
                                    label=""
                                    value={selectedLANR || formData?.doctorNumber}
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Typography className={classes.typography}>Datum</Typography>
                                  <Controls.DatePicker
                                    disabled={readOnlyView}
                                    name="date"
                                    label=""
                                    value={formData?.date}
                                    onChange={handleInputChange}
                                    variant="standard"
                                    style={{
                                      width: "100%",
                                      padding: "0px",
                                      margin: "0px",
                                      fontSize: "10px",
                                      "& .MuiInputBase-input": {
                                        padding: 0,
                                        margin: 0,
                                        paddingRight: "0px",
                                      },
                                    }}
                                    formatDate="dd.MM.yy"
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        {/* Checkboxes on the right */}
                        <Grid item xs={5} sx={{ paddingLeft: "95px !important" }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              whiteSpace: "normal",
                              color: attentionColors.immediateAttention,
                            }}
                          >
                            Heilmittelverordnung 13
                          </Typography>
                          <Grid item xs={2}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    whiteSpace: "normal",
                                    color: attentionColors.immediateAttention,
                                  }}
                                >
                                  Physiotherapie
                                </Typography>
                              }
                              value={true}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    whiteSpace: "normal",
                                    color: attentionColors.immediateAttention,
                                  }}
                                >
                                  Podologische
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    whiteSpace: "normal",
                                    color: attentionColors.immediateAttention,
                                  }}
                                >
                                  Stimm-,Sprech-
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Controls.Checkbox
                              disabled={readOnlyView}
                              name=""
                              label={
                                <Typography
                                  sx={{
                                    fontSize: "12px",
                                    whiteSpace: "normal",
                                    color: attentionColors.immediateAttention,
                                  }}
                                >
                                  Ergotherapie
                                </Typography>
                              }
                              value={false}
                              onChange={handleInputChange}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} sx={{ paddingTop: "23px" }}>
                        <Grid item xs={12}>
                          <Typography className={classes.typography} sx={{ fontSize: "12px" }}>
                            Behandlungsrelevante Diagnose(n)
                            <br />
                            ICD-10{" "}
                            <Button
                              onClick={handleAddInput}
                              sx={{ padding: 0, minWidth: "20px", height: "12px" }}
                              disabled={readOnlyView}
                            >
                              <Add
                                sx={{
                                  width: "15px",
                                  color: attentionColors.immediateAttention,
                                }}
                              />
                            </Button>
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <Box
                            sx={{
                              height: "75px",
                              width: "90%",
                              border: "1px solid lightgray",
                              overflowY: "scroll",
                              overflowX: "hidden",
                              padding: "3px",
                              backgroundColor: "white",
                            }}
                          >
                            {formData.icdCodeEntries.map((icdEntry: any, index: any) => (
                              <div key={`icd-${index}`}>
                                <Controls.Input
                                  disabled={readOnlyView}
                                  label=""
                                  name={`icdCodeEntries-${index}`}
                                  value={icdEntry.userText}
                                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleInputChange(e, index, icdEntry?.id);
                                  }}
                                  onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                    if (e.key === "Tab" || e.key === "Enter") {
                                      e.preventDefault();
                                      // handleICDCodeEntrySubmit(formValues.icdCodeEntries[index], index);
                                      handleICDCodeEntrySubmit(icdEntry, index);
                                    }
                                  }}
                                />
                              </div>
                            ))}
                          </Box>
                        </Grid>
                        <Grid
                          item
                          xs={9}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                        >
                          <Controls.TextArea
                            disabled={readOnlyView}
                            name="diagnosisText"
                            rows={5}
                            placeholder=""
                            value={formData?.diagnosisText}
                            onChange={handleInputChange}
                            style={{ flex: 1, height: "75px" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={1} sx={{ paddingTop: "5px" }}>
                        <Grid item xs={12} sx={{ paddingTop: "10px" }}>
                          <Grid container>
                            <Grid item xs={1}>
                              <Typography className={classes.typography} sx={{ fontSize: "12px" }}>
                                Diagnose gruppe
                              </Typography>
                            </Grid>
                            <Grid item xs={1}>
                              <div
                                style={{
                                  position: "absolute",
                                  width: "0.6in",
                                  height: "0.3in",
                                  backgroundColor: "white",
                                }}
                              >
                                <Controls.Select
                                  disabled={readOnlyView}
                                  name="diagnosegroup"
                                  label=""
                                  // id={1}
                                  value={formData?.diagnosegroup}
                                  onChange={handleInputChange}
                                  options={filteredDiagnoseGroups?.map((option: any) => ({
                                    label: option?.code.toString(),
                                    value: option?.code.toString(),
                                    id: option?.id.toString(),
                                  }))}
                                  style={{
                                    width: "100%",
                                    marginTop: "0px",
                                    marginBottom: "0px",
                                    fontSize: "10px",
                                  }}
                                />
                              </div>
                            </Grid>
                            <Grid item xs={2} sx={{ paddingLeft: "12px", paddingTop: "1px" }}>
                              <Typography className={classes.typography} sx={{ fontSize: "12px" }}>
                                Leitsymptomatik
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "10px",
                                  color: attentionColors.immediateAttention,
                                }}
                              >
                                gemäß Heilmittelkatalog
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sx={{ paddingLeft: "15px" }}>
                              <Controls.Checkbox
                                name="a"
                                label="a"
                                value={formData?.a}
                                onChange={handleInputChange}
                                disabled={!selectedDiagnoseGroup?.a || readOnlyView}
                              />
                              <Controls.Checkbox
                                name="b"
                                label="b"
                                value={formData?.b}
                                onChange={handleInputChange}
                                disabled={!selectedDiagnoseGroup?.b || readOnlyView}
                              />
                              <Controls.Checkbox
                                name="c"
                                label="c"
                                value={formData?.c}
                                onChange={handleInputChange}
                                disabled={!selectedDiagnoseGroup?.c || readOnlyView}
                              />
                              <Controls.Checkbox
                                name="d"
                                label="d"
                                value={formData?.d}
                                onChange={handleInputChange}
                                disabled={!selectedDiagnoseGroup?.d || readOnlyView}
                              />
                            </Grid>
                            <Grid item xs={3} sx={{ paddingLeft: "3px" }}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                name="hasIndividualSymptoms"
                                label={
                                  <Typography sx={{ fontSize: "10px" }}>
                                    Patientenindividuelle Leitsymptomatik
                                  </Typography>
                                }
                                value={formData?.hasIndividualSymptoms}
                                onChange={handleInputChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={9}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: attentionColors.immediateAttention,
                          }}
                        >
                          Leitsymptomatik (patientenindividuelle Leitsymptomatik als Freitext
                          angeben)
                        </Typography>
                        <Controls.TextArea
                          name="symptoms"
                          disabled={readOnlyView}
                          rows={4}
                          placeholder=""
                          value={formData?.symptoms}
                          onChange={handleInputChange}
                          style={{ flex: 1, height: "50px " }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Typography className={classes.typography}>
                          Heilmittel nach Maßgabe des Kataloges
                        </Typography>
                        <Grid item xs={12} sx={{ maxHeight: "140px", overflowY: "auto" }}>
                          <Table
                            sx={{
                              borderCollapse: "collapse",
                              width: "100%",
                              border: "1px solid red",
                              background: "white",
                            }}
                          >
                            <TableBody sx={{ maxHeight: "50px", overflowY: "scroll" }}>
                              {formData?.therapyRxs.map((therapy, rowIndex) => (
                                <TableRow
                                  key={rowIndex}
                                  // style={{ border: "1px solid red", height: "1px" }}
                                >
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      width: "50px",
                                      height: "1px",
                                    }}
                                  >
                                    <Controls.Select
                                      disabled={readOnlyView}
                                      name="therapy"
                                      label=""
                                      value={therapy.therapy.abbreviation}
                                      onChange={(event: any) => handleInputChange(event, rowIndex)}
                                      // options={therapyOptions[0]
                                      //   ?.filter((therapyObj: any) => therapyObj.duration)
                                      //   ?.map((therapy: any) => ({
                                      //     label: therapy?.abbreviation?.toString(),
                                      //     value: therapy?.abbreviation?.toString(),
                                      //   }))}
                                      options={therapyOptions[0]
                                        ?.filter((therapyObj: any) => therapyObj.duration)
                                        ?.reduce((acc: any[], therapy: any) => {
                                          const abbreviation = therapy?.abbreviation?.toString();
                                          if (
                                            abbreviation &&
                                            !acc.some((option) => option.value === abbreviation)
                                          ) {
                                            acc.push({
                                              label: abbreviation,
                                              value: abbreviation,
                                            });
                                          }
                                          return acc;
                                        }, [])}
                                      style={{
                                        width: "110px",
                                        marginTop: "0px",
                                        marginBottom: "0px",
                                        fontSize: "10px",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      direction: "column",
                                      height: "1px",
                                    }}
                                  >
                                    <Typography sx={{ fontSize: "10px" }}>
                                      {therapy.therapy.description}
                                    </Typography>
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      width: "30px",
                                      height: "1px",
                                    }}
                                  >
                                    <Controls.Checkbox
                                      name="doubled"
                                      value={therapy.isDoubled}
                                      label={
                                        <Typography sx={{ fontSize: "10px" }}>
                                          Doppelbehandlung
                                        </Typography>
                                      }
                                      disabled={!therapy.therapy.abbreviation || readOnlyView}
                                      onChange={(event: any) => {
                                        handleDuplicateRow(
                                          rowIndex,
                                          event,
                                          therapy.id
                                        );
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      width: "30px",
                                      height: "1px",
                                      borderLeft: "1px solid red",
                                    }}
                                  >
                                    <Controls.Input
                                      name="amount"
                                      disabled={readOnlyView}
                                      label=""
                                      value={therapy.amount}
                                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        handleInputChange(event, rowIndex, therapy.id);
                                      }} // Pass rowIndex to handleInputChange
                                      style={{ width: "100%", margin: "0px" }}
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>

                        <Typography
                          sx={{
                            fontSize: "10px",
                            color: attentionColors.immediateAttention,
                          }}
                        >
                          Ergänzendes Heilmettel
                        </Typography>
                        <Grid item xs={12}>
                          <Table
                            sx={{
                              borderCollapse: "collapse",
                              width: "100%",
                              border: "1px solid red",
                              background: "white",
                            }}
                          >
                            <TableBody sx={{ maxheight: "10px", overflowY: "scroll" }}>
                              {[...Array(1)].map((_, rowIndex) => (
                                <TableRow
                                  key={rowIndex}
                                  style={{ border: "1px solid red", height: "2px" }}
                                >
                                  {/* First column - Autocomplete with therapy.id */}
                                  <TableCell
                                    sx={{
                                      padding: "0px",
                                      width: "50px",
                                      height: "1px",
                                    }}
                                  >
                                    <Autocomplete
                                      id={`therapy-id-${rowIndex}`}
                                      disabled={readOnlyView}
                                      options={formData.therapyRxs.map((rx) => rx.therapy.id)}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label=""
                                          variant="standard"
                                          sx={{ width: "90px", margin: "0px" }}
                                        />
                                      )}
                                    />
                                  </TableCell>
                                  {/* Second column - Display therapy abbreviation with checkbox */}
                                  <TableCell sx={{ padding: "5px", direction: "column" }}>
                                    {/* <Typography  sx={{ fontSize:"10px" }}>{formData.therapyRxs[rowIndex]?.therapy?.abbreviation}</Typography> */}
                                  </TableCell>
                                  <TableCell sx={{ padding: "5px", width: "30px" }}>
                                    {/* <Controls.Checkbox
                    name="duplicate"
                    label={<Typography  sx={{ fontSize:"10px" }}>Doppelbehandlung</Typography>}
                    // Check if rowIndex is within bounds and therapy is not empty
                    disabled={!(rowIndex >= 0 && rowIndex < therapyRxs.length && therapyRxs[rowIndex].therapy)}
                    // Pass rowIndex to the handleChange function
                    onChange={(event:any) => handleDuplicateRow(rowIndex,event)}
                  /> */}
                                  </TableCell>
                                  {/* Third column - Display amount as text */}
                                  <TableCell
                                    sx={{
                                      padding: "5px",
                                      width: "30px",
                                      borderLeft: "1px solid red",
                                    }}
                                  >
                                    {/* <Typography >{formData.therapyRxs[rowIndex]?.amount}</Typography> */}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={3}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                name="therapyReport"
                                label={
                                  <Typography className={classes.typography}>
                                    {" "}
                                    Therapiebericht
                                  </Typography>
                                }
                                value={formData?.therapyReport}
                                onChange={handleInputChange}
                              />
                            </Grid>
                            <Grid item xs={5}>
                              <Box display="flex" alignItems="center">
                                <Typography
                                  variant="body1"
                                  className={classes.typography}
                                  sx={{ marginRight: "8px" }}
                                >
                                  Hausbesuch
                                </Typography>
                                <FormControlLabel
                                  label=""
                                  control={
                                    <>
                                      <Checkbox
                                        checked={isHausbesuchSelected === true}
                                        onChange={handleCheckboxChange}
                                        disabled={readOnlyView}
                                        name="visitTypeCheckboxJa"
                                        value="yes"
                                      />
                                      <Typography variant="body1">ja</Typography>
                                      <Checkbox
                                        checked={isHausbesuchSelected === false}
                                        onChange={handleCheckboxChange}
                                        disabled={readOnlyView}
                                        name="visitTypeCheckboxNein"
                                        value="no"
                                      />
                                      <Typography variant="body1">nein</Typography>
                                    </>
                                  }
                                />
                              </Box>

                              <Dialog open={popupOpen} onClose={handlePopupClose}>
                                <DialogTitle>
                                  Bitte wählen Sie die Art des Hausbesuchs, um mit Ihrer Auswahl
                                  fortzufahren
                                </DialogTitle>
                                <DialogContent>
                                  <FormControl component="fieldset">
                                    <RadioGroup
                                      aria-label="visitType"
                                      name="popupVisitType"
                                      value={formData.visitType}
                                      onChange={handlePopupSelect}
                                    >
                                      <FormControlLabel
                                        value="Haus"
                                        control={<Radio />}
                                        label="Haus"
                                      />
                                      <FormControlLabel
                                        value="Heim"
                                        control={<Radio />}
                                        label="Heim"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </DialogContent>
                                <DialogActions>
                                  <Button variant="text" onClick={handlePopupClose}>
                                    Abbrechen
                                  </Button>
                                </DialogActions>
                              </Dialog>
                            </Grid>

                            <Grid item xs={4}>
                              <Grid container>
                                <Grid item xs={4} sx={{ paddingTop: "2px" }}>
                                  <Typography className={classes.typography}>
                                    Therapie-frequenz
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={6}
                                  sx={{
                                    background: "white",
                                    paddingTop: "4px",
                                    borderBottom: `1px solid ${attentionColors.immediateAttention}`,
                                  }}
                                >
                                  <Controls.Input
                                    disabled={readOnlyView}
                                    name="therapyFrequency"
                                    label=""
                                    value={
                                      Array.from(
                                        new Set(
                                          formData.therapyRxs.map(
                                            (therapy: any) => therapy.frequency?.id
                                          )
                                        )
                                      )[0]
                                    }
                                    onChange={handleInputChange}
                                    style={{ width: "100%", margin: "0px" }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={5}>
                              <Controls.Checkbox
                                disabled={readOnlyView}
                                name="urgent"
                                label={
                                  <Typography className={classes.typography}>
                                    Dringlicher Behandlungsbedarf innerhalb von 14 Tagen
                                  </Typography>
                                }
                                value={formData?.urgent}
                                onChange={handleInputChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={1}>
                        {/* <Grid item xs={12}> */}
                        <Grid container>
                          <Grid
                            item
                            xs={7}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              paddingTop: "5px",
                            }}
                          >
                            <Controls.TextArea
                              label={
                                <Typography className={classes.typography}>
                                  ggf. Thrapieziele/weitre med. Befunde und Himweise
                                </Typography>
                              }
                              disabled={readOnlyView}
                              name="additionalNotes"
                              rows={6}
                              placeholder=""
                              value={formData?.additionalNotes}
                              onChange={handleInputChange}
                              style={{ flex: 1, height: "100px " }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              paddingLeft: "20px",
                            }}
                          >
                            {/* <TextArea
                  rows={7}
                  cols={100}
                  value={`${formData.doctorData?.contactInfo?.salutation} ${formData.doctorData?.contactInfo?.firstNames} ${formData.doctorData?.contactInfo?.lastName}\n` +
                    `${formData.doctorData?.contactInfo?.addressLine1}\n` +
                    `${formData.doctorData?.contactInfo?.addressLine2}\n` +
                    `Tel: ${formData.doctorData?.contactInfo?.tel}\n` + // Added "Tel:" label
                    `BSNR: ${formData.doctorData?.clinicNumber}` // Added "BSNR:" label
                  }
                  onChange={handleInputChange}
                  sx={{ width: '50px', textAlign: 'justify', maxHeight: "80px", overflowY: "scroll" }} /> */}
                            <Controls.TextArea
                              name="doctorData"
                              rows={7}
                              placeholder=""
                              value={
                                `${formData.doctorData?.contactInfo?.salutation || ""} ${
                                  formData.doctorData?.contactInfo?.firstNames || ""
                                } ${formData.doctorData?.contactInfo?.lastName || ""}\n` +
                                `${formData.doctorData?.contactInfo?.addressLine1 || ""}\n` +
                                `${formData.doctorData?.contactInfo?.addressLine2 || ""}\n` +
                                `Tel: ${formData.doctorData?.contactInfo?.phone1 || ""}\n` + // Added "Tel:" label
                                `BSNR: ${formData.doctorData?.clinicNumber || ""}` // Added "BSNR:" label
                              }
                              onChange={handleInputChange}
                              disabled
                              style={{ flex: 1, height: "80px " }}
                            />
                          </Grid>
                          <Grid container spacing={1}>
                            <Grid item xs={4}>
                              <Typography className={classes.typography}>
                                IK des Leitungserbringers
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Box
                                sx={{
                                  background: "white",
                                  width: "100px",
                                  height: "20px",
                                }}
                              ></Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* </Grid> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
                <Modal
                  open={loading}
                  aria-labelledby="loading-modal-title"
                  aria-describedby="loading-modal-description"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <CircularProgress size={60} />
                  </div>
                </Modal>
              </Container>
            </ScalableComponentSimple>
          </Grid>
        </Grid>
      );
    }
  )
);

export default Verordnung13;
