import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { Configuration, RxApi } from '../../api-client';
import RxTherapyStore from './rxStore';
import RxStore from './rxStore';
import useStore from '../../helpers/useStore';
import { AuthContext } from '../Auth/auth.provider';
import { PatientsContext } from '../Patients/patients.provider';


interface RxProviderProps {
  children: ReactNode;
}

export const RxStoreContext = createContext<RxStore | null>(null);
RxStoreContext.displayName = 'RxStoreContext';

export const RxStoreProvider = ({ children }: RxProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;
const patientStore=useStore(PatientsContext)
const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});
  const apiClient = new RxApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new RxStore(apiClient,AuthenticationStore,patientStore)
  );

  store.apiClient = apiClient;

  return (
    <RxStoreContext.Provider value={store}>
      {children}
    </RxStoreContext.Provider>
  );
};