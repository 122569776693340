// MasterTablesProvider.js
import React, { createContext, ReactNode, useEffect } from 'react';
import { Configuration, MasterlistsApi } from '../../api-client';
import MasterTablesStore from './MasterTablesStore';

interface MasterTablesProviderProps {
  children: ReactNode;
}

export const MasterTablesContext = createContext<MasterTablesStore | null>(null);
MasterTablesContext.displayName = 'MasterTablesContext';

export const MasterTablesProvider = ({ children }: MasterTablesProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const apiClient = new MasterlistsApi({} as Configuration, basePath);
  const store = new MasterTablesStore(apiClient);

  useEffect(() => {
    store.initialize(); // Call the initialization method only once when component mounts
  }, []); // Empty dependency array ensures this effect runs only once

  

  return (
    <MasterTablesContext.Provider value={store}>
      {children}
    </MasterTablesContext.Provider>
  );
};
