import React from "react";
import Avatar from "@mui/material/Avatar";
import { SxProps } from "@mui/system";
import { mobiliTheme } from "../themes/mobiliTheme";
import { Theme, Tooltip, Typography } from "@mui/material";
import { UserDto } from "../api-client";
import { Person } from "@mui/icons-material";

interface CustomAvatarProps {
  src?: string;
  isTherapist?: boolean;
  sx?: SxProps<Theme>; // Adding an sx prop for external styling
  user?: UserDto;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

// CustomAvatar as an arrow function with TypeScript typings and external sx support
export const MobiliAvatar: React.FC<CustomAvatarProps> = ({ src, isTherapist, sx, user ,onClick}) => {
  let isTherapist__ = isTherapist;
  let fontSize = "1rem";
  if (sx && (sx as any).fontSize)
    fontSize = (sx as any).fontSize as string;

  if (isTherapist__ === undefined && user !== undefined) {
    isTherapist__ = user?.roles?.some((role: { id: string }) => role.id === "therapist");
    console.log("isTherapist__", isTherapist__);
    console.log("user", user);
    console.log("user?.roles", user?.roles);
  }
  // Define default styles conditionally based on isTherapist
  const defaultStyles = {
    // width: 48,   // or any other size
    // height: 48,  // match width for a perfect circle
    color: "white", // Text color
    backgroundColor: isTherapist__ ? mobiliTheme.palette.secondary.main : mobiliTheme.mainHeader, // Background color
    border: `0.2rem solid ${
      isTherapist__ ? mobiliTheme.mainHeader : mobiliTheme.palette.secondary.main
    }`, // Border color
    boxShadow: "0 0.2rem 0.4rem rgba(0,0,0,0.25)",
  };

  let toolTip = "Keiner";
  if (user)
    toolTip = `${user?.firstName} ${user?.lastName}`;

  return (
    <Tooltip title={toolTip}>
      <Avatar
        onClick={onClick}
        sx={{ ...defaultStyles, ...sx }} // Combine default styles with external sx
        src={src}
        alt={src ? "User Avatar" : "Default Avatar"}
      >
        {!src && user?.nickName && (
            <Typography fontSize={fontSize}>{user?.nickName}</Typography>
        )}
        {!src && !user?.nickName && <Person />}
      </Avatar>
    </Tooltip>
  );
};
