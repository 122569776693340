import { useLocalStore } from "mobx-react";
import React, { createContext, ReactNode } from "react";

import { UserProfileApi, Configuration } from "../../api-client";
import UserStore from "./UserStore";
import useStore from "../../helpers/useStore";
import { AuthContext } from "../Auth/auth.provider";
import { observer } from "mobx-react";

interface UserProviderProps {
  children: ReactNode;
}

export const UserContext = createContext<UserStore | null>(null);
UserContext.displayName = "UserContext";

export const UserProvider = observer(({ children }: UserProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore


  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  console.log("User Provider has token:", authToken);
  const apiClient = new UserProfileApi(config);

  const store = new UserStore(apiClient,AuthenticationStore);


  store.apiClient = apiClient;

  if (authToken) {
    store.getUser().then((res) => { console.log("User Provider has token:", res, res?.email); });
  }else{
    console.log("User Provider has no token:", authToken);
  }
  
  return (
    <UserContext.Provider value={store}>{children}</UserContext.Provider>
  );

});
