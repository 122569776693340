import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Controls from "../../../../atoms/Controls";
import { WorkPlanDto } from "../../../../api-client";
import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import useStore from "../../../../helpers/useStore";
import notificationStore from "../../../../stores/Notification/notificationStore";
import { UserContext } from "../../../../stores/User/User.provider";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { Delete, Add, Clear, Save, ChevronRight, Title } from "@mui/icons-material";
import FlatCard from "../../../../atoms/FlatCard";

interface WorkPlansProps {
  user: any;
  onItemClick?: (workPlan: WorkPlanDto) => void;
  onDeleted?: (workPlan: WorkPlanDto) => void;
}

type ShadowWorkPlanDtoType = WorkPlanDto;

interface WorkPlanInclusive extends ShadowWorkPlanDtoType {
  validityEndInclusive: string | null;
}

const workplanColumns = [
  { id: "id", label: "id", minWidth: 50 },
  { id: "validityStart", label: "validityStart", minWidth: 50 },
  { id: "validityEnd", label: "validityEnd", minWidth: 50 },
  {
    id: "actions",
    label: "Actions",
    minWidth: 50,
  },
];

const WorkPlans: React.FC<WorkPlansProps> = ({ user, onItemClick, onDeleted }) => {
  const [workPlans, setWorkPlans] = useState<WorkPlanInclusive[]>([]);
  const UserStore = useStore(UserContext);
  const { getWorkPlans, createWorkPlan, deleteWorkPlan } = UserStore;
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [startDate, setStartDate] = useState<Moment | null>();
  const [endDate, setEndDate] = useState<Moment | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedWorkPlan, setSelectedWorkPlan] = useState<WorkPlanInclusive>();

  useEffect(() => {
    fetchWorkPlans();
  }, []);

  const convertWPToInclusive = (workPlan: WorkPlanDto): WorkPlanInclusive => {
    const convertedWorkPlan = {
      ...workPlan,
      validityEndInclusive: workPlan.validityEnd
        ? moment(workPlan.validityEnd).subtract(1, "days").format("L")
        : "nicht eingestellt",
    };
    convertedWorkPlan.validityStart = moment(convertedWorkPlan.validityStart).format("L");
    console.log("convertWPToInclusive", convertedWorkPlan);
    return convertedWorkPlan;
  };
  const convertInclusiveToWP = (workPlanIncl: WorkPlanInclusive): WorkPlanDto => {
    const wp = {
      ...workPlanIncl,
      validityEnd: workPlanIncl.validityEndInclusive
        ? moment(workPlanIncl.validityEndInclusive)
            .add(1, "days")
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format()
        : null,
    };
    wp.validityStart = moment(wp.validityStart)
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
      .format();
    console.log("convertInclusiveToWP", wp);
    return wp;
  };

  const fetchWorkPlans = async () => {
    try {
      const workPlans = await getWorkPlans(user.id);

      const workPlansInclusive: WorkPlanInclusive[] = workPlans.map(convertWPToInclusive);

      setWorkPlans(workPlansInclusive);
      console.log("workPlans", workPlans);
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("error", "Fehler beim Abrufen der Arbeitspläne", error);
    }
  };

  const handleWorkPlanClick = async (workPlan: WorkPlanInclusive) => {
    setSelectedWorkPlan(workPlan);
    console.log("workPlan clicked", workPlan);
    return onItemClick && onItemClick(workPlan);
  };

  const handleAddNewRow = () => {
    setIsAddingRow(true);
  };

  const handleDeleteRow = () => {
    setIsDialogOpen(true);
  };

  const handleConfirmDelete = async (id: number) => {
    // Perform delete operation
    const deletedWorkPlan = await deleteWorkPlan(id);
    if (deletedWorkPlan) {
      const updatedWorkPlans = workPlans.filter((workPlan) => workPlan.id !== id);
      setWorkPlans(updatedWorkPlans);
      setIsAddingRow(false);
    }

    // Close the confirmation dialog
    setIsDialogOpen(false);
    return onDeleted && onDeleted(deletedWorkPlan);
  };

  const handleCloseDialog = () => {
    // Close the confirmation dialog without deleting
    setIsDialogOpen(false);
  };

  const dateRangePickerChange = ({
    startDate,
    endDate,
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleAddWorkPlan = async () => {
    try {
      if (!startDate) return;

      const workPlanIncl: WorkPlanInclusive = {
        employeeId: user.id,
        validityStart: startDate.format(),
        validityEndInclusive: endDate ? endDate.format() : null,
      };

      const workPlan = convertInclusiveToWP(workPlanIncl);

      const newWorkPlan = await createWorkPlan(workPlan);
      if (newWorkPlan) {
        const newWorkPlanIncl = convertWPToInclusive(newWorkPlan);
        notificationStore.showMessage("Arbeitsplan erfolgreich hinzugefügt", "success");
        setWorkPlans([...workPlans, newWorkPlanIncl]);
        setIsAddingRow(false);
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("error", "Fehler beim Hinzufügen des Arbeitsplans", error);
    }
  };

  const handleCancelClick = () => {
    setIsAddingRow(false);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <FlatCard title="Arbeitsplan">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {workplanColumns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {workPlans.map((workPlan) => (
                <TableRow
                  key={workPlan.id}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleWorkPlanClick(workPlan)}
                  selected={selectedWorkPlan?.id === workPlan.id}
                >
                  <TableCell>{workPlan.id}</TableCell>
                  <TableCell>{workPlan.validityStart}</TableCell>
                  <TableCell>{workPlan.validityEndInclusive}</TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button onClick={() => {}}>
                          <ChevronRight />
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button onClick={handleDeleteRow}>
                          <Delete />
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              {/* New Row for Adding */}
              {isAddingRow && (
                <TableRow>
                  <TableCell>*</TableCell>
                  <TableCell colSpan={2}>
                    <Controls.DateRangePicker
                      startDate={startDate || null}
                      endDate={endDate || null}
                      handleDatesChange={dateRangePickerChange}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button onClick={handleAddWorkPlan}>
                          <Save />
                          Speichern
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" onClick={handleCancelClick}>
                          <Clear />
                          Abbrechen
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!isAddingRow && (
          <Button onClick={handleAddNewRow} sx={{ mt: 2 }}>
            <Add />
            Neu
          </Button>
        )}
      </FlatCard>
      <DeleteConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        id={selectedWorkPlan?.id!}
      />
    </>
  );
};

export default WorkPlans;
