import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { KeyboardBackspace, Visibility, VisibilityOff } from '@mui/icons-material';
import notificationStore from '../stores/Notification/notificationStore';
import { UserContext } from '../stores/User/User.provider';
import useStore from '../helpers/useStore';
import { set } from 'mobx';
import { observer } from 'mobx-react';
import { useNavigate, useParams,useLocation } from 'react-router-dom';

const ResetLoginDetails = observer(({forgotPassword,userEmail}:{forgotPassword?:boolean,userEmail?:string}) => {
    const UserStore=useStore(UserContext);
    const navigate = useNavigate(); // Hook to access the navigate function
    const {changeUserLoginDetails,user,allUsers,sendOtp}=UserStore;
  const [email, setEmail] = useState(userEmail?userEmail:'');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(Array.from({ length: 6 }, () => ''));
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const emailId = searchParams.get('user');
useEffect(()=>{
    console.log("IkkkkD",id,emailId);
    if(id){
        setEmail(emailId as string);
        setIsOtpVerified(true);
    }
},[id]);

//   const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [enteredOtp, setEnteredOtp] =useState(Array.from({ length: 6 }, () => ''));

  const textInputRefs:any = Array.from({ length: 6 }, () => useRef());

  const handleInputChange = (index:any, value:any) => {
    const newOtp = [...enteredOtp];
    newOtp[index] = value;
    setEnteredOtp(newOtp);

    // Move focus to the next TextField if a value is entered
    if (value && index < textInputRefs.length - 1) {
      textInputRefs[index + 1].current.focus();
    }
  };

  const handleSendOTP=async (e:any)=>{
    console.log("Email",email);
  const response=  await sendOtp(email);
  if(response){
   // Assuming response is a string containing the OTP
   const otpArray = response.toString().split('');
  setOtp(otpArray); // Splitting string OTP into an array of digits
  }
  }

  const handleResetLoginDetails = async () => {
    try {
      await changeUserLoginDetails({id: !id ? (user?.id as string) : "" ,email:email,password:password});

      // Perform password reset logic here
      // You can make an API call to your backend to handle the password reset

      // Reset form fields
      setEmail('');
      setPassword('');
      setConfirmPassword('');

    } catch (error) {
      console.error('Error resetting password:', error);
      notificationStore.showMessage('An error occurred while resetting password', 'error');
    }
  };
  const handleVerifyOTP = () => {
  // Check if entered OTP matches the response
const isOtpMatched = otp.every((digit, index) => digit === enteredOtp[index]);

if (isOtpMatched) {
  setIsOtpVerified(true);
}
    else{
        notificationStore.showMessage('OTP is incorrect', 'error');
    }
    setEnteredOtp(Array.from({ length: 6 }, () => ''));
  };

  const handlePasswordChange = (e:any) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e:any) => {
    setConfirmPassword(e.target.value);
  };


  // Define inputProps object for TextField input element styles
  const confirmPassInputProps = {
    style: {
      borderColor: password === confirmPassword ? 'green' : 'red',
    },
  };


  console.log("OTP",otp);

  return (
    <div>
        {forgotPassword ?(
            <>
            {otp.some(value => value !== '') || !isOtpVerified && (
  <>
    <Typography variant="h6" gutterBottom>Passwort zurücksetzen</Typography>
    <TextField
      label="Bitte überprüfen Sie vor dem Zurücksetzen noch einmal Ihren Benutzernamen/Ihre E-Mail-Adresse"
      type="email"
      fullWidth
      value={email}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
    />
    <Button variant="contained" color="primary" onClick={handleSendOTP} disabled={!email}>
      Send OTP
    </Button></>)}
    {/* Render OTP input fields only when otp is available and not yet verified */}
    {otp.every(value => value !== '') && !isOtpVerified && (
      <>
      <Typography variant="h6" gutterBottom>E-Mail-Verifizierung</Typography>
      <Typography variant="body2" gutterBottom>
  Bitte geben Sie den OTP ein, der an Ihre E-Mail-Adresse gesendet wurde: {email}
</Typography>

<div style={{ display: 'flex' }}>
      {enteredOtp.map((digit, index) => (
        <TextField
          key={index}
          type="text"
          style={{ width: '40px', marginRight: '5px' }}
          value={digit}
          onChange={(e) => handleInputChange(index, e.target.value)}
          inputRef={textInputRefs[index]}
        />
      ))}
    </div>

        {/* Button to verify OTP */}
        <Button variant="contained" color="primary" onClick={handleVerifyOTP}>
          Verify OTP
        </Button>
        <Typography
  variant="body1"
  color="primary"
  onClick={handleSendOTP}
  style={{ cursor: 'pointer', textAlign: 'left', textDecoration: 'underline' }}
>
  Sie haben keinen OTP erhalten? OTP erneut senden
</Typography>

      </>
    )}
     {/* Render new password fields only when OTP is verified */}
     {isOtpVerified && (
      <>
      <Typography variant="h6" gutterBottom>Kennwort ändern</Typography>
        <TextField
          label="Neues Kennwort"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={password}
          onChange={handlePasswordChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Bestätige das Passwort"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          value={confirmPassword}
          onChange={handleConfirmPasswordChange}
          InputLabelProps={confirmPassInputProps} // Apply labelProps directly
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button variant="contained" color="primary" onClick={handleResetLoginDetails} disabled={ confirmPassword!==password}>
          Passwort zurücksetzen
        </Button>
        {id && 
        <Typography
            variant="body1"
            color="primary"
            onClick={()=>{navigate('/login')}}
            style={{ cursor: 'pointer', textAlign: 'left' }}
          >
            <KeyboardBackspace/>Zurück zur Anmeldung
          </Typography>}
      
  </>
)}
</>)


   
                :(
            <>
        <Typography variant="h6" gutterBottom>Kennwort ändern</Typography>
      {/* <TextField
        label="Email Address"
        type="email"
        fullWidth
        value={user?.email}
        onChange={(e) => setEmail(e.target.value)}
      /> */}
        <TextField
        label="Altes Passwort"
        type="password"
        fullWidth
        value={user?.password || ""}
      />
      <TextField
        label="Neues Kennwort"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        value={password}
        onChange={handlePasswordChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Bestätige das Passwort"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        InputLabelProps={confirmPassInputProps} // Apply labelProps directly
        InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
      />

      <Button variant="contained" color="primary" onClick={handleResetLoginDetails}>
      Passwort zurücksetzen
      </Button>

      <Typography variant="h6" gutterBottom>Ändern Sie die primäre E-Mail-Adresse/den primären Benutzernamen</Typography>
       <TextField
        label="Alte E-Mail-Adresse"
        type="email"
        fullWidth
        value={user?.email}
      />
       <TextField
        label="Neue Email Adresse"
        type="email"
        fullWidth
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
       <Button variant="contained" color="primary" onClick={handleResetLoginDetails}>
       E-Mail-Adresse zurücksetzen
      </Button>
      </>)}
    </div>
  );
});

export default ResetLoginDetails;
