import React, { useEffect, useRef, useState } from "react";
import {
  DataGrid,
  GridCellEditCommitParams,
  GridCellParams,
  GridColDef,
} from "@mui/x-data-grid";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { Box, Button, TextField, Tooltip } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import useStore from "../helpers/useStore";
import { treatmentToRows, visitTypeReverseMapping } from "../helpers/parseInput";
import notificationStore from "../stores/Notification/notificationStore";
import Controls from "../atoms/Controls";
import { RxStoreContext } from "../stores/Rx/rx.provider";

interface RowData {
  id: string;
  [key: string]: any;
}

interface ColumnData {
  field: string;
  headerName: string;
  width?: number;
  editable?: boolean;
  renderCell?: (params: GridCellParams) => JSX.Element;
  type: any;
}

interface CustomToolbarProps {
  handleInputClick: () => void;
  inputValue: any;
  setInputValue: (value: string) => void;
  handleAddRowClick: () => void;
  handleSaveRowClick: () => void;
  handleDeleteRowClick?: () => void;
  handleAddColumnSet?: () => void;
  isSingleRxPage?: boolean;
  isQuickAdd?: boolean;
}

const CustomToolbar = ({
  handleInputClick,
  inputValue,
  setInputValue,
  handleAddRowClick,
  handleSaveRowClick,
  handleDeleteRowClick,
  handleAddColumnSet,
  isSingleRxPage,
  isQuickAdd
}: CustomToolbarProps) => (
  <GridToolbarContainer style={{ height: "60px" }} >
    {!isSingleRxPage  && !isQuickAdd && (
      <Tooltip title="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x">
        <TextField
          type="text"
          label="Schnelltext"
          placeholder="6 KG HB 2x or 6 KG HB 8 KGG 1-2 x or 6 KG 2x"
          style={{ width: "200px", height: "50px", padding: "5px" }} // Adjust the width as needed
          value={inputValue}
          InputLabelProps={{ style: { fontWeight: "bold" } }}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault(); // Prevent the default Enter key behavior
              handleInputClick(); // Call the handleInputClick function
            }
          }}
        />
      </Tooltip>
    )}
    <IconButton onClick={handleAddRowClick}>
      <AddIcon />
    </IconButton>
    {/* <IconButton onClick={handleSaveRowClick}>
      <SaveIcon />
    </IconButton> */}
    {/* <IconButton onClick={handleDeleteRowClick}>
      <DeleteIcon /> 
    </IconButton> */}
    {handleAddColumnSet && (
      <Button
        sx={{
          padding: "5px 10px",
          border: "none",
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleAddColumnSet();
        }}
      >
        Behandlung hinzufügen
      </Button>
    )}
    <GridToolbarColumnsButton {...{} as any}/>
    <GridToolbarFilterButton {...{} as any}/>
  </GridToolbarContainer>
);

interface EditTableProps {
  initialRows: RowData[];
  initialColumns: ColumnData[];
  columnSets?: ColumnData[][];
  dynamicColumns?: string[];
  setSelectedRow?: any;
  setOpenDialog?: any;
  onTableDataChange: (data: RowData[]) => void;
  parseInput?: any;
  handleAddColumnSet?: () => void;
  isSingleRxPage?: boolean;
  masterTablesData?: any;
  isQuickAdd?: boolean;
  quickAddValue?: any;
  isTreatmentRows?:boolean;
}

const EditTable = ({
  initialRows,
  initialColumns,
  columnSets,
  dynamicColumns,
  setSelectedRow,
  setOpenDialog,
  onTableDataChange,
  parseInput,
  handleAddColumnSet,
  isSingleRxPage,
  masterTablesData,
  isQuickAdd,
  quickAddValue,
  isTreatmentRows
}: EditTableProps) => {
  // const [tableData, setTableData] = useState<RowData[]>(initialRows);
  const [tableData, setTableData] = useState<RowData[]>(
    isQuickAdd ? initialRows : []
  ); // Initialize with an empty array
  const [inputValue, setInputValue] = useState(quickAddValue || "");
  const [errorMessage, setErrorMessage] = useState("");
  // Initialize an object to store error messages for each row
  const [rowErrorMessages, setRowErrorMessages] = useState<{
    [key: string]: string;
  }>({});
  const RxStore=useStore(RxStoreContext);
  const {savedSeriesForPatient}=RxStore;
  useEffect(() => {
    // Ensure that there's always at least one row
    const initialData =
      initialRows.length > 0 ? initialRows : [{ id: uuidv4() }];
      console.log("Check my data",initialData)
    setTableData(initialData);
    onTableDataChange(initialData);
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRows]); // Run the effect only once when the component mounts
  
  const [counter, setCounter] = useState(initialRows.length);
  const allColumns = [...initialColumns, ...(columnSets || []).flat()];
  console.log("allColumns", allColumns, columnSets, initialColumns);

  const hasUpdatedRef = useRef(false);
  const handleInputClick = () => {
    const emptyRow = tableData.find((row) => row.id && !row.therapy);
    if (emptyRow) {
      // Update the existing empty row's therapy field
      handleCellValueChange({
        id: emptyRow.id,
        field: "therapy",
        value: inputValue,
      } as GridCellEditCommitParams);
    } else {
      // Add a new empty row and update its therapy field
      const newRow: RowData = { id: uuidv4() };
      allColumns.forEach((column: ColumnData) => {
        if (column.editable) {
          newRow[column.field] = "";
        }
      });
      dynamicColumns?.forEach((dynamicField) => {
        newRow[dynamicField] = "";
      });
      // Update tableData state
      const updatedTableData = [...tableData, newRow];
      setTableData(updatedTableData);
      onTableDataChange(updatedTableData);
      // Set the ref to true to indicate an update
      hasUpdatedRef.current = true;
    }
  };
  useEffect(() => {
    // This block will run whenever tableData changes
    if (inputValue && hasUpdatedRef.current) {
      const newRow = tableData[tableData.length - 1]; // Assuming the new row is the last one
      handleCellValueChange({
        id: newRow.id,
        field: "therapy",
        value: inputValue,
      } as GridCellEditCommitParams);

      // Reset the ref to false after handling the update
      hasUpdatedRef.current = false;
    }
  }, [tableData, inputValue]);
  useEffect(() => {
    if (isQuickAdd) {
      handleInputClick();
    }
  }, [isQuickAdd]);

  const handleAddRowClick = () => {
    const newRow: RowData = { id: uuidv4() };
    allColumns.forEach((column: ColumnData) => {
      if (column.editable) {
        newRow[column.field] = "";
      }
    });
    dynamicColumns?.forEach((dynamicField) => {
      newRow[dynamicField] = "";
    });
    setTableData([...tableData, newRow]);
    // setTableData((prevTableData) => [...prevTableData, newRow]);
    onTableDataChange([...tableData, newRow]);
    setCounter((prevCounter: any) => prevCounter + 1);
  };
  // const handleAddRowClick = () => {
  //   const emptyRow = tableData.find((row) => row.therapy && row.therapy.trim() === "");

  //   if (!emptyRow) {
  //     const newRow: RowData = { id: uuidv4() };
  //     allColumns.forEach((column: ColumnData) => {
  //       if (column.editable) {
  //         newRow[column.field] = "";
  //       }
  //     });
  //     dynamicColumns?.forEach((dynamicField) => {
  //       newRow[dynamicField] = "";
  //     });
  //     newRow["therapy"] = inputValue;
  //     setTableData([...tableData, newRow]);
  //     onTableDataChange([...tableData, newRow]);
  //     setInputValue(""); // Clear the input value after adding the row
  //     setCounter((prevCounter: any) => prevCounter + 1);
  //     handleCellValueChange(
  //       { id: newRow.id, field: "therapy", value: newRow.therapy }
  //     );
  //   } else {
  //     // Update the existing empty row's therapy field
  //     const updatedData = tableData.map((row) => {
  //       if (row.id === emptyRow.id) {
  //         return { ...row, therapy: inputValue };
  //       }
  //       return row;
  //     });

  //     setTableData(updatedData);
  //     onTableDataChange(updatedData);
  //     setInputValue(""); // Clear the input value
  //     handleCellValueChange({
  //       id: emptyRow.id,
  //       field: "therapy",
  //       value: inputValue, // Set inputValue directly
  //     });
  //   }
  // };

  // ... (rest of the component)

  // useEffect(() => {
  //   handleAddRowClick(); // Update tableData when initialRows prop changes
  // }, []);

  // const handleSaveRowClick = () => {
  //   const newRows = tableData.filter((row) => !row.id); // Filter rows without an 'id'

  //   const updatedRows = newRows.map((row) => ({
  //     ...row,
  //     id: uuidv4(),
  //   }));

  //   const updatedData = [...tableData.filter((row) => row.id), ...updatedRows];

  //   setTableData(updatedData);
  //   onTableDataChange(updatedData);
  // };

  //   const handleCellValueChange1 = (params: GridCellEditCommitParams) => {
  //     const { id, field, value } = params;
  // console.log('kkkaii',tableData,id,initialRows)
  //     // Find the row with the matching 'id'
  //     const updatedData = tableData.map((row) => {
  //       if (row.id === id) {
  //         return { ...row, [field]: value };
  //       }
  //       return row;
  //     });
  // console.log('updatedDtaa',updatedData)
  //     // Update the 'tableData' state with the updated row data
  //     setTableData(updatedData);
  //     onTableDataChange(updatedData);
  //     // onTableDataChange(updatedData);
  //   };

  const checkUniqueTherapyValues = (data:any) => {
    const therapyValues = new Set();
    for (const obj of data) {
        for (const key in obj) {
            if (key.startsWith('therapy')) {
                const value = obj[key];
                if (therapyValues.has(value)) {
                    return true; // Duplicate value found
                }
                therapyValues.add(value);
            }
        }
    }
    return false; // All values are unique
}

  
  

  const handleCellValueChange = (params: GridCellEditCommitParams) => {
    const { id, field, value } = params;
    const invalidTherapies: any = []; // Create an array to store invalid therapy abbreviations

    const therapyData = masterTablesData
      .filter((obj: any) => "therapy" in obj)
      .map((obj: any) => obj.therapy);

      const frequencyValues = masterTablesData
      .filter((obj: any) => "therapyFrequency" in obj)
      .map((obj: any) => obj.therapyFrequency);

    console.log("Params", params, therapyData, masterTablesData,tableData,value,field);
    // Check for errors and update the rowErrorMessages object
    const updatedRowErrorMessages = { ...rowErrorMessages };
    let parsed:any;
    if ((field.startsWith("therapy") || field.startsWith("frequency"))  && value && !isSingleRxPage) {
       parsed = parseInput(value, field); // Parse the entered value
      if (isQuickAdd && handleAddColumnSet) {
        // Count the number of therapy fields in the parsed value
        const therapyFieldsCount = Object.keys(parsed).filter((key) =>
          key.startsWith("therapy")
        ).length;

        // If there is at least one therapy field
        if (therapyFieldsCount > 1) {
          handleAddColumnSet(); // Call handleAddColumnSet function
        }
        // Update the visitType property for each row
        parsed = {
          ...parsed,
          visitType:
            visitTypeReverseMapping[parsed.visitType] ||
            visitTypeReverseMapping["px"],
        };
      }

      // Check if the parsed value is a valid abbreviation in therapyData
      //  const isValidAbbreviation = therapyData[0].some((therapyObj:any) => therapyObj.abbreviation===parsed.therapy);
      const isValidAbbreviation = Object.keys(parsed).every((key) => {
        if (key.startsWith("therapy")) {
          // Check if the therapy property is valid
          const therapyAbbreviation = parsed[key];

       
          if (
            therapyAbbreviation && !therapyData[0].some(
              (therapyObj: any) =>
                therapyObj.abbreviation.toString().toLowerCase() ===
                  therapyAbbreviation.toString().toLowerCase() && therapyObj.duration
            )
          ) {
      
            // If the therapy abbreviation is invalid, push it to the invalidTherapies array
            // Update the error message for this specific row
            // Update the error message for this specific row
            updatedRowErrorMessages[id] =
              therapyAbbreviation + ":Ungültige Therapie";
            setRowErrorMessages(updatedRowErrorMessages);
            return false;
          }
        }
        else if (key.startsWith("frequency")) {
          const frequencyValue = parsed[key];
          if (frequencyValue && !frequencyValues[0].some((frequencyObj: any) => frequencyObj.id === frequencyValue)) {
              updatedRowErrorMessages[id] = frequencyValue + ": Ungültige Frequenz";
              setRowErrorMessages(updatedRowErrorMessages);
              return false;
          }
      }   
        return true; // Non-"therapy" properties are always considered valid
      });
      console.log(
        "Parsed",
        parsed,
        value,
        tableData,
        therapyData,
        isValidAbbreviation
      );
    


      // Check if the parsed value is in the valid therapyData array
      if (isValidAbbreviation) {
        // Clear the error message for this row if the abbreviation is valid
        delete updatedRowErrorMessages[id];
        setRowErrorMessages(updatedRowErrorMessages);
let updatedData:any[]=[]
if (isTreatmentRows && field.startsWith("therapy")) {
  parsed = {
    ...parsed,
    visitType:
      visitTypeReverseMapping[parsed.visitType] ||
      visitTypeReverseMapping["px"],
  };
  updatedData = treatmentToRows(parsed);

  console.log("Findajjsjsj", updatedData, tableData);

  const emptyRow = tableData.find((row) => row.id && !row.therapy);

  // Flag to track if an object without an ID has been updated
  let updatedObject = false;

  // Iterate through updatedData
  updatedData.forEach(obj => { 
      // Check if the object doesn't have an ID and hasn't been updated yet
      if (!obj.id && !updatedObject) {
          // Update its ID
          obj.id = emptyRow ? emptyRow.id : !tableData.some(row => row.id.toString() === id.toString()) ? uuidv4() : id;

          updatedObject = true; // Set the flag to true
      } else if (!obj.id) {
          // If the object doesn't have an ID but another object has already been updated, assign a new UUID
          obj.id = uuidv4();
      }

      // Check if the object already exists in tableData
      const existingIndex = tableData.findIndex(row => row.id === obj.id);
      if (existingIndex !== -1) {
          // If the object exists, update its properties
          tableData[existingIndex] = obj;
      } else {
          // If the object doesn't exist, add it to tableData
          tableData.push(obj);
      }
  });
  const existingTherapy = checkUniqueTherapyValues(tableData)
  if (existingTherapy) {
    notificationStore.showMessage("Die gleiche Behandlung gibt es bereits auf Rezept.", "error");
    return;
  }
  // Update tableData with the merged data
  setTableData([...tableData]);

  // Pass the merged data to the onTableDataChange function
  onTableDataChange([...tableData]);

}

        else{
          // Find the row with the matching 'id' and update the 'therapy' field with parsed value
          updatedData = tableData.map((row) => {
            if (row.id === id) {
              if (field === "therapy") {
                return { ...row, ...parsed };
              } else {
                return { ...row, [field]: value };
              }
            }
            return row;
          });
          // Check for existing therapy before updating data
          const existingTherapy = checkUniqueTherapyValues(updatedData)
          if (existingTherapy) {
            notificationStore.showMessage("Die gleiche Behandlung gibt es bereits auf Rezept.", "error");
            return;
          }
          // Update the 'tableData' state with the updated row data
          setTableData(updatedData);
          onTableDataChange(updatedData);
        }
    
        
        setErrorMessage(" ");
      }
      else {
        if (updatedRowErrorMessages[id].includes("Ungültige Frequenz")) {
            notificationStore.showMessage(updatedRowErrorMessages[id], "error");
        } else {
            notificationStore.showMessage(updatedRowErrorMessages[id], "error");
        }
    } 
    // else {
    //     // Handle the case where the entered value is not valid
    //     // You can display an error message or take appropriate action here
    //     // Set the error message for an invalid therapy abbreviation
    //     notificationStore.showMessage("Ungültige Therapie: " + parsed.therapy, "error");
    //     // setErrorMessage("Ungültige Therapie: " + invalidTherapies.join(","));
    //   }
    } else {
      // Handle other fields without parsing
      const updatedData = tableData.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value };
        }
        return row;
      });
      
  // Check for existing therapy before updating data
  const existingTherapy = checkUniqueTherapyValues(updatedData)
  if (existingTherapy) {
    notificationStore.showMessage("Die gleiche Behandlung gibt es bereits auf Rezept.", "error");
    return;
  }
      setTableData(updatedData);
      onTableDataChange(updatedData);
    }
    setInputValue("");
  };
const handleDuplicateRow = (rowIndex: string, event: any) => {
  const {value}=event.target;
  // Find the index of the row to duplicate
  const rowIndexToDuplicate = tableData.findIndex(row => row.id === rowIndex);

  if (rowIndexToDuplicate !== -1) {
    // Find the original row
    const originalRow = tableData[rowIndexToDuplicate];
     // Check if the amount is entered and if it is an even number
     if (!originalRow.amount || originalRow.amount % 2 !== 0) {
      // Display warning and return if validation fails
      notificationStore.showMessage("Bitte fügen Sie einen gültigen Betrag hinzu.","warning")
      return;
    }


    // Generate a new ID for the duplicated row
    const newId = uuidv4();

    // Add the new ID to the doubleTreatmentIds array
    const updatedRow = {
      ...originalRow,
      amount: originalRow.amount, // Update the original row's amount
      isDoubled: value,
      doubledTreatmentIds:originalRow.doubledTreatmentIds && originalRow.doubledTreatmentIds.length>0
        ? originalRow.doubledTreatmentIds
        : [newId],
    };

    // Create a new table data array with the updated original row
    const updatedTableData = [
      ...tableData.slice(0, rowIndexToDuplicate),
      updatedRow,
      ...tableData.slice(rowIndexToDuplicate + 1)
    ];
          // // Check for duplicate therapies (you need to define your comparison logic)
          // const isDuplicate = updatedTableData.some((othertherapy:any, otherIndex:any) => {
          //   if (otherIndex !== rowIndex) {
          //     // Compare relevant properties 
          //     return (
          //       originalRow.therapy.abbreviation === othertherapy.therapy.abbreviation  && originalRow.id!==othertherapy.id
          //     );
          //   }
          //   return false;
          // });
          // if (isDuplicate) {
          //   // Show an alert for duplicate therapies
          //   notificationStore.showMessage("Die Behandlung ist bereits beendet, so dass kein Duplikat zulässig ist.","warning")
          //   return ;
          // }

    // Update the UI table data with the new array
    setTableData(updatedTableData);

    // Call onTableDataChange with the updated table data
    onTableDataChange(updatedTableData);
  }
};

// Function to handle series selection change
const handleSeries = (rowIndex: string, event: any) => {
  const { id,value } = event.target;

  // Find the index of the row to update
  const rowIndexToUpdate = tableData.findIndex((item) => item.id === rowIndex);
  if (rowIndexToUpdate === -1) return;

  // Update the rxSeries for the selected row
  const updatedRow = {
    ...tableData[rowIndexToUpdate],
    rxSeries: {
      id: id as string,
      label: value || "",
    },
  };

  // Create a new table data array with the updated row
  const updatedTableData = [...tableData];
  updatedTableData[rowIndexToUpdate] = updatedRow;

  // Update the state with the new array
  setTableData(updatedTableData);
  onTableDataChange(updatedTableData)
};


  // const handleDuplicateRow = (rowIndex: string, event: any) => {
  //   console.log("ASDKDKKDKDKD", tableData, rowIndex, tableData[0]);
  
  //   // Find the index of the row to duplicate
  //   const rowIndexToDuplicate = tableData.findIndex(row => row.id === rowIndex);
  
  //   if (rowIndexToDuplicate !== -1) {
  //     // Create a copy of the original row with updated amount
  //     const originalRow = { ...tableData[rowIndexToDuplicate],amount: tableData[rowIndexToDuplicate].amount.toString(),isDoubled:true };
  
  //     // Create the duplicated row with updated amount and new id
  //     const newRow = { ...originalRow, id: uuidv4(), amount: originalRow.amount / 2 };
  
  //     // Update the original row's amount
    
  
  //     // Create a new table data array with the updated original row
  //     const updatedTableData = [
  //       ...tableData.slice(0, rowIndexToDuplicate),
  //       originalRow,
  //       ...tableData.slice(rowIndexToDuplicate + 1)
  //     ];
  
  //     // Update the UI table data with the new array
  //     setTableData(updatedTableData);
  
  //     // Call onTableDataChange with only the updated row
  //     onTableDataChange(updatedTableData);
  //   }
  // };
  


  const customTreatmentColumns: GridColDef[] = [
    ...allColumns,
    {
      field: "duplicate",
      headerName: "Doppelbehandlung",
      renderCell: (params) => (
        <Controls.Checkbox
        value={params.row.isDoubled || false}
          onChange={(event:any) => handleDuplicateRow(params.row.id as string, event)}
          disabled={
             !params.row.therapy}
          
        />
      ),
    },
    {
      field: "rxSeries",
      headerName: "RxSeries",
      renderCell: (params) => (
      
        <Controls.Select
        name="rxSeries"
        // label="Rezeptverbleib"
        id={1}
        value={params.row?.rxSeries?.label}
        onChange={(event:any) =>  handleSeries(params.row.id as string, event)}
        options={savedSeriesForPatient.map((option:any) => ({
          label: option.label,
          value: option.label,
          id:option.id
        }))}/>
      ),
    },
  ];

  const handleRowDoubleClick = (params: any) => {
    if (setSelectedRow) {
      setSelectedRow(params.row);

      setOpenDialog(true);
    }
  };
  // const handleDeleteRowClick = () => {
  //   const lastRowIndex = tableData.length - 1;
  //   if (lastRowIndex >= 0) {
  //     const updatedData = [...tableData];
  //     updatedData.splice(lastRowIndex, 1);
  //     setTableData(updatedData);
  //     onTableDataChange(updatedData);
  //   }
  // };

  return (
    <Box
      height="250px"
      sx={{
        border: (theme) => `1px solid ${theme.palette.primary.light}`,
        alignContent: "stretch",
      }}
    >
      {/* {errorMessage && <div className="error-message">{errorMessage}</div>} */}
      <DataGrid   
        rows={tableData.map((row, index) => ({
          ...row,
          id: row.id || index.toString(),
        }))}
        // columns={allColumns}
        columns={columnSets?allColumns:customTreatmentColumns.map((column) => {
          // Customize the "therapy" column to render the error message if needed
          if (column.field.startsWith("therapy") && errorMessage) {
            return {
              ...column,
              renderCell: (params: GridCellParams) => (
                <>
                  {params.value}
                  
                  {/* {rowErrorMessages[params.row.id] && (
                    <span
                      className="error-message"
                      style={{ color: "red", fontWeight: "bold" }}
                    >
                      {rowErrorMessages[params.row.id]}
                    </span>
                  )} */}
                </>
              ),
            };
          }
          return column;
        })}
        // pageSize={tableData.length}
        disableColumnMenu
        disableColumnSelector
        disableColumnFilter
        components={{
          Toolbar: CustomToolbar,
        }}
        hideFooter={true}
        componentsProps={{
          toolbar: {
            handleInputClick,
            inputValue,
            setInputValue,
            handleAddRowClick,
            // handleSaveRowClick,
            // handleDeleteRowClick,
            handleAddColumnSet,
            isSingleRxPage,
            isQuickAdd
          },
        }}
        // onRowDoubleClick={handleRowDoubleClick}
        onCellEditCommit={handleCellValueChange}
      />
    </Box>
  );
};

export default EditTable;
