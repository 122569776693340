import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";

export const TopLevelPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.topLevelBackground,
  // borderRadius: "12px 12px 0 0",
  borderRadius: "12px",
  border: `1px solid ${theme.mainHeader}`,
  padding: theme.topLevelPadding,
  gap: "10px",
}));
