import {
  Avatar,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import { observer, useObserver } from "mobx-react";
import React from "react";
import { AuthContext } from "../../stores/Auth/auth.provider";
import { ItemsContext } from "../../stores/Items.provider";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { SecurityOutlined, SupervisedUserCircle } from "@mui/icons-material";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import UnScheduled from "./UnScheduled";
import TicketForm from "../Ticketing/TicketEditCreate/ticketForm";
import TicketOverview from "../Ticketing/TicketOverview";
import { JsonMui } from "../../atoms/Json";

const Dashboard = observer(() => {
  const UserStore = useStore(UserContext);
  const AppointmentStore = useStore(AppointmentContext);
  const { getCancelledAppointments, cancelledAppointments, setCancelledAppointments } =
    AppointmentStore;
  const ItemsStore = useStore(ItemsContext);
  const [isLoadingCancelled, setIsLoadingCancelled] = useState(false);
  const { getAllItems } = ItemsStore;
  const { user, getRoles, listPermissions, apiInfo } = UserStore;
  // const [cancelledAppointments, setCancelledAppointments] = useState([]);

  const AuthStore = useStore(AuthContext);

  const token = AuthStore.authToken;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoadingCancelled(true);
        console.log("AKKSKSKSK");
        const response = await getCancelledAppointments();
        console.log("getCancelledAppointments", response);
        setCancelledAppointments(response);
      } catch (error) {
        console.log("error", error);
      } finally {
        setIsLoadingCancelled(false);
      }
    };

    fetchData();
  }, [user?.location?.id]);

  console.log("Cancelled Appointments", cancelledAppointments);
  return useObserver(() => {
    return (
      <>
        {/* First Main Grid with two columns */}
        <Grid
          container
          sx={{
            flexDirection: "column",
            border: "0px dashed green",
            overflowY: "auto",
            padding: "0",
          }}
        >
          <Grid item container spacing={2} sx={{ padding: "2px" }}>
            {/* First Column */}
            <Grid item xs={12} sm={4}>
              {/* Main Title */}
              <Card>
                <CardHeader title="Nutzerinformation" />
                <CardContent style={{ padding: "16px", textAlign: "center", minHeight: 400 }}>
                  {user ? (
                    <>
                      {/* User Info Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <PersonIcon />
                          </Avatar>
                          <Typography variant="subtitle2">
                            {user?.firstName + " " + user?.lastName}
                          </Typography>
                        </Grid>
                      </Grid>

                      {/* Roles Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SupervisedUserCircle />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            Roles
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                            }}
                          >
                            <Typography variant="subtitle2">{getRoles().join(", ")}</Typography>
                          </Box>
                        </Grid>
                      </Grid>

                      {/* Permissions Section */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SecurityOutlined />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            Permissions
                          </Typography>
                        </Grid>
                        <Box
                          sx={{
                            border: "1px solid #ccc",
                            borderRadius: "8px",
                            padding: "8px",
                          }}
                        >
                          <Typography variant="subtitle2">
                            {listPermissions().join(", ")}
                          </Typography>
                        </Box>
                      </Grid>
                      {/* API Info */}
                      <Grid container spacing={2} alignItems="center" justifyContent="center">
                        <Grid
                          item
                          xs={12}
                          sm={12}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Avatar sx={{ width: 30, height: 30, marginRight: "8px" }}>
                            <SecurityOutlined />
                          </Avatar>
                          <Typography
                            variant="subtitle1"
                            sx={{ paddingBottom: "8px", fontWeight: "bold" }}
                          >
                            API Info
                          </Typography>
                        </Grid>
                        <Tooltip title={apiInfo ? <JsonMui obj={apiInfo} /> : "No Connection"}>
                          <Box
                            sx={{
                              border: "1px solid #ccc",
                              borderRadius: "8px",
                              padding: "8px",
                            }}
                          >
                            <Typography variant="subtitle2">
                              {process.env.REACT_APP_API_URL}
                              {/* {apiInfo ? apiInfo.dbOptions.database : "No Connection"} */}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Grid>
                    </>
                  ) : (
                    // Show CircularProgress for loading state
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Second Column */}
            <Grid item xs={12} sm={5}>
              <Card >
                <CardHeader title="Stornierungen" />
                <CardContent sx={{
                    minHeight: 400,
                    maxHeight: 400,
                    overflow: "auto",
                  }}>
                  {!isLoadingCancelled ? (
                    cancelledAppointments?.cancelledAppointments?.length > 0 ? (
                      <UnScheduled
                        cancelledAppointments={{
                          cancelledAppointments:
                            cancelledAppointments?.cancelledAppointments?.filter(
                              (cancelledAppointment: any) =>
                                cancelledAppointment.byTherapist === true
                            ) || [],
                          scheduledAppointments: cancelledAppointments.scheduledAppointments || [],
                        }}
                      />
                    ) : (
                      <Typography variant="body1">Keine Stornierung</Typography>
                    )
                  ) : (
                    <CircularProgress />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <TicketOverview />
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  });
});
export default Dashboard;
