import moment from "moment";
import {
  AppointmentDto,
  ContinuousAppointmentDto,
  ContinuousEventTimeSlotDto,
  EventTimeSlotDto,
  LeaveOfAbsenceDto,
  PatientDto,
  TimeSlotDto,
  UserDto,
} from "../../api-client";
import useStore from "../../helpers/useStore";
import { UserContext } from "../../stores/User/User.provider";
import Calendar from "../../molecules/Calendar/Calendar";
import { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import { Event, TherapyExt, TimeSlotExt } from "../../molecules/Calendar/Calendar.type";
import { useParams, useSearchParams } from "react-router-dom";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import { Draggable, EventReceiveArg } from "@fullcalendar/interaction";
import UnscheduledEvents from "../../molecules/Calendar/UnscheduledEvents";
import { observer } from "mobx-react";
import notificationStore from "../../stores/Notification/notificationStore";
import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import EventShelf from "../../molecules/Calendar/EventShelf";
import { Directions } from "@mui/icons-material";
import CalendarDrawer from "../../molecules/Calendar/CalendarDrawer";

const Scheduler = observer(() => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const location = user?.location?.id;
  if (!location) {
    console.warn("No location found for user");
  } else {
    console.log("No worries, location is right here:", location);
  }
  const [users, setUsers] = useState<UserDto[]>([]);
  const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
  const [goneFishings, setGoneFishings] = useState<TimeSlotDto[]>([]);
  const [leaveOfAbsences, setLeaveOfAbsences] = useState<LeaveOfAbsenceDto[]>([]);
  const [EventTimeslots, setEventTimeslots] = useState<EventTimeSlotDto[]>([]);
  const [lunchBreaks, setLunchBreaks] = useState<TimeSlotDto[]>([]);
  const [continuousEventTimeslots, setContinuousEventTimeslots] = useState<
    ContinuousEventTimeSlotDto[]
  >([]);
  const [unscheduleds, setUnscheduleds] = useState<AppointmentDto[]>([]);
  const [showLoading, setShowLoading] = useState(false);
  const PatientsStore = useStore(PatientsContext);
  const { getPatient, getAllPatients } = PatientsStore;
  const CalendarStore = useStore(CalendarContext);
  const {
    getAppointment,
    getContinuousAppointment,
    getUsersByLocation,
    getPatientsByAppointmentDate,
    getAppointmentsByDateAndLocation,
    createTimeslot,
    createEventTimeSlot,
    createContinuousEventTimeSlot,
    createLeaveOfAbsence,
    saveAppointment,
    getUnscheduledPatientAppointments,
    deleteTimeslot,
    deleteContinuousTimeslot,
    getContinuousAppointmentsByLocation,
    getContinuousAppointmentsForPatient,
    getUnscheduledContinuousAppointments,
    updateTimeSlot,
    updateContinuousTimeSlot,
  } = CalendarStore;
  const AppointmentStore = useStore(AppointmentContext);
  const { saveAppointmentsForContinuous, updateContinuousAppointment, getAppointmentsForPatient } =
    AppointmentStore;
  const [selectedDate, setSelectedDate] = useState<Date>();
  const weeklyWorkDay = true;
  const [queryParameters] = useSearchParams();
  const [patient, setPatient] = useState<PatientDto>();
  const [continuousEvents, setContinuousEvents] = useState<ContinuousAppointmentDto[]>([]);
  const isContinuousParam = queryParameters.get("continuousView");
  const [continuousView, setContinuousView] = useState<boolean>(isContinuousParam === "true");
  const [shelfAppointments, setShelfAppointments] = useState<AppointmentDto[]>([]);
  const [shelfContinuousAppointments, setShelfContinuousAppointments] = useState<
    ContinuousAppointmentDto[]
  >([]);
  const [reFetch, setRefetch] = useState<Date>(new Date());
  const [patientAppointments, setPatientAppointments] = useState<AppointmentDto[]>();
  const [drawerOpen, setDrawerOpen] = useState(!!queryParameters.get("pid"));
  const [selectedEventRxId, setSelectedEventRxId] = useState<string | undefined>();



  const handleContinuousViewChange = async (continuous: boolean) => {
    const patientId = patient?.id?.toString();
    if (patientId) {
      await fetchUnscheduledAppointments(patientId, continuous);
    }
    setContinuousView(continuous);
    setShelfAppointments([]);
    setShelfContinuousAppointments([]);
  };

  useEffect(() => {
    const isContinuousParam = queryParameters.get("continuousView");
    if (isContinuousParam !== null) {
      handleContinuousViewChange(isContinuousParam === "true");
    }
  }, [queryParameters]);

  useEffect(() => {
    const fetchData = async () => {
      if (!location) {
        // cant do anythign yet
        return;
      }
      try {
        Loading(true);
        console.log("Loading started");
        const patientId = queryParameters.get("pid");
        let date = moment(selectedDate).format(DATE_FORMAT);

        const fetchPromises = [
          fetchUsers(),
          continuousView
            ? fetchContiniuousAppointmentsByLocation(location)
            : fetchAppointments(date, location),
        ];

        if (patientId) {
          fetchPromises.push(fetchPatient(patientId));
          fetchPromises.push(fetchUnscheduledAppointments(patientId, continuousView));
        }

        await Promise.all(fetchPromises);
      } catch (error) {
        Loading(false);
        notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
        console.log("Loading error", error);
      } finally {
        Loading(false);
        console.log("Loading finished");
      }
    };
    fetchData();
  }, [selectedDate, location, continuousView, reFetch]);

  const Loading = (isLoading: boolean) => {
    setShowLoading(isLoading);
  };

  const fetchPatient = async (patientId: string) => {
    const pid = parseInt(patientId);
    try {
      const patient = await getPatient(pid);
      setPatient(patient);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const users = await getUsersByLocation(location!);
      setUsers(users);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAppointments = async (date: string, locationId: string) => {
    try {
      const data = await getAppointmentsByDateAndLocation(date, locationId, weeklyWorkDay);
      if (data) {
        setAppointments(data.appointments);
        setGoneFishings(data.goneFishings);
        setLeaveOfAbsences(data.leaveOfAbsences);
        setEventTimeslots(data.eventTimeSlots);
        setLunchBreaks(data.lunchBreaks);
        console.log("appointments:", data.appointments);
        console.log("goneFishings:", data.goneFishings);
        console.log("leaveOfAbsences:", data.leaveOfAbsences);
        console.log("eventTimeSlots:", data.eventTimeSlots);
        console.log("lunchBreaks:", data.lunchBreaks);
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Termine", "error", error);
    } finally {
      console.log("fetchAppointments Loading finished");
    }
  };

  const fetchContiniuousAppointmentsByLocation = async (locationId: string) => {
    try {
      const continuousApps = await getContinuousAppointmentsByLocation(locationId);
      setContinuousEvents(continuousApps.appointments);
      setContinuousEventTimeslots(continuousApps.eventTimeSlots);
      console.log("Continiuous Appointments:", continuousApps.appointments);
      console.log("Continiuous EventTimeSlots:", continuousApps.eventTimeSlots);
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Laden der Dauertermine", "error", error);
    } finally {
      console.log("fetch Continiuous Appointments Loading finished");
    }
  };

  const fetchUnscheduledAppointments = async (patientId: string, continuousView: boolean) => {
    try {
      const unscheduleds = continuousView
        ? await getUnscheduledContinuousAppointments(patientId)
        : await getUnscheduledPatientAppointments(patientId);
      console.log("unscheduleds:", unscheduleds);
      setUnscheduleds(unscheduleds);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("Unscheduled Loading finished");
    }
  };

  const fetchPatientAppointments = async (patientId: string) => {
    try {
      const appointments = await getAppointmentsForPatient(patientId);
      console.log("appointments:", appointments);
      setPatientAppointments(appointments);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("fetchPatientAppointments Loading finished");
    }
  };

  const onCreateEvent = async (event: Event) => {
    try {
      console.log("Event", event);
      const userId: string = event.resourceId || "";
      const user = users.find((u) => u.id === userId);
      let result: any;

      switch (event.type) {
        case "GoneFishing":
          const timeslot: TimeSlotDto = {
            user,
            type: "GoneFishing",
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("Timeslot", timeslot);
          result = await createTimeslot(timeslot);
          break;
        case "LeaveOfAbsence":
          const leaveOfAbsence: LeaveOfAbsenceDto = {
            user,
            type: "LeaveOfAbsence",
            loAType: event.loaType,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("LeaveOfAbsence", leaveOfAbsence);
          result = await createLeaveOfAbsence(leaveOfAbsence);
          break;
        case "EventTimeSlot":
          const eventTimeSlot: EventTimeSlotDto = {
            user,
            type: "EventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("EventTimeSlot", eventTimeSlot);
          result = await createEventTimeSlot(eventTimeSlot);
          break;
        case "ContinuousEventTimeSlot":
          const continuousEventTimeSlot: ContinuousEventTimeSlotDto = {
            user,
            type: "ContinuousEventTimeSlot",
            title: event.title,
            start: moment(event.start!).toISOString(),
            end: moment(event.end!).toISOString(),
          };
          console.log("ContinuousEventTimeSlot", continuousEventTimeSlot);
          result = await createContinuousEventTimeSlot(continuousEventTimeSlot);
          break;
        default:
          break;
      }
      notificationStore.showMessage("Event erfolgreich hinzugefügt", "success");
      console.log(result);
    } catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
    } finally {
      console.log("Calling renderCalendar");
      renderCalendar();
    }
  };

  const renderCalendar = async () => {
    let date = moment(selectedDate).format(DATE_FORMAT);
    console.log("renderCalendar date:", date);
    setRefetch(new Date());
    // continuousView ?
    // fetchContiniuousAppointmentsByLocation(location!) :
    // fetchAppointments(date, location!);
  };

  const onChangeEvent = async (event: Event, relatedEvents?: Event[]) => {
    const userId = event.resourceId || "";
    const user = users.find((u) => u.id === userId);

    const relatedEventUserId = relatedEvents?.map((event) => event.resourceId)[0];
    const relatedEventUser = users.find((u) => u.id === relatedEventUserId);

    console.log("onChangeEvent Event", event);

    if (!event.appointmentId) {
      console.log("Event has no appointmentId, there is a timeslot of a different type");
      const timeslot: TimeSlotDto = {
        id: event.id!,
        user,
        start: event.start!,
        end: event.end!,
        type: event.type!,
      };
      const result = continuousView
        ? await updateContinuousTimeSlot(event.id!, timeslot)
        : await updateTimeSlot(event.id!, timeslot);
      console.log(result);
    } else {
      const appointment = continuousView
        ? await getContinuousAppointment(event.appointmentId!)
        : await getAppointment(event.appointmentId!);
      if (appointment) {
        const appDto = appointment as AppointmentDto;
        console.log("Appointment in onChangeEvent in Scheduler", appDto);

        // for main event
        setTimeSlotChanges(appDto, event, user!);

        // for related events
        relatedEvents?.forEach((relatedEvent) => {
          setTimeSlotChanges(appDto, relatedEvent, relatedEventUser!);
        });

        const result = continuousView
          ? await updateContinuousAppointment(event.appointmentId!, appDto)
          : await saveAppointment(event.appointmentId!, appDto);
        console.log(result);
      }
    }
  };

  const setTimeSlotChanges = (appDto: AppointmentDto, event: Event, user: UserDto) => {
    const updatedTimeSlot = appDto.timeSlots?.find((slot) => slot.id?.toString() === event.id);
    if (updatedTimeSlot) {
      updatedTimeSlot.user = user;
      updatedTimeSlot.start = event.start!;
      updatedTimeSlot.end = event.end!;
    }
    const indexOfUpdatedTimeSlot = appDto.timeSlots?.findIndex(
      (slot) => slot.id === event.timeSlot?.id
    );
    if (indexOfUpdatedTimeSlot !== undefined && indexOfUpdatedTimeSlot !== -1) {
      if (appDto.timeSlots) {
        appDto.timeSlots[indexOfUpdatedTimeSlot] = updatedTimeSlot!;
      }
    }
  };

  const onEventReceive = async (eventReceive: EventReceiveArg) => {
    // const draggedEl = eventReceive.draggedEl;
    // draggedEl.style.display = "none"; // Detach the dragged element from the DOM
    // eventReceive.draggedEl.parentNode?.removeChild(eventReceive.draggedEl);
    const event = eventReceive.event;
    console.log("Event", event.toPlainObject());
    const userId = eventReceive.event.getResources().map((resource) => resource.id)[0];
    const user = users.find((u) => u.id === userId);
    let current = new Date(event.start?.getTime()!);

    // calculate the shift for heat treatments
    let shift = 0;
    const heatTreatments = ["PA", "HL"];
    let firstNonHeatTreatmentIndex = Infinity;

    // Find the first non-heat treatment index
    event.extendedProps.timeSlots?.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (!heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation)) {
        firstNonHeatTreatmentIndex = Math.min(firstNonHeatTreatmentIndex, index);
      }
    });

    // Calculate the shift for heat treatments that are before the first non-heat treatment index
    event.extendedProps.timeSlots?.forEach((timeSlot: TimeSlotDto, index: number) => {
      if (
        heatTreatments.includes((timeSlot.therapyRx?.therapy as TherapyExt).abbreviation) &&
        index < firstNonHeatTreatmentIndex
      ) {
        shift -= (timeSlot.therapyRx?.therapy as TherapyExt).duration;
      }
    });

    const appointment: AppointmentDto = {
      start: event.start?.toString()!,
      end: event.end?.toString()!,
      frequency: {
        id: event.extendedProps.frequency.id,
        text: event.extendedProps.frequency.text,
        prefferedValue: event.extendedProps.frequency.prefferedValue,
      },
      timeSlots: event.extendedProps.timeSlots
        ?.sort(
          (a: TimeSlotDto, b: TimeSlotDto) =>
            new Date(a.start).getTime() - new Date(b.start).getTime()
        )
        .map((slot: any) => {
          const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
          console.log("duration:", duration);
          const start = new Date(current.getTime());
          start.setMinutes(start.getMinutes());
          current.setMinutes(current.getMinutes() + duration);
          const end = new Date(current.getTime());
          start.setMinutes(start.getMinutes() + shift);
          end.setMinutes(end.getMinutes() + shift);
          console.log("start", start, "end", end);
          const timeSlot: TimeSlotDto = {
            id: slot.id,
            user,
            start: start.toISOString(),
            end: end.toISOString(),
            type: slot.type,
            therapyRx: {
              id: slot.therapyRx.id,
              therapy: {
                abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
                duration: (slot.therapyRx.therapy as TherapyExt).duration,
              },
              amount: slot.therapyRx.amount,
              rx: {
                rxNumber: slot.therapyRx.rx.rxNumber,
              },
            },
          };
          return timeSlot;
        }),
    };
    console.log("Appointment", appointment);

    const result = continuousView
      ? // await saveContinuousAppointment(event.id!, appointment) :
        await updateContinuousAppointment(event.id!, appointment)
      : await saveAppointment(event.id!, appointment);
    console.log(result);
    if (result) {
      setShelfAppointments(shelfAppointments.filter((app) => app.id !== event.id));
      setShelfContinuousAppointments(
        shelfContinuousAppointments.filter((app) => app.id !== event.id)
      );
      setUnscheduleds(unscheduleds.filter((app) => app.id !== event.id));
      notificationStore.showMessage("Termin erfolgreich umgezogen", "success");
      renderCalendar();
    }
  };

  const onRemoveEvent = async (timeslotId: string) => {
    const result = await deleteTimeslot(timeslotId);
    console.log(result);
  };

  const onRemoveContinuousEvent = async (timeslotId: string) => {
    const result = await deleteContinuousTimeslot(timeslotId);
    console.log(result);
  };

  const onMoveEventDate = async (event: Event) => {
    if (continuousView) {
      const continuousAppDto: ContinuousAppointmentDto = await getContinuousAppointment(
        event.appointmentId!
      );
      console.log("Event moved to shelf box:", continuousAppDto);
      continuousAppDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      notificationStore.showMessage("Dauertermin ins Regal verschoben", "info");
      setShelfContinuousAppointments([...shelfContinuousAppointments, continuousAppDto]);
    } else {
      const appDto: AppointmentDto = await getAppointment(event.appointmentId!);
      appDto.timeSlots?.sort(
        (a: TimeSlotDto, b: TimeSlotDto) =>
          new Date(a.start).getTime() - new Date(b.start).getTime()
      );
      console.log("Event moved to shelf box:", appDto);
      notificationStore.showMessage("Termin ins Regal verschoben", "info");
      setShelfAppointments([...shelfAppointments, appDto]);
    }
  };

  const handleAddShelfEvent = async (event: Event) => {
    console.log("Event moved from shelf box:", event);
    const lastShelfEvent = continuousView
      ? shelfContinuousAppointments[shelfContinuousAppointments.length - 1]
      : shelfAppointments[shelfAppointments.length - 1];
    const userId = event.resourceId || "";
    const user = users.find((u) => u.id === userId);
    let current = new Date(event.start);

    if (lastShelfEvent) {
      try {
        //get the last shelf event duration in minutes
        const lastShelfEventDuration = lastShelfEvent.timeSlots!.reduce(
          (acc, slot) => acc + (slot.therapyRx?.therapy as TherapyExt).duration,
          0
        );

        lastShelfEvent.start = event.start;
        lastShelfEvent.end = moment(event.start)
          .add(lastShelfEventDuration, "minutes")
          .toISOString();

        lastShelfEvent.timeSlots = lastShelfEvent
          .timeSlots!.sort(
            (a: TimeSlotDto, b: TimeSlotDto) =>
              new Date(a.start).getTime() - new Date(b.start).getTime()
          )
          .map((slot: any) => {
            const duration = parseInt((slot.therapyRx.therapy as TherapyExt).duration.toString());
            const start = new Date(current.getTime());
            start.setMinutes(start.getMinutes());
            current.setMinutes(current.getMinutes() + duration);
            const end = new Date(current.getTime());
            console.log("start", start, "end", end);
            const timeSlot: TimeSlotDto = {
              id: slot.id,
              user,
              start: start.toISOString(),
              end: end.toISOString(),
              type: slot.type,
              therapyRx: {
                id: slot.therapyRx.id,
                therapy: {
                  abbreviation: (slot.therapyRx.therapy as TherapyExt).abbreviation,
                  duration: (slot.therapyRx.therapy as TherapyExt).duration,
                },
                amount: slot.therapyRx.amount,
                rx: {
                  rxNumber: slot.therapyRx.rx?.rxNumber,
                },
              },
            };
            return timeSlot;
          });

        const result = continuousView
          ? await saveAppointmentsForContinuous(lastShelfEvent as ContinuousAppointmentDto)
          : await saveAppointment(lastShelfEvent.id!, lastShelfEvent as AppointmentDto);
        console.log(result);
        setShelfAppointments(shelfAppointments.filter((app) => app.id !== lastShelfEvent.id));
        setShelfContinuousAppointments(
          shelfContinuousAppointments.filter((app) => app.id !== lastShelfEvent.id)
        );
        notificationStore.showMessage("Termin erfolgreich hinzugefügt", "success");
      } catch (error) {
        console.log(error);
        notificationStore.showMessage("Fehler beim Hinzufügen des Events", "error", error);
      } finally {
        renderCalendar();
      }
    }
  };

  const onEventContentClick = async (event: TimeSlotExt) => {
    console.log("Event content clicked", event);
    const patientId = event?.patient?.id?.toString();
    const rxId = event?.therapyRx?.rx?.id;
    if (patientId) {
      setDrawerOpen(true);
      setSelectedEventRxId(rxId);
      await fetchPatient(patientId);
      await fetchUnscheduledAppointments(patientId, continuousView);
      await fetchPatientAppointments(patientId);
    }
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const drawerWidth = 320;
  return (
    <Grid container height={"100%"}>
      <Grid item width={drawerOpen?drawerWidth:0}>
        <CalendarDrawer
          open={drawerOpen}
          patient={patient!}
          appointments={patientAppointments || []}
          drawerWidth={drawerWidth}
          selectedTab={selectedEventRxId}
          onClose={handleCloseDrawer}
        />{" "}
      </Grid>
      <Grid item width={drawerOpen?`calc(100% - ${drawerWidth}px)`:"100%"}>
        <Grid
          container
          direction="column"
          sx={{
            border: "0px dotted blue",
            height: "100%",
            alignItems: "stretch",
            flexWrap: "nowrap",
          }}
        >
          <Grid item sx={{ border: "0px solid green" }}>
            {/* unscheduled events */}
            {patient &&
              (continuousView
                ? shelfContinuousAppointments.length === 0
                : shelfAppointments.length === 0) && (
                <EventShelf
                  appointments={unscheduleds}
                  title={`Offene ${continuousView ? "Dauertermin" : "Termine"}`}
                  patient={patient.lastName}
                />
              )}
            {/* shelf events */}
            {(shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0) && (
              <EventShelf
                appointments={continuousView ? shelfContinuousAppointments : shelfAppointments}
                title={"Ablage"}
              />
            )}
          </Grid>

          <Grid item sx={{ border: "0px solid red", flexGrow: 1 }}>
            <Calendar
              isContinuous={continuousView}
              users={users}
              appointments={appointments.filter(
                (app) => shelfAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              goneFishings={goneFishings}
              leaveOfAbsences={leaveOfAbsences}
              eventTimeslots={EventTimeslots}
              lunchBreaks={lunchBreaks}
              continuousEventTimeSlots={continuousEventTimeslots}
              continuousAppointments={continuousEvents.filter(
                (app) =>
                  shelfContinuousAppointments.findIndex((shelfApp) => shelfApp.id === app.id) === -1
              )}
              onDatesSet={setSelectedDate}
              onCreateEvent={onCreateEvent}
              onChangeEvent={onChangeEvent}
              onEventReceive={onEventReceive}
              onRemoveEvent={onRemoveEvent}
              onRemoveContinuousEvent={onRemoveContinuousEvent}
              onOutdated={renderCalendar}
              onContinuousViewChange={handleContinuousViewChange}
              isLoading={showLoading}
              onMoveEventDate={onMoveEventDate}
              isShelfEvents={shelfAppointments.length > 0 || shelfContinuousAppointments.length > 0}
              onAddShelfEvent={handleAddShelfEvent}
              onEventContentClick={onEventContentClick}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Scheduler;
