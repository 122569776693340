import React, { useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Tooltip,
  Divider,
  LinearProgress,
  TableRow,
  TableHead,
  IconButton,
  Slider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Chip,
} from "@mui/material";
import {
  AppointmentDto,
  AppointmentTemplateDto,
  PatientAvailabilityDto,
  SolutionDto,
  TimeSlotDto,
  UserDto,
} from "../../../api-client";
import { format } from "date-fns";
import { CancelRounded, CheckCircle } from "@mui/icons-material";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import useStore from "../../../helpers/useStore";
import PatientAvailability from "../PatientAvailability";
import { formatDateUI, formatTime } from "../../../helpers/dateFormat";
import styled from "styled-components";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import notificationStore from "../../../stores/Notification/notificationStore";
import Controls from "../../../atoms/Controls";
import { observer } from "mobx-react";
import PatientUnavailability from "../PatientUnavailabilty";
import { visitTypeMapping } from "../../../helpers/visitTypeMapping";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import { SmallChip, TherapyChip } from "../../../atoms/Chip";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import TerminView from "../../PatientOverview/RxInfo/TerminView";
import { TerminViewConstants } from "../../PatientOverview/TerminView";
import FlatCard from "../../../atoms/FlatCard";
import { AgentParemetersDto } from "../../../stores/Appointment/appointmentStore";
import { UserContext } from "../../../stores/User/User.provider";

const CustomInput = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
`;

// Define an interface for the appointment object
interface Appointment {
  id: string;
  start: string | undefined;
  // Add other properties of the appointment object here
}

interface TerminProps {
  appointments: AppointmentDto[];
  contexts?: any[];
  continous?: boolean | undefined;
  patientId: number;
  setSolutionPatientRefresh: () => void;
  cardClick?: (index: number) => void;
  handleSaveAppointment: (app: AppointmentDto) => void;
}
interface SuggestionProps {
  onClose: (wasSaved: boolean) => void;
  open: boolean;
  setDataRefreshFlag: any;
  dataRefreshFlag: any;
  patientId: number;
  blockedData: TimeSlotDto[];
  setblockedData: any;
  patientAvailabilityData: PatientAvailabilityDto[];
  setPatientAvailabilityData: any;
  continous?: boolean;
  continousData?: AppointmentTemplateDto;
  handleContinousRx?: () => any;
}
const date = (date: any) => {
  const dateString: string | undefined = format(date, "dd.MM.yyyy");
  const dateobj = dateString ? new Date(dateString) : null;
  return dateobj;
};

const TherapyCard = ({
  timeSlot,
  appointment,
  continous,
}: {
  timeSlot: any;
  appointment: AppointmentDto;
  continous: boolean | undefined;
}) => {
  const rxNumber = timeSlot?.therapyRx?.rx?.rxNumber || "";
  const visitType = visitTypeMapping[timeSlot?.therapyRx?.rx?.visitType];
  return (
    <Tooltip
      title={
        <Box p={2}>
          {timeSlot?.start && (
            <Typography variant="body1">
              Start:{formatDateUI(timeSlot?.start, continous)} {formatTime(timeSlot?.start)}
            </Typography>
          )}
          {timeSlot?.end && (
            <Typography variant="body1">
              End: {formatDateUI(timeSlot?.end, continous)} {formatTime(timeSlot?.end)}
            </Typography>
          )}
          <Typography variant="body1">RxNo.:{timeSlot?.therapyRx?.rx?.rxNumber}</Typography>
          <Typography variant="body1">
            Therapy:{timeSlot?.therapyRx?.therapy?.abbreviation}
          </Typography>
          {timeSlot?.user?.id && (
            <Typography variant="body1">
              Therapist:{timeSlot?.user.firstName} {timeSlot?.user.lastName}
            </Typography>
          )}
        </Box>
      }
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={3}>
          {/* Therapy details */}
          <Tooltip title={rxNumber}>
            <Controls.Chip label={rxNumber} sx={{ fontSize: "0.6em" }} variant="outlined" />
          </Tooltip>
        </Grid>
        <Grid item xs={3}>
          <TherapyChip
            therapyName={timeSlot?.therapyRx?.therapy?.abbreviation}
            sx={{ fontSize: "0.5em" }}
          />
        </Grid>
        <Grid item xs={2}>
          {/* Avatar */}
          {timeSlot?.user?.id && (
            <MobiliAvatar
              user={timeSlot?.user}
              isTherapist
              sx={{ width: 25, height: 25, fontSize: "0.8em" }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          {timeSlot.visitFee && (
            <Tooltip title={timeSlot.visitFee.abbreviation}>
              <SmallChip label={timeSlot.visitFee.abbreviation} />
            </Tooltip>
          )}{" "}
        </Grid>
        {/* We can Add visit type back in if requested:  */}
        {/* {visitType && (
        <Grid item>
          <Tooltip title={visitType}>
            <Controls.Chip label={visitType} color="primary" variant="outlined" />
          </Tooltip>
        </Grid>
      )} */}
      </Grid>
    </Tooltip>
  );
};
const AppointmentCard = ({
  appointment,
  continous,
  openDialog,
  setOpenDialog,
}: {
  appointment: any;
  continous: boolean | undefined;
  openDialog: boolean;
  setOpenDialog: any;
}) => {
  const { timeSlots } = appointment;
  // Calculate the height based on the number of therapyRx items
  const cardHeight = `${timeSlots.length * 30 + 30}px`; // Adjust the height calculation as needed

  // Determine the background color based on conditions
  const backgroundColor = appointment.start
    ? appointment.attended
      ? "success.main" // Attended and appointment.start exists
      : "primary.main" // Unattended and appointment.start exists
    : "info.main"; // No appointment.start

  return (
    <Box sx={{ margin: "10px", padding: "0px", border: "0px dotted red", width: "100%" }}>
      <Box
        sx={{
          // height: cardHeight,
          // backgroundColor: backgroundColor,
          // display: "flex",
          // justifyContent: "center", // Center horizontally
          // alignItems: "center", // Center vertically
          // borderRadius: "10px",

          position: "relative",
          border: `1px solid ${mobiliTheme.mainHeader}`,
          p: 0,
          m: 0,
          padding: "0px",
          paddingBottom: "4px",
          margin: "0px",
          height: cardHeight,
          backgroundColor: backgroundColor,
          borderRadius: "0.4em",
        }}
      >
        {/* First grid in one row */}
        <Grid container>
          <Grid item xs={6} display="flex" justifyContent="space-between">
            {/* Date on the left side */}
            <Typography
              sx={{
                fontSize: "0.75em",
                fontWeight: "bold",
                padding: "0px",
                textDecoration:
                  appointment.end === null && appointment.originalDate ? "line-through" : "none",
                textDecorationThickness: "2px",
              }}
            >
              {appointment.start ? formatDateUI(appointment.start, continous) : ""}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-start",
            }}
          >
            {/* Start and end times on the right side */}
            {appointment.start && appointment.end && (
              <Typography
                noWrap
                sx={{
                  fontSize: "0.75em",
                  fontWeight: "bold",
                  textDecoration: "underline",
                }}
              >
                {appointment.start ? formatTime(appointment?.start as any) : " "}
                {"- "}
                {appointment.end ? formatTime(appointment.end as any) : ""}
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* Second grid in another row */}
        <Grid container>
          {timeSlots.map((timeSlot: any, index: any) => (
            <Grid item xs={12} key={index}>
              <TherapyCard timeSlot={timeSlot} appointment={appointment} continous={continous} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
const ContextCard = ({ context }: { context: { type: string; value: number } }) => {
  return (
    <Card
      sx={{
        margin: "8px",
        height: "flex",
        maxHeight: "35px",
        width: "150px",
        display: "flex",
        paddingTop: "6px",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        backgroundColor: getBackgroundColor(context),
      }}
    >
      <CardContent>
        <Typography
          style={{
            wordWrap: "break-word", //  text from wrapping
          }}
        >
          {getTypeText(context)}
        </Typography>
      </CardContent>
    </Card>
  );
};

const getBackgroundColor = (context: { type: string; value: number }) => {
  if (context.type === "gap") {
    return context.value !== 0 ? "info.main" : "success.main";
  } else if (context.type === "drive") {
    return context.value !== 0 ? "#eeeeee" : "grey";
  }
};

const getTypeText = (context: { type: string; value: number }) => {
  if (context.type === "gap") {
    return context.value ? `${context.value} min Lücke` : "Keine Lücke";
  } else if (context.type === "drive") {
    return context.value ? `${context.value} min Fahrt` : "";
  }
};

const Termin = ({
  appointments,
  contexts,
  continous,
  patientId,
  setSolutionPatientRefresh,
  cardClick,
  handleSaveAppointment,
}: TerminProps) => {
  const [disabledStates, setDisabledStates] = useState<boolean[]>(
    new Array(appointments.length).fill(false)
  );
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [selectedAppointmentIndex, setSelectedAppointmentIndex] = useState<number | null>(null);

  const handleCancelTerminClick = (index: number) => {
    // Disable the specific appointment by setting the corresponding state to true
    const updatedDisabledStates = [...disabledStates];
    updatedDisabledStates[index] = true;
    setDisabledStates(updatedDisabledStates);

    // Get the selected appointment
    const selectedAppointment = appointments[index];

    // Check if start and end are defined before setting blockedData
    if (selectedAppointment && selectedAppointment.start && selectedAppointment.end) {
      setblockedData([
        {
          start: selectedAppointment.start,
          end: selectedAppointment.end,
          type: "PatientUnvailable",
        },
      ]);
    } else {
      setblockedData([]);
    }

    // Open the dialog box
    setSelectedAppointmentIndex(index);
    setOpenDialog(true);
  };

  const handleCancel = () => {
    setDisabledStates(new Array(appointments.length).fill(false));
    setblockedData([]);
    setOpenDialog(false);
  };
  const AppointmentStore = useStore(AppointmentContext);
  const { saveBlockedSlotForPatient } = AppointmentStore;
  const [blockedData, setblockedData] = useState<TimeSlotDto[]>([]);

  const handleCellChange = (field: string, value: any, index: number) => {
    console.log("value", value);
    if (cardClick) {
      cardClick(-1);
    }
    const updatedData = [...blockedData];

    // Ensure that the row exists or initialize it as an empty TimeSlotDto
    if (!updatedData[0]) {
      updatedData[0] = {} as TimeSlotDto;
    }

    const row = updatedData[0];

    // Convert the custom input value to a Date object
    const parsedValue = new Date(value);

    if (!isNaN(parsedValue.getTime())) {
      if (field === "start.date") {
        const newStartDate = new Date(row?.start);
        newStartDate.setFullYear(parsedValue.getFullYear());
        newStartDate.setMonth(parsedValue.getMonth());
        newStartDate.setDate(parsedValue.getDate());
        row.start = newStartDate.toISOString();
      } else if (field === "start.time") {
        const newStartTime = new Date(row.start);
        newStartTime.setHours(parsedValue.getHours());
        newStartTime.setMinutes(parsedValue.getMinutes());
        row.start = newStartTime.toISOString();
      } else if (field === "end.date") {
        const newEndDate = new Date(row.end);
        newEndDate.setFullYear(parsedValue.getFullYear());
        newEndDate.setMonth(parsedValue.getMonth());
        newEndDate.setDate(parsedValue.getDate());
        row.end = newEndDate.toISOString();
      } else if (field === "end.time") {
        const newEndTime = new Date(row.end);
        newEndTime.setHours(parsedValue.getHours());
        newEndTime.setMinutes(parsedValue.getMinutes());
        row.end = newEndTime.toISOString();
      }
      setblockedData(updatedData);
    } else {
      console.error("Invalid time value");
    }
  };

  const handleOk = async () => {
    try {
      const updatedData = blockedData.map((item) => {
        return {
          id: item.id ? item.id : undefined,
          start: new Date(item.start).toISOString(),
          end: new Date(item.end).toISOString(),
          type: "PatientUnvailable",
        };
      });

      const response = await saveBlockedSlotForPatient(patientId, updatedData);

      if (response) {
        setSolutionPatientRefresh();
      }
      setOpenDialog(true);
      // fetchData();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };
  const [isWholeDay, setIsWholeDay] = useState(false);

  // ... (other functions)

  const handleCheckboxChange = (event: any) => {
    if (cardClick) {
      cardClick(-1);
    }
    console.log("isWholeDay", isWholeDay);
    // If the checkbox is checked, set the start and end times to 00:00
    if (!isWholeDay) {
      const updatedData = [...blockedData];
      const row = updatedData[0] || ({} as TimeSlotDto);

      // Convert the strings to Date objects
      const startDate = new Date(row.start);
      const endDate = new Date(row.end);

      // Set the time components to represent the whole day
      startDate.setHours(0, 0, 0);
      endDate.setHours(23, 59, 59);

      // Convert the Date objects back to strings
      row.start = startDate.toISOString();
      row.end = endDate.toISOString();

      setblockedData(updatedData);
    } else {
      // Get the selected appointment
      const selectedAppointment = appointments[selectedAppointmentIndex as number];
      setblockedData([
        {
          start: selectedAppointment.start as string,
          end: selectedAppointment.end as string,
          type: "PatientUnvailable",
        },
      ]);
    }
  };
  console.log("Appointments", blockedData);
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        {appointments.map((appointment: AppointmentDto, index: number) => (
          <>
            <Grid item xs={4}>
              <AppointmentCard
                appointment={appointment}
                continous={continous}
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
              />
              {/* Dialog for user confirmation */}
              {openDialog && (
                <Dialog open={openDialog}>
                  <DialogTitle>
                    Dieser Termin passt nicht? <br />
                    In welchem Zeitraum steht der Patient nicht zur Verfügung?
                  </DialogTitle>
                  <DialogContent>
                    {selectedAppointmentIndex !== null && (
                      <>
                        {" "}
                        <TableHead>
                          <TableRow>
                            <th>Startdatum</th>
                            <th>Startzeit</th>
                            <th>Enddatum</th>
                            <th>Endzeit</th>
                          </TableRow>
                        </TableHead>
                        <TableRow key={selectedAppointmentIndex}>
                          <td>
                            <CustomInput>
                              <DatePicker
                                // cancelLabel=""
                                // okLabel=""
                                // autoOk={true}
                                value={
                                  new Date(appointments[selectedAppointmentIndex].start as any)
                                }
                                onChange={(date: any) =>
                                  handleCellChange("start.date", date, index)
                                }
                                format="dd.MM.yyyy"
                              />
                            </CustomInput>
                          </td>
                          <td>
                            <CustomInput>
                              <TimePicker
                                // KeyboardButtonProps={{
                                //   'aria-label': 'change time',
                                // }}
                                // cancelLabel=""
                                // okLabel=""
                                // autoOk={true}
                                // keyboardIcon={<AccessTime />}
                                value={
                                  new Date(appointments[selectedAppointmentIndex].start as any)
                                }
                                onChange={(time: any) =>
                                  handleCellChange("start.time", time, index)
                                }
                                ampm={false}
                              />
                            </CustomInput>
                          </td>
                          <td>
                            <CustomInput>
                              <DatePicker
                                // cancelLabel=""
                                // okLabel=""
                                // autoOk={true}
                                value={new Date(appointments[selectedAppointmentIndex].end as any)}
                                onChange={(date: any) => handleCellChange("end.date", date, index)}
                                format="dd.MM.yyyy"
                              />
                            </CustomInput>
                          </td>
                          <td>
                            <CustomInput>
                              <TimePicker
                                // keyboardIcon={<AccessTime />}
                                value={new Date(appointments[selectedAppointmentIndex].end as any)}
                                onChange={(time: any) => handleCellChange("end.time", time, index)}
                                ampm={false}
                              />
                            </CustomInput>
                          </td>
                        </TableRow>
                        <TableRow>
                          <td colSpan={2}>
                            <Controls.Checkbox
                              name="isWholeDay"
                              label="den ganzen Tag"
                              value={isWholeDay}
                              onChange={(event: any) => {
                                setIsWholeDay(event.target.value);
                                handleCheckboxChange(event);
                              }}
                            />
                          </td>
                        </TableRow>
                      </>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="text"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleCancel();
                      }}
                    >
                      Abbrechen
                    </Button>
                    <Button
                      onClick={(event) => {
                        event.stopPropagation();
                        handleOk();
                      }}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
            </Grid>
            <Grid item xs={4}>
              {contexts && contexts[index] && (
                <div style={{ flexDirection: "column" }}>
                  <ContextCard context={contexts[index].pre} />
                  <ContextCard context={contexts[index].post} />
                </div>
              )}
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Termin speichern">
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation(); // Stop the event propagation
                    handleSaveAppointment(appointments[index]);
                  }}
                >
                  <CheckCircle color="success" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Termin ablehnen">
                <IconButton
                  onClick={(event: any) => {
                    event.stopPropagation(); // Stop the event propagation
                    handleCancelTerminClick(index);
                  }}
                >
                  <CancelRounded />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* Add a horizontal line below the AppointmentCard */}
          </>
        ))}
      </Grid>
    </LocalizationProvider>
  );
};

const AppointmentSuggestion = observer(
  ({
    open,
    onClose,
    patientId,
    blockedData,
    setblockedData,
    continous,
    continousData,
    handleContinousRx,
    patientAvailabilityData,
    setPatientAvailabilityData,
  }: SuggestionProps) => {
    const [loading, setLoading] = useState(false);
    const [fetchingError, setFetchingError] = useState<string | null>(null);
    // const [data, setSuggestionData] = useState<SolutionDto[]>([]);
    const [solution, setSolution] = useState<SolutionDto | null>(null);
    const { getSolution, getSolutionsForContinuous, getAppointmentsForPatient } =
      useStore(AppointmentContext);
    const [solutionPatientRefresh, setSolutionPatientRefresh] = useState(0);
    const [appointments, setAppointments] = useState<AppointmentDto[]>([]);
    const [appointmentRefreshFlag, setAppointmentRefreshFlag] = useState(false);
    const [appointmentsLoading, setAppointmentsLoading] = useState(false);
    const AppointmentStore = useStore(AppointmentContext);
    const { saveSolution, saveAppointmentsForContinuous } = AppointmentStore;
    const [agentParams, setAgentParams] = useState<AgentParemetersDto>(new AgentParemetersDto());

    const { allUsers } = useStore(UserContext);
    const [selectedTherapists, setSelectedTherapists] = useState<string[]>([]);
    console.log("allUsers", allUsers);
    const therapists = allUsers.filter((user: UserDto) =>
      user.roles?.some((role) => role.id === "therapist")
    );
    console.log("allUsers --> Therapists ", therapists);

    const fetchSolutions = async () => {
      setLoading(true);
      setFetchingError(null);
      try {
        console.log("Get Solutions in use Effect in Terimin", continous);
        let response: SolutionDto | null;
        if (continous) {
          response = await getSolutionsForContinuous(continousData as AppointmentTemplateDto, 4);
        } else {
          response = await getSolution(
            patientId.toString(),
            agentParams,
            selectedTherapists && selectedTherapists.length > 0 ? selectedTherapists : undefined
          );
        }

        console.log("solution", response);
        setSolution(response);
      } catch (error: any) {
        console.log("fetchingError getting suggestions", error);
        setFetchingError(error.response.data.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    // useEffect for getting the appointment data
    useEffect(() => {
      const fetchData = async () => {
        try {
          setAppointmentsLoading(true);
          const response = await getAppointmentsForPatient(patientId.toString());
          console.log("APPONTMENTS", response);
          if (response) setAppointments(response);
        } catch (error) {
          console.log("error", error);
        } finally {
          setAppointmentsLoading(false);
        }
      };

      fetchData();
    }, [appointmentRefreshFlag]);

    // useEffect for fetching the solution data
    useEffect(() => {
      console.log("open324", open);
      if (open || solutionPatientRefresh) {
        fetchSolutions();
      }
    }, [open, solutionPatientRefresh]);

    const handleRefresh = () => {
      setSolutionPatientRefresh((prevKey) => prevKey + 1); // Change the refreshKey to trigger useEffect
    };

    // const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
    // console.log("Setccall", selectedCardIndex);

    const handleCancelClick = () => {
      handleClose(true); // todo - replace with actual state variable that tracts whether changes were made
    };

    // const handleCardClick = (index: number) => {
    //   console.log("index", index);
    //   // Toggle the selected card index
    //   if (index !== -1) {
    //     setSelectedCardIndex(index === selectedCardIndex ? -1 : index);
    //   }
    //   if (index === -1) {
    //     setSelectedCardIndex(-1);
    //   }
    //   console.log("selectedCardIndex", selectedCardIndex);
    // };

    const handleSaveAppointment = async (app: AppointmentDto) => {
      // first get the potential lunch break associated with this app
      if(!solution)
        throw new Error("Cannot save appointments without a solution");
      const lunch = solution.lunchBreaks?.find((l) => {
        const lunchDate = new Date(l.start).setHours(0, 0, 0, 0);
        const appDate = new Date(app.start!).setHours(0, 0, 0, 0);
        return lunchDate === appDate;
      });
      if (lunch) {
        console.log("lunch found for this single appointment", lunch);
      }
      const singleSolution: SolutionDto = {
        appointments: [app],
        lunchBreaks: lunch ? [lunch] : [],
      };

      console.log("saving singleSolution", singleSolution);
      if (await saveSolution(singleSolution)) {
        setAppointmentRefreshFlag(true);
        const newSolution: SolutionDto = {
          appointments: solution.appointments.filter((a) => a.id !== app.id),
          lunchBreaks: lunch ?solution?.lunchBreaks?.filter((l) => l.id !== lunch.id): [],
        };
        setSolution(newSolution);
      }
    };

    const handleSaveAllAppointments = async () => {
      // console.log("Selectedsadd", selectedCardIndex, data);
      if (solution === null) {
        throw new Error("Cannot save empty solution");
      }

      if (continous) {
        throw new Error("Continuous not implemented");
      } else {
        await saveSolution(solution);
      }

      handleClose(true);
    };

    const handleClose = (wasSaved: boolean) => {
      // setSelectedCardIndex(-1);
      // setSuggestionData([]);
      setSolution(null); // clear for next time
      setLoading(false);
      setFetchingError(null);
      onClose(wasSaved);
    };
    const handleTherapistSelection = (event: any) => {
      console.log("handleTherapistSelection: ", event);
      setSelectedTherapists(event.target.value);
    };

    // const handleTickClick = (event: React.MouseEvent, index: number) => {
    //   // Prevent the click event from propagating to the card
    //   event.stopPropagation();

    //   // Toggle the selected card index
    //   setSelectedCardIndex(index === selectedCardIndex ? -1 : index);
    // };
    // console.log("data438583853", data);
    return (
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>Terminvorschläge</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            overflowY: "hidden",
            pt: 40,
          }}
        >
          <Grid container spacing={2} sx={{ flexGrow: 1, pt: 2 }}>
            <Grid item xs={2} sx={{ border: "0px solid", height: "100%", overflowY: "auto" }}>
              {" "}
              {/* Current appointments */}
              <FlatCard title="Aktueller Terminplan">
                <TerminView
                  appointments={appointments}
                  patientId={patientId}
                  dataRefreshFlag={appointmentRefreshFlag}
                  setDataRefreshFlag={setAppointmentRefreshFlag}
                  appointmentsLoading={appointmentsLoading}
                  isView={TerminViewConstants.TERMIN_VIEW}
                />
              </FlatCard>
            </Grid>
            <Grid item xs={5} sx={{ height: "100%" }}>
              {/* AI Suggestions */}
              <FlatCard title="Terminvorschläge">
                <Grid container spacing={2} padding={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      backgroundColor: mobiliTheme.lowLevelContainerBackground,
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ flexGrow: 1 }}>Power</Typography>
                    <Slider
                      sx={{ flexGrow: 1 }}
                      valueLabelDisplay="auto"
                      defaultValue={agentParams.pruningTarget}
                      min={2}
                      max={10}
                      onChange={(event, value) => {
                        setAgentParams((prevParams) => ({
                          ...prevParams, // Spread the previous parameters
                          pruningTarget: value as number, // Update the pruningTarget property
                        }));
                      }}
                    />
                    <Typography sx={{ flexGrow: 1 }}>Therapeuten</Typography>
                    <Slider
                      sx={{ flexGrow: 1 }}
                      valueLabelDisplay="auto"
                      defaultValue={10}
                      min={1}
                      max={100}
                      onChange={(event, value) => {
                        setAgentParams((prevParams) => ({
                          ...prevParams, // Spread the previous parameters
                          beta1: value as number, // Update the pruningTarget property
                        }));
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      backgroundColor: mobiliTheme.lowLevelContainerBackground,
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Typography sx={{ flexGrow: 1 }}>Behandlungsdauer</Typography>
                    <Slider
                      sx={{ flexGrow: 1 }}
                      valueLabelDisplay="auto"
                      defaultValue={100}
                      min={10}
                      max={1000}
                      step={10}
                      onChange={(event, value) => {
                        setAgentParams((prevParams) => ({
                          ...prevParams, // Spread the previous parameters
                          beta2: value as number, // Update the pruningTarget property
                        }));
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      backgroundColor: mobiliTheme.lowLevelContainerBackground,
                    }}
                  >
                    <FormControl sx={{ flexGrow: 1, backgroundColor: "white" }}>
                      <InputLabel id="physios">Wünschphysio</InputLabel>
                      <Select
                        multiple
                        value={selectedTherapists}
                        onChange={handleTherapistSelection}
                        MenuProps={{
                          sx: {
                            "&& .Mui-selected": {
                              backgroundColor: mobiliTheme.palette.secondary.main,
                            },
                          },
                        }}
                        renderValue={(selected) => (
                          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => {
                              const u = therapists.find((t) => value == t.id);
                              return <MobiliAvatar user={u} isTherapist={true} />;
                            })}
                          </Box>
                        )}
                      >
                        {therapists.map((therapist) => (
                          <MenuItem key={therapist.id} value={therapist.id}>
                            {therapist.firstName} {therapist.lastName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button sx={{ flexShrink: 0 }} onClick={handleRefresh}>
                      Berechnen
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ overflowY: "auto", border: "1px solid black" }}>
                    {/* Show this when loading */}
                    {loading && (
                      <LinearProgress
                        sx={{
                          minWidth: "100%",
                          minHeight: "8px",
                          backgroundColor: "white",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor: mobiliTheme.palette.info.main,
                          },
                        }}
                      />
                    )}
                    {/* Show this if an error occured */}
                    {!loading && fetchingError && <p>Error: {fetchingError}</p>}
                    {/* Show this when data is available */}
                    {!loading && !fetchingError && solution && (
                      <Grid container spacing={2} sx={{ border: "1px dotted blue" }}>
                        <Grid item xs={12}>
                          {" "}
                          {/* Display only 2 cards per row */}{" "}
                          <table
                            style={{
                              border: "0px solid #ccc",
                              borderCollapse: "collapse",
                            }}
                          >
                            <thead></thead>
                            <tbody>
                              {solution.summary &&
                                solution.summary.map((summaryItem: any, index: any) => (
                                  <tr key={index}>
                                    <td
                                      style={{
                                        border: "0px solid #ccc",
                                        padding: "8px",
                                        whiteSpace: "nowrap", // Prevent text from wrapping
                                        overflow: "hidden", // Hide overflow text
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      {summaryItem.label}
                                    </td>
                                    <td
                                      style={{
                                        border: "0px solid #ccc",
                                        padding: "8px",
                                      }}
                                    >
                                      {summaryItem.value} {summaryItem.units}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              flexDirection: "column",
                              fontWeight: "bold", // Set the font weight to bold
                              whiteSpace: "nowrap", // Prevent text from wrapping
                              overflow: "hidden", // Hide overflow text
                              textOverflow: "ellipsis",
                            }}
                          >
                            <Termin
                              appointments={solution.appointments}
                              contexts={solution.contexts}
                              continous={continous}
                              patientId={patientId}
                              setSolutionPatientRefresh={handleRefresh}
                              // cardClick={handleCardClick}
                              handleSaveAppointment={handleSaveAppointment}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    )}
                    {/* END of component with data*/}
                    {/* Display a message when there is no data */}
                    {!loading && !fetchingError && !solution && (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          minHeight: "30vh", // Adjust the height as needed
                        }}
                      >
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          No Appointment Suggestions found
                        </Typography>
                      </div>
                    )}
                  </Grid>{" "}
                  {/* End of suggested appointments*/}
                </Grid>
              </FlatCard>
              {/* End of AI Suggestions Container (including controls)*/}
            </Grid>
            {/* End of left side column*/}

            <Grid item xs={5} sx={{ border: "0px solid", height: "100%" }}>
              {" "}
              {/* Card for Patient Availability */} {/* right side of the window */}
              {/* Card for Patient Availability */}
              {/* <Box
              style={{
                flex: 1,
                overflowY: "auto",
                overflowX: "auto",
                border: "0px solid #ccc",
                height: "100%",
              }}
            > */}
              <FlatCard
                title="Zeitliche Einschränkungen"
                sx={{
                  width: "100%",
                  overflow: "auto",
                  // maxHeight: "600px",

                  // display:"flex",
                  position: "relative",
                  borderRadius: "8px",
                  marginTop: "10px",
                  // // flex: "40%", // Set the initial width to 40%
                  // maxWidth: "40%", // Set the maximum width to limit the card size on larger screens
                  // marginRight: "10px", // Add some spacing between the card and the appointment cards
                }}
              >
                <PatientUnavailability
                  patientId={patientId}
                  patientAvailabilityData={patientAvailabilityData}
                  setPatientAvailability={setPatientAvailabilityData}
                  patientAvailrefresh={solutionPatientRefresh}
                  setPatientAvailrefresh={setSolutionPatientRefresh}
                />
                <Divider sx={{ margin: "16px 0" }} />
                <PatientAvailability
                  patientId={patientId}
                  blockedData={blockedData}
                  setblockedData={setblockedData}
                  patientAvailrefresh={solutionPatientRefresh}
                  setPatientAvailrefresh={setSolutionPatientRefresh}
                />
                {/* Add Divider between the components */}
              </FlatCard>
            </Grid>
          </Grid>
          {/* End of main grid for the whole dialog content */}
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={handleCancelClick}>
            Abbrechen
          </Button>
          <Button onClick={handleSaveAllAppointments}>Alle Vorschläge speichern</Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default AppointmentSuggestion;
