import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TimeSlotExt } from "../../molecules/Calendar/Calendar.type";
import PatientControls from "../pages/Patient/PatientControls";
import { PatientsProvider } from "../../stores/Patients/patients.provider";
import React, { useEffect, useState } from "react";
import { RxDto } from "../../api-client";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import useStore from "../../helpers/useStore";
import { RssFeed } from "@mui/icons-material";
import { observer } from "mobx-react";

// This component will need to fetch:
// * Patient information
// * Rx information
// * Appointment information
interface TimeSlotDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  timeSlot: TimeSlotExt;
}

export const TimeSlotDialog: React.FC<TimeSlotDialogProps> = ({ open, setOpen, timeSlot }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullScreen
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setOpen(false)}
        aria-label="close"
        sx={{ position: "absolute", top: 0, right: 0, m: 2 }}
      >
        <CloseIcon />
      </IconButton>
      <TimeSlotDetails timeSlot={timeSlot} />
    </Dialog>
  );
};

const TimeSlotDetails: React.FC<{ timeSlot: TimeSlotExt }> = observer(({ timeSlot })  => {
  // create a state variable for the Rx data and a use effect function to fetch it

  const { getRxForTimeslot } = useStore(CalendarContext);

  const [rx, setRx] = useState<RxDto>();

  useEffect(() => {
    const fetchRx = async () => {
      // fetch the Rx data
      // set the Rx data
      if (timeSlot.id) {
        const rx = await getRxForTimeslot(timeSlot.id);
        setRx(rx);
      }
    };

    try {
      fetchRx();
    } catch (e) {
      console.log(e);
      alert((e as any).message); // replace this with the snackbar, when Maithri has merged her branch
    }
  }, []);

  return (
    <Grid container spacing={0} direction="column">
      {/* Left Side with Two Cards */}

      {/*  A top item we may use for the selected appointment? */}
      <Grid item>
        <Card sx={{backgroundColor:"primary.dark"}}>
          
          <CardContent>
            <Grid container spacing={4} direction="row">
            <Grid item>
                <Typography variant="h5" sx={{color:"white", backgroundColor:"primary.dark"}}>{"Termin"}</Typography>
                <Typography>
                  {timeSlot.id}
                </Typography>
              </Grid>

              <Grid item>
                <Typography variant="h5" sx={{color:"white", backgroundColor:"primary.dark"}}>{"Patient"}</Typography>
                <Typography>
                  {(timeSlot.patient as any).firstName || ""}{" "}
                  {(timeSlot.patient as any).lastName || ""}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{color:"white", backgroundColor:"primary.dark"}}>{"Heilmittel"}</Typography>
                <Typography>
                  {timeSlot.therapy?.abbreviation}, {timeSlot.therapy?.duration}min
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h5" sx={{color:"white", backgroundColor:"primary.dark"}}>{"Therapeut"}</Typography>
                <Typography>{timeSlot.user}</Typography>
              </Grid>
              {rx && rx.id && rx.rxNumber && (
                <Grid item>
                  <Typography variant="h5" sx={{color:"white", backgroundColor:"primary.dark"}}>{"Rezept"}</Typography>
                  <Typography>{rx.rxNumber}</Typography>
                </Grid>
              )}
            </Grid>
            <Typography>Eventuelle Notizen zum Termin hierhin ?</Typography>
          </CardContent>
        </Card>
      </Grid>

      {/* the main event --> the Patient Controls */}
      <Grid item>
        <Card>
          <CardContent>
            {timeSlot.patient?.id ? (
              <>
                {rx && rx.id && rx.rxNumber && (
                  <PatientControls
                    patientId={timeSlot.patient?.id}
                    selectedRxId={rx.id}
                    rxNumber={rx.rxNumber}
                  />
                )}
               </>
            ) : (
              <Typography variant="h6">Bad selection</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
});
