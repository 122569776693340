import { observable, action, computed, makeAutoObservable, makeObservable } from 'mobx';
import { AuthApi, Configuration, UserDto, UserProfileApi } from '../../api-client';
import notificationStore from '../Notification/notificationStore';

export default class AuthStore {

  @observable authToken: string | null= null;
  
  public apiClient: AuthApi;

  public constructor(apiClient: AuthApi) {
    makeObservable(this);
    this.apiClient = apiClient;

    this.getUserToken = this.getUserToken.bind(this);
    // Load authToken from local storage on store instantiation
    const storedToken = localStorage.getItem('authToken');
    if (storedToken) {
      this.setAuthToken(storedToken);
    }
  }

  @action setAuthToken = (token: string| null) =>{
    console.log('Action Setting Token in AuthStore',token)
    this.authToken = token;
    console.log('Updated authToken:', this.authToken);

    // Save authToken to local storage
    if (token)
      localStorage.setItem('authToken', token);
    else
      localStorage.removeItem('authToken');
  }

//   @computed
//   public get isAuthenticated() {
//     console.log('thiiuuk',this.authToken)
//     return this.authToken !== '';
//   }

  public getUserToken=async(userDto:UserDto)=> {
    try {
        
        const response: any = await this.apiClient.authControllerLogin(userDto);
        this.setAuthToken(response.data.access_token);
        console.log('Auth Token after login',this.authToken)
        notificationStore.showMessage('Login Successful','success');
        return this.authToken;
    } catch (error:any) {
      console.error('Error fetching authToken:', error);
      const errorMessage = error.response?.data?.message || "Fehler bei der Anmeldung";

      notificationStore.showMessage(errorMessage, "error", error);
    }
  }

}
