import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/de";import dayjs, { Dayjs } from 'dayjs';


// const FORMAT = 'DD/MM/YYYY';

interface CalendarDatePickerProps {
  onChange: (date: string) => void;
  date: string;
}

const CalendarDatePicker = ({ date, onChange }: CalendarDatePickerProps) => {
  console.log("Date givent to CalendarDatePicker: ", date, dayjs(date)); 

  const handleChange = (date: Dayjs | null) => {
    const formattedDate = date ? date.toISOString() : '';
    return onChange(formattedDate);
  }
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de" >
      <DatePicker value={dayjs(date)} onChange={handleChange}  sx={{ maxWidth: 160}}/>
    </LocalizationProvider>
  )
}

export default CalendarDatePicker;