import React, { useState, useEffect, useRef } from "react";
import { Person3, CalendarMonth, ImportContacts, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Tab,
  TabScrollButton,
  Tabs,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PatientsContext, PatientsProvider } from "../../stores/Patients/patients.provider";
import PatientPage from "../PatientDataEntryForm/PatientPage";
import { MasterTablesContext } from "../../stores/MasterTables/MasterTables.provider";
import useStore from "../../helpers/useStore";
import RxOverView from "../PatientOverview/RxInfo/RxOverView";
import RxPage from "../RxPage";
import { observer } from "mobx-react";
import ScheduleOverview from "../ScheduleOverview/ScheduleOverview";
import PatientOverview from "../PatientOverview/PatientOverview";
import moment from "moment";
import { useSearchParams, Link, useNavigate } from "react-router-dom";
import notificationStore from "../../stores/Notification/notificationStore";
import { TopLevelPaper } from "../../themes/StyledComponents";
import { mobiliTheme } from "../../themes/mobiliTheme";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { PatientNotesAndRecords } from "../PatientOverview/PatientNotesAndRecords";
import CloseIcon from "@mui/icons-material/Close";

const DeviceSizeLogger = () => {
  const theme = useTheme();
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"));
  const matchesSM = useMediaQuery(theme.breakpoints.up("sm") && theme.breakpoints.down("md"));
  const matchesMD = useMediaQuery(theme.breakpoints.up("md") && theme.breakpoints.down("lg"));
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg") && theme.breakpoints.down("xl"));
  const matchesXL = useMediaQuery(theme.breakpoints.up("xl"));

  React.useEffect(() => {
    console.log("Current breakpoint:");
    if (matchesXS) console.log("XS");
    if (matchesSM) console.log("SM");
    if (matchesMD) console.log("MD");
    if (matchesLG) console.log("LG");
    if (matchesXL) console.log("XL");
  }, [matchesXS, matchesSM, matchesMD, matchesLG, matchesXL]);

  return (
    <div>
      Current screen size is:
      {matchesXS && "XS"}
      {matchesSM && "SM"}
      {matchesMD && "MD"}
      {matchesLG && "LG"}
      {matchesXL && "XL"}
    </div>
  );
};
export interface PatientData {
  id: number;
  lastName?: string;
  firstName?: string;
  dob?: string;
  isActive: boolean;
}

const PatientLayout = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedValues, setSelectedValues] = useState<number | null>(null);
  const [patientOptions, setPatientOptions] = useState<string[]>([]);
  const PatientsStore = useStore(PatientsContext);
  const {
    getAllPatients,
    searchPatient,
    setOpen,
    isOpen,
    filteredPatient,
    selectedPatientsRoute,
    selectedPatients,
    constructSearchPath,
    selectedSubTab,
    setSelectedSubTab,
    getSelectedPatientparams,
    updateSelectedPatientRoute,
    setSelectedPatients,
    state,
    setState,
    setFilteredPatient,
    setSelectedValue,
    selectedValue,
    showSubTabs,
    setShowSubTabs,
    patientSubTabs,
    setPatientSubTabs,
  } = PatientsStore;
  const [newPatientTabIndex, setNewPatientTabIndex] = useState<number>(0);

  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();
  const patientId = queryParams.get("pId") || filteredPatient;
  const rxId = queryParams.get("rxId");
  const selectedSubTabPath = Array.from(queryParams.keys()).filter(
    (key) => key === "termin" || key === "rxinfo" || key === "bill"
  );

  console.log(
    "UOSPDPODKDKDKDKDKDKD",
    selectedPatientsRoute,
    patientId,
    rxId,
    queryParams,
    getSelectedPatientparams(patientId),
    selectedSubTabPath,
    selectedSubTab
  );

  useEffect(() => {
    // alert("useEffect patientId first");
    const fetchData = async () => {
      if (patientId) {
        console.log("AJKSKSKSKKSKKS", selectedPatientsRoute, patientId);
        // Check if patient is already in selectedPatientsRoute
        const existingPatient = selectedPatientsRoute.find(
          (patient: any) => patient.pId === patientId
        );
        if (!existingPatient) {
          // Patient not found in selectedPatientsRoute, fetch patient data
          const patientData = await searchPatient({ id: patientId as any });
          if (patientData) {
            const patient = updateSelectedPatientRoute({
              pId: patientId,
            });

            const params = getSelectedPatientparams(patientId);
            const newUrl = constructSearchPath(params);
            // alert("Navigate to " + newUrl);
            navigate(newUrl);
          } else {
            notificationStore.showMessage("Patient not found", "error");
          }
        } else {
          // Patient already exists in selectedPatientsRoute, proceed with updating and constructing URL
          const patient = updateSelectedPatientRoute({
            pId: patientId,
          });

          const params = getSelectedPatientparams(patientId);
          const newParams = new URLSearchParams();
          const newUrl = constructSearchPath(params);
          // alert("Navigate to " + newUrl);

          navigate(newUrl);
        }
      }
    };

    fetchData();
  }, [patientId]);

  useEffect(() => {
    // alert("useEffect rxId, selectedSubTab");
    const fetchData = async () => {
      if (selectedSubTabPath.length > 0) {
        console.log("arerx", patientId);
        if (selectedSubTabPath.includes("termin")) {
          setSelectedSubTab(2);
          const patient = updateSelectedPatientRoute({
            pId: patientId,
            // selectedSubTab:2
          });

          const params = getSelectedPatientparams(patientId);
          // const newParams = new URLSearchParams();
          // newParams.append('pId', params.pId.toString());
          // if (params.rxId) {
          //   newParams.append('prescription?rxId', params.rxId);
          // }
          const newUrl = constructSearchPath({ pId: params.pId, selectedSubTab: 2 });
          // alert("Navigate to " + newUrl);
          navigate(newUrl);
        }
      }
      if (selectedSubTabPath.includes("rxinfo")) {
        setSelectedSubTab(1);
        const patient = updateSelectedPatientRoute({
          pId: patientId,
          rxId: rxId || null,
          // selectedSubTab:1
        });

        const params = getSelectedPatientparams(patientId);
        // const newParams = new URLSearchParams();
        // newParams.append('pId', params.pId.toString());
        // if (params.rxId) {
        //   newParams.append('prescription?rxId', params.rxId);
        // }
        const newUrl = constructSearchPath({
          pId: params.pId,
          rxId: rxId || null,
          selectedSubTab: 1,
        });
        // alert("Navigate to " + newUrl);

        navigate(newUrl);
      }

      if (selectedSubTabPath.includes("bill")) {
        setSelectedSubTab(3);
        const patient = updateSelectedPatientRoute({
          pId: patientId,
          // selectedSubTab:3
        });

        const params = getSelectedPatientparams(patientId);
        // const newParams = new URLSearchParams();
        // newParams.append('pId', params.pId.toString());
        // if (params.rxId) {
        //   newParams.append('prescription?rxId', params.rxId);
        // }
        const newUrl = constructSearchPath({ pId: params.pId, selectedSubTab: 3 });
        // alert("Navigate to " + newUrl);

        navigate(newUrl);
      }
    };

    fetchData();
  }, [rxId, selectedSubTab]);

  const [selectedRow, setSelectedRow] = useState<any | null>(null);
  // const [patientData, setPatientData] = useState<any>([]);
  const masterTablesStore = useStore(MasterTablesContext);
  const { masterTablesData } = masterTablesStore;

  const fetchData = async (patient: any) => {
    try {
      // Destructure the patient object to get the id, lastName, and dob
      const { id, lastName, dob, firstName } = patient;
      setIsLoading(true);
      console.log("PATIENdddTID", patient);

      // Construct the search parameters based on the available data
      const searchParams: any = {};
      if (id) {
        searchParams.id = id;
      } else {
        searchParams.lastName = lastName || "";
        searchParams.firstName = firstName || "";
        searchParams.dob = dob || "";
      }

      // Call the searchPatient function with the constructed search parameters
      const fetchedData = await searchPatient(searchParams);
      if (!fetchedData) {
        throw new Error(`No data found for patient ID ${id}`);
      }

      const patientIndex = state.value;

      // Create a shallow copy of the previous data, or an empty array if prevData doesn't exist
      const updatedDataArray = selectedPatients.length > 0 ? [...selectedPatients] : [];
      console.log("IIOOSJDMMMDM", searchParams, fetchedData, updatedDataArray, selectedPatients);

      // Check if the patient with the same ID already exists in the array
      const existingPatientIndex = updatedDataArray.findIndex(
        (existingPatient) => existingPatient.id === fetchedData.id
      );
      console.log("jkkkk", existingPatientIndex);
      if (existingPatientIndex !== -1) {
        // Update the existing patient data in the array
        updatedDataArray[existingPatientIndex] = fetchedData;
      } else {
        // Append the new patient data to the array
        updatedDataArray.push(fetchedData);
      }
      console.log("PATIENsdfsffddddddddddTINDEX", updatedDataArray);
      // Update the patient data for the specific patient
      // setPatientData(updatedDataArray,fetchedData);
      console.log("PATIENTINkfkfkfkfkDEX", state.value, selectedPatients);
      //   const updatedSelectedPatients = selectedPatients.length>0 ? [...selectedPatients] : [];
      // console.log("updatedd",updatedSelectedPatients)
      //   updatedSelectedPatients[state.value] = fetchedData;
      //   console.log("ANYZZZSZS",updatedSelectedPatients,fetchedData)
      //   setSelectedPatients(updatedSelectedPatients,fetchedData);
      setSelectedPatients(updatedDataArray, fetchedData);
    } catch (error) {
      console.error(`Error fetching data for patient ${patient.id}:`, error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // alert("useEffect patientId second");
    console.log("APATIjjkjzzuuutffdddddfffENTID");
    if (patientId) {
      console.log("PATIjjkjzzuuutffdddddfffENTID");

      // Call handleSearchClick directly with the appropriate arguments
      handleSearchClick({
        target: {
          id: patientId || filteredPatient,
        },
      } as React.ChangeEvent<HTMLInputElement>);
      // setFilteredPatient(masterPatientData?.id?.toString() || patientId)
      // setSelectedValue(`${masterPatientData?.lastName} (${moment(masterPatientData?.dob).format('DD.MM.yyyy')})-${masterPatientData?.id}`)
    }
  }, [patientId]);

  const updatePatientData = (updatedPatientData: any) => {
    const updatedData = selectedPatients.length > 0 ? [...selectedPatients] : [];
    const updatedPatients = selectedPatients.length > 0 ? [...selectedPatients] : [];
    updatedPatients[state.value - 1] = updatedPatientData;
    setSelectedPatients(updatedPatients, updatedPatientData);
    updatedData[state.value - 1] = updatedPatientData;
    // setPatientData(updatedData,updatedPatientData);
    // handleTabChange(null,selectedPatients.length + 1)
    // setFilteredPatient(updatedPatientData.id)
    // setSelectedValue(`${updatedPatientData.lastName} (${moment(updatedPatientData.dob).format('DD.MM.yyyy')})-${updatedPatientData.id}`)
  };
  // const setPatientsData = (data: any) => {
  //   const updatedData = patientData.length > 0 ? [...patientData] : [];
  //   updatedData[state.value - 1] = data;
  //   setPatientData(updatedData);
  // };

  console.log("why", showSubTabs, patientSubTabs);

  const openPatientSubTab = (
    patientIndex: number,
    rowData: any // Pass additional data from handleRowClick
  ) => {
    const updatedSubTabs = [...patientSubTabs];
    if (!updatedSubTabs[patientIndex]) {
      updatedSubTabs[patientIndex] = [];
    }

    // Check if a sub-tab already exists for the patient
    if (updatedSubTabs[patientIndex].length === 0) {
      updatedSubTabs[patientIndex].push(rowData);
    } else {
      // If a sub-tab already exists, update its content with the received data
      updatedSubTabs[patientIndex][0] = rowData;
    }

    setPatientSubTabs(updatedSubTabs);
  };

  const handleClosePatientTab = (indexToRemove: number) => {
    // alert("handling close tab");
    const updatedPatients = [...selectedPatients];
    const updatedSubTabs = [...patientSubTabs];
    // const updatedPatientData = [...selectedPatients];

    updatedPatients.splice(indexToRemove, 1); // Remove the patient from the selectedPatients array
    updatedSubTabs.splice(indexToRemove, 1); // Remove the patient's subtabs
    // updatedPatientData.splice(indexToRemove, 1); // Remove the patient's data

    // setSelectedPatients(updatedPatients,"");
    setPatientSubTabs(updatedSubTabs);

    // Update the active tab based on the number of remaining patients
    if (updatedPatients.length === 0) {
      // If no patients remain, go back to the "Neu" tab
      setState({ ...state, value: newPatientTabIndex, tab: "new-person" });
      setSelectedPatients(updatedPatients, null);
      // setPatientData(updatedPatientData,null);
      // throw new Error("no more tabs")
      console.log("No more tabs, navigating to patient home page");
      // alert("No more tabs, navigating to patient home pageNavigate to " + "/patient");

      navigate("/patient");
    } else {
      // If there are remaining patients, update the active tab accordingly
      const newIndex = Math.min(state.value, updatedPatients.length);
      const newIndexPatientData = updatedPatients[newIndex - 1]; // Get the patient data at newIndex
      setSelectedPatients(updatedPatients, newIndexPatientData);
      // setSelectedPatients(updatedPatients,newIndexPatientData);
      const params = getSelectedPatientparams(newIndexPatientData.id);
      console.log("sa");

      // throw new Error("more tabs exits")
      // alert("Navigating after tab closure " +constructSearchPath(params));
      navigate(constructSearchPath(params));

      setState({ ...state, value: newIndex, tab: "" });
    }
  };

  // Function to handle row click in the "Rx Overview" table

  const handleRowClick = (data: any) => {
    console.log("STATE", state);
    if (state.value > 0 && state.tab === "") {
      const patientIndex = state.value - 1; // Subtract 1 to account for the "Neu" tab
      openPatientSubTab(patientIndex, data);
      setShowSubTabs(true);
      setSelectedSubTab(3); // Set the selected sub-tab to "Rx Overview"
      setSelectedRow(data);
    }
  };

  const handleSubTabChange = (event: any, newValue: number) => {
    setSelectedSubTab(newValue);
  };

  const setActiveTab = async (data: PatientData) => {
    try {
      console.log("Tabdllda", data);
      // Create a new patient object
      const newPatient = {
        id: data?.id,
        isActive: true,
      };
      setSelectedPatients([...selectedPatients, data], data);

      // Update selectedPatients state with the new patient object
      setSelectedPatients([...selectedPatients, data], data);
      console.log("Tabdlldddda", selectedPatients);

      // Update the active tab

      setState({ ...state, value: selectedPatients.length + 1, tab: "" });
      setSelectedSubTab(1);
    } catch (error) {
      console.error("Error setting active tab:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setState({ ...state, value: newValue });
  };

  //       const updatedPatients = [...updatedPatientsData];
  //       updatedPatients.shift(); // Remove the first patient
  //       setSelectedPatients(updatedPatients);

  //       // Set the active tab to the newly added patient tab
  //       const newIndex = updatedPatients.length;
  //       setState({ value: newIndex, tab: "" });
  //       setNewPatientTabIndex(newIndex);
  //       // setPatientData(updatedPatients)
  //  console.log('updatedPatients',updatedPatients,selectedSubTab,newIndex)
  //   // Reset prevFilteredValue to null
  //   setSelectedSubTab(0)
  //   prevFilteredValue.current = null;
  //       // setSelectedPatients(updatedPatients)

  //   };

  // Function to handle tab change (patient switch)
  const handleTabChange = (event: any, newValue: number | string) => {
    // alert("tab change")

    console.log("TABCHANGE", newValue);
    if (newValue === "neu") {
      // When "Neu" tab is clicked, set the tab index to the "Neu" index
      if (setFilteredPatient && setSelectedValue) {
        setFilteredPatient("");
        setSelectedValue(null);
      }
      setState({ ...state, value: newPatientTabIndex, tab: "new-person" });
    } else {
      const activePatientIndex = newValue as number;
      console.log("TABNUMBER", activePatientIndex, selectedPatients);
      if (setFilteredPatient && setSelectedValue) {
        const patient = selectedPatients[(newValue as number) - 1];
        console.log("tabchange", patient);
        setFilteredPatient(patient.id);
        setSelectedValue(
          `${patient.lastName},${patient.firstName} (${moment(patient.dob).format("DD.MM.yyyy")})-${
            patient.id
          }`
        );
      }

      setState({ ...state, value: activePatientIndex, tab: "" });
    }
  };
  const setPatientsData = (data: any) => {
    const updatedData = selectedPatients.length > 0 ? [...selectedPatients] : [];
    updatedData[state.value - 1] = data;
    setSelectedPatients(updatedData, data);
  };

  const onTablClickPath = (pId: any) => {
    console.log("this.seee", selectedPatientsRoute, pId);
    const params = getSelectedPatientparams(pId);
    console.log("GXHDHJDJDKKDKDKD", params);
    let url;
    if (params) {
      url = constructSearchPath(params);
    } else {
      url = constructSearchPath({ pId: pId });
    }
    return url;
  };

  // Function to construct the URL based on the selected sub-tab
  const onSubTabClick = (pId: any, subTabIndex: number) => {
    console.log("pId", pId);
    const params = getSelectedPatientparams(pId); // Get the query parameters for the patient
    console.log("JIYKYKKXKKXKXKXK", params, subTabIndex);
    let url;
    // Depending on the sub-tab index, construct the URL with appropriate query parameters
    switch (subTabIndex) {
      case 1: // Rx Info sub-tab
        url = constructSearchPath({
          pId: params?.pId,
          rxId: params?.rxId || null,
          selectedSubTab: 1,
        });
        // url = constructSearchPath({ pId: params?.pId,selectedSubTab:0 });
        break;
      case 3: // Bill sub-tab
        url = constructSearchPath({ pId: params?.pId, selectedSubTab: 3 });
        break;
      case 2: // Termine sub-tab
        url = constructSearchPath({ pId: params?.pId, selectedSubTab: 2 });
        break;
      default:
        url = constructSearchPath({ pId: params?.pId, selectedSubTab: 2 }); // Default to patient overview
        break;
    }
    console.log("URSKSLDLLD", url);
    return url;
  };
  const handleSearchClick = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const updatedSelectedValue = event.target.id;
      console.log("jfsjfjjdddddddfs", event.target.id);
      const response = await getAllPatients();
      // Map the response data to options
      // Map the response data to options
      const options = response.map((patient: any) => ({
        label: `${patient.lastName},${patient.firstName} (${moment(patient.dob).format(
          "DD.MM.yyyy"
        )})-${patient.id}`,
        value: patient.id,
        originalDob: patient.dob, // Store the original date format in the state
        id: patient.id,
      }));

      // Update the patientOptions state with the new options
      setPatientOptions(options);
      if (patientOptions.length === 0) {
        // Map the response data to options
        const options = response.map((patient: any) => ({
          label: `${patient.lastName},${patient.firstName} (${moment(patient.dob).format(
            "DD.MM.yyyy"
          )})-${patient.id}`,
          value: patient.id,
          originalDob: patient.dob, // Store the original date format in the state
          id: patient.id,
        }));
        setPatientOptions(options);
      }

      if (updatedSelectedValue !== "") {
        // Check if the patient is already in selectedPatients

        const existingPatientIndex = selectedPatients.findIndex(
          (patient: any) => patient.id === Number(updatedSelectedValue)
        );

        if (existingPatientIndex !== -1) {
          // Patient exists, set the active tab to their tab
          setState({
            ...state,
            value: existingPatientIndex + 1, // Add 1 because tabs start from 1
            tab: "",
          });
        } else {
          const selectedPatientData = response.find(
            (patient: any) => patient.id === Number(updatedSelectedValue)
          );

          // Patient does not exist, create a new tab
          const selectedPatient: PatientData = {
            id: selectedPatientData?.id,
            lastName: updatedSelectedValue?.match(/^(.*?),/)?.[1] || "", // Capture last name before ','
            firstName: updatedSelectedValue?.match(/,\s*(.*?)\s*\(/)?.[1] || "", // Capture first name after ','
            dob: updatedSelectedValue?.match(/\(([^)]+)\)/)?.[1] || "", // Use the original date format
            isActive: true,
          };

          console.log("ABC4", selectedPatient);
          let updatedPatients;
          if (selectedPatient.id) {
            updatedPatients = [...selectedPatients, selectedPatient];

            // if (updatedPatients.length > 7) {
            //   const firstPatientTabToRemove = updatedPatients[0];
            //   updatedPatients.shift(); // Remove the first patient
            //   handleClosePatientTab(0); // Close the first patient tab
            //   setSelectedSubTab(0); // Reset sub-tab selection to "Patient Info"

            //   // Refresh "Rx Overview" and remove "Rx Page" for the first patient
            //   const updatedPatientSubTabs = [...patientSubTabs];
            //   updatedPatientSubTabs.shift(); // Remove the sub-tabs for the first patient
            //   setPatientSubTabs(updatedPatientSubTabs);

            //   // Set the active tab to the newly added patient's tab
            //   const newIndex = updatedPatients.length;
            //  setSelectedValue(selectedPatient.id);
            //   setState({ ...state, value: newIndex, tab: "" });
            //   setNewPatientTabIndex(newIndex);
            //   console.log('ABddsddsC1',updatedPatients,selectedPatient)
            // }
            console.log("ABC", updatedPatients, selectedPatient);
            setSelectedPatients(updatedPatients, selectedPatient);
            fetchData(selectedPatient);

            // Set the tab index to the newly added patient's index
            const newIndex = updatedPatients.length;
            if (!isOpen) {
              setSelectedValue(
                `${selectedPatientData?.lastName},${selectedPatientData?.firstName} (${moment(
                  selectedPatientData?.dob
                ).format("DD.MM.yyyy")})-${selectedPatientData.id}`
              );
            }
            setState({ ...state, value: newIndex, tab: "" });
            setNewPatientTabIndex(newIndex);
          }

          // Check if there are more than 10 tabs and close the first added patient tab
        }
      }
      setOpen(false);
      //  setSelectedValue(null)
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  const handleSearchCancel = () => {
    setSelectedValue(null);
    setPatientOptions([]);
  };
  console.log("TZZSZSHHD", patientOptions);

  let selectedPatient = null;
  if (state.value > 0) selectedPatient = selectedPatients[state.value - 1];
  console.log("selectedPatient", selectedPatient?.lastName);

  const patient = selectedPatient;
  const patientIndex = state.value - 1;

  return (
    <Grid
      container
      direction="column"
      sx={{
        width: "100%",
        height: "100%",
        border: "0px dashed blue",
        flexWrap: "nowrap",
      }}
    >
      {/* Contain for the content for a single patient */}
      <Grid
        item
        xs
        sx={{
          border: "0px solid green",
          display: "flex",
          padding: "0px",
          overflowY: "auto",
          overflowX: "auto",
        }}
      >
        {isLoading && state.tab !== "new-person" && (
          <Modal
            open={isLoading}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <CircularProgress size={60} />
            </div>
          </Modal>
        )}

        <PatientsProvider>
          {!isLoading && (
            <Grid
              container
              sx={{ display: "flex", border: "0px dotted blue", width: "100%", gap: "10px" }}
            >
              <Grid
                item
                xs={12}
                md={state.tab === "new-person" ? 12 : 2.6}
                sx={{ border: "0px solid red", alignItems: "stretch" }}
              >
                {" "}
                {/* This is the grid for the left side */}
                <Grid item xs={12} sx={{ border: "0px dotted green" }}>
                  {" "}
                  {/* This is the grid for the tabs */}
                  <Tabs
                    value={state.tab === "new-person" ? "neu" : state.value}
                    onChange={handleTabChange}
                    aria-label="icon label tabs example"
                    orientation="horizontal" // Set to horizontal
                    variant="scrollable"
                    scrollButtons="auto"
                    sx={{
                      minHeight: "",
                      paddingLeft: mobiliTheme.topLevelPadding,
                      "& .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabs-scrollButtons": {
                        width: "10px", // Adjust this value as needed  ,
                      },
                    }}
                  >
                    <Tab
                      iconPosition="start"
                      icon={<PersonAddIcon />}
                      component={Link}
                      to="/patient"
                      label=""
                      value="neu"
                      sx={{
                        backgroundColor:
                          state.tab === "new-person" ? "secondary.main" : "primary.main",
                        color: "black", // Text color for the box
                        padding: "0px 10px 1px 10px",
                        minHeight: "40px",
                        minWidth: "",
                      }}
                      onClick={() => {
                        const newIndex = selectedPatients.length + 1; // Adjusted to start from 1
                        setNewPatientTabIndex(newIndex); // Update the "Neu" tab index
                        setState({ ...state, value: newIndex, tab: "new-person" });
                      }}
                    />
                    {!isLoading &&
                      selectedPatients?.map((patient: any, index: any) => (
                        <Tab
                          iconPosition="start"
                          icon={<Person3 />}
                          component={Link}
                          to={onTablClickPath(patient.id)}
                          label={
                            <Box>
                              {`${patient.lastName},${patient.firstName}`}
                              <IconButton
                                sx={{ zIndex: 4 }}
                                onClick={(e) => {
                                  e.stopPropagation(); // Stop event propagation
                                  e.preventDefault(); // Prevent the default event
                                  handleClosePatientTab(index);
                                }}
                                aria-label="Close"
                                style={{
                                  border: "0px solid red",
                                  color: mobiliTheme.mainHeader,
                                  position: "absolute",
                                  backgroundColor: "transparent",
                                  right: "-0px",
                                  top: "-0px",
                                  padding: "1px",
                                }}
                              >
                                <CloseIcon sx={{ fontSize: "16px" }} />
                              </IconButton>
                            </Box>
                          }
                          onClick={(e: any) => handleTabChange(null, index + 1)}
                          value={index + 1}
                        />
                      ))}
                  </Tabs>
                </Grid>
                <TopLevelPaper key={selectedPatients[patientIndex]?.id}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    height: "100%",
                  }}
                >
                  {state.tab === "new-person" && (
                    <PatientPage isEditing={false} setActiveTab={setActiveTab} />
                  )}
                  {patient && (
                    <>
                      {/* Let's keep this box as the left column */}
                      <PatientOverview
                        patientData={selectedPatients[patientIndex]}
                        onRowClick={(row: any) => handleRowClick(row)}
                        setPatientData={(row: any) => setPatientsData(row)}
                        handleData={fetchData}
                        masterData={masterTablesData}
                        updatePatientData={updatePatientData}
                      />

                      {/* ADD OTHER LEFT SIDE COMPONENTS HERE ( notes, history, tickets) */}
                      <PatientNotesAndRecords patientId={selectedPatients[patientIndex].id} />
                      <DeviceSizeLogger />
                    </>
                  )}
                </TopLevelPaper>
              </Grid>

              {/* Right side - RxOverview */}
              {patient && (
                <Grid item xs={12} md sx={{ border: "0px solid green" }}>
                  <Tabs
                    orientation="horizontal" // Display tabs vertically
                    value={selectedSubTab}
                    onChange={handleSubTabChange}
                    aria-label="sub-tabs"
                    sx={{ minHeight: "", paddingLeft: mobiliTheme.topLevelPadding }}
                  >
                    <Tab
                      iconPosition="start"
                      label="Rezepte"
                      component={Link}
                      to={onSubTabClick(selectedPatients[patientIndex].id, 1)}
                      icon={<ImportContacts />}
                      value={1}
                    />
                    <Tab
                      iconPosition="start"
                      label="Termine"
                      component={Link}
                      to={onSubTabClick(selectedPatients[patientIndex].id, 2)}
                      icon={
                        <CalendarMonth
                          sx={{
                            fontWeight: selectedSubTab === 2 ? "bold" : "normal",
                          }}
                        />
                      }
                      value={2}
                      sx={{
                        // backgroundColor:
                        //   selectedSubTab === 2 ? "secondary.light" : "secondary.dark",
                        // borderBottom: "2px solid #7c4dff",
                        // borderTopLeftRadius: "10px", // Apply border radius to top-left corner
                        // borderTopRightRadius: "10px", // Apply border radius to top-right corner
                        marginRight: "4px",
                      }}
                    />
                    <Tab
                      iconPosition="start"
                      label="Bill"
                      component={Link}
                      to={onSubTabClick(selectedPatients[patientIndex].id, 3)}
                      icon={
                        <CalendarMonth
                          sx={{
                            fontWeight: selectedSubTab === 3 ? "bold" : "normal",
                          }}
                        />
                      }
                      value={3}
                      sx={
                        {
                          // backgroundColor:
                          //   selectedSubTab === 2 ? "secondary.light" : "secondary.dark",
                          // borderBottom: "2px solid #7c4dff",
                          // borderTopLeftRadius: "10px", // Apply border radius to top-left corner
                          // borderTopRightRadius: "10px", // Apply border radius to top-right corner
                        }
                      }
                    />
                  </Tabs>

                  <TopLevelPaper
                    key={selectedPatients[patientIndex]?.id}
                    sx={{ borderTopRightRadius: "0px", height: "100%" }}
                  >
                    {(() => {
                      switch (selectedSubTab) {
                        case 1:
                          console.log("ABCe", patient);
                          return (
                            <RxOverView
                              patientData={selectedPatients[patientIndex]}
                              openPatientTab={(row: any) => handleRowClick(row)}
                              setPatientData={(row: any) => setPatientsData(row)}
                              handleData={fetchData}
                              masterData={masterTablesData}
                              routingParams={getSelectedPatientparams(
                                selectedPatients[patientIndex].id
                              )}
                            />
                          );
                        case 2:
                          return (
                            <ScheduleOverview
                              masterData={masterTablesData}
                              patientData={selectedPatients[patientIndex].id}
                            />
                          );

                        case 3:
                          return;

                        // case 4:
                        //   console.log("ABCe", patient,index,patientData,patientData[index]);
                        //   return (
                        //     <RxOverView
                        //      patientData={patientData[index]}
                        //       openPatientTab={(row: any) => handleRowClick(row)}
                        //       setPatientData={(row: any) =>
                        //         setPatientsData(row)
                        //       }
                        //       handleData={fetchData}
                        //     />
                        //   );
                        default:
                          if (
                            selectedSubTab >= 3 &&
                            patientSubTabs[patientIndex] &&
                            patientSubTabs[patientIndex][selectedSubTab - 3]
                          ) {
                            console.log(
                              "ABC",
                              selectedSubTab,
                              patientSubTabs,
                              patientIndex,
                              selectedPatients
                            );
                            return (
                              <RxPage
                                isEditing={true}
                                selectedRow={patientSubTabs[patientIndex][selectedSubTab - 3]}
                                patientValues={selectedPatients[patientIndex]}
                                masterData={masterTablesData}
                                handleData={updatePatientData}
                                setPatientData={(row: any) => setPatientsData(row)}
                              />
                            );
                          } else {
                            // If no "Rx Page" sub-tab exists, set control to "Patient Info" sub-tab
                            setSelectedSubTab(0);
                          }
                          return null;
                      }
                    })()}
                  </TopLevelPaper>
                </Grid>
              )}
            </Grid>
          )}
        </PatientsProvider>
      </Grid>
    </Grid>
  );
});

export default PatientLayout;
