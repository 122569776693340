import React, { useEffect, useState } from "react";
import "react-dates/lib/css/_datepicker.css";
import {
  Button,
  Switch,
  TextField,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  FormControl,
  styled,
  Tooltip,
  Popover,
  IconButton,
} from "@mui/material";
import { DateRangePicker } from "react-dates";
import { v4 as uuidv4 } from "uuid";
import {
  AccessTime,
  AccessTimeFilledOutlined,
  Add,
  Delete,
  Edit,
  Info,
  Save,
} from "@mui/icons-material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import "react-dates/initialize";
import { PatientAvailabilityDto } from "../../api-client";

import { AppointmentContext } from "../../stores/Appointment/appointment.provider";
import useStore from "../../helpers/useStore";
import "./datepicker.css";
import { mobiliTheme } from "../../themes/mobiliTheme";
// import "./switch.css"

const CustomInput = styled("div")`
  display: flex;
  gap: 1px;
  align-items: center;
`;
const SwitchWrapper = styled(Switch)`
  && {
    .MuiSwitch-thumb {
      color: ${(props) =>
        props.checked
          ? mobiliTheme.palette.error.main
          : mobiliTheme.palette.success.dark}; /* Your error or primary color */
    }
    .MuiSwitch-track {
      background-color: ${(props) =>
        props.checked
          ? mobiliTheme.palette.error.main
          : mobiliTheme.palette.success.main}; /* Your error or primary color */
      opacity: 0.5; /* Adjust transparency as needed */
    }
  }
`;
interface PatientUnavailabilityProps {
  patientId: number;
  patientAvailabilityData: PatientAvailabilityDto[];
  setPatientAvailability: any;
  patientAvailrefresh?: any;
  setPatientAvailrefresh?: any;
}
const PatientUnavailability: React.FC<PatientUnavailabilityProps> = ({
  patientId,
  patientAvailabilityData,
  setPatientAvailability,
  patientAvailrefresh,
  setPatientAvailrefresh,
}) => {
  const AppointmentStore = useStore(AppointmentContext);
  const {
    savePatientUnavailibilityForPatient,
    getPatientUnavailabilityForPatient,
    deletePatientUnavailability,
  } = AppointmentStore;
  //   State to store Popover and IconButton state for each day of the week in each group
  const [popoverOpen, setPopoverOpen] = useState<{
    [key: string]: { [key: number]: HTMLElement | null };
  }>({});
  const [anchorEl, setAnchorEl] = useState<{
    [key: string]: { [key: number]: HTMLElement | null };
  }>({});
  const [isLoading, setLoading] = useState(false);
  const transformDataToRows = (availabilityData: any) => {
    const rows = [];

    // Create a map to group data by groupId
    const groupedData = availabilityData.reduce((acc: any, data: any) => {
      const groupId = data.groupId;

      if (!acc[groupId]) {
        acc[groupId] = {
          groupId: groupId,
          validityStart:
            data.validityStart !== null
              ? new Date(data.validityStart).toISOString()
              : null,
          validityEnd:
            data.validityEnd !== null
              ? new Date(data.validityEnd).toISOString()
              : null,
          dayData: [],
        };
      }

      acc[groupId].dayData.push({
        groupId: groupId,
        id: data.id,
        dayOfWeek: data.dayOfWeek,
        selected: true, // Since it's coming from availabilityData
        startTime: data.startTime
          ? new Date(data.startTime).toISOString()
          : null,
        endTime: data.endTime ? new Date(data.endTime).toISOString() : null,
      });

      return acc;
    }, {});

    // Convert the map values into an array (rows)
    for (const key in groupedData) {
      // Sort dayData within each group based on dayOfWeek
      const sortedRow = {
        ...groupedData[key],
        dayData: groupedData[key].dayData.sort(
          (a: any, b: any) => a.dayOfWeek - b.dayOfWeek
        ),
      };
      rows.push(sortedRow);
    }

    return rows;
  };
  const uuidGroupId = uuidv4();
  const [rows, setRows] = useState([
    {
      groupId: uuidGroupId, // Replace with actual existing groupId from your data
      validityStart: null,
      validityEnd: null,
      dayData: [
        {
          id: null,
          groupId: uuidGroupId,
          dayOfWeek: 1,
          selected: false,
          startTime: null,
          endTime: null,
        },
        {
          id: null,
          groupId: uuidGroupId,
          dayOfWeek: 2,
          selected: false,
          startTime: null,
          endTime: null,
        },
        {
          id: null,
          groupId: uuidGroupId,
          dayOfWeek: 3,
          selected: false,
          startTime: null,
          endTime: null,
        },
        {
          id: null,
          groupId: uuidGroupId,
          dayOfWeek: 4,
          selected: false,
          startTime: null,
          endTime: null,
        },
        {
          id: null,
          groupId: uuidGroupId,
          dayOfWeek: 5,
          selected: false,
          startTime: null,
          endTime: null,
        },
      ],
    },
  ]);

    useEffect(() => {
      const transformedRows = transformDataToRows(patientAvailabilityData);
      if (transformedRows.length !== 0) {
        setRows(transformedRows);}
        else{
        // Add a default row when there's no data from the API
        const newGroupId = uuidv4();
        const defaultRow = {
          groupId: newGroupId,
          validityStart: null,
          validityEnd: null,
          dayData: [
            {
              id: null,
              groupId: newGroupId,
              dayOfWeek: 1,
              selected: false,
              startTime: null,
              endTime: null,
            },
            {
              id: null,
              groupId: newGroupId,
              dayOfWeek: 2,
              selected: false,
              startTime: null,
              endTime: null,
            },
            {
              id: null,
              groupId: newGroupId,
              dayOfWeek: 3,
              selected: false,
              startTime: null,
              endTime: null,
            },
            {
              id: null,
              groupId: newGroupId,
              dayOfWeek: 4,
              selected: false,
              startTime: null,
              endTime: null,
            },
            {
              id: null,
              groupId: newGroupId,
              dayOfWeek: 5,
              selected: false,
              startTime: null,
              endTime: null,
            },
          ],
        };
        setRows([defaultRow]);
      }
    }, [patientAvailabilityData]);
    


  const fetchData = async () => {
    try {
      const fetchedData = await getPatientUnavailabilityForPatient(patientId);
      setPatientAvailability(fetchedData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [patientAvailrefresh]);

  const [editingId, setEditingId] = useState(null);

  const daysOfWeek = ["Mo", "Di", "Mi", "Do", "Fr"];

  const handlePopoverOpen = (
    groupId: string,
    dayOfWeek: number,
    event: React.MouseEvent<HTMLElement>
  ) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[groupId],
        [dayOfWeek]: event.currentTarget,
      },
    }));
  };

  const handlePopoverClose = (groupId: string, dayOfWeek: number) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[groupId],
        [dayOfWeek]: null,
      },
    }));
  };

  const handleDayToggle = (groupId: any, dayOfWeek: any) => {
    setRows((prevRows) =>
      prevRows.map((row) =>
        row.groupId === groupId
          ? {
              ...row,
              dayData: row.dayData.some((day) => day.dayOfWeek === dayOfWeek)
                ? row.dayData.map((day) =>
                    day.dayOfWeek === dayOfWeek
                      ? {
                          ...day,
                          selected: !day.selected,
                          startTime: day.selected ? day.startTime : null,
                          endTime: day.selected ? day.endTime : null,
                        }
                      : day
                  )
                : [
                    ...row.dayData,
                    {
                      id: null,
                      groupId: groupId,
                      dayOfWeek: dayOfWeek,
                      selected: true,
                      startTime: null,
                      endTime: null,
                    },
                  ],
            }
          : row
      )
    );
  };

  const handleTimeChange = (
    groupId: any,
    dayOfWeek: any,
    field: any,
    value: any
  ) => {
    const parsedValue = new Date(value);

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.groupId === groupId
          ? {
              ...row,
              dayData: row.dayData.map((day: any) =>
                day.dayOfWeek === dayOfWeek
                  ? { ...day, [field]: parsedValue }
                  : day
              ),
            }
          : row
      )
    );
  };

  const handleValidityChange = (groupId: any, field: any, value: any) => {
    const parsedValue = value !== null ? new Date(value) : null;

    setRows((prevRows) =>
      prevRows.map((row) =>
        row.groupId === groupId ? { ...row, [field]: parsedValue } : row
      )
    );
  };

  const handleAddRow = () => {
    const newGroupId = uuidv4();

    setRows((prevRows) => [
      ...prevRows,
      {
        groupId: newGroupId,
        validityStart: null,
        validityEnd: null,
        dayData: [
          {
            id: null,
            groupId: newGroupId,
            dayOfWeek: 1,
            selected: false,
            startTime: null,
            endTime: null,
          },
          {
            id: null,
            groupId: newGroupId,
            dayOfWeek: 2,
            selected: false,
            startTime: null,
            endTime: null,
          },
          {
            id: null,
            groupId: newGroupId,
            dayOfWeek: 3,
            selected: false,
            startTime: null,
            endTime: null,
          },
          {
            id: null,
            groupId: newGroupId,
            dayOfWeek: 4,
            selected: false,
            startTime: null,
            endTime: null,
          },
          {
            id: null,
            groupId: newGroupId,
            dayOfWeek: 5,
            selected: false,
            startTime: null,
            endTime: null,
          },
        ],
      },
    ]);
  };

  const handleSaveRow = async () => {
    try {
      setLoading(true);
      const transformedData = transformInputData();
      console.log("transformedData", transformedData);
      if (transformedData.length > 0) {
        const response = await savePatientUnavailibilityForPatient(
          patientId,
          transformedData
        );
        if (setPatientAvailrefresh) {
          // Toggle the value of patientAvailrefresh
          setPatientAvailrefresh((prevValue: any) => !prevValue);
        }
      }
      setEditingId(null);
    } catch (error) {
      console.error("Error saving data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteRow = async (groupId: any) => {
    try {
      setLoading(true);
      const rowToDelete = rows.find((row) => row.groupId === groupId);

      if (
        rowToDelete &&
        patientAvailabilityData.some((data) => data.groupId === groupId)
      ) {
        // If the row has an id, it means it is already saved, so we need to delete it using the API
        try {
          // Call your delete API here (replace deleteRowsApi with the actual API function)
          await deletePatientUnavailability(rowToDelete.groupId);

          // If the deletion is successful, update the rows in the state
          setRows((prevRows) =>
            prevRows.filter((row) => row.groupId !== groupId)
          );
          if (setPatientAvailrefresh) {
            // Toggle the value of patientAvailrefresh
            setPatientAvailrefresh((prevValue: any) => !prevValue);
          }
        } catch (error) {
          console.error("Error deleting row:", error);
        }
      } else {
        // If the row doesn't have an id, it means it is not saved yet, so we can directly remove it from the state
        setRows((prevRows) =>
          prevRows.filter((row) => row.groupId !== groupId)
        );
      }
    } catch (error) {
      console.error("Error deleting row:", error);
    } finally {
      setLoading(false);
    }
  };

  // State to track focusedInput for each groupId
  type FocusedInputs = { [key: string]: "startDate" | "endDate" | null };
  const [focusedInputs, setFocusedInputs] = useState<FocusedInputs>({});

  const handleFocusChange = (focused: any, groupId: any) => {
    setFocusedInputs((prev) => ({
      ...prev,
      [groupId]: focused,
    }));
  };
  const transformInputData = () => {
    const transformedData: PatientAvailabilityDto[] = [];
    rows.forEach((row) => {
      row.dayData.forEach((day: any) => {
        if (day.selected) {
          const transformedObject: any = {
            id: day.id ? day.id : undefined,
            groupId: row.groupId,
            dayOfWeek: day.dayOfWeek,
            startTime: day.startTime
              ? new Date(day.startTime).toISOString()
              : null,
            endTime: day.endTime ? new Date(day.endTime).toISOString() : null,
            validityStart: row.validityStart
              ? new Date(row.validityStart).toISOString()
              : null,
            validityEnd: row.validityEnd
              ? new Date(row.validityEnd).toISOString()
              : null,
          };

          transformedData.push(transformedObject);
        }
      });
    });
    return transformedData;
  };
  console.log("IIOIOOO", rows);
  return (
    <>
      <TableContainer>
        <Table sx={{ borderCollapse: "collapse", border: "4px solid #ccc" }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.groupId}
                  sx={{ borderCollapse: "collapse", border: "4px solid #ccc" }}
                >
                  {daysOfWeek.map((day, index) => {
                    const dayData = row.dayData.find(
                      (d) => d.dayOfWeek === index + 1
                    );
                    console.log("dayData", day, dayData);
                    return (
                      <React.Fragment key={index}>
                        <TableCell
                          sx={{ padding: "2px", border: "4px solid #ccc" }}
                        >
                          <Box
                            sx={{
                              direction: "column",
                              // border: dayData?.selected ? "1px solid black" : "",
                            }}
                          >
                            <TableCell>{day}</TableCell>
                            <FormControlLabel
                              control={
                                <SwitchWrapper
                                  checked={dayData?.selected}
                                  onChange={() =>
                                    handleDayToggle(row.groupId, index + 1)
                                  }
                                />

                                // <Switch
                                //   checked={dayData?.selected}
                                //   color={dayData && dayData.selected? "error" : "primary"}
                                //   onChange={() =>
                                //     handleDayToggle(row.groupId, index + 1)
                                //   }
                                // />
                              }
                              label=""
                            />

                            {/* {dayData?.selected && ( */}
                            <>
                              <Box sx={{ direction: "column" }}>
                            
                                <IconButton
                                  aria-label="info"
                                  onClick={(e: any) => {
                                    handlePopoverOpen(
                                      row.groupId,
                                      index + 1,
                                      e
                                    );
                                    setAnchorEl(e.currentTarget);
                                  }}
                                  disabled={!dayData?.selected}
                                >
                                  <AccessTimeFilledOutlined
                                    color={
                                      dayData?.startTime ? "error" : "disabled"
                                    }
                                    sx={{ filter: dayData?.selected ? 'none' : 'blur(1px)' }}
                                  />
                                </IconButton>
                              </Box>
                              <Popover
                                open={Boolean(
                                  popoverOpen[row.groupId] &&
                                    popoverOpen[row.groupId][index + 1]
                                )}
                                anchorEl={
                                  popoverOpen[row.groupId] &&
                                  popoverOpen[row.groupId][index + 1]
                                }
                                onClose={() =>
                                  handlePopoverClose(row.groupId, index + 1)
                                }
                                anchorOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                                transformOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                              >
                                <Box
                                  sx={{
                                    direction: "row",
                                    border: "5px solid #ccc",
                                  }}
                                >
                                  <CustomInput
                                    sx={{ margin: "4px", fontWeight: "bold" }}
                                  >
                                    Aus:
                                    <TimePicker
                                      value={
                                        dayData?.startTime
                                          ? new Date(dayData.startTime)
                                          : null
                                      }
                                      onChange={(newValue) =>
                                        handleTimeChange(
                                          row.groupId,
                                          index + 1,
                                          "startTime",
                                          newValue
                                        )
                                      }
                                      // disabled={editingId !== null && editingId !== row.groupId}
                                      ampm={false}
                                      format="HH:mm" // Display format
                                      sx={{ width: "150px" }}
                                    />
                                  </CustomInput>
                                  <CustomInput
                                    sx={{ margin: "4px", fontWeight: "bold" }}
                                  >
                                    Zu:
                                    <TimePicker
                                      value={
                                        dayData?.endTime
                                          ? new Date(dayData.endTime)
                                          : null
                                      }
                                      onChange={(newValue) =>
                                        handleTimeChange(
                                          row.groupId,
                                          index + 1,
                                          "endTime",
                                          newValue
                                        )
                                      }
                                      format="HH:mm" // Display format
                                      // disabled={editingId !== null && editingId !== row.groupId}
                                      ampm={false}
                                      sx={{ width: "150px" }}
                                    />
                                  </CustomInput>
                                </Box>
                              </Popover>
                            </>
                            {/* )} */}
                          </Box>
                        </TableCell>
                      </React.Fragment>
                    );
                  })}

                  <TableCell
                    sx={{
                      padding: "2px",
                      borderCollapse: "collapse",
                      border: "4px solid #ccc",
                    }}
                  >
                    <CustomInput
                      sx={{ fontWeight: "bold", paddingLeft: "10px" }}
                    >
                      Gültigkeit
                    </CustomInput>
                    <DateRangePicker
                      startDate={
                        row.validityStart ? moment(row.validityStart) : null
                      }
                      startDatePlaceholderText="Start"
                      endDatePlaceholderText="Ende"
                      startDateId="validityStartDate"
                      endDate={row.validityEnd ? moment(row.validityEnd) : null}
                      endDateId="validityEndDate"
                      onDatesChange={({ startDate, endDate }) => {
                        const startString = startDate ? startDate : null;
                        const endString = endDate ? endDate : null;

                        handleValidityChange(
                          row.groupId,
                          "validityStart",
                          startDate ? startString : null
                        );
                        handleValidityChange(
                          row.groupId,
                          "validityEnd",
                          endDate ? endString : null
                        );

                        setFocusedInputs((prev: any) => ({
                          ...prev,
                          [row.groupId]: startDate ? "endDate" : "startDate",
                        }));
                      }}
                      focusedInput={
                        focusedInputs[row.groupId] as
                          | "startDate"
                          | "endDate"
                          | null
                      }
                      onFocusChange={(focused) =>
                        handleFocusChange(focused, row.groupId)
                      }
                      displayFormat="DD.MM.YYYY"
                      isOutsideRange={() => false}
                      small
                      withPortal
                      showClearDates
                      anchorDirection="left"
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "4px",
                      borderCollapse: "collapse",
                      border: "4px solid #ccc",
                    }}
                  >
                    <Box sx={{ direction: "column" }}>
                      <Button
                        onClick={handleSaveRow}
                        disabled={isLoading}
                        sx={{ margin: "2px" }}
                      >
                        <Save />
                      </Button>
                      <Button
                        onClick={() => handleDeleteRow(row.groupId)}
                        disabled={isLoading}
                        sx={{ margin: "2px" }}
                      >
                        <Delete />
                      </Button>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </LocalizationProvider>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <Button startIcon={<Add />} onClick={handleAddRow}>
          Hinzufügen
        </Button>
      </Box>
      {/* <Button onClick={handleAddRow}>Hinzufügen</Button> */}
    </>
  );
};

export default PatientUnavailability;
