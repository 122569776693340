import React, { createRef, useEffect, useState } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import { DateSelectArg, EventApi, EventClickArg, EventDropArg } from '@fullcalendar/core';
import { Event } from "../../molecules/Calendar/Calendar.type";
import interactionPlugin, { EventDragStartArg, EventReceiveArg, EventResizeDoneArg } from "@fullcalendar/interaction";
import { CalendarContext } from '../../stores/Calendar/calendar.provider';
import useStore from '../../helpers/useStore';
import moment, * as Moment from 'moment';
import { extendMoment } from 'moment-range';

interface WeeklyCalendarProps {
  events: Event[];
  onDatesSet?: (dates: any) => void;
  onCreateEvent?: (event: any) => void;
  onChangeEvent?: (event: Event, relatedEvents?: Event[]) => void;
  onEventReceive?: (event: any) => void;
  onRemoveEvent?: (event: any) => void;
  onOutdated?: (event: any) => void;
}

const WeeklyCalendar: React.FC<WeeklyCalendarProps> = ({events, onEventReceive, onChangeEvent, onCreateEvent, onOutdated}) => {

  let eventGuid = 0;
  const slotMinTime = "08:00:00";
  const slotMaxTime = "20:00:00";
  const calendarRef = createRef<FullCalendar>();
  const date = moment(new Date()).format("YYYY-MM-DD");
  const [event, setEvent] = useState<Event>();
  const [continiusEvents, setContiniuousEvents] = useState<Event[]>([]);

  const createEventId = () => {
    return String(eventGuid++);
  };

  useEffect(() => {

  }, []);


  const handleCreateEvent = (selectInfo: DateSelectArg) => {    
    let calendarApi = selectInfo.view.calendar;
    const event: Event = {
      id: createEventId(),
      title: "",
      start: moment(selectInfo.start).toISOString(),
      end: moment(selectInfo.end).toISOString(),
    };
    calendarApi.addEvent(event);
    setEvent(event);
    console.log("event added to calendar", event);
    return onCreateEvent && onCreateEvent(event);
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    const event : Event = {
      id: clickInfo.event.id,
      title: clickInfo.event.title,
      start: moment(clickInfo.event.start).format('YYYY-MM-DD'),
      end: moment(clickInfo.event.end).format('YYYY-MM-DD'),
      // type: clickInfo.event.extendedProps.type
    };
    setEvent(event);
    console.log("event clicked", event);
  };

  const handleEvents = (events: EventApi[]) => {
    
  };

  const handleEventReceive = (eventReceiveInfo: EventReceiveArg) => {
    console.log("eventReceiveInfo", eventReceiveInfo);
    const originalEvent = eventReceiveInfo.event;
    originalEvent.remove();
    return onEventReceive && onEventReceive(eventReceiveInfo);
  };
  
  const handleEventDrop = (eventDropInfo: EventDropArg) => {
    if (eventDropInfo.event.extendedProps.multipleTimeSlots === false) {
      return;
    }
    console.log("event:", eventDropInfo.event.toPlainObject());

    // main event that is dragged
    const event: Event = {
      id: eventDropInfo.event.id,
      title: eventDropInfo.event.title,
      start: eventDropInfo.event.start?.toISOString()!,
      end: eventDropInfo.event.end?.toISOString()!,
      resourceId: eventDropInfo.event._def.resourceIds?.toString()!,
      allDay: eventDropInfo.event.allDay,
      appointmentId: eventDropInfo.event.extendedProps.appointmentId,
      type: eventDropInfo.event.extendedProps.type,
    };

    const relatedEvents = eventDropInfo.relatedEvents.map((related) => {
      console.log("related event:", related.toPlainObject());
      const relevent: Event = {
        id: related.id,
        title: related.title,
        start: related.start?.toISOString()!,
        end: related.end?.toISOString()!,
        resourceId: related._def.resourceIds?.toString()!,
        allDay: related.allDay,
        appointmentId: related.extendedProps.appointmentId,
        type: related.extendedProps.type,
      };
      return relevent;
    });

    console.log("event is going to be updated in the database:", event);
    return onChangeEvent && onChangeEvent(event, relatedEvents);
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        locale={"de"}
        displayEventTime={true}
        initialDate={date}
        schedulerLicenseKey="0067980715-fcs-1697454050"
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin
        ]}
        headerToolbar={{
          left: "",
          center: "",
          right: "timeGridWeek,timeGridDay",
        }}
        titleFormat={{ weekday: 'long', separator: ' bis ' }}
        buttonText={{
          today: "Heute",
          month: "Monat",
          year: "Jahr",
          week: "Woche",
          day: "Tag",
          list: "Liste"
        }}
        initialView="timeGridWeek"
        views={
          {
            timeGridWeek: {
              dayHeaderFormat: { weekday: 'long' },
            }
          }
        }
        slotDuration={'00:15:00'}
        slotLabelInterval= {
          {
            hour: 1
          }
        }
        slotLabelFormat={
          {
            hour: '2-digit', //2-digit, numeric
            minute: '2-digit', //2-digit, numeric
            meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
            hour12: false //true, false
          }
        }
        eventTimeFormat={
          {
            hour: '2-digit', //2-digit, numeric
            minute: '2-digit', //2-digit, numeric
            meridiem: false, //lowercase, short, narrow, false (display of AM/PM)
            hour12: false //true, false
          }  
        }
        slotMinTime={slotMinTime}
        slotMaxTime={slotMaxTime}
        // eventAllow={(info) => !info.start && !info.end} // Disables resizing
        firstDay={1}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        droppable={true}
        allDaySlot={false}
        events={events}
        select={handleCreateEvent}
        eventClick={handleEventClick}
        eventsSet={handleEvents}
        eventReceive={handleEventReceive}
        eventDrop={handleEventDrop}
      />
    </>
  )
}

export default WeeklyCalendar;