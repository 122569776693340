import {
  Cancel,
  Create,
  Delete,
  DragHandle,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  LocalActivityOutlined,
  Note,
  Person,
  Save,
  Send,
  SendAndArchiveSharp,
} from "@mui/icons-material";
import {
  Typography,
  TextField,
  Grid,
  Container,
  Button,
  Box,
  Select,
  Autocomplete,
  Avatar,
  Popover,
  Paper,
  Menu,
  MenuItem,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Tab,
  Tabs,
  Badge,
  ListItemIcon,
  ListItemText,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  ButtonGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Controls from "../../../atoms/Controls";
import { UserContext } from "../../../stores/User/User.provider";
import useStore from "../../../helpers/useStore";
import { attentionColors, mobiliTheme } from "../../../themes/mobiliTheme";
import { TicketDto } from "../../../api-client";
import { set } from "date-fns";
import { TicketsStoreContext } from "../../../stores/Ticketing/tickets.provider";
import { format } from "date-fns";
import { Timeline, TimelineEvent } from "react-event-timeline";

import { commonTabStyle } from "../../PatientOverview/tabStyles";
import { ActivityDto } from "../../../api-client";

import TextArea from "../../../atoms/TextArea";
import Picker from "emoji-picker-react";
import Involvements from "./Involvements";
import { is } from "date-fns/locale";
import WarningDialog from "../../../atoms/WarningDialog";
import FlatCard from "../../../atoms/FlatCard";
import { MobiliAvatar } from "../../../atoms/MobiliAvatar";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

interface TicketFormProps {
  openTicket: boolean;
  onCloseTicketForm?: () => void;
  data?: any;
  type?: { ticketType: string; title: string; id: string; idType: string };
  // editable?: boolean;
  // setEditable?:any;
  refresh?: boolean;
  setRefresh?: any;
  isTicketLoading?: boolean;
}

const TicketForm: React.FC<TicketFormProps> = ({
  openTicket,
  onCloseTicketForm,
  data,
  // editable,
  // setEditable,
  isTicketLoading,
}) => {
  const UserStore = useStore(UserContext);
  const TicketsStore = useStore(TicketsStoreContext);
  const { user, allUsers, getUserBy, getAllUsers } = UserStore;
  const { createTicket, updateTicket, lastTicketsUpdate } = TicketsStore;
  const [userOptions, setUserOptions] = useState<any>([]);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedAssigneeOption, setAssigneeSelectedOption] = useState<any>(null); // New state to hold the selected option
  const [selectedCreaterOption, setCreaterSelectedOption] = useState<any>(null); // New state to hold the selected option

  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: any, newValue: any) => {
    setTabValue(newValue);
  };

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    console.log("datadkkdkdskkd", data, user);
    try {
      if (data) {
        // setTicketData(data);
        // Check if assignedToUser exists in data
        if (data.assignedToUser) {
          // Create a new object with id and label properties
          const assignedToOption = {
            id: data.assignedToUser.id,
            label: `${data.assignedToUser.firstName} ${data.assignedToUser.lastName}`,
          };
          setAssigneeSelectedOption(data.assignedToUser); // Update selectedOption with assignedToUser
        }
        if (data.createdByUser?.id) {
          // Create a new object with id and label properties
          const createOption = {
            id: data.createdByUser.id,
            label: `${data.createdByUser.firstName} ${data.createdByUser.lastName}`,
          };
          setTicketData({
            ...data,
            createdByUser: {
              id: data?.createdByUser.id as any,
              nickName: data?.createdByUser.nickName,
              firstName: data?.createdByUser.firstName,
              lastName: data?.createdByUser.lastName,
            },
          });
        } else if (!data.createdByUser?.id) {
          setTicketData({
            ...data,
            createdByUser: {
              id: user?.id as any,
              nickName: user?.nickName,
              firstName: user?.firstName,
              lastName: user?.lastName,
            },
          });
        }
      } else {
        setTicketData({
          ...ticketData,
          createdByUser: {
            id: user?.id as any,
            nickName: user?.nickName,
            firstName: user?.firstName,
            lastName: user?.lastName,
          },
        });
      }
    } catch (error) {
      console.error("Error while fetching data:", error);
    }
  }, [data, user]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          // Fetch user data
          // const userResponse = await getUserBy(user.location?.id as string);
          const userResponse = await getAllUsers();
          // Find index of logged-in user in userResponse
          const userIndex = userResponse.findIndex((u: any) => u.id === user.id);

          // If logged-in user exists in userResponse, move it to the top
          if (userIndex !== -1) {
            userResponse.splice(userIndex, 1); // Remove logged-in user from its current position
            userResponse.unshift(user); // Add logged-in user at the beginning
          }
          setUserOptions(userResponse);
        } else {
          setUserOptions([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Fetch data when user changes or on initial render
    if (!initialRender || user) {
      fetchData();
    }

    // Set initialRender flag to false after initial render
    setInitialRender(false);
  }, [user, initialRender]);
  const initialValues = {
    assignedToUser: { id: "" },
    description: "",
    activities: [],
    createdByUser: user
      ? {
          id: user?.id as any,
          nickName: user?.nickName,
          firstName: user?.firstName,
          lastName: user?.lastName,
        }
      : undefined,
    updatedByUser: user
      ? {
          id: user?.id as any,
          nickName: user?.nickName,
          firstName: user?.firstName,
          lastName: user?.lastName,
        }
      : undefined,
    ticketType: "",
    ticketStatus: "New",
    priority: "Low",
    comments: "",
    rxId: data?.rx?.id || data?.id,
    patientId: data?.patient?.id || data?.patientId,
    appointmentId: data?.appointment?.id || data?.appointmentId,
  };
  const [ticketData, setTicketData] = useState<TicketDto>(initialValues);
  const [unsavedChangesWarning, setShowunsavedChangesWarning] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false); // Flag to track unsaved changes

  console.log("ticketDaDHDJJSJSJJSJJSJSta", ticketData, data, user);
  const handleChange = (e: any) => {
    setUnsavedChanges(true);
    console.log("GSHJDJDJDJJD", e.target);
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const handleSubmit = async (e?: any) => {
    try {
      setLoading(true);
      if (e) {
        e.preventDefault();
      }
      console.log("TICKETSDATTZSHS", ticketData);
      ticketData.updatedByUser = {
        id: user?.id as any,
        nickName: user?.nickName,
        firstName: user?.firstName,
        lastName: user?.lastName,
      };
      let response;
      if (!ticketData.id) {
        response = await createTicket(ticketData);
      } else {
        response = await updateTicket(ticketData.id as number, ticketData);
      }
      setUnsavedChanges(false);
      setShowunsavedChangesWarning(false);
      if (response) {
        setTicketData(response);
      }
    } catch (error) {
      console.error("Error while ticket creation:", error);
    } finally {
      // Set loading back to false when the API call is complete
      setLoading(false);
    }
  };

  const handleAssignToMe = (type: string) => {
    setUnsavedChanges(true);
    // Assign the logged-in user
    if (user) {
      if (type === "assignedToUser") {
        setAssigneeSelectedOption(user); // Update the selected option state with the logged-in user's data
        setTicketData({
          ...ticketData,
          assignedToUser: {
            id: user.id as any,
            nickName: user?.nickName,
            firstName: user?.firstName,
            lastName: user?.lastName,
          },
        }); // Assign the logged-in user's ID
      }
      if (type === "createdByUser") {
        setCreaterSelectedOption(user); // Update the selected option state with the logged-in user's data
        setTicketData({
          ...ticketData,
          createdByUser: {
            id: user.id as any,
            nickName: user?.nickName,
            firstName: user?.firstName,
            lastName: user?.lastName,
          },
        }); // Assign the logged-in user's ID
      }
    }
    console.log(
      "Assign to me clicked",

      ticketData.assignedToUser?.id,
      user
    );
  };
  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);
  const handleMoreUsersClick = async () => {
    setFetchingMoreUsers(true);
    try {
      const moreUsers = await getAllUsers();
      // Filter out duplicate users by ID
      const filteredUsers = moreUsers.filter(
        (user) => !userOptions.some((existingUser: any) => existingUser.id === user.id)
      );
      setUserOptions((prevUsers: any) => [...prevUsers, ...filteredUsers]);
      // setFetchingMoreUsers(false);
    } catch (error) {
      console.error("Error fetching more users:", error);
      setFetchingMoreUsers(false);
    }
  };
  console.log(
    "selectedOption",

    ticketData.assignedToUser?.id,
    data
  );
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(
    <KeyboardDoubleArrowDown sx={{ color: attentionColors.lowPriority }} />
  ); // Default selected priority symbol
  const handleClick = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    if (onCloseTicketForm) {
      setTicketData(initialValues);
      onCloseTicketForm();
    }
  };
  const handleCloseTicketFormDialog = () => {
    if (unsavedChanges) {
      // If there are unsaved changes, set a state to open the warning dialog
      setShowunsavedChangesWarning(true);
    } else {
      handleClose();
    }
  };

  const handleUnsavedChangesWarning = () => {
    handleSubmit();
  };

  const handleCloseUnsavedChangesWarning = () => {
    handleClose();
    // If user cancels and chooses not to continue, close the warning dialog
    setShowunsavedChangesWarning(false);
  };

  const handlePrioritySelect = (prioritySymbol: any, value: string) => {
    setSelectedPriority(prioritySymbol); // Update selected priority symbol
    setTicketData({ ...ticketData, priority: value }); // Update ticket data with selected priority
  };
  console.log("ticketDataOutside", ticketData);

  // Assignee Popover
  const [anchorAssigneeEl, setAnchorAssigneeEl] = useState<HTMLDivElement | null>(null);
  const [openAssignee, setOpenAssignee] = useState(false);

  const handleAssigneeClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setUnsavedChanges(true);
    setAnchorAssigneeEl(event.currentTarget);
    setOpenAssignee(!openAssignee);
  };

  const handleAssigneeClose = () => {
    setAnchorAssigneeEl(null);
    setOpenAssignee(false);
  };

  // Creater Popover
  // const [anchorCreaterEl, setAnchorCreaterEl] = useState<HTMLDivElement | null>(null);
  // const [openCreater, setOpenCreater] = useState(false);

  // const handleCreaterClick = (event: React.MouseEvent<HTMLDivElement>) => {
  //   setAnchorCreaterEl(event.currentTarget);
  //   setOpenCreater(!openCreater);
  // };

  // const handleCreaterClose = () => {
  //   setAnchorCreaterEl(null);
  //   setOpenCreater(false);
  // };
  console.log("isTikx", ticketData, user);
  return (
    <Dialog open={openTicket} fullWidth maxWidth="sm" sx={{ height: "770px" }}>
      <DialogTitle>
        <Note />
        {ticketData.id ? `Ticket: ${ticketData.id}` : "Neu Ticket"}
      </DialogTitle>

      <DialogContent>
        {!isTicketLoading && (
          <Grid container alignItems="stretch" alignContent="space-between" sx={{ paddingTop: 1 }}>
            <Grid item xs={6} sx={{ border: "0px solid red" }}>
              <Autocomplete
                sx={{ margin: "0px", width: "100%" }}
                disablePortal
                options={
                  [
                    { value: "Rezeptänderung", label: "Rezeptänderung" },
                    { value: "Rezeptbestellung", label: "Rezeptbestellung" },
                    { value: "Sonstiges", label: "Sonstiges" },
                  ] as any
                }
                value={ticketData.ticketType}
                onChange={(e, value) =>
                  handleChange({ target: { name: "ticketType", value: (value as any)?.value } })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Problemtyp"
                    name="ticketType"
                    sx={{ margin: "0px", width: "100%" }}
                  />
                )}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
            >
              <Typography sx={{ textAlign: "center" }}>Erstellt von</Typography>

              {ticketData.createdByUser ? (
                <MobiliAvatar
                  user={ticketData.createdByUser}
                  sx={{ width: 32, height: 32, fontSize: "0.8rem" }}
                />
              ) : (
                <MobiliAvatar
                  user={user || undefined}
                  sx={{ width: 32, height: 32, fontSize: "0.8rem" }}
                />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
            >
              <Typography sx={{ textAlign: "center" }}>Zugewiesen</Typography>
              <div onClick={handleAssigneeClick} style={{cursor: "pointer" , display:"flex"}}>
                {ticketData.assignedToUser ? (
                  <MobiliAvatar
                    user={ticketData?.assignedToUser}
                    sx={{ width: 32, height: 32, fontSize: "0.8rem", cursor: "pointer" }}
                  />
                ) : (
                  <MobiliAvatar
                    user={ticketData?.assignedToUser}
                    sx={{ width: 32, height: 32, fontSize: "0.8rem", cursor: "pointer" }}
                  />
                )}
                <ArrowDropDownIcon />
              </div>

              <Popover
                open={openAssignee}
                anchorEl={anchorAssigneeEl}
                onClose={handleAssigneeClose}
                sx={{ height: "700px", width: "400px" }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Paper style={{ height: "400px", width: "400px", overflow: "hidden" }}>
                  <div>
                    <Typography
                      variant="caption"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: mobiliTheme.palette.primary.dark,
                        fontSize: "0.rem",
                        padding: "3px",
                      }}
                      onClick={() => handleAssignToMe("assignedToUser")}
                    >
                      Assign to me
                    </Typography>
                  </div>
                  <div style={{ padding: "8px" }}>
                    <Autocomplete
                      disablePortal
                      open={openAssignee}
                      onClose={handleAssigneeClose}
                      options={
                        // !fetchingMoreUsers
                        //   ? userOptions.concat([{ label: "...more" }])
                        //   :
                        userOptions
                      }
                      value={selectedAssigneeOption}
                      onChange={(event, value) => {
                        // if (value && value.label === "...more") {
                        //   handleMoreUsersClick();
                        // } else {
                        console.log("value8899", value);
                        setAssigneeSelectedOption(value);
                        setTicketData({
                          ...ticketData,
                          assignedToUser: {
                            id: value?.id as any,
                            nickName: value?.nickName,
                            firstName: value?.firstName,
                            lastName: value?.lastName,
                          },
                        });
                        // handleChange({
                        //   target: {
                        //     name: "assignedToUser",
                        //     value: value ? { id: value.id } : "",
                        //   },
                        // });

                        // }
                      }}
                      getOptionLabel={(option: any) => `${option?.firstName} ${option?.lastName}`}
                      renderOption={(props: any, option: any) => (
                        <li {...props} style={{ width: "100%" }}>
                          {option.label === "...more" ? (
                            <Typography variant="body1" fontSize="14px" padding="2px">
                              ...more
                            </Typography>
                          ) : (
                            <>
                              <Avatar sx={{ width: 32, height: 32, fontSize: "0.8rem" }}>
                                {option.nickName}
                              </Avatar>
                              <Typography variant="body1" fontSize="12px" padding="2px">
                                {option.firstName} {option.lastName}
                              </Typography>
                            </>
                          )}
                        </li>
                      )}
                      renderInput={(params) => (
                        <>
                          <div>
                            <TextField
                              {...params}
                              sx={{ width: "100%" }}
                              label="Assignee"
                              name="assignedToUser"
                            />
                          </div>
                        </>
                      )}
                    />
                  </div>
                </Paper>
              </Popover>
            </Grid>

            <Grid
              item
              xs={6}
              sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
            >
              <Involvements ticketData={ticketData} />
            </Grid>

            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                border: "0px solid red",
              }}
            >
              <FlatCard title="Status" sx={{ flexGrow: 1 }}>
                <RadioGroup
                  sx={{ display: "flex", padding: "0px", margin: "0px" }}
                  aria-label="status"
                  name="status"
                  value={ticketData?.ticketStatus || "New"}
                  onChange={(e) =>
                    handleChange({ target: { name: "ticketStatus", value: e.target.value } })
                  }
                >
                  <FormControlLabel
                    value="New"
                    control={<Radio sx={{ padding: "2px" }} />}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>New</Typography>}
                  />
                  <FormControlLabel
                    value="In Progress"
                    control={<Radio sx={{ padding: "2px" }} />}
                    sx={{ fontSize: "0.5rem" }}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>In Progress</Typography>}
                  />
                  <FormControlLabel
                    value="Pending"
                    control={<Radio sx={{ padding: "2px" }} />}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>Pending</Typography>}
                  />
                  <FormControlLabel
                    value="Closed"
                    control={<Radio sx={{ padding: "2px" }} />}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>Closed</Typography>}
                  />
                </RadioGroup>
              </FlatCard>
            </Grid>

            <Grid item xs={3} sx={{ border: "0px solid red", flexDirection: "column" }}>
              <FlatCard title="Priorität" sx={{ flexGrow: 1 }}>
                <RadioGroup
                  sx={{ display: "flex", padding: "0px", margin: "0px" }}
                  aria-label="priority"
                  name="priority"
                  value={ticketData?.priority || "Low"}
                  onChange={(e) =>
                    handleChange({ target: { name: "priority", value: e.target.value } })
                  }
                >
                  <FormControlLabel
                    value="High"
                    control={<Radio sx={{ padding: "2px" }} />}
                    sx={{ fontSize: "12px" }}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>High</Typography>}
                  />
                  <FormControlLabel
                    value="Medium"
                    control={<Radio sx={{ padding: "2px" }} />}
                    sx={{ fontSize: "12px" }}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>Medium</Typography>}
                  />
                  <FormControlLabel
                    value="Low"
                    control={<Radio sx={{ padding: "2px" }} />}
                    sx={{ fontSize: "12px" }}
                    label={<Typography sx={{ fontSize: "0.75rem" }}>Low</Typography>}
                  />
                </RadioGroup>
              </FlatCard>
            </Grid>

            <Grid item xs={12} sx={{ border: "0px solid red" }}>
              <TextField
                fullWidth
                label="Beschreibung"
                name="description"
                value={ticketData.description}
                onChange={handleChange}
                variant="outlined"
                multiline
                rows={1}
              />
            </Grid>

            <Grid item xs={12} height="300px" sx={{ border: "0px solid red" }}>
              <Paper
                variant="outlined"
                sx={{ backgroundColor: mobiliTheme.lowLevelContainerBackground }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // backgroundColor: "#b2dbef",
                    // flexGrow: 1,
                    height: "40px",
                    borderBottom: "2px solid #ccc",
                  }}
                >
                  <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                  >
                    <Tab iconPosition="start" label="Alle" value={0} sx={commonTabStyle} />
                    <Tab iconPosition="start" label="Kommentare" value={1} sx={commonTabStyle} />
                    <Tab iconPosition="start" label="Aktivität" value={2} sx={commonTabStyle} />
                  </Tabs>
                </Box>
                {unsavedChangesWarning && (
                  <WarningDialog
                    open={true} // Ensure the warning dialog is open
                    onClose={handleCloseUnsavedChangesWarning} // Function to close the dialog
                    onContinue={handleUnsavedChangesWarning} // Function to continue despite unsaved changes
                    title="Änderungen speichern?"
                    content="Du hast einige Änderungen gemacht. Möchtest du diese speichern, bevor du schließt?"
                    isConfirmSave
        
                  />
                )}
                <>
                  {/* <Box p={2}> */}
                  {tabValue === 0 && (
                    <AllActivityTab selectedTicket={ticketData} setTicketData={setTicketData} />
                  )}
                  {tabValue === 1 && (
                    <CommentsTab
                      selectedTicket={ticketData}
                      setTicketData={setTicketData}
                      user={user}
                      ticketId={ticketData.id}
                    />
                  )}
                  {tabValue === 2 && (
                    <HistoryTab selectedTicket={ticketData} setTicketData={setTicketData} />
                  )}
                  {/* </Box> */}
                </>
              </Paper>
            </Grid>
          </Grid>
        )}
        {isTicketLoading && (
          <Grid container>
            <CircularProgress />
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseTicketFormDialog} variant="text">
          Schließen
        </Button>
        <Button disabled={loading} onClick={(e: any) => handleSubmit(e)}>
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const AllActivityTab: React.FC<{ selectedTicket: any; setTicketData: any }> = ({
  selectedTicket,
  setTicketData: any,
}) => {
  console.log("selectedTicketaddsdffsffsf", selectedTicket);
  return (
    <Grid minHeight="250px" maxHeight="250px" sx={{ overflowY: "auto", overflowX: "hidden" }}>
      {selectedTicket.id && (
        <Timeline>
          {selectedTicket &&
            selectedTicket.activities &&
            selectedTicket.activities?.map((activity: any, index: any) => (
              <React.Fragment key={index}>
                {activity.text &&
                  activity.text?.map((textItem: any, textIndex: any) => (
                    <TimelineEvent
                      key={`${index}-${textIndex}`}
                      title={`${textItem.updatedByUser?.firstName} ${
                        textItem.updatedByUser?.lastName
                      } ${
                        textItem.action === "Created"
                          ? `${textItem.action} ${textItem.current}`
                          : `${textItem.action} ${textItem.field}`
                      }`}
                      createdAt={
                        <div style={{ textAlign: "right" }}>
                          {format(new Date(textItem.createdAt), "dd.MM.yyyy HH:mm:ss")}
                        </div>
                      }
                      icon={
                        textItem.updatedBy && (
                          <Avatar sx={{ width: 32, height: 32, fontSize: "0.8rem" }}>
                            {textItem.updatedByUser.nickName}
                          </Avatar>
                        )
                      }
                    >
                      {textItem.action !== "Created" && (
                        <Typography variant="body2">
                          {`${textItem.previous ? `${textItem.previous} -` : ""} ${
                            textItem.current
                          }`}
                        </Typography>
                      )}
                    </TimelineEvent>
                  ))}
              </React.Fragment>
            ))}
        </Timeline>
      )}
    </Grid>
  );
};
const CommentsTab: React.FC<{
  selectedTicket: any;
  setTicketData?: any;
  user: any;
  ticketId: any;
}> = ({ selectedTicket, user, ticketId, setTicketData }) => {
  const TicketsStore = useStore(TicketsStoreContext);
  const { postorUpdateComments, deleteComment, lastTicketsUpdate } = TicketsStore;
  const [activityData, setActivityData] = useState<any>();
  const [newCommentText, setNewCommentText] = useState("");
  const [editableCommentId, setEditableCommentId] = useState<number | null>(null);
  const [editedCommentText, setEditedCommentText] = useState<string>("");
  const [comments, setComments] = useState<any>(selectedTicket);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiSelect = (emoji: any) => {
    if (emoji && emoji.emoji) {
      setNewCommentText((prevText) => prevText + emoji.emoji);
    }
    setShowEmojiPicker(false);
  };

  // Function to handle submitting a new comment
  const handleSubmit = async (data: any) => {
    try {
      const newComment: any = {
        ticketId: ticketId,
        type: "COMMENTS",
        text: [
          {
            field: "comments",
            action: "Created",
            current: newCommentText,
            updatedBy: user.id,
          },
        ],
      };
      let response;
      // Call the API to post the new comment
      response = await postorUpdateComments(ticketId, newComment);
      if (response) {
        // Update the UI by adding the new comment to the list of comments
        setTicketData(response);
        setComments(response);

        // Clear the new comment text
        setNewCommentText("");
      }
      // Call the API to post or update the comment
      // await postorUpdateComments(data);
      // Optionally, you can fetch the updated data and update the UI
      // Example: Fetch the updated ticket data
      // const updatedTicket = await fetchUpdatedTicket(selectedTicket.id);
      // Update the UI with the updated data
      // updateTicketData(updatedTicket);
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  // Function to handle editing a comment
  const handleEdit = (comment: any) => {
    console.log("comment", comment);
    setEditableCommentId(comment.id);
    setEditedCommentText(comment.text[0].current);
    // You can implement further logic for editing the comment here
  };

  // Function to handle canceling edit mode
  const handleCancelEdit = () => {
    setEditableCommentId(null);
    setEditedCommentText("");
  };

  // Function to handle saving the edited comment
  const handleSaveEdit = async (commentData: any, updatedCommentText: string) => {
    try {
      const updatedComment: any = {
        id: commentData.id,
        ticketId: ticketId,
        type: "COMMENTS",
        text: [
          {
            id: commentData.text[0].id,
            field: "comments",
            action: "Updated",
            current: updatedCommentText,
            updatedBy: user.id,
          },
        ],
      };

      // Call the API to update the comment
      const response = await postorUpdateComments(ticketId, updatedComment);
      if (response) {
        // Update the UI by replacing the edited comment in the list of comments
        setComments(response);
        setTicketData(response);

        // Clear the edited comment text
        setEditableCommentId(null);
        setEditedCommentText("");
      }
    } catch (error) {
      console.error("Error updating comment:", error);
    }
  };

  const handleDelete = async (commentId: any) => {
    try {
      console.log("commentId", commentId);
      let response;
      response = await deleteComment(commentId);
      if (response) {
        setComments(response);
        setTicketData(response);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          display: "grid",
          width: "100%",
          // maxHeight: "60%",
          minHeight: "180px",
          overflowY: "auto", // Add overflow-y for messages only
          maxHeight: "180px", // Set max height for the container
        }}
      >
        {/* Display existing comments */}
        {comments?.activities
          ?.filter((activity: any) => activity.type === "COMMENTS")
          .map((comment: any, index: any) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: index % 2 !== 0 ? "row" : "row-reverse", // Reverse flex direction based on index
                padding: "3px",
                minWidth: "50%",
                minHeight: "50%",
                alignItems: "flex-start",
                marginLeft: index % 2 !== 0 ? "auto" : "none", // Margin to the left or auto based on index
                marginRight: index % 2 !== 0 ? "none" : "auto", // Margin to the right or auto based on index
              }}
            >
              <Paper
                key={comment.id}
                elevation={3}
                sx={{
                  padding: "10px",
                  position: "relative",
                  background: "white",
                  marginBottom: "2px",
                  width: "80%",
                  display: "flex",
                  alignItems: "flex-start",
                  borderRadius: "10px",
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                  marginLeft: index % 2 !== 0 ? "auto" : "none", // Position to left or none based on index
                  marginRight: index % 2 !== 0 ? "flex-end" : "none", // Position to right or none based on index
                }}
              >
                {editableCommentId === comment.id ? (
                  <TextArea
                    value={editedCommentText}
                    onChange={(e: any) => setEditedCommentText(e.target.value)}
                    sx={{ wordWrap: "break-word", flexDirection: "column" }}
                    placeholder="Edit your comment here..."
                  />
                ) : (
                  <div
                    style={{
                      width: "70%",
                      wordWrap: "break-word",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ fontSize: "14px", fontFamily: "sans-serif" }}>
                      {comment.text[0].current}
                    </div>
                    <div style={{ fontSize: "12px", color: "#888", paddingTop: "10px" }}>
                      {format(new Date(comment.createdAt), "dd.MM.yyyy")}{" "}
                      {format(new Date(comment.createdAt), "HH:mm:ss")}
                    </div>
                  </div>
                )}
                {/* Edit and delete buttons */}
                {editableCommentId === comment.id ? (
                  <div style={{ paddingTop: "10px" }}>
                    <IconButton onClick={() => handleSaveEdit(comment, editedCommentText)}>
                      <Save sx={{ width: "15px", height: "15px" }} />
                    </IconButton>
                    <IconButton onClick={handleCancelEdit}>
                      <Cancel sx={{ width: "15px", height: "15px" }} />
                    </IconButton>
                  </div>
                ) : (
                  <div style={{ paddingTop: "20px", paddingRight: "2px" }}>
                    <IconButton onClick={() => handleEdit(comment)}>
                      <Edit sx={{ width: "15px", height: "15px" }} />
                    </IconButton>
                    {editableCommentId !== comment.id && (
                      <IconButton onClick={() => handleDelete(comment.id)}>
                        <Delete sx={{ width: "15px", height: "15px" }} />
                      </IconButton>
                    )}
                  </div>
                )}
                {/* </Box>
              </Box>
            </Box> */}
              </Paper>
              <Box>
                <Avatar sx={{ width: 32, height: 32, fontSize: "0.8rem", gap: "2px" }}>
                  {comment.text[0].updatedByUser.nickName ||
                    `${comment.text[0].updatedByUser.firstName?.charAt(0) || ""}${
                      comment.text[0].updatedByUser.lastName?.charAt(0) || ""
                    }`}
                </Avatar>
              </Box>
            </Box>
          ))}

        {/* Section for typing a new comment */}
      </Box>

      <Box
        className="new-comment"
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          gap: "10px",
        }}
      >
        <Box
          className="new-comment"
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr auto",
            gap: "10px",
            // width: "440px",
          }}
        >
          <Box className="comment-avatar">
            <Avatar sx={{ width: 32, height: 32, fontSize: "0.8rem" }}>
              {user.nickName ||
                `${user.firstName?.charAt(0) || ""}${user.lastName?.charAt(0) || ""}`}
            </Avatar>
          </Box>

          {/* <div style={{ maxHeight: '200px',minHeight:"100px", overflowY: 'scroll',position:"relative" }}> */}
          <div
            style={{ maxHeight: "50px", overflowY: "auto", width: "390px", overflowX: "hidden" }}
          >
            <TextArea
              value={newCommentText}
              onChange={(e: any) => setNewCommentText(e.target.value)}
              placeholder="Type your comment here..."
              rows={2}
            />
          </div>
        </Box>
        {/* </div> */}
        <Box sx={{ position: "relative", display: "flex", flexDirection: "column", width: "100%" }}>
          <Send
            onClick={handleSubmit}
            sx={{
              position: "absolute",
              top: "6px",
              right: "8px",
              height: "30px",
              width: "30px",
              cursor: "pointer",
            }}
          />
          <Button
            style={{
              position: "absolute",
              top: "6px",
              right: "40px", // Adjust the right position as needed
              padding: "4px",
              fontSize: "10px",
            }}
            onClick={() => setShowEmojiPicker(!showEmojiPicker)}
          >
            😊
          </Button>
        </Box>
      </Box>

      {/* Emoji Picker */}
      {showEmojiPicker && (
        <Picker
          style={{
            position: "absolute",
            top: "400px", // Adjust top position to place it below the textarea
            right: "8px",
            zIndex: 9999, // Set a high z-index to ensure it appears on top
            width: "300px",
            height: "300px",
            overflow: "scroll",
          }}
          onEmojiClick={handleEmojiSelect}
        />
      )}
    </>
  );
};

const HistoryTab: React.FC<{ selectedTicket: any; setTicketData: any }> = ({
  selectedTicket,
  setTicketData,
}) => {
  return (
    <Grid minHeight="250px" maxHeight="250px" sx={{ overflowY: "auto", overflowX: "hidden" }}>
      {selectedTicket.id && (
        <Timeline>
          {selectedTicket &&
            selectedTicket.activities &&
            selectedTicket.activities.map((activity: any, index: any) => (
              <React.Fragment key={index}>
                {activity.text &&
                  activity.type !== "COMMENTS" &&
                  activity.text.map((textItem: any, textIndex: any) => (
                    <TimelineEvent
                      key={`${index}-${textIndex}`}
                      title={`${textItem.updatedByUser.firstName} ${
                        textItem.updatedByUser.lastName
                      } ${
                        textItem.action === "Created"
                          ? `${textItem.action} ${textItem.current}`
                          : `${textItem.action} ${textItem.field}`
                      }`}
                      createdAt={
                        <div style={{ textAlign: "right" }}>
                          {format(new Date(textItem.createdAt), "dd.MM.yyyy HH:mm:ss")}
                        </div>
                      }
                      icon={
                        textItem.updatedBy && (
                          <Avatar sx={{ width: 32, height: 32, fontSize: "0.8rem" }}>
                            {textItem.updatedByUser.nickName ||
                              `${textItem.updatedByUser.firstName?.charAt(0) || ""}${
                                textItem.updatedByUser.lastName?.charAt(0) || ""
                              }`}
                          </Avatar>
                        )
                      }
                    >
                      {textItem.action !== "Created" && (
                        <Typography variant="body2" gutterBottom>
                          {`${textItem.previous} - ${textItem.current}`}
                        </Typography>
                      )}
                    </TimelineEvent>
                  ))}
              </React.Fragment>
            ))}
        </Timeline>
      )}
    </Grid>
  );
};

export default TicketForm;
