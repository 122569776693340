import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { Configuration, NoteApi } from '../../api-client';
import { AuthContext } from '../Auth/auth.provider';
import useStore from '../../helpers/useStore';
import NotesStore from './notesStore';
import { Note } from '@mui/icons-material';
import { PatientsContext } from '../Patients/patients.provider';



interface NotesProviderProps {
  children: ReactNode;
}

export const NotesContext = createContext<NotesStore | null>(null);
NotesContext.displayName = 'NotesContext';

export const NotesProvider = ({ children }: NotesProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;
const patientStore=useStore(PatientsContext)


const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});

// console.log('authiii',authToken,config)
  const apiClient = new NoteApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new NotesStore(apiClient,AuthenticationStore,patientStore)
  );

  store.apiClient = apiClient;

  return (
    <NotesContext.Provider value={store}>
      {children}
    </NotesContext.Provider>
  );
};