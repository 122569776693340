import {
  action,
  computed,
  makeAutoObservable,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { MasterlistsApi } from "../../api-client";
import { observer } from "mobx-react";
import notificationStore from "../Notification/notificationStore";

export default class MasterTablesStore {
  public apiClient: MasterlistsApi;

  @observable
  public resourceRequest: Promise<any[]> | null = null;

  public constructor(apiClient: MasterlistsApi) {
    makeObservable(this);
    this.apiClient = apiClient;
    // this.initialize(); // Call the initialization method
    console.log("MasterTablesStore NEW OBJECT CREATION");
  }
  @observable
  public masterTablesData: any[] = [];

  @action
  public setMasterTables = (options: any) => {
    this.masterTablesData = options;
  };

  @action
  initialize = async() => {
    await this.fetchMasterList();
    console.log("MasterTablesStore initialized", this.masterTablesData);
    // Any additional initialization logic can go here
  }

  @action
  public fetchMasterList = async (): Promise<any[]> => {
    this.resourceRequest = null;
    try {
      const response = await this.apiClient.getAllMasterTables();
      const data = response.data as unknown as any[];
      // this.masterTablesData = data;
      this.setMasterTables(data);
      console.log("masterTablesData in store", this.masterTablesData);
      return data; // Return the data
    } catch (error) {
      console.error("Error fetching master tables:", error);
      throw error; // Rethrow the error to handle it in the component if needed
    }
  };
  @action
  public getICD = async (text: string): Promise<any[]> => {
    try {
      const response = await this.apiClient.getICDCCode(text);
      const data = response.data as unknown as any[];
      return data || [];
    } catch (error: any) {
      console.error("Error fetching icd data:", error);
      const errorMessage = error.response?.data?.message || "ICD-Code für normCode nicht gefunden";

      notificationStore.showMessage(errorMessage, "error", error);
      throw error; // Rethrow the error to handle it in the component if needed
    }
  };

  getTherapyId = (tarifGroup: string, abbreviation: string) => {
    if (!abbreviation || abbreviation =="")  throw new Error("Abbreviation is required to get the therapy id.");

    if (!this.masterTablesData || this.masterTablesData.length === 0) {
      console.warn("Master tables data is not available when tryign to get the therapy id.");
      return null;
    }
    const MASTER_TABLES_THERAPY_INDEX = 3;

    const therapies = this.masterTablesData[MASTER_TABLES_THERAPY_INDEX];
    const therapy = therapies.therapy.find(
      (item: any) =>
        item.tarifGroup.id === tarifGroup &&
        item.abbreviation.toLowerCase() === abbreviation.toLowerCase()
    );

    return therapy?.id;
  };

  getTarifGroupIds = () => {
    if (!this.masterTablesData || this.masterTablesData.length === 0) {
      console.warn("Master tables data is not available when tryign to get the therapy id.");
      return null;
    }
    const MASTER_TABLES_TARIFGROUP_INDEX = 10;

    const groups = this.masterTablesData[MASTER_TABLES_TARIFGROUP_INDEX].tarifGroup;
    return groups.map((item: any) => item.id);
  };

  getTherapyPrice = (tarifGroup: string, abbreviation: string) => {
    if (!abbreviation || abbreviation =="")  throw new Error("Abbreviation is required to get the therapy id.");

    if (!this.masterTablesData || this.masterTablesData.length === 0) {
      console.warn("Master tables data is not available when tryign to get the therapy id.");
      return null;
    }
    const MASTER_TABLES_THERAPY_INDEX = 3;

    const therapies = this.masterTablesData[MASTER_TABLES_THERAPY_INDEX];
    const therapy = therapies.therapy.find(
      (item: any) =>
        item.tarifGroup.id === tarifGroup &&
        item.abbreviation.toLowerCase() === abbreviation.toLowerCase()
    );

    return therapy?.price;
  }
}

// @computed
// public get insuranceStatus(): any[] {
//   if (this.masterTablesData.length === 0) {
//     // If masterTablesData is empty, return an empty array or handle it as per your requirements
//     return [];
//   }

//   const insuranceStatus = this.masterTablesData;
//   console.log('INSURERR', insuranceStatus, this.masterTablesData);
//   return insuranceStatus;
// }
