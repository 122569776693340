import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { Configuration, DoctorApi, PatientsApi } from '../../api-client';
import { AuthContext } from '../Auth/auth.provider';
import useStore from '../../helpers/useStore';
import DoctorStore from './doctorStore';
import { D } from '@fullcalendar/resource/internal-common';


interface DoctorProviderProps {
  children: ReactNode;
}

export const DoctorContext = createContext<DoctorStore | null>(null);
DoctorContext.displayName = 'DoctorContext';

export const DoctorProvider = ({ children }: DoctorProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;


const config = new Configuration({
  basePath: basePath,  
  accessToken: authToken || "",
});

console.log('auth<Doctoriii',authToken,config)
  const apiClient = new DoctorApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new DoctorStore(apiClient,AuthenticationStore)
  );

  store.apiClient = apiClient;

  return (
    <DoctorContext.Provider value={store}>
      {children}
    </DoctorContext.Provider>
  );
};