import React from 'react'
import {Button as MuiButton} from '@mui/material/';


// const useStyles = makeStyles(theme => ({
//     root: {
//         margin: theme.spacing(0.5)
//     },
//     label: {
//         textTransform: 'none'
//     }
// }))

const Button=(props:any)=> {
    return (
        <MuiButton {...props} />
    )
}

// const Button=(props:any)=> {

//     const { text, size, color, variant,required, onClick, ...other } = props
//     const classes = useStyles();

//     return (
//         <MuiButton
//             variant={variant || "contained"}
//             size={size || "large"}
//             required={required}
//             color={color || "primary"}
//             onClick={onClick}
//             {...other}
//             classes={{ root: classes.root, label: classes.label }}
//             >
//             {text}
//         </MuiButton>
//     )
// }
export default Button;