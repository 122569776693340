import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TimeSlotExt } from "../../molecules/Calendar/Calendar.type";
import PatientControls from "../pages/Patient/PatientControls";
import { PatientsProvider } from "../../stores/Patients/patients.provider";
import React, { useEffect, useState } from "react";
import { RxDto } from "../../api-client";
import { CalendarContext } from "../../stores/Calendar/calendar.provider";
import useStore from "../../helpers/useStore";
import { RssFeed } from "@mui/icons-material";
import ContinuousScheduler from "./ContinuousScheduler";

// This component will need to fetch:
// * Patient information
// * Rx information
// * Appointment information
interface ContinuousUserCalendarDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  userId: string;
}

export const ContinuousUserCalendarDialog: React.FC<ContinuousUserCalendarDialogProps> = ({ open, setOpen, userId }) => {
  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      fullScreen
    >
      <IconButton
        edge="start"
        color="inherit"
        onClick={() => setOpen(false)}
        aria-label="close"
        sx={{ position: "absolute", top: 0, right: 0, m: 2 }}
      >
        <CloseIcon />
      </IconButton>
      <ContinuousScheduler userId={userId}/>
    </Dialog>
  );
};

