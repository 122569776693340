import { Divider, List, ListItem, Typography } from '@mui/material';
import React from 'react';


const Json = ({ obj }:{obj:any}) => {
  if (Array.isArray(obj)) {
    return (
      <ul>
        {obj.map((item, index) => (
          <li key={index}>
            <Json obj={item} />
          </li>
        ))}
      </ul>
    );
  } else if (typeof obj === 'object' && obj !== null) {
    return (
      <div>
        {Object.keys(obj).map((key) => (
          <div key={key}>
            <strong>{key}:</strong> <Json obj={obj[key]} />
          </div>
        ))}
      </div>
    );
  } else {
    return <span>{obj.toString()}</span>;
  }
};

export const JsonMui = ({ obj }:{obj:any}) => {
  if (Array.isArray(obj)) {
    return (
      <List>
        {obj.map((item, index) => (
          <ListItem key={index}>
            <JsonMui obj={item} />
          </ListItem>
        ))}
      </List>
    );
  } else if (typeof obj === 'object' && obj !== null) {
    return (
      <>
        {Object.keys(obj).map((key, index) => (
          <React.Fragment key={key}>
            <Typography component="span">
              <strong>{key}:</strong>
            </Typography>
            {' '}
            <JsonMui obj={obj[key]} />
            {index < Object.keys(obj).length - 1 && <Divider />}
          </React.Fragment>
        ))}
      </>
    );
  } else {
    return <Typography>{obj.toString()}</Typography>;
  }
};
  