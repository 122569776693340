import { useLocalStore } from "mobx-react";
import React, { createContext, ReactNode } from "react";
import PatientsStore from "./patientsStore";
import { Configuration, PatientsApi } from "../../api-client";
import { AuthContext } from "../Auth/auth.provider";
import useStore from "../../helpers/useStore";

interface PatientsProviderProps {
  children: ReactNode;
}

export const PatientsContext = createContext<PatientsStore | null>(null);
PatientsContext.displayName = "PatientsContext";

export const PatientsProvider = ({ children }: PatientsProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const AuthenticationStore = useStore(AuthContext);
  const { authToken } = AuthenticationStore;

  const config = new Configuration({
    basePath: basePath,
    accessToken: authToken || "",
  });

  console.log("authiii", authToken, config);
  const apiClient = new PatientsApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(() => new PatientsStore(apiClient, AuthenticationStore));

  store.apiClient = apiClient;

  return <PatientsContext.Provider value={store}>{children}</PatientsContext.Provider>;
};
