import React, { useEffect } from "react";
import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CardHeader,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import Controls from "../../../atoms/Controls";
import { Edit, MoreVert } from "@mui/icons-material";
import RxPage from "../../RxPage";
import { mobiliTheme } from "../../../themes/mobiliTheme";
import moment from "moment";
import { observer } from "mobx-react";
import FlatCard from "../../../atoms/FlatCard";
import { FlexListContainer, LabelItem, ValueItem } from "../../../molecules/FlexList";

interface RowDetailsProps {
  rowData: any;
  editRxMode?: boolean;
  setEditRxMode?: any;
  patientValues?: any;
  masterData?: any;
  isEditing?: boolean;
  setPatientData?: any;
  handleData?: any;
  handleMoreButtonClick: (event: React.MouseEvent<HTMLButtonElement>, rx: any) => void;
}
// const cellStyle = { minWidth: "50px", width: "20%" }; // Style for TableCell
const chipStyle = { width: "280px" }; // Style for Chip

const RxRowOverview: React.FC<RowDetailsProps> = observer(
  ({
    rowData,
    editRxMode,
    setEditRxMode,
    patientValues,
    masterData,
    isEditing,
    setPatientData,
    handleData,
    handleMoreButtonClick,
  }) => {
    const renderBooleanProperty = (label: string, value: boolean) => {
      if (value === true) {
        return (
          <TableRow>
            <TableCell sx={{ fontWeight: "bold" }}>{label}</TableCell>
            {/* <TableCell>{value.toString()}</TableCell> */}
          </TableRow>
        );
      }
      return null;
    };
    return (
      <Card sx={{ borderRadius: "10px" }}>
        <CardHeader
          title={`Rezept- ${rowData.rxNumber}`}
          sx={{ height: "40px" }}
          action={
            <>
              <IconButton onClick={() => setEditRxMode(!editRxMode)}>
                <Edit />
              </IconButton>
              <IconButton onClick={(event) => handleMoreButtonClick(event, rowData)}>
                <MoreVert />
              </IconButton>
            </>
          }
        />

        <CardContent>
          {!rowData?.rxtype?.id || rowData?.rxtype?.id !== "Kasse" ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} sx={{ border: "0px solid green" }}>
                <FlexListContainer>
                  <LabelItem label="Rx Datum" />
                  <ValueItem
                    value={
                      rowData.date
                        ? moment(rowData.date, "YYYY-MM-DD", true).isValid()
                          ? moment(rowData.date).format("DD.MM.YYYY")
                          : rowData.date
                        : "-"
                    }
                  />
                  <LabelItem label="Eingegeben am" />
                  <ValueItem value={rowData.creationDate || "-"} />
                  <LabelItem label="Behandlungsort" />
                  <ValueItem value={rowData?.visitType || "-"} />
                  <LabelItem label="Mandant" />
                  <ValueItem value={rowData.account?.id || "-"} />
                  <LabelItem label="Rezeptverbleib" />
                  <ValueItem value={rowData?.status || "-"} />

                  <LabelItem label="Kostenträger" />
                  <ValueItem value={rowData?.payer?.insurer || "-"} />
                  <LabelItem label="IK Nummer" />
                  <ValueItem value={rowData?.payer?.ik || "-"} />
                  <LabelItem label="Tarif Group" />
                  <ValueItem value={rowData?.tarifGroupId || "-"} />
                  <LabelItem label="Versichertennummer" />
                  <ValueItem value={rowData.insureeNumber || patientValues.insuranceNo} />
                  <LabelItem label="Status" />
                  <ValueItem value={rowData?.insuranceStatus || patientValues.status} />
                  <LabelItem label="Arzt" />
                  <ValueItem
                    value={
                      `${rowData?.doctor?.contactInfo?.firstName || " "} ${
                        rowData?.doctor?.contactInfo?.lastName || " "
                      }` || "-"
                    }
                  />
                  <LabelItem label="Rezept-Typ" />
                  <ValueItem value={rowData.rxtype?.id || "-"} />
                  <LabelItem label="LANR" />
                  <ValueItem value={rowData?.doctorNumber || "-"} />
                  <LabelItem label="BSNR" />
                  <ValueItem value={rowData?.practiceNumber || "-"} />
                  <LabelItem label="TextDiagnose" />
                  <ValueItem value={rowData?.diagnosisText || "-"} />
                  <LabelItem label="ICDCodes" />
                  <ValueItem
                    value={
                      (rowData || [])?.icdCodeEntries?.map((icd: any) => icd.userText).join(", ") ||
                      "-"
                    }
                  />
                  <LabelItem label="DiagnoseGruppe" />
                  <ValueItem value={rowData?.diagnosisGroup?.id || "-"} />

                  <LabelItem label="Optionen" />
                  <ValueItem
                    value={[
                      rowData?.isPerpetualApproved ? "Langfristgenehmigung" : "",
                      rowData?.isCopaymentExempted ? "Zuzahlungsfrei" : "",
                      rowData?.isAccident ? "Unfallfolgen" : "",
                      rowData?.isBVG ? "BVG" : "",
                      rowData?.problematic ? "auf Kontrolle" : "",
                      rowData?.changeRequested ? "auf zum Ändern" : "",
                      rowData?.therapyReport ? "Therapiebericht" : "",
                      rowData?.urgent ? "Dringlicher Behandlungsbedarf" : "",
                      rowData?.hygiene ? "Hygieneposition" : "",
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  />
                </FlexListContainer>
              </Grid>
              <Grid item xs={12} md={6}>
                <FlatCard title="Behandlungen">
                  <Table>
                    <TableRow>
                      <TableCell>Heilm.</TableCell>
                      <TableCell>Menge</TableCell>
                      <TableCell>Frequency</TableCell>
                    </TableRow>
                    {rowData.therapyRxs?.map((therapyRx: any, index: number) => (
                      <TableRow key={index}>
                        <TableCell>{therapyRx?.therapy?.abbreviation}</TableCell>
                        <TableCell>{therapyRx?.amount}</TableCell>
                        <TableCell>{therapyRx?.frequency?.text}</TableCell>
                      </TableRow>
                    ))}
                  </Table>
                </FlatCard>
              </Grid>
            </Grid>
          ) : (
            <RxPage
              isEditing={true}
              selectedRow={rowData}
              patientValues={patientValues}
              masterData={masterData}
              handleData={handleData}
              setPatientData={(row: any) => setPatientData(row)}
              readOnlyView={true}
            />
          )}
        </CardContent>
      </Card>
    );
  }
);

export default RxRowOverview;
