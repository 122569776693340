import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Header from "./Header";
import useStore from "../../../helpers/useStore";
import { AppointmentContext } from "../../../stores/Appointment/appointment.provider";
import Footer from "./Footer";
import Body from "./Body";
import { AppointmentDto } from "../../../api-client";
import { format, add } from 'date-fns';
import { transactionMethods } from "../TransactionForm";
import { formatDateUI } from "../../../helpers/dateFormat";

interface DialogBoxAppointmentProps {
  openDialog: boolean;
  handleOnClose: () => void;
  selectedRow: any;
  totalPrice: number;
  transactionType: transactionMethods
  tarifRx: string | undefined | null;
  isCoPaymentExempted: boolean | undefined | null;
  transactionNumber: string | undefined | null;
}


const Rechnung: FC<DialogBoxAppointmentProps> = ({openDialog, handleOnClose, selectedRow, totalPrice, transactionType, tarifRx, isCoPaymentExempted, transactionNumber}) => {


    const AppointmentStore = useStore(AppointmentContext);
    const { getAppointmentsForPatientWithRx} = AppointmentStore;
    const dialogRef = useRef<any>(null);
    const [selectedValue, setSelectedValue] = useState('No Office');
    const [patientDetails, setPatientDetails] = useState({
      firstName: '',
      lastName: '',
      salutation: 'Frau',
      address: {},
    });
    const [patientAppointments, setPatientAppointments] = useState<AppointmentDto[]>([]);

    const handlePrint = useReactToPrint({
        content: () => dialogRef.current,
        pageStyle: `
        @media print {
            @page {
                size: 210mm 297mm;
              }
        }
        `,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const appointmentData = await getAppointmentsForPatientWithRx(selectedRow.rxNumber);
                setPatientAppointments(appointmentData);
                const {firstName, lastName, salutation, addresses} = appointmentData[0].timeSlots[0].therapyRx.rx.patient;
                const preferredAddress = addresses.find((addr: { isAlternativeBilling: any; }) => addr.isAlternativeBilling) || addresses.find((addr: { isPrimary: any; }) => addr.isPrimary);
                setPatientDetails({
                    firstName,
                    lastName,
                    salutation,
                    address: preferredAddress
                })
                console.log("HERE IS THE APPOINTMENT DATA FROM USEEFFECT IN TRANSACTION", appointmentData);
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    },[getAppointmentsForPatientWithRx, selectedRow.rxNumber])


  const dueDate = format(add(new Date(), { days: 14 }), 'dd.MM.yyyy');
  const patientName = patientDetails.firstName + " " + patientDetails.lastName;
  const salutation = patientDetails.salutation;
  const doctorName = selectedRow.doctor?.contactInfo?.firstNames + " " + selectedRow.doctor?.contactInfo?.lastName;
  const creationDate = formatDateUI(selectedRow.date);
  return (
    <div>
      <Dialog open={openDialog} onClose={handleOnClose}>

        <DialogActions>
              <Grid container justifyContent={'space-between'}>
              <Button onClick={handleOnClose}>Close</Button>
              <select value={selectedValue} onChange={(e) => setSelectedValue(e.target.value)}>
                <option value="No Office">No Office</option>
                <option value="Physiotherapie Mobili">Mobili</option>
                <option value="Physiotherapie Riemke">Riemke</option>
              </select>
              <Button variant="contained" onClick={handlePrint}>Drucken</Button>
              </Grid>
        </DialogActions>

        <DialogContent ref={dialogRef}>
          <Header header={selectedValue} patientDetails={patientDetails}/>

          {transactionType === transactionMethods.RECHNUNG && (
            <Typography variant="h6" sx={{fontWeight: "bold", color: "black", marginBottom: '40px'}}>
            Rechnung Nr. {transactionNumber}
            </Typography>
          )}
          {
            transactionType !== transactionMethods.RECHNUNG && (
              <Typography variant="h6" sx={{fontWeight: "bold", color: "black", marginBottom: '40px' }}>
              Quittung Nr. {transactionNumber}
              </Typography>
            )
          }

          {tarifRx !== 'Privat' && (
            <>
            <p>
            Versicherte einer gesetzlichen Krankenkasse müssen nach §32 SGB V eine Zuzahlung zu den Kosten ihrer Behandlung leisten. <br/> <br/>
            Aufgrund der Verordnung von {doctorName} vom {creationDate} erlauben wir uns Zuzahlung an einer Heilmittelbehandlung für {salutation} {patientName} zu berechnen: <br/>
            </p>
            <br/>

            <Typography variant="body2" sx={{ fontSize: '16px', fontWeight: 'bold', color: 'black' }}>
               Betrag: {totalPrice.toFixed(2).replace('.', ',')} €
            </Typography>
            <Typography variant="body2" sx={{color: "black", fontSize: '13px', marginBottom: '40px' }}>
             (Steuerfreie Leistungen nach §4 Nr. 14 UStG)
          </Typography>

          <p>
            Den Rechnungsbetrag überweisen Sie bitte bis zum {dueDate} auf das unten angegebene Konto.
          </p>
          <p>
            Bei zu viel entrichteter Zuzahlung haben Sie einen Anspruch auf Erstattung. <br/>
            Diese Schreiben wurde maschinell erstellt und benötigt keine Unterschrift.
          </p>
            </>
            
          )}

          {tarifRx === 'Privat' && (
            <Body patientAppointments={patientAppointments} selectedRow={selectedRow} patientDetails={patientDetails} totalPrice = {totalPrice}/>
          )}

          

          {tarifRx === 'Privat' && transactionType === transactionMethods.RECHNUNG && (
            <Typography variant="body2" sx={{color: "black", paddingBottom:"20px" }}>
            Den Rechnungsbetrag überweisen Sie bitte bis zum {dueDate} auf das unten angegebene Konto. Dieses Schriben wurde maschinell
            erstellt und benötigt keine Unterschrift.
          </Typography>
          )}

          {tarifRx === 'Privat' && transactionType !== transactionMethods.RECHNUNG &&(
            <Typography variant="body2" sx={{color: "black", paddingBottom:"20px" }}>
            Den Rechnungsbetrag habe ich heute dankend erhalten.
          </Typography>
          )}
          
          {
            tarifRx === 'Privat' && (
              <Typography variant="body2" sx={{color: "black", fontSize: '12px' }}>
              Steuer-Nr.:
            </Typography>
            )
          }
          

          {selectedValue === 'Physiotherapie Mobili' && (
            <Footer address="Sägemühlenstraße 5 29221 Celle" phone="05141/ 90 90 600" />
          )}

          {selectedValue === 'Physiotherapie Riemke' && (
            <Footer address="Karl-Wiechert-Allee 76, 30625 Hannover" phone="05141/ 90 90 600" />
          )}

        </DialogContent>
        
      </Dialog>
    </div>
  );
}

export default Rechnung;