import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import useStore from "../../helpers/useStore";
import Controls from '../../atoms/Controls';
import { Event } from './Calendar.type';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import moment, * as Moments from 'moment';
import { Moment } from 'moment';
import { CalendarContext } from '../../stores/Calendar/calendar.provider';
import { LeaveOfAbsenceDto } from '../../api-client';
import notificationStore from '../../stores/Notification/notificationStore';
import { extendMoment } from 'moment-range';
const momentRange = extendMoment(Moments);

interface AbsenceDialogProps {
  open: boolean;
  event: Event;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  leaveOfAbsence?: LeaveOfAbsenceDto;
  blockedDays?: Moment[];
  highlightDays?: Moment[];
}

interface AbsenceFormDto {
  userId?: string;
  type: string;
  from: string;
  to: string;
  comment?: string;
}

const AbsenceDialog: React.FC<AbsenceDialogProps> = ({
  open,
  event,
  onClose,
  onConfirm,
  title,
  leaveOfAbsence,
  blockedDays,
  highlightDays
}) => {

  moment.locale('de');

  const initialFormValues: AbsenceFormDto = {
    userId: leaveOfAbsence?.user?.id,
    type: leaveOfAbsence?.loAType || "",
    from: event?.start || "",
    to: event?.end || "",
    comment: "",
  };

  const [formState, setFormState] = useState<AbsenceFormDto>(initialFormValues);

  const defaultStartDate = event?.start || leaveOfAbsence?.start;
  const defaultEndDate = event?.end || leaveOfAbsence?.end;

  const [startDate, setStartDate] = useState<Moment | null>(moment(defaultStartDate));
  const [endDate, setEndDate] = useState<Moment | null>(moment(defaultEndDate));
  const [range, setRange] = useState<Moment[] | null>();
  const formattedStartDate = startDate?.format('DD MMM YYYY');
  const formattedEndDate = endDate?.format('DD MMM YYYY');
  
  const [loading, setLoading] = useState(false);
  const CalendarStore = useStore(CalendarContext);
  const { userAbsence } = CalendarStore;

  const absenceOptions =[
    { value: "vacation", label: "Urlaub" },
    { value: "sickness", label: "Krank" },
    { value: "sickness_child", label: "Kindkrank" },
    { value: "training", label: "Fortbildung" },
    { value: "compensatory_leave", label: "Ü frei" },
    { value: "remaining_vacation", label: "Resturlaub" },
    { value: "special_leave", label: "Sonderurlaub" },
    { value: "parental_leave", label: "Elternzeit" },
    { value: "exempted", label: "Freigestellt" },
    { value: "professional_ban", label: "Berufsverbot" },
  ];

  useEffect(() => {
    setRangeDates();
  }, [startDate, endDate])

  useEffect(() => {
    setStartDate(moment(leaveOfAbsence?.start))
    setEndDate(moment(leaveOfAbsence?.end ? leaveOfAbsence?.end : leaveOfAbsence?.start ))
  }, [leaveOfAbsence?.start, leaveOfAbsence?.end])

  const handleAddAbsenceClick = async () => {    
    try {
      if (!loading) {
        setLoading(true);        
        const userAbsenceDto : LeaveOfAbsenceDto = {
          loAType: formState.type,
          start: startDate?.format(),
          end: endDate?.format(),
          comment: formState.comment
        };
        console.log('userAbsenceDto',userAbsenceDto);
        
        const timeSlot = await userAbsence(leaveOfAbsence?.user?.id!, userAbsenceDto);
        console.log(timeSlot);
        setLoading(false);
        if (timeSlot) {
          notificationStore.showMessage("Abwesenheit erfolgreich hinzugefügt", "success");
          onConfirm();
          onClose();
        }
        return timeSlot;
      }
    }
    catch (error) {
      console.log(error);
      notificationStore.showMessage("Fehler beim Hinzufügen der Abwesenheit", "error");
    }
    finally {
      setLoading(false);
    }
  }

  const handleInputChange = (fieldName: string, value: any) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [fieldName]: value,
    }));
  };

  const handleDatesChange = ({ startDate, endDate }: { startDate: Moment | null, endDate: Moment | null }) => {
    setStartDate(startDate?.set({hour: 0, minute: 0, second: 0, millisecond: 0})!);
    setEndDate(endDate?.set({hour: 0, minute: 0, second: 0, millisecond: 0})!);
    console.log('startDate in range picker', startDate);
    console.log('endDate in range picker', endDate);

    setRangeDates();
    handleInputChange("from", startDate?.format("YYYY-MM-DD"));
    handleInputChange("to", endDate?.format("YYYY-MM-DD"));
  };

  const isOutsideRange = (day: Moment) => {
    // Disable past days
    return day.isBefore(moment(), 'day')
  };

  const setRangeDates = () => {
    if (startDate && endDate) {
      const range = momentRange.range(startDate!, endDate!);
      const dates:any = Array.from(range.by('day'));
      setRange(dates);
    }
  }

  return (
    <Dialog 
      open={open!}
      onClose={onClose}>
      <DialogTitle id="dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} marginBottom={2}>
            <Controls.Select
              placeholder="Bitte auswählen"
              label="Abwesenheitsart"
              name="type"
              value={absenceOptions.find((option) => option.label === formState.type)?.value || initialFormValues.type}
              showOptionLabel
              options={absenceOptions}
              onChange={(e: any) =>
                handleInputChange("type", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Controls.DateRangePicker
              startDate={startDate}
              endDate={endDate}
              handleDatesChange={handleDatesChange}
              isOutsideRange={isOutsideRange}
              displayFormat="DD/MM/YYYY"
              blockedDays={blockedDays!}
              highlightDays={highlightDays!}
              block
            />
          </Grid>  
          <Grid item xs={12}>
            <Controls.TextArea
              label="Kommentar"
              name="comment"
              rows={5}
              onChange={(e: any) =>
                handleInputChange("comment", e.target.value)
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Sollten alle Termine {(range?.length! > 1) ? "von" : "am"} {formattedStartDate} {(range?.length! > 1) && (`bis ${formattedEndDate} (${range!.length} Tage)`)} storniert werden?
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Abbrechen
        </Button>
        <Button onClick={handleAddAbsenceClick} disabled={loading} >
          {loading ? <CircularProgress size={24} /> : "Bestätigen"}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AbsenceDialog