import { margin } from "@mui/system";
import { mobiliTheme } from "../../themes/mobiliTheme";

export const commonTabStyle = {
  "&.Mui-selected": {
    backgroundColor: "transparent",
    color: mobiliTheme.palette.primary.dark,
    // border: `1px solid ${mobiliTheme.palette.primary.dark}`,
    borderRadius: "10px",
    marginLeft: "1px",
  },
  backgroundColor: "transparent",
  color: mobiliTheme.palette.primary.contrastText,
  // border: `1px solid ${mobiliTheme.palette.primary.dark}`,
  borderRadius: "10px",
  marginLeft: "1px",
};
