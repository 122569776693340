export const visitTypeReverseMapping: { [key: string]: string } = {
  'hm': 'Heim',
  'hb': 'Haus',
  'px':'Praxis'
  // Add more mappings as needed
};

export const handleParseInput = (input: string, field?: string) => {
  // const parts:any = input.split(/(hb|hm|HB|HM|PX|px|x\s*\d+(?:-\d+)?|\d+\s*|ml\d+)/i)
    if(field==="frequency"){
      input=input.concat("x")
    }
    const parts:any = input.split(/(hb|hm|HB|HM|PX|px|s*\d+(?:-\d+)?\s*\x|\d+\s*|ml\d+)/i)
    .map(part => part.trim())
    .filter(Boolean);
    
    console.log('PARTS',parts)
      const result:any = {};
      let therapyIndex = 0;
      let mengeIndex = 0;
      let frequencyIndex = 0;
      let frequencyValues: string[] = []; // Store frequency values
    
      for (let i = 0; i < parts.length; i++) {
        if (isNaN(parts[i])) {
          if (parts[i].toLowerCase().includes('hb') || parts[i].toLowerCase().includes('hm') || parts[i].toLowerCase().includes('px')) {
            result['visitType'] = parts[i].toLowerCase();
          }
          // else if (parts[i].startsWith('x')) {
          //   frequencyValues.push(parts[i].substring(1)); // Store frequency values
          // } 
           else if (parts[i].endsWith('x')) {
            frequencyValues.push(parts[i].split('x')[0].trim()); // Store frequency values
          }
           else {
            const therapyKey = `therapy${therapyIndex === 0 ? '' : therapyIndex}`;
            result[therapyKey] = parts[i].trim();
            therapyIndex++;
          }
        } else {
          const mengeKey = `amount${mengeIndex === 0 ? '' : mengeIndex}`;
          result[mengeKey] = parts[i].trim();
          mengeIndex++;
        }
      }
    
      // Assign frequency values to therapies based on the number of frequency values
      if (!result['visitType']) {
        result['visitType'] = 'Praxis';
      }
    
      if (frequencyValues.length === 1) {
        // If only one frequency value, assign it to all therapies
        for (let i = 0; i < therapyIndex; i++) {
          const frequencyKey = `frequency${i === 0 ? '' : i}`;
          result[frequencyKey] = frequencyValues[0];
        }
      } else {
        // If multiple frequency values, assign based on therapy index
        for (let i = 0; i < therapyIndex; i++) {
          const frequencyKey = `frequency${i === 0 ? '' : i}`;
          result[frequencyKey] = frequencyValues[i] || frequencyValues[0]; // Use the corresponding frequency value or the first value
        }
      }
    
      console.log('Result', result);
      return result;
    };

    export const treatmentToRows=(input:any)=> {
      console.log("Input",input)
      const grouped:any = [];

      // Iterate through the input object
      for (const key in input) {
          const match = key.match(/(\D+)(\d*)$/); // Regex to match non-digit characters and optional digits at the end of the key
          if (match) {
              const [, property, suffix] = match;
              const suffixKey = suffix || "0"; // If no suffix, use "0" as key
              grouped[suffixKey] = grouped[suffixKey] || { visitType: input.visitType }; // Include visitType
              grouped[suffixKey][property] = input[key];
          }
      }
       // Remove the 'id' property from each object
    for (const key in grouped) {
      if (grouped[key].hasOwnProperty('id')) {
          delete grouped[key].id;
      }
  }
      return grouped;
  }

    
    