import { AddCard } from "@mui/icons-material";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import Users from "./Users/Users";
import UserDetails from "./Users/UserDetails";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { PatientList } from "./PatientList";
import { useSearchParams } from "react-router-dom";
import { TopLevelPaper } from "../../themes/StyledComponents";

const Administration = observer(() => {
  // Introduce a new state variable for the selected location
  const UserStore = useStore(UserContext);
  const { user } = UserStore;

  let [searchParams, setSearchParams] = useSearchParams();

  const tabSelection = searchParams.get("tabSelection");

  let firstState = {
    value: 1,
    tab: "user",
    selectedUser: null, // Add a state to keep track of the selected user
  };

  if (tabSelection === "patients") {
    firstState.value = 2;
    firstState.tab = "patients";
  }

  const [state, setState] = useState(firstState);

  const [selectedLocation, setSelectedLocation] = useState<string>(user?.location?.id || "");
  // Callback function to handle closing UserDetails
  const handleCloseUserDetails = () => {
    setState({ ...state, selectedUser: null });
  };

  useEffect(() => {
    setSelectedLocation(user?.location?.id || "");
    // fetchData(user?.location?.id || "");
  }, [user?.location]);

  // Callback function to handle selecting a user or opening UserDetails with an empty user
  const handleSelectUser = (user: any) => {
    console.log("userinhandlesl", user);
    if (!user) {
      // If no user is passed, it means "Add User" button is clicked, open UserDetails with an empty user
      setState({ ...state, selectedUser: null });
    } else {
      // If a user is passed, it means an existing user is selected
      setState({ ...state, selectedUser: user });
    }
  };

  return (
    <Grid spacing={2} sx={{ overflowY: "auto", height: "calc(100vh - 80px)" }}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            height: "70px",
          }}
        >
          <Tabs
            value={state.tab === "user" ? "user" : state.value}
            // onChange={handleTabChange}
            aria-label="icon label tabs example"
            orientation="horizontal" // Set to horizontal
          >
            <Tab
              iconPosition="start"
              icon={
                <AddCard
                  sx={{
                    fontWeight: state.value === 0 ? "bold" : "normal",
                  }}
                />
              }
              label="Benutzer"
              value="user"
              onClick={() => {
                setState({ ...state, value: 1, tab: "user" });
              }}
            />
            <Tab
              icon={
                <AddCard
                  sx={{
                    fontWeight: state.value === 0 ? "bold" : "normal",
                  }}
                />
              }
              label="Patienten"
              value="patients"
              onClick={() => {
                setSearchParams({ tabSelection: "patients" });
                setState({ ...state, value: 2, tab: "patients" });
              }}
            />
            <Tab
              icon={
                <AddCard
                  sx={{
                    fontWeight: state.value === 0 ? "bold" : "normal",
                  }}
                />
              }
              label="Zimmer"
              value="room"
              onClick={() => {
                setState({ ...state, value: 3, tab: "room" });
              }}
            />
            {/* {selectedPatients.map((patient, index) => (
              <Tab
                key={index + 1}
                icon={<Person3 />}
                label={ <span style={{ color: 'black', fontWeight:'bold'}}>{patient.lastName}</span>}
                onClick={() => handleTabChange(null, index + 1)}
                value={index + 1}
              />
            ))} */}
          </Tabs>
        </Box>
      </Grid>
      <TopLevelPaper>
        <Grid container spacing={2} sx={{ marginTop: "20px !important" }}>
          {/* Add left margin to the Users component */}
          <Grid
            item
            xs={12}
            sx={{ marginLeft: "10px", width: "100%", border: "0px solid red", display: "flex" }}
          >
            {state.tab === "user" && !state.selectedUser && (
              <Users
                onSelectUser={handleSelectUser}
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            )}
            {state.selectedUser && state.tab === "user" && (
              <UserDetails
                user={state.selectedUser}
                onClose={handleCloseUserDetails}
                onSelectUser={handleSelectUser}
              />
            )}
            {state.tab === "patients" && <PatientList />}
          </Grid>
        </Grid>
      </TopLevelPaper>
    </Grid>
  );
});

export default Administration;
