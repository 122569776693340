import { format } from 'date-fns';
import {Grid, Typography } from "@mui/material";
import { FC } from "react";

const logo = require("../../../assets/favicon_orig.png")

interface HeaderProps {
    header: string;
    patientDetails: {
        firstName: string,
        lastName: string,
        salutation: string,
        address: any
    };
}

const Header: FC<HeaderProps> = ({header, patientDetails}) => {
    const today = format(new Date(), 'dd.MM.yyyy');

    return (
        <div className="flex justify-between items-center">
            {header !== "" && header !== "No Office" && (
                        <Grid container justifyContent="center" alignItems="center">
                            <img src={logo} alt="Logo" height={30} />
                            <Typography variant="body1" sx={{ fontWeight: "bold", color: "#a22141" }}>
                                {header}
                            </Typography>
                        </Grid>
                    )}
            <Grid container justifyContent="space-between" alignItems="center" sx={{paddingTop: '100px', paddingBottom: '40px'}}>
                <Grid item>
                    <Typography variant="body2" sx={{ fontWeight: "normal", color: "#080808" }}>
                        {patientDetails.salutation}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "normal", color: "#080808" }}>
                        {patientDetails.firstName} {patientDetails.lastName}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: "normal", color: "#080808"}}>
                        {patientDetails.address.contactInfo.addressLine1} {patientDetails.address.contactInfo.city} {patientDetails.address.contactInfo.zip}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body2" sx={{ fontWeight: "normal", color: "#080808" }}>
                        {today}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    );
}


export default Header;