import { action, observable } from "mobx";
import { IPromiseBasedObservable } from "mobx-utils";
import { ItemsApi } from "../api-client";
import AuthStore from "./Auth/authStore";

export default class ItemsStore {
  public apiClient: ItemsApi;
  public authStore: AuthStore;

  public constructor(apiClient: ItemsApi,  authStore: AuthStore) {
    this.apiClient = apiClient;
    this.authStore=authStore;
  }

  @observable
  public resourceRequest: IPromiseBasedObservable<any> | null = null;

  @action
  public getAllItems = async () => {
    const { data } = (await this.apiClient.getItems()) as any;
    return data || [];
  };
}
