import React, { useState } from "react";
import { Box, Card, CardContent, Grid, IconButton, Paper, Tab, Tabs } from "@mui/material";
import { Close } from "@mui/icons-material";
import BasicUserInfo from "./BasicUserInfo";
import TherapiesAssignment from "./Therapies";
import PermissionAssignment from "./Permissions";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";
import UserCalendar from "./Calendar/UserCalendar";
import WeeklyUserCalendar from "./Calendar/WeeklyUserCalendar";
import { TopLevelPaper } from "../../../themes/StyledComponents";

interface UserDetailsProps {
  user: any;
  onClose: () => void; // Callback function to close UserDetails
  onSelectUser: (user: any) => void; // Callback function to select a user
}

const UserDetails: React.FC<UserDetailsProps> = ({ user, onClose, onSelectUser }) => {
  const [selectedSubTab, setSelectedSubTab] = useState(0);
  const UserStore = useStore(UserContext);
  const { user: userStore } = UserStore;

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedSubTab(newValue);
  };

  return (
    <Grid container xs={12} spacing={2}>
      <Card sx={{ display: "flex", width: "100%" }}>
        <CardContent sx={{ display: "flex", width: "100%" }}>
          <Grid xs={3} sm={4} md={2} lg={1}>
            {/* Colored box */}

            <Tabs
              orientation="vertical"
              value={selectedSubTab}
              onChange={handleSubTabChange}
              aria-label="sub-tabs"
              sx={{
                paddingTop: "10px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: "6px",
                borderBottom: "1px solid #ccc",
              }}
            >
              <Tab iconPosition="start" label="Info" value={0} sx={{ margin: "6px" }} />
              <Tab iconPosition="start" label="Berechtigung" value={1} sx={{ margin: "6px" }} />
              {user?.roles?.some((role: { id: string }) => role.id === "therapist") && (
                <Tab label="Fortbildungen" value={2} sx={{ margin: "6px" }} />
              )}
              <Tab iconPosition="start" label="Arbeitsplan" value={3} sx={{ margin: "6px" }} />
              <Tab iconPosition="start" label="Urlaub" value={4} sx={{ margin: "6px" }} />
            </Tabs>
          </Grid>

          <Grid item xs={11} sx={{ border: "1px solid #ccc" }}>
            {/* Render content based on the selected subtab */}
            {(() => {
              switch (selectedSubTab) {
                case 0:
                  return <BasicUserInfo userData={user} onSelectUser={onSelectUser} />;
                case 1:
                  return <PermissionAssignment userData={user} onSelectUser={onSelectUser} />;
                case 2:
                  return <TherapiesAssignment userData={user} onSelectUser={onSelectUser} />;
                case 3:
                  return <WeeklyUserCalendar userData={user} />;
                case 4:
                  return <UserCalendar userData={user} />;
                default:
                  return null;
              }
            })()}
            <Box
              sx={{
                position: "absolute",
                top: "160px",
                right: "100px",
                width: "20px",
                height: "20px",
                borderRadius: "10px",
                padding: "5px",
                border: "0px solid red",
              }}
            >
              {/* Close button */}
              <IconButton onClick={onClose} sx={{ color: "#212121" }}>
                <Close />
              </IconButton>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default UserDetails;
