import React, { useState } from 'react';
import { AuthContext } from '../stores/Auth/auth.provider';
import useStore from '../helpers/useStore';
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { UserContext } from '../stores/User/User.provider';
import { observer } from 'mobx-react';
import notificationStore from '../stores/Notification/notificationStore';
import { KeyboardBackspace, Login, PsychologyAlt, Visibility, VisibilityOff } from '@mui/icons-material';
import ResetLoginDetails from './ResetLoginDetails';


const LoginForm = observer(() => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewUser, setShowNewUser] = useState(false);
 

  const AuthStore = useStore(AuthContext);
  const { getUserToken } = AuthStore;
  const UserStore = useStore(UserContext);
  const { userRegister } = UserStore;
  const [showReset, setShowReset] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Check if required fields are entered
    if (!username || !password) {
      // You can display an error message or take other actions to inform the user
      notificationStore.showMessage('Bitte füllen Sie das erforderliche Feld aus.','warning');
      return;
    }

    try {
      // Perform login logic
      const response = await getUserToken({ username: username, password: password });

      if (response) {
        // Reset the form
        setUsername('');
        setPassword('');
      }

      // Perform any further actions (e.g., redirect to a protected route)

    } catch (error) {
      console.error(error);
    }
  };
  const handleForgotPassword = () => {
    setShowReset(true); // Open the password reset dialog
  };


  return (
    <Container
      maxWidth="xs"
      sx={{
        paddingTop: '600px',
        border: '2px solid #ccc', // Border style
        borderRadius: '10px', // Border radius
       
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Box shadow
        padding: '20px', // Padding
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Background color with opacity
      }}
    >
      {showReset ? (
          <><ResetLoginDetails forgotPassword={showReset} userEmail={username} /><Grid item xs={12}>
          <Typography
            variant="body1"
            color="inherit"
            onClick={() => setShowReset(false)}
            style={{ cursor: 'pointer', textAlign: 'left' }}
          >
            <KeyboardBackspace/>Zurück zur Anmeldung
          </Typography>
        </Grid></>
          ):(
   <>
      <Typography variant="h5" align="center" gutterBottom>
        Anmelden <LockIcon />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Nutzername/E-Mail-Adresse"
            type="text"
            fullWidth
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Passwort"
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Anmelden
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
          <Typography
            variant="body2"
            color="inherit"
            onClick={handleForgotPassword}
            style={{ cursor: 'pointer',textAlign: 'left', textDecoration: 'underline' }}
          >
            Ich habe mein Passwort vergessen <PsychologyAlt sx={{paddingTop:"10px"}}/>
          </Typography>
        </Grid>      
    </>)}
    </Container>
  );
});

export default LoginForm;
