import React, { useState } from "react";
import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import Controls from "../../../atoms/Controls";
import { observer } from "mobx-react";
import useStore from "../../../helpers/useStore";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { UserContext } from "../../../stores/User/User.provider";
import { UserDto } from "../../../api-client";
import { set } from "mobx";





interface TherapiesAssignmentProps {
    userData: any;
    onSelectUser: (user: any) => void;
  }
  
  const TherapiesAssignment: React.FC<TherapiesAssignmentProps> = observer(({ userData, onSelectUser }) => {

    const MasterTablesStore = useStore(MasterTablesContext);
    const { masterTablesData } = MasterTablesStore;
    const UserStore = useStore(UserContext);
    const { saveUserInfo } = UserStore;
    const [formState, setFormState] = useState<UserDto>(userData);


   
    let therapyOptions:any ;
    therapyOptions= masterTablesData
    .filter((obj: any) => "therapy" in obj )
    .map((obj: any) => obj.therapy);
   therapyOptions= therapyOptions[0].filter(
        (therapyObj: any) =>
         
          therapyObj.duration
      )
    console.log('masterTablesData',masterTablesData,therapyOptions)

    
    const handleCheckboxChange = async (therapyId: number) => {
        console.log(`Checkbox with ID ${therapyId} changed`);
    
        // Find the therapy object based on therapyId
        const therapy = therapyOptions?.find((t: any) => t.id === therapyId);
    
        if (therapy) {
          // Toggle the therapy in the userData.therapies array
          const updatedTherapies = userData.therapies
            ? userData.therapies.some((t: any) => t.id === therapyId)
              ? userData.therapies.filter((t: any) => t.id !== therapyId)
              : [...userData.therapies, therapy]
            : [therapy];
    setFormState({ ...formState, therapies: updatedTherapies });
          // Update the user data
          // You may need to handle this state update according to your state management logic
          // e.g., if userData is managed by state, dispatch an action to update it
          // setUserData({ ...userData, therapies: updatedTherapies });
    
          try {
            // Call the API (replace with your actual API endpoint)
            const response = await saveUserInfo({ ...userData, therapies: updatedTherapies });
    onSelectUser(response);
            // Handle the API response as needed
            console.log("API Response:", response);
          } catch (error) {
            // Handle API error
            console.error("API Error:", error);
          }
        }
      };
    
  console.log('userData',masterTablesData,userData)  
    return (
        <Box>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Therapies Assignments
        </Typography>
  
        <Grid container spacing={1}>
          {therapyOptions?.map((therapy: any) => (
            <Grid key={therapy.id} item xs={12} sm={4}>
              <FormControl component="fieldset" fullWidth>
                <Box
                  border="1px solid #ddd"
                  borderRadius="1px"
                //   padding="1px"
                display="flex"
              
                  alignItems="center"
                >
                
                <Checkbox
                  checked={formState.therapies?.some((p: any) => p.id === therapy.id)}
                //   disabled={userData.therapies?.some((p: any) => p.id === therapy.id)}
                  onChange={() => handleCheckboxChange(therapy.id)}
                />
              
                  {/* <Box flex="1"> */}
                    <Typography variant="body2">
                      {therapy.id} (<span style={{ fontSize: "0.8rem" }}>{therapy.description}</span>)
                    </Typography>
                  {/* </Box> */}
                  
                </Box>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Box>
    );

});

export default TherapiesAssignment;

