import { Button, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

import React from "react";

export interface DateTimeRange {
  startDate: Date;
  endDate: Date;
}

interface DateTimeRangeProps {
  range: DateTimeRange;
  onChange: (range: DateTimeRange) => void;
}

export const DateTimeRangePicker = ({ range, onChange }: DateTimeRangeProps) => {
  const [startDate, setStartDate] = React.useState(new Date(range.startDate ));
  const [endDate, setEndDate] = React.useState(new Date(range.endDate ));

  const handleWholeDay = () => {
    let newDate = new Date(startDate);
    newDate.setHours(0, 0, 0, 0);
    setStartDate(newDate);
    newDate = new Date(startDate);
    newDate.setHours(23, 59, 59, 999);
    setEndDate(newDate);
    onChange({ startDate: startDate, endDate: endDate });
  };

  const handleChangeStartDate = (date: Date | null) => {
    if (date) {
      setStartDate(date);
      onChange({ startDate: date, endDate: endDate });
    }
  };
  const handleChangeEndDate = (date: Date | null) => {
    if (date) {
      setEndDate(date);
      onChange({ startDate: startDate, endDate: date });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Startdatum"
          value={startDate}
          onChange={(date: Date | null) => {
            handleChangeStartDate(date);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimePicker
          label="Startzeit"
          value={startDate}
          onChange={(date: Date | null) => {
            handleChangeStartDate(date);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <DatePicker
          label="Enddatum"
          value={endDate}
          onChange={(date: Date | null) => {
            handleChangeEndDate(date);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TimePicker
          label="Endzeit"
          value={endDate}
          onChange={(date: Date | null) => {
            handleChangeEndDate(date);
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button onClick={handleWholeDay}>ganzen Tag</Button>
      </Grid>
    </Grid>
  );
};
