import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import useStore from "../helpers/useStore";
import { AuthContext } from "../stores/Auth/auth.provider";
import { Select } from "@mui/material";
import { UserContext } from "../stores/User/User.provider";
import Controls from "../atoms/Controls";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { AccountCircle, Logout } from "@mui/icons-material";
import moment from "moment";
import { PatientsContext } from "../stores/Patients/patients.provider";
import { SearchDto } from "../api-client";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import SideBarList from "./SideBarList";
import { mainAppGridStyle } from "../themes/mobiliTheme";
import {MobiliAvatar} from "../atoms/MobiliAvatar";

const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.down("sm")]: {
    // width: `calc(${theme.spacing(5)} + 1px)`,
    width: 0
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": {background:mainAppGridStyle.background,...openedMixin(theme)},
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": {border: 'unset',background:mainAppGridStyle.background,...closedMixin(theme)},
    }),
  })
);

export const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  paddingBottom: 5,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const AppBarOverView = observer(({ masterTablesData }: { masterTablesData: any[] }) => {
  const { user, hasPermission, changeUserLocation } = useStore(UserContext);
  const PatientStore=useStore(PatientsContext);
  const AuthStore=useStore(AuthContext);
  const {authToken}=AuthStore;
  const { getAllPatients,selectedPatients,setSelectedPatients,getSelectedPatientparams,constructSearchPath,searchPatient,state,setState,selectedValue,setSelectedValue,setFilteredPatient,filteredPatient } = PatientStore;
  const [patientOptions, setPatientOptions] = useState<any[]>([]);
  const UserStore = useStore(UserContext);

  console.log("APBAR", selectedValue, filteredPatient);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showReset, setShowReset] = useState(false);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedLocation, setselectedLocation] = useState("");

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        try {
          const response = await getAllPatients();
          const options = response.map((patient: any) => ({
            label: `${patient.lastName}, ${patient.firstName}, ${moment(patient.dob).format(
              "DD.MM.yyyy"
            )} (${patient.id})`,
            value: patient.id,
            originalDob: patient.dob,
            id: patient.id,
          }));
          setPatientOptions(options);
        } catch (error) {
          console.error("Error fetching patient data:", error);
        }
      };

      fetchData();
    }
  }, [getAllPatients, selectedValue, user]);

  const { setAuthToken } = useStore(AuthContext);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleChange = async (event: any) => {
    const newValue = event.target.value;
    console.log("newValue", newValue);

    // Update the selected value
    setselectedLocation(newValue);

    // Call your API with the new value
    try {
      await changeUserLocation(newValue);
    } catch (error) {
      console.error("Error updating location:", error);
    }
  };

  const handleLogout = () => {
    setAuthToken(null);
    handleMenuClose()
    // Clear the authentication token from localStorage

    window.location.href = "/";
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <AccountCircle />
        Profile
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <Logout />
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}></MenuItem>
    </Menu>
  );

  const locationsOptions = masterTablesData
  .filter((obj: any) => "location" in obj)
  .map((obj: any) => obj.location);

  const navigate=useNavigate()
  
  const handleSearchClick = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const updatedSelectedValue = event.target.id;
      const response = await getAllPatients();
      // Map the response data to options
      const options = response.map((patient: any) => ({
        label: `${patient.lastName}, ${patient.firstName}, ${moment(patient.dob).format(
          "DD.MM.yyyy"
        )} (${patient.id})`,
        value: patient.id,
        originalDob: patient.dob, // Store the original date format in the state
        id: patient.id,
      }));

      // Update the patientOptions state with the new options
      setPatientOptions(options);
      if (patientOptions.length === 0) {
        // Map the response data to options
        const options = response.map((patient: any) => ({
          label: `${patient.lastName}, ${patient.firstName}, ${moment(patient.dob).format(
            "DD.MM.yyyy"
          )} (${patient.id})`,
          value: patient.id,
          originalDob: patient.dob, // Store the original date format in the state
          id: patient.id,
        }));
        setPatientOptions(options);
      }

      if (updatedSelectedValue !== "") {
        // Check if the patient is already in selectedPatients
        const existingPatientIndex = selectedPatients.findIndex(
          (patient: any) => patient.id === Number(updatedSelectedValue)
        );

        if (existingPatientIndex !== -1) {
          // Patient exists, set the active tab to their tab
          setState({
            ...state,
            value: existingPatientIndex + 1, // Add 1 because tabs start from 1
            tab: "",
          });
        } else {
          const selectedPatientData = response.find(
            (patient: any) => patient.id === Number(updatedSelectedValue)
          );

          const selectedPatient: any = {
            id: selectedPatientData?.id,
            isActive: true,
          };
          console.log("ABC4", selectedPatient);
          let updatedPatients;
          if (selectedPatient.id) {
             updatedPatients = [...selectedPatients, selectedPatient]; 
     
         const response=await searchPatient({
           id: selectedPatient.id
         } as SearchDto)
         if (!response) {
          throw new Error(`No data found for patient ID ${selectedPatient.id}`);
        }
      
            console.log('ABCioooo',updatedPatients,selectedPatient,response)
            // setPatientData([...patientData,response],response)
            setSelectedPatients([...selectedPatients,response],response) 
            const params=getSelectedPatientparams(response.id)
            let url;
            if(params){
             url=constructSearchPath(params)}
            else{
              url=constructSearchPath({pId:response.id})
            }

               navigate(url)
              //  updateSelectedPatientRoute({pId:response.id})
               
 
           
           
            setState({ ...state, value: updatedPatients.length, tab: "" });
          }
        }
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
    }
  };

  console.log("Selected value", selectedValue);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  const drawerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (open && drawerRef.current && !drawerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [open]);

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            // color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              ...(open && { display: "none" }),
              color:"primary.main"
              
            }}
          >
            <MenuIcon />
          </IconButton>

          {/* Use the Link component to wrap the logo and make it clickable */}
          <Link
            to="/dashboard"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src="favicon.png" alt="Logo" height="58" />
            <Typography
              variant="h4"
              noWrap
              sx={{ display: { xs: "none", sm: "block" }, color: "white" }}
            >
              Mobili Decision
            </Typography>
            {process.env.REACT_APP_INSTANCE_NAME && process.env.REACT_APP_INSTANCE_NAME!="production" &&(
              <Typography
                variant="h4"
                noWrap
                sx={{
                  display: { xs: "none", sm: "block" },
                  color: "info.main",  // Use the info color from the theme
                  fontStyle: "italic", // Make the text italic
                  px: 2                // Add padding (shorthand for padding in all directions)
              }}
              >
                {process.env.REACT_APP_INSTANCE_NAME}
              </Typography>
            )}
          </Link>

          <Box sx={{ flexGrow: 1 }} />
          {user && (
            <Toolbar sx={{ width: "30%", height: "20px" }}>
              <Controls.Select
                label="Patienten suchen"
                name="search"
                id={1}
                value={selectedValue}
                onChange={handleSearchClick}
                options={patientOptions.map((option: any) => ({
                  label: option?.label.toString(),
                  value: option?.label.toString(),
                  id: option?.value.toString(),
                }))}
                style={{
                  color: "white",
                  width: "100%",
                  backgroundColor: "white",
                  padding: "2px",
                  borderRadius: "5px",
                  height: "50px",
                }}
              />
            </Toolbar>
          )}
          <Box  sx={{  display: { xs: "none", md: "flex" }, paddingRight: 2 }}>
            {hasPermission("changeLocation") ? (
              <Select
                value={selectedLocation || user?.location?.name}
                label="Location"
                sx={{ color: "white" }}
                onChange={handleChange}
              >
                {locationsOptions[0]?.map((location: any) => (
                  <MenuItem key={location.id} value={location.name}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <Typography sx={{ color: "white" }}>{user?.location?.name}</Typography>
            )}
          </Box>
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            {user && 
              <MobiliAvatar user={user}  onClick={handleProfileMenuOpen} sx={{cursor: "pointer"}}/>
              }
            {/* </Button> */}
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="info"
            >
              <AccountCircle style={{ color: "blue" }} />{" "}
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {user && (
        <Drawer variant="permanent" open={open} ref={drawerRef}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <SideBarList
            isOpen={open}
            menus={[
              { id: 1, name: "Dashboard", linkTo: "/dashboard" },
              { id: 2, name: "Patient", linkTo: "/patient" },
              { id: 3, name: "Termin Plan", linkTo: "/termin" },
              {
                id: 4,
                name: "Kasse",
                linkTo: "/transactions",
                isDisabled: !UserStore.hasPermission("ledger"),
              },
              {
                id: 9,
                name: "Administration",
                linkTo: "/admin",
                isDisabled: !UserStore.hasPermission("adminPage"),
              },
            ]}
            onClick={handleDrawerClose}
          />
        </Drawer>
      )}
      {renderMenu}
      {renderMobileMenu}
    </>
  );
});

export default AppBarOverView;
