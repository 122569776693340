import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Controls from "../../atoms/Controls";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Event } from "./Calendar.type";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";

export interface AddEventModalProps {
  open: boolean;
  event?: Event;
  continuous?: boolean;
  onClose?: () => void;
  onConfirm: (event: Event) => void;
}

export interface EventModal {
  title: string;
  loaType: string;
  start: Dayjs;
  end: Dayjs;
}

const absenceOptions = [
  { value: "vacation", label: "Urlaub" },
  { value: "sickness", label: "Krank" },
  { value: "sickness_child", label: "Kindkrank" },
  { value: "training", label: "Fortbildung" },
  { value: "compensatory_leave", label: "Ü frei" },
  { value: "remaining_vacation", label: "Resturlaub" },
  { value: "special_leave", label: "Sonderurlaub" },
  { value: "parental_leave", label: "Elternzeit" },
  { value: "exempted", label: "Freigestellt" },
  { value: "professional_ban", label: "Berufsverbot" },
];

const initialFormValues = {
  title: "",
  loaType: "",
  start: dayjs(),
  end: dayjs(),
};

const AddEventModal = (props: AddEventModalProps) => {
  const { onClose, onConfirm, open, event, continuous } = props;
  const [formState, setFormState] = useState<EventModal>(initialFormValues);
  const [eventType, setEventType] = useState(
    continuous ? "ContinuousEventTimeSlot" : "EventTimeSlot"
  );

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      start: dayjs(event?.start),
      end: dayjs(event?.end),
    }));
    setEventType(event?.type || continuous ? "ContinuousEventTimeSlot" : "EventTimeSlot");
  }, [event]);

  const handleClose = () => {
    return onClose && onClose();
  };

  const handleAddEventClick = () => {
    if (eventType === "LeaveOfAbsence" && formState.loaType === "") return;
    const newEvent: Event = {
      resourceId: event?.resourceId,
      title: formState.title,
      start: formState.start.toISOString(),
      end: formState.end.toISOString(),
      type: eventType,
      loaType: formState.loaType,
      continuous: continuous,
    };
    console.log("event modal", newEvent);
    setFormState(initialFormValues);
    onConfirm && onConfirm(newEvent);
  };

  const handleInputChange = (fieldName: string, value: any) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [fieldName]: value,
    }));
    console.log("formState", formState);
  };

  const handleEventTypeChange = (event: React.MouseEvent<HTMLElement>, type: string) => {
    if (type !== null) setEventType(type);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Neuer Termin</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            <ToggleButtonGroup
              color="primary"
              value={eventType}
              exclusive
              onChange={handleEventTypeChange}
              aria-label="Platform"
            >
              <ToggleButton value="EventTimeSlot">Termin</ToggleButton>
              {!continuous && <ToggleButton value="LeaveOfAbsence">Abwesenheit</ToggleButton>}
              {!continuous && <ToggleButton value="GoneFishing">Gone Fishing</ToggleButton>}
            </ToggleButtonGroup>
          </Grid>

          {eventType !== "LeaveOfAbsence" && eventType != "GoneFishing" && (
            <Grid item xs={12}>
              <Controls.Input
                label="Title"
                style = {{width: "100%"}}
                onChange={(e: any) => handleInputChange("title", e.target.value)}
              />
            </Grid>
          )}
          {eventType !== "EventTimeSlot" &&
            eventType !== "ContinuousEventTimeSlot" &&
            eventType != "GoneFishing" && (
              <Grid item xs={12}>
                <Controls.Select
                  style={{ width: "100%" }}
                  placeholder="Bitte auswählen"
                  label="Abwesenheitsart"
                  name="type"
                  value={
                    absenceOptions.find((option) => option.label === formState.loaType)?.value ||
                    initialFormValues.loaType
                  }
                  showOptionLabel
                  options={absenceOptions}
                  onChange={(e: any) => handleInputChange("loaType", e.target.value)}
                  required
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                ampm={false}
                label="ab"
                defaultValue={dayjs(event?.start)}
                onChange={(date: any) => handleInputChange("start", dayjs(date))}
              />
              <TimePicker
                ampm={false}
                label="bis"
                value={dayjs(event?.end)}
                onChange={(date: any) => handleInputChange("end", dayjs(date))}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          Abbrechen
        </Button>
        <Button onClick={handleAddEventClick}>Bestätigen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddEventModal;
