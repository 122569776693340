import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  Paper,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Collapse,
  Box,
  Button,
  Menu,
  MenuItem,
  Avatar,
  Autocomplete,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Tooltip,
  TableContainer,
  IconButton,
  CircularProgress,
  Modal,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import {
  KeyboardDoubleArrowUp,
  KeyboardArrowUp,
  DragHandle,
  KeyboardDoubleArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Add,
  Note,
} from "@mui/icons-material";
import { mobiliTheme, attentionColors } from "../../themes/mobiliTheme";
import { UserContext } from "../../stores/User/User.provider";
import useStore from "../../helpers/useStore";
import { TicketDto } from "../../api-client";
import { TicketsStoreContext } from "../../stores/Ticketing/tickets.provider";
import { get } from "http";
import { commonTabStyle } from "../PatientOverview/tabStyles";
import TicketForm from "./TicketEditCreate/ticketForm";
import { format } from "date-fns";
import Person from "@mui/icons-material/Person";
import { set } from "mobx";
import Involvements from "./TicketEditCreate/Involvements";
import { observer } from "mobx-react";
import { MobiliAvatar } from "../../atoms/MobiliAvatar";

const getPriorityColor = (priority: string) => {
  switch (priority) {
    case "High":
      return attentionColors.immediateAttention; // Set background color to red for High priority
    case "Medium":
      return attentionColors.mediumPriority; // Set background color to yellow for Medium priority
    case "Low":
      return attentionColors.lowPriority; // Set background color to green for Low priority
    default:
      return attentionColors.customInfoPrimary; // Default background color
  }
};

const TicketOverview: React.FC = observer(() => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const TicketsStore = useStore(TicketsStoreContext);
  const { getTickets, getTicketById } = TicketsStore;
  const [refresh, setRefresh] = useState(false);
  const [ticketsData, setTicketsData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const UserStore = useStore(UserContext);
  const { user } = UserStore;

  const [openDialog, setOpenDialog] = useState(false);
  const [openTicket, setOpenTicket] = useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  const [isTicketLoading, setIsTicketLoading] = useState(false);
  const handleRowClick = async (ticket: any) => {
    setIsTicketLoading(true);
    try {
      setOpenDialog(true);

      const response = await getTicketById(ticket?.id);
      setSelectedTicket(response);
    } catch (error) {
      console.error("Error fetching ticket:", error);
    } finally {
      setIsTicketLoading(false);
    }
  };

  const handleSubTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setOpenDropdown(newValue);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  // Data for the table
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Set loading state to true before fetching data
      try {
        const response = await getTickets();
        setTicketsData(response);
      } catch (error) {
        console.error("Error fetching tickets:", error);
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false); // Set loading state to false regardless of success or failure
        setRefresh(false);
      }
    };

    fetchData();
  }, [refresh, user?.location?.id]); // Empty dependency array to run the effect only once on component mount

  return (
    <>
      <Card sx={{maxHeight:"400px"}}>
        <CardHeader
          avatar={<Note />}
          title={
            <Typography
              variant="h6"
              sx={{
                display: "flex",
                alignItems: "flex-end",
                paddingLeft: "8px",
                flexGrow: 1,
                borderRadius: 4,
                fontFamily: "inherit",
                justifyContent: "space-between",
              }}
            >
              Tickets
              <Tabs value={selectedTab} onChange={handleTabChange}>
                <Tab iconPosition="start" label="Neu" />
                <Tab iconPosition="start" label="Von mir" />
                <Tab iconPosition="start" label="Für mich" />
              </Tabs>
              <IconButton
                onClick={() => setOpenTicket(true)}
                sx={{ backgroundColor: mobiliTheme.palette.secondary.main }}
              >
                <Add sx={{ color: "white" }} />
              </IconButton>
            </Typography>
          }
        />
        <CardContent sx={{overflowY:"auto", height:"400px"}}>
          {isLoading && <CircularProgress />}
          {!isLoading && ticketsData.length === 0 && (
            <Typography>Keine Tickets gefunden</Typography>
          )}
          {selectedTab === 0 && !isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData.filter((ticket: any) => ticket.ticketStatus === "New")}
            />
          )}
          {selectedTab === 1 && !isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData.filter(
                (ticket: any) =>
                  ticket.createdByUser?.id === user?.id && ticket.assignedToUser?.id !== user?.id
              )}
            />
          )}
          {selectedTab === 2 && !isLoading && ticketsData.length > 0 && (
            <TicketsTable
              handleRowClick={handleRowClick}
              ticketsData={ticketsData.filter(
                (ticket: any) =>
                  ticket.assignedToUser?.id === user?.id && ticket.createdByUser?.id !== user?.id
              )}
            />
          )}
        </CardContent>
      </Card>
      <TicketForm
        openTicket={openTicket}
        onCloseTicketForm={() => setOpenTicket(!openTicket)}
        refresh={refresh}
        setRefresh={setRefresh}
      />
      <TicketDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        isTicketLoading={isTicketLoading}
      />
    </>
  );
});

export const TicketsTable: React.FC<{
  handleRowClick: (ticket: any) => void;
  ticketsData: any;
}> = ({ handleRowClick, ticketsData }) => {
  const UserStore = useStore(UserContext);
  const TicketsStore = useStore(TicketsStoreContext);
  const { user, allUsers, getUserBy, getAllUsers } = UserStore;
  const { getTickets } = TicketsStore;
  const [userOptions, setUserOptions] = useState<any>([]);
  const [initialRender, setInitialRender] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenPopover = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (user) {
  //         // Fetch user data
  //         const userResponse = await getUserBy(user.location?.id as string);
  //         // Find index of logged-in user in userResponse
  //         const userIndex = userResponse.findIndex(
  //           (u: any) => u.id === user.id
  //         );

  //         // If logged-in user exists in userResponse, move it to the top
  //         if (userIndex !== -1) {
  //           userResponse.splice(userIndex, 1); // Remove logged-in user from its current position
  //           userResponse.unshift(user); // Add logged-in user at the beginning
  //         }
  //         setUserOptions(userResponse);
  //       } else {
  //         setUserOptions([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   // Fetch data when user changes or on initial render
  //   if (!initialRender || user) {
  //     fetchData();
  //   }

  //   // Set initialRender flag to false after initial render
  //   setInitialRender(false);
  // }, [user, initialRender]);
  const [ticketData, setTicketData] = useState<TicketDto>({
    title: "",
    assignedToUser: { id: "" },
    description: "",
    activities: [],
    createdByUser: { id: "" },
    updatedByUser: { id: "" },
    ticketType: "",
    ticketStatus: "NEW",
    priority: "Low",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setTicketData({ ...ticketData, [name]: value });
  };

  const [fetchingMoreUsers, setFetchingMoreUsers] = useState(false);
  const handleMoreUsersClick = async () => {
    setFetchingMoreUsers(true);
    try {
      const moreUsers = await getAllUsers();
      // Filter out duplicate users by ID
      const filteredUsers = moreUsers.filter(
        (user) => !userOptions.some((existingUser: any) => existingUser.id === user.id)
      );
      setUserOptions((prevUsers: any) => [...prevUsers, ...filteredUsers]);
      // setFetchingMoreUsers(false);
    } catch (error) {
      console.error("Error fetching more users:", error);
      setFetchingMoreUsers(false);
    }
  };

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(
    <KeyboardDoubleArrowDown sx={{ color: attentionColors.lowPriority }} />
  ); // Default selected priority symbol

  const handleClick = (event: any) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl1(null);
  };

  const handlePrioritySelect = (prioritySymbol: any, value: string) => {
    setSelectedPriority(prioritySymbol); // Update selected priority symbol
    setTicketData({ ...ticketData, priority: value }); // Update ticket data with selected priority
    handleClose();
  };

  console.log("sDSAGHSHSJJS", selectedOptions);

  return (
    <TableContainer sx={{ maxHeight: "calc(100% - 48px)" }}>
      <Table sx={{ tableLayout: "fixed" }}>
        <TableHead sx={{ position: "sticky", zIndex: "1", top: 0, backgroundColor:mobiliTheme.cardBackground }}>
          <TableRow>
            <TableCell sx={{ width: "3%", padding: "0px" }}>ID#</TableCell>
            <TableCell sx={{ width: "5%", padding: "0px" }}>Typ</TableCell>
            <TableCell sx={{ width: "5%", padding: "0px" }}>Datum</TableCell>
            {/* <TableCell sx={{ width: "6%",padding:"0px" }}>Time</TableCell> */}
            <TableCell sx={{ width: "4%", padding: "0px" }}>Erstellt</TableCell>
            <TableCell sx={{ width: "4%", padding: "0px" }}>Priorität</TableCell>
            <TableCell sx={{ width: "5%", padding: "0px" }}>Zugewiesen</TableCell>
            <TableCell sx={{ width: "5%", padding: "0px" }}>Betroffen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflowY: "auto" }}>
          {ticketsData.map((ticket: any, index: number) => (
            <React.Fragment key={ticket.id}>
              <TableRow onClick={() => handleRowClick(ticket)} sx={{ width: "60pxpx" }}>
                <TableCell style={{ width: "3%", padding: "0px" }}>
                  <Tooltip title={`${ticket.id}`}>
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                          width: "100%",
                        },
                      }}
                      label={ticket.id}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "5%", padding: "0px" }}>
                  <Tooltip title={`${ticket.ticketType}`}>
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      label={ticket.ticketType}
                    />
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ width: "5%", padding: "0px" }}>
                  <Tooltip
                    title={`${format(new Date(ticket.createdAt), "dd.MM.yyyy")}${format(
                      new Date(ticket.createdAt),
                      "HH:MM:SS"
                    )}`}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      label={format(new Date(ticket.createdAt), "dd.MM")}
                    />
                  </Tooltip>
                </TableCell>
                {/* <TableCell sx={{ width: "6%",padding:"0px" }}>
                  <Tooltip
                    title={`${format(new Date(ticket.createdAt), "HH:MM:SS")}`}
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      label={format(new Date(ticket.createdAt), "HH:MM:SS")}
                    />
                  </Tooltip>
                </TableCell> */}
                <TableCell sx={{ width: "5%", padding: "0px" }}>
                  <MobiliAvatar
                    user={ticket.createdByUser}
                    sx={{ width: 32, height: 32, fontSize: "0.8rem" }}
                  />
                </TableCell>
                <TableCell sx={{ width: "4%", padding: "0px" }}>
                  <Tooltip title={`${ticket?.priority}`}>
                    <Avatar
                      // variant="circular"
                      sx={{
                        width: 30,
                        height: 30,
                        backgroundColor: getPriorityColor(ticket?.priority),
                        "& > *": {
                          display: "none", // Hide default content inside the Avatar
                        },
                      }}
                    >
                      <React.Fragment></React.Fragment>
                    </Avatar>
                  </Tooltip>
                </TableCell>

                <TableCell sx={{ width: "4%", padding: "0px" }}>
                <MobiliAvatar
                    user={ticket.assignedToUser}
                    sx={{ width: 32, height: 32, fontSize: "0.8rem" }}
                  />
                </TableCell>
                <TableCell sx={{ width: "5%", padding: "0px" }}>
                  <Tooltip
                    title={
                      ticket.patient ? (
                        <Box
                          sx={{
                            background: "white",
                            borderRadius: "10px",
                            margin: "2px",
                            padding: "5px",
                          }}
                          key={index}
                        >
                          <Involvements ticketData={ticket} />
                        </Box>
                      ) : (
                        ""
                      )
                    }
                  >
                    <Chip
                      sx={{
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                        },
                      }}
                      label={
                        ticket.patient
                          ? `${ticket.patient?.firstName} ${ticket.patient?.lastName}`
                          : "-"
                      }
                    />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export const TicketDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  selectedTicket: any;
  setSelectedTicket: any;
  isTicketLoading: boolean;
}> = ({ open, handleClose, selectedTicket, setSelectedTicket, isTicketLoading }) => {
  return (
    <>
      <TicketForm
        openTicket={open}
        onCloseTicketForm={handleClose}
        data={selectedTicket}
        isTicketLoading={isTicketLoading}
      />
      <Tabs
        value={selectedTicket && selectedTicket.activities ? selectedTicket.activities.type : "All"}
        indicatorColor="primary"
        textColor="primary"
        centered
      />
    </>
  );
};

const TicketDropdown: React.FC<{
  openDropdown: string | null;
  subTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  selectedTicket: any;
}> = ({ openDropdown, subTabChange, selectedTicket }) => {
  return (
    <Collapse in={openDropdown !== null} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Tabs
          value={openDropdown}
          onChange={subTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
          sx={{ minHeight: "" }}
        >
          <Tab iconPosition="start" label="All" value="All" sx={commonTabStyle} />
          <Tab iconPosition="start" label="Comments" value="Comments" sx={commonTabStyle} />
          <Tab iconPosition="start" label="History" value="History" sx={commonTabStyle} />
        </Tabs>
      </Box>
    </Collapse>
  );
};

export default TicketOverview;
