
import { createContext, ReactNode } from 'react';
import { CalendarApi, Configuration } from '../../api-client';
import CalendarStore from './calendarStore';
import { AuthContext } from '../Auth/auth.provider';
import useStore from '../../helpers/useStore';
import { useLocalStore } from 'mobx-react';

interface CalendarProviderProps {
  children: ReactNode;
}

export const CalendarContext = createContext<CalendarStore | null>(null);
CalendarContext.displayName = 'CalendarContext';

export const CalendarProvider = ({ children }: CalendarProviderProps) => {
const basePath = process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;

const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});

  const apiClient = new CalendarApi(config);
  const store = useLocalStore(
    () => new CalendarStore(apiClient,AuthenticationStore)
  );

  store.apiClient = apiClient;

  return (
    <CalendarContext.Provider value={store}>
      {children}
    </CalendarContext.Provider>
  );
};