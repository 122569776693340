import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

interface WarningDialogProps {
  open: boolean;
  onClose: () => void;
  onContinue: () => void; // Pass the reason to onContinue callback
  title: string;
  content: any;
  isConfirmSave?: boolean;
}

const WarningDialog: React.FC<WarningDialogProps> = ({
  open,
  onClose,
  onContinue,
  title,
  content,
  isConfirmSave = false,
}) => {
  const [reason, setReason] = useState(""); // State for the reason

  const dialogStyle = {
    minWidth: "600px", // Adjust the width as needed
    backgroundColor: "#e3f2fd", // Your desired background color
  };

  const titleStyle = {
    // backgroundColor: "#2196f3", // Title background color
    // color: "#fff", // Title text color
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      PaperProps={{
        style: dialogStyle, // Apply custom styles to the dialog container
      }}
      BackdropProps={{
        invisible: false, // This will remove the backdrop
      }}
    >
      <DialogTitle style={titleStyle} id="form-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {(isConfirmSave) ? "Ohne Speichern schließen" : "Abbrechen"}
        </Button>
        <Button onClick={onContinue}>
          {isConfirmSave ? "Speichern und schließen" : "Bestätigen"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarningDialog;
