import { Moment } from 'moment';
import { useState } from 'react';
import { DateRangePicker as ReactDateRangePicker, isSameDay } from 'react-dates'
import './dateRangePicker.css';
import moment from 'moment';
import 'moment/locale/de';

interface DateRangePickerProps {
  startDate: Moment | null;
  endDate: Moment | null;
  handleDatesChange: ({ startDate, endDate }: { startDate: Moment | null, endDate: Moment | null }) => void;
  isOutsideRange?: (day: Moment) => boolean;
  isDayBlocked?: (day: Moment) => boolean;
  isDayHighlighted?: (day: Moment) => boolean;
  displayFormat?: string;
  block?: boolean;
  blockedDays?: Moment[];
  highlightDays?: Moment[];
}

const DateRangePicker: React.FC<DateRangePickerProps> = (props) => {

  moment.locale('de'); // Set the locale globally
  const {startDate, endDate, handleDatesChange, isOutsideRange, isDayBlocked, isDayHighlighted, blockedDays, highlightDays, displayFormat, block} = props
  const [focusedInput, setFocusedInput] = useState<'startDate' | 'endDate' | null>(null);

  const _isDayBlocked = (day: Moment) => {
    if (!blockedDays) return false;
    return blockedDays.some(date => isSameDay(date, day));
  }

  const _isDayHighlighted = (day: Moment) => {
    if (!highlightDays) return false;
    return highlightDays.some(date => isSameDay(date, day));
  }

  return (
    <ReactDateRangePicker
    startDate={startDate}
    startDateId="startDate"
    endDate={endDate}
    endDateId="endDate"
    onDatesChange={handleDatesChange}
    focusedInput={focusedInput}
    onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
    isOutsideRange={isOutsideRange}
    displayFormat={displayFormat}
    isDayBlocked={blockedDays ? _isDayBlocked : isDayBlocked}
    isDayHighlighted={highlightDays ? _isDayHighlighted : isDayHighlighted}
    withPortal
    block={block}
    showClearDates
  />
  )
}

export default DateRangePicker