import React from 'react';
import { InputLabel, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { formatISO, isValid } from 'date-fns';

const DatePicker = (props:any) => {
  const { name, label, value, required, onChange, disabled, formatDate, style } = props;

  const convertToDefEventPara = (name:any, value:any) => ({
    target: {
      name,
      value,
    },
  });

  const handleDateChange = (date:any, event:any) => {
    // Check if the date is valid
    if (isValid(date)) {
      // Format the date to ISO string format
      const isoDateString = formatISO(date);
      onChange(convertToDefEventPara(name, isoDateString), event);
    } else {
      // Handle the invalid date case if necessary
      onChange(convertToDefEventPara(name, null), event);
    }
  };
  
  const textFieldStyle = {
    borderBottom: '1px solid', // Add a black underline
  };

  const formattedValue = value ? new Date(value) : ""; // Assuming `value` is a valid date

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <InputLabel sx={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
        {label} {required && (
          <Typography component="span" variant="body2" color="error" style={{ fontSize: "20px" }}>
            *
          </Typography>
        )}
      </InputLabel>
      <MuiDatePicker
        value={formattedValue}
        format={formatDate ? formatDate : 'dd.MM.yyyy'}
        disabled={disabled}
        onChange={handleDateChange}
        onAccept={(date:any) => handleDateChange(date, null)} // Trigger on complete date
        sx={style ? style : textFieldStyle}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
