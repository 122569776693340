// new component which just lists the patients:

import { useEffect, useState } from "react";
import { PatientsContext } from "../../stores/Patients/patients.provider";
import useStore from "../../helpers/useStore";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PatientList = () => {
  const PatientStore = useStore(PatientsContext);

  const empty: any[] = [];
  const [patients, setPatients] = useState(empty);
  const [loading, setLoading] = useState(true);
  const { getAllPatients, selectedPatients } = PatientStore;
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  //   const selectedPatientId = searchParams.get("patientId");

  const selectedIds = selectedPatients.map((p:any) => p.id);

  useEffect(() => {
    const fetchPatients = async () => {
      setLoading(true);
      const { data } = await PatientStore.apiClient.getStats();
      const patients = data as unknown as any[];
      setPatients(patients);
      setLoading(false);
    };

    fetchPatients();
  }, []);

  // a table of patients
  return (
    <div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Id</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Geburtsdatum</TableCell>
                <TableCell>Rezepte</TableCell>
                <TableCell>Geplannte Therapien</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {patients.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{cursor:"pointer", hover: "#000", background: selectedIds.includes(row.patient_id) ? "yellow" : "white" }}
                  onClick={() => {
                    searchParams.set("patientId", row.patient_id);
                    setSearchParams(searchParams);
                    navigate(`/patient?pId=${row.patient_id}&rxinfo`);
                  }}
                >
                  <TableCell component="th" scope="row">
                    {row.patient_id}
                  </TableCell>
                  <TableCell>
                    {row.patient_lastName}, {row.patient_firstName}
                  </TableCell>
                  <TableCell>{row.patient_dob}</TableCell>
                  <TableCell>{row.rxcount}</TableCell>
                  <TableCell>{row.treatmentcount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};
