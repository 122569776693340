
import { Box, TextareaAutosize } from '@mui/material';

const TextArea = (props:any) => {

    const { value, onChange,label, placeholder, rows,style, ...rest }=props;
  return (
    <>
      {label && <Box style={{ fontSize: '11px', fontWeight: 'normal'}}  >{label}</Box>}
      <TextareaAutosize
        minRows={rows}
        placeholder={!value ? placeholder : ''}
        value={value || ''}
        onChange={onChange}
        style={style?style:{ width: '100%', resize: 'none', border: '1px solid lightgray' }}
        {...rest}
      />
    </>
  );
};

export default TextArea;
