import React from "react";
import { InputBase, InputLabel, TextField, Typography } from "@mui/material";

const Input = (props: any) => {
  const textFieldStyle = {
    borderBottom: "2px solid grey", // Add a black underline
    // fontSize: '10px', // Adjust the label font size
    flexGrow: 1, // Take up all available space
  };

  const {
    name,
    label,
    value,
    required,
    error = null,
    onChange,
    disabled,
    onKeyPress,
    style,
  } = props;

  return (
    <>
      <InputLabel sx={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
        {label}{" "}
        {required && (
          <Typography component="span" variant="body2" color="error" style={{}}>
            *
          </Typography>
        )}
      </InputLabel>{" "}
      {error && <div style={{ color: "red" }}>{error}</div>}
      <InputBase
        name={name}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabled}
        inputProps={{ shrink: true }} // Ensures that the label doesn't overlap with the value
        style={style ? {...textFieldStyle, ...style} : textFieldStyle}
        // {...(error && { error: true, helperText: error })}
      />
    </>
  );
};

export default Input;
