/* eslint-disable react-hooks/exhaustive-deps */
import {
  CircularProgress,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { observer, useObserver } from "mobx-react";
import React, { useState, useEffect } from "react";
import useStore from "../../../helpers/useStore";
import { UserContext } from "../../../stores/User/User.provider";
import formatDate from "../../../helpers/dateFormat";
import { MoreVert } from "@mui/icons-material";
import DataTable from "../../../molecules/DataTable";
import { format } from "date-fns";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { TopLevelPaper } from "../../../themes/StyledComponents";

interface UsersProps {
  onSelectUser: (user: any) => void; // Replace 'any' with the actual type of your user object
  selectedLocation: string;
  setSelectedLocation: (locationId: string) => void;
}

const Users = observer(({ onSelectUser, selectedLocation, setSelectedLocation }: UsersProps) => {
  const UserStore = useStore(UserContext);
  const [userData, setUserData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true); // New state for loading
  const [additionalProperty, setAdditionalProperty] = useState<boolean>(false);
  const { getUserBy, user, activateOrDeactivateUser } = UserStore;
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
  const MasterTableStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTableStore;
  // Introduce a new state variable for the selected location
  //  const [selectedLocation, setSelectedLocation] = useState<string>("")

  const locationsOptions = masterTablesData
    .filter((obj: any) => "location" in obj)
    .map((obj: any) => obj.location);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenu(null);
  };

  const handleEditClick = (selectedUser: any) => {
    console.log("selectedUser", selectedUser);
    if (selectedUser) {
      console.log("Edit clicked for user:", selectedUser);
      onSelectUser(selectedUser);
      handleMenuClose();
    }
  };
  // const handleDeleteClick = (selectedUser: any) => {
  //   // Handle delete action here
  //   // Call the API to delete the user
  //   console.log("Delete clicked for user:", user);
  //   handleMenuClose();
  // };
  const handleActivateDeactivateClick = async (selectedUser: any) => {
    // Call the API to deactivate or activate the user based on the current state
    try {
      if (selectedUser.deletedAt) {
        // User is currently deactivated, activate the user
        await activateOrDeactivateUser(selectedUser.id, false);
        console.log("User activated:", selectedUser);
      } else {
        // User is currently active, deactivate the user (soft delete)
        await activateOrDeactivateUser(selectedUser.id, true);
        console.log("User deactivated:", selectedUser);
      }

      fetchData(selectedLocation);
    } catch (error) {
      console.error("Error during delete:", error);
    } finally {
      handleMenuClose();
    }
  };
  // Separate handler for the "More" button click
  const handleMoreButtonClick = (event: React.MouseEvent<HTMLElement>, row: any) => {
    console.log("ROWHJFJJF", row);
    setSelectedUser(row);
    // setSelectedRow(row);
    setMenu(event.currentTarget);
    // Stop event propagation to prevent it from reaching the row click handler
    event.stopPropagation();
  };

  const fetchData = async (locationId: string) => {
    console.log("userfecthData", user);
    try {
      setLoading(true); // Set loading to true when starting to fetch data
      const response = await getUserBy(locationId);
      const updatedData = (response || []).map((obj: any) => {
        const rolesData = (obj.roles || []).map((role: any) => role.name).join(", ");
        const parsedDate: any = new Date(obj.createdAt);
        // if (!isNaN(parsedDate)) {
        //   // Check if the parsed date is valid
        //   formattedDob = format(parsedDate, "dd.MM.yyyy");
        const therapiesData = (obj.therapies || []).map((t: any) => t.abbreviation).join(", ");

        return {
          ...obj,
          role: rolesData,
          therapiesList: therapiesData,
          createdAt: obj.createdAt ? format(parsedDate, "dd.MM.yyyy") : "",
          location: obj.location ? obj.location.id : "",
          therapistLocation: obj.therapistLocation ? obj.therapistLocation.id : "",
          // Add the "actions" property
          actions: (
            <>
              <IconButton onClick={(event) => handleMoreButtonClick(event, obj)}>
                <MoreVert />
              </IconButton>
            </>
          ),
        };
      });
      const filteredData = updatedData.filter((user) => includeDeleted || user.deletedAt === null);

      setUserData(filteredData);

      // setUserData(updatedData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false); // Set loading to false when data fetching is done
    }
  };

  useEffect(() => {
    fetchData(selectedLocation);
  }, [user, includeDeleted, selectedLocation]);

  const handleLocationChange = (selectedLocationId: string) => {
    // Update the selected location and fetch data
    setSelectedLocation(selectedLocationId);
    fetchData(selectedLocationId);
  };

  console.log("USERDATATTA", userData, selectedLocation);
  const columns = [
    { id: "firstName", label: "Vorname", minWidth: 50 },
    { id: "lastName", label: "Nachname", minWidth: 50 },
    { id: "email", label: "Email", minWidth: 80 },
    { id: "role", label: "Rolen", minWidth: 80 },
    { id: "therapiesList", label: "Heilmittel", minWidth: 50 },
    { id: "location", label: "Standort", minWidth: 50 },
    { id: "therapistLocation", label: "Behandlungsort", minWidth: 50 },
    { id: "createdAt", label: "Erstellet am", minWidth: 50 },
    // Add a new "Actions" column
    {
      id: "actions",
      label: "Actions",
      minWidth: 50,
    },
  ];

  return useObserver(() => {
    return (
      <>
        {loading ? (
          <Modal
            open={loading}
            aria-labelledby="loading-modal-title"
            aria-describedby="loading-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <CircularProgress size={60} />
              Loading...
            </div>
          </Modal>
        ) : (
            <Grid container spacing={2}>
              <DataTable
                columns={columns}
                data={userData}
                onRowClick={(row) => {
                  onSelectUser(row);
                }}
                additionalProperty={{
                  additionalProperty: true,
                  includeDeleted: includeDeleted,
                  onIncludeDeletedChange: setIncludeDeleted,
                  selectionTab: {
                    user: selectedLocation, // Pass user details
                    handleInputChange: fetchData, // Pass handleInputChange callback
                    locationOptions: locationsOptions[0]?.map((option: any) => ({
                      // Pass location options
                      label: option.id?.toString(),
                      value: option.id?.toString(),
                    })),
                    handleLocationChange: handleLocationChange, // Pass the function here
                  },
                }}
                onAddClick={onSelectUser} // Pass onSelectUser callback to DataTable
              />
              <Menu
                id="actions-menu"
                anchorEl={menu}
                open={Boolean(menu)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={() => handleEditClick(selectedUser)}>Edit</MenuItem>
                {!selectedUser?.deletedAt && (
                  <MenuItem onClick={() => handleActivateDeactivateClick(selectedUser)}>
                    Deactivate User
                  </MenuItem>
                )}
                {selectedUser?.deletedAt && (
                  <MenuItem onClick={() => handleActivateDeactivateClick(selectedUser)}>
                    Activate User
                  </MenuItem>
                )}
              </Menu>
            </Grid>
        )}
      </>
    );
  });
});
export default Users;
