import React from "react";

import {FormControl,
  FormControlLabel,FormLabel,Radio,RadioGroup as MuiRadioGroup} from '@mui/material/';
  
const RadioGroup = (props: any) => {
  const { name, label, value, onChange, options,style,disabled, ...other} = props;

  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row name={name} value={value} onChange={onChange} {...other}>
        {options.map((item: any) => (
          <FormControlLabel
            key={item.id}
            value={item.id}
            disabled={disabled}
            control={<Radio />}
            label={<FormLabel>{item.label}</FormLabel>}
            style={style}
          />
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};
export default RadioGroup;
