// import { useLocalObservable, useLocalStore } from 'mobx-react';
// import React, { createContext, ReactNode, useContext } from 'react';
// import { Configuration, AuthApi } from '../../api-client';
// import AuthStore from './authStore';


// interface AuthProviderProps {
//   children: ReactNode;
// }

// export const AuthContext = createContext<AuthStore | null>(null);

// export const AuthProvider = ({ children }: AuthProviderProps) => {
// const basePath=process.env.REACT_APP_API_URL

//   const apiClient = new AuthApi({} as Configuration,basePath);

//   // eslint-disable-next-line react-hooks/rules-of-hooks
//   const store = useLocalStore(
//     () => new AuthStore(apiClient)
//   );
//   store.apiClient = apiClient;

//   return (
//     <AuthContext.Provider value={store}>
//       {children}
//     </AuthContext.Provider>
//   );
  
// };
// export const useAuth = (): AuthStore => {
  
//     const authStore = useContext(AuthContext);
//     console.log('IOZZGBSGHDHJHDKJJK',authStore)
//     if (!authStore) {
//       throw new Error('AuthProvider not found');
//     }
//     return authStore;
//   };
import { useLocalObservable } from 'mobx-react';
import React, { createContext, ReactNode, useContext } from 'react';
import { Configuration, AuthApi, UserProfileApi } from '../../api-client';
import AuthStore from './authStore';

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthContext = createContext<AuthStore | null>(null);
AuthContext.displayName = 'AuthContext';

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const basePath = process.env.REACT_APP_API_URL;
  const apiClient = new AuthApi({} as Configuration, basePath);
  
  
  const userAPIClient = new UserProfileApi();
  
  const store = useLocalObservable(() => new AuthStore(apiClient));
  store.apiClient = apiClient;
  
  return (
    <AuthContext.Provider value={store}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthStore => {
  const authStore = useContext(AuthContext);

  if (!authStore) {
    throw new Error('AuthProvider not found');
  }

  return authStore;
};
