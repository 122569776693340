import { Chip, Grid,  SxProps, Theme, Typography } from "@mui/material";
import { ReactNode } from "react";

interface FlexListContainerProps {
    children: ReactNode; // ReactNode covers anything that can be rendered in React
    sx?: SxProps<Theme>; // SxProps type for theme-based styling
    [key: string]: any; // Allow any additional props
  }
  
export const FlexListContainer : React.FC<FlexListContainerProps>= ({ children, sx, ...props }) => {
    // Default styling combined with user-provided `sx` prop
    const defaultSx = {
      justifyContent: "space-between",
      alignItems: "center",
      columnSpacing: 2,
      ...sx // This allows users to override or extend the default styles
    };
  
    return (
      <Grid container rowSpacing="3px" sx={defaultSx} {...props}>
        {children}
      </Grid>
    );
  };

export const LabelItem = ({ label }: { label: string }) => {
  return (
    <Grid item xs={4} border={"0px solid black"}>
      <Typography fontSize={"0.9rem"} align="left" textOverflow="ellipsis">
        {label}:
      </Typography>
    </Grid>
  );
};

export const ValueItem = ({ value }: { value: any }) => {
  return (
    <Grid item xs={8}>
      <Chip label={value} sx={{ width: "100%", justifyContent: "space-between", height:"unset" }} />
    </Grid>
  );
};
