import React, { createContext, ReactNode } from "react";
import { TransactionsApi, Configuration } from "../../api-client";
import useStore from "../../helpers/useStore";
import { AuthContext } from "../Auth/auth.provider";
import { observer, useLocalStore } from "mobx-react";
import TransactionsStore from "./TransactionsStore";



interface TransactionsProviderProps{
    children: ReactNode;
}

export const TransactionsContext = createContext<TransactionsStore | null >(null);
TransactionsContext.displayName = "TransactionsContext"


export const TransactionsProvider = observer(({ children }: TransactionsProviderProps) => {
    const basePath = process.env.REACT_APP_API_URL
    const AuthenticationStore = useStore(AuthContext);
    const { authToken } = AuthenticationStore
  
  
    const config = new Configuration({
      basePath: basePath,
      accessToken: authToken || "",
    });
  
    console.log("User Provider has token:", authToken);
    const apiClient = new TransactionsApi(config);
    const store = useLocalStore(
        () => new TransactionsStore(apiClient,AuthenticationStore)
      )
  
  
    store.apiClient = apiClient;
    
    return (
      <TransactionsContext.Provider value={store}>{children}</TransactionsContext.Provider>
    );
  
  });