import { action, makeObservable, observable } from "mobx";
import AuthStore from "../Auth/authStore";
import { TransactionsApi, CreateTransactionDto, GetRechnungDetailsDto, BaseTransactionDetailsDto, GetCashDetailsDto } from "../../api-client";
import notificationStore from "../Notification/notificationStore";

export default class TransactionsStore {
    public apiClient: TransactionsApi;
    public authStore: AuthStore;


    public constructor(apiClient: TransactionsApi ,authStore: AuthStore) {
        this.apiClient = apiClient;
        this.authStore=authStore;
        makeObservable(this)
      }

    @observable
    public lastUpdate = new Date();
   

    @action
    public getAllTransactions = async ():Promise<BaseTransactionDetailsDto[]> => {
        const { data } = await this.apiClient.getAllTransactions() as any;
        return data || [];
    }


    @action
    public getRechnungTransactionById = async(id: string):Promise<GetRechnungDetailsDto> => {
        const {data} = await this.apiClient.getRechnungTransactionById(id);
        return data || [];
    }


    @action
    public createTransaction = async (creatDTO: CreateTransactionDto): Promise<BaseTransactionDetailsDto> => {
        try {
            const {data} = await this.apiClient.createTransaction(creatDTO);
            this.lastUpdate = new Date();
            notificationStore.showMessage("Transaction created successfully", "success");
            return data || [];

        } catch (error) {
            console.error('Error creating transaction:', error);
            notificationStore.showMessage("Error creating transaction", "error", error);
            throw error;
        }
    };

    @action
    public getRechnungTransactionsBetweenDates = async (startDate: string, endDate: string):Promise<GetRechnungDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getRechnungTransactionsBetweenDates(startDate, endDate) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    @action
    public getRechnungTransactionsWithSearch = async (startDate: string, endDate: string, searchString: string):Promise<GetRechnungDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getCashTransactionsWithSearch(startDate, endDate, searchString) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    @action
    public getCashTransactionsBetweenDates = async (startDate: string, endDate: string):Promise<GetCashDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getCashTransactionsBetweenDates(startDate, endDate) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    @action
    public getCashTransactionsWithSearch = async (startDate: string, endDate: string, searchString: string):Promise<GetCashDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getCashTransactionsWithSearch(startDate, endDate, searchString) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }


    @action
    public getEcCashTransactionsBetweenDates = async (startDate: string, endDate: string):Promise<BaseTransactionDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getEcCashTransactionsBetweenDates(startDate, endDate) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    @action
    public getEcCashTransactionsWithSearch = async (startDate: string, endDate: string, searchString: string):Promise<BaseTransactionDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getEcCashTransactionsWithSearch(startDate, endDate, searchString) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }


    @action
    public getLastSchriftTransactionsBetweenDates = async (startDate: string, endDate: string):Promise<BaseTransactionDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getLastSchriftTransactionsBetweenDates(startDate, endDate) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    @action
    public getLastSchriftTransactionsWithSearch = async (startDate: string, endDate: string, searchString: string):Promise<BaseTransactionDetailsDto[]> =>{
        try{
            const {data} = await this.apiClient.getLastSchriftTransactionsWithSearch(startDate, endDate, searchString) as any;
            return data || [];
        } catch(error) {
            throw error;
        }
    }

    
    
}