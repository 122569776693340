import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Divider,
  TableCell,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { observer } from "mobx-react";
import { PatientDto, RxSeriesDto, TherapyRxDto } from "../../../../api-client";
import { MoreVert } from "@mui/icons-material";
import { RxStoreContext } from "../../../../stores/Rx/rx.provider";
import useStore from "../../../../helpers/useStore";
import Controls from "../../../../atoms/Controls";
import WarningDialog from "../../../../atoms/WarningDialog";

interface RxSeriesRowViewProps {
  rowData: RxSeriesDto;
  patientData: PatientDto;
  setDataRefresh: any;
  dataRefreshFlag: boolean | null;
  setSeriesOpenView: any;
  setChangeInSeriesData: any;
  changeInSeriesData: boolean;
}

const RxSeriesRowView: React.FC<RxSeriesRowViewProps> = observer(
  ({
    rowData,
    patientData,
    setDataRefresh,
    dataRefreshFlag,
    setSeriesOpenView,
    setChangeInSeriesData,
    changeInSeriesData,
  }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [menu, setMenu] = useState(null);
    const RxStore = useStore(RxStoreContext);
    const { updateSeries, deleteSeries } = RxStore;

    const [renamedialogOpen, setRenameDialogOpen] = useState(false);
    const [newLabel, setNewLabel] = useState("");
    const [unsavedDeleteWarning, setShowDeleteWarning] = useState(false);

    const handleDeleteContinue = () => {
    handleDeleteClick();
    };

    const handleCloseDeleteWarning= () => {
      handleMenuClose();
      // If user cancels and chooses not to continue, close the warning dialog
      setShowDeleteWarning(false);
    };


    const handleMoreButtonClick = (event: any, rowData: any) => {
      setMenu(event.currentTarget);
    };

    const handleMenuClose = () => {
      setMenu(null);
    };

    const handleRenameClick = () => {
      setRenameDialogOpen(true);
      handleMenuClose();
    };

    const handleRenameDialogClose = () => {
      setRenameDialogOpen(false);
      setNewLabel("");
    };

    const handleNewLabelChange = (event: any) => {
      setNewLabel(event.target.value);
    };

    const handleRenameSubmit = async () => {
      const result = await updateSeries(
        rowData.id as string,
        patientData.id as number,
        {
          label: newLabel,
          therapyRxs: null,
        }
      );
      if (result) {
        // handleData(patientData)
        setRenameDialogOpen(false);
        setDataRefresh(!dataRefreshFlag);
        setChangeInSeriesData(!changeInSeriesData);
        // setSeriesSelectedRow(selectedSeriesRow)
        setNewLabel("");
      }
    };

    const handleDeleteClick = async () => {
      const result = await deleteSeries(rowData.id as any);

      // handleMenuClose();
      // setDataRefresh(!dataRefreshFlag)
      setDataRefresh(!dataRefreshFlag);
      setSeriesOpenView(false);
      setChangeInSeriesData(true);
    };

    const handleDialogOpen = (notes: any) => {
      setSelectedNotes(notes);
      setDialogOpen(true);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
      setSelectedNotes([]);
    };

    const truncateText = (text: string, maxLength: number) => {
      if (text && text?.length > maxLength) {
        return `${text.substring(0, maxLength)}...`;
      }
      return text;
    };
    console.log("INSIDE SERIE", rowData);
    return (
      <Card>
        <CardHeader
          title="Series"
          action={
            <>
              <IconButton
                onClick={(event: any) => handleMoreButtonClick(event, rowData)}
              >
                <MoreVert />
              </IconButton>
              <Menu
                anchorEl={menu}
                open={Boolean(menu)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleRenameClick}>
                  Serie umbenennen
                </MenuItem>
                <MenuItem onClick={()=>setShowDeleteWarning(true)}>Serie löschen</MenuItem>
              </Menu>
              {unsavedDeleteWarning && (
        <WarningDialog
          open={true} // Ensure the warning dialog is open
          onClose={handleCloseDeleteWarning} // Function to close the dialog
          onContinue={handleDeleteContinue} // Function to continue despite unsaved changes
          title="Sind Sie sicher, dass Sie die Serie löschen möchten?"
          content="Bitte klicken Sie auf Bestätigen, um Ihre Serie zu löschen"
          

        />
      )}
              <Dialog open={renamedialogOpen} onClose={handleRenameDialogClose}>
                <DialogTitle>Serie umbenennen</DialogTitle>
                <DialogContent dividers>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <div style={{ flex: "1" }}>
                      <Controls.Input
                        autoFocus
                        margin="dense"
                        label="Current"
                        type="text"
                        style={{
                          width: "200px",
                          borderBottom: "2px solid grey",
                        }}
                        value={rowData?.label}
                        disabled
                      />
                    </div>
                    <span>------</span>
                    <div style={{ flex: "1" }}>
                      <Controls.Input
                        margin="dense"
                        label="New Label"
                        type="text"
                        style={{
                          width: "200px",
                          borderBottom: "2px solid grey",
                        }}
                        value={newLabel}
                        onChange={handleNewLabelChange}
                      />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleRenameDialogClose} variant="text">
                    Abbrechen
                  </Button>
                  <Button onClick={handleRenameSubmit} variant="contained">
                    Speichern
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          }
        />
        <CardContent>
          <Table aria-label="series-rx-table">
            <TableHead>
              <TableRow>
                <TableCell>Serienetikett</TableCell>
                <TableCell>Rx</TableCell>
                <TableCell>ICD Codes</TableCell>
                <TableCell>Heilmittel</TableCell>
                <TableCell>Diagnosegruppe</TableCell>
                {/* <TableCell>Therapy Description</TableCell> */}
                {/* <TableCell>Amount</TableCell> */}
                <TableCell>Weitere med. Befunde</TableCell>
                <TableCell>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.therapyRxs?.map((therapyRx: any, index: any) => (
                <TableRow key={`${rowData.id}-${index}`}>
                  {index === 0 ? (
                    <TableCell rowSpan={rowData?.therapyRxs?.length}>
                      <Typography variant="subtitle1">
                        {rowData.label}
                      </Typography>
                    </TableCell>
                  ) : null}
                  <TableCell>{therapyRx.rx.rxNumber}</TableCell>
                  <TableCell>
                    {therapyRx.rx.icdCodeEntries.map(
                      (icdCode: any, idx: any) => (
                        <Typography key={idx}>{icdCode.text}</Typography>
                      )
                    )}
                  </TableCell>
                  <TableCell>
  {therapyRx.therapy.abbreviation}
  {therapyRx.isDoubledBy || therapyRx.isSupplementedBy ? 
    `${therapyRx.isDoubledBy ? `, ${therapyRx.isDoubledBy.therapy.abbreviation}` : ""}${therapyRx.isSupplementedBy ? `, ${therapyRx.isSupplementedBy.therapy.abbreviation}` : ""}` 
    : ""}
</TableCell>

                  <TableCell>
                    {therapyRx.rx?.diagnosisGroup?.id || "-"}
                  </TableCell>
                  {/* <TableCell>{therapyRx.therapy.description}</TableCell>
                <TableCell>{therapyRx.amount}</TableCell> */}
                  <TableCell
                    style={{
                      cursor: therapyRx.rx.additionalNotes
                        ? "pointer"
                        : "default",
                    }}
                    onClick={() => {
                      if (therapyRx.rx.additionalNotes) {
                        handleDialogOpen([
                          { text: therapyRx.rx.additionalNotes },
                        ]);
                      }
                    }}
                  >
                    {truncateText(therapyRx.rx?.additionalNotes, 20) || "-"}
                  </TableCell>
                  <TableCell
                    style={{
                      cursor:
                        therapyRx.rx.notes.length > 0 ? "pointer" : "default",
                    }}
                    onClick={() => {
                      if (therapyRx.rx.notes.length > 0) {
                        handleDialogOpen(therapyRx.rx.notes);
                      }
                    }}
                  >
                    {therapyRx.rx.notes.length > 0
                      ? truncateText(therapyRx.rx?.notes[0]?.text, 20)
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
              {/* Render an empty row with the label if there are no therapyRxs */}
              {!rowData?.therapyRxs || rowData?.therapyRxs.length === 0 ? (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {rowData?.label}
                    </Typography>
                  </TableCell>
                  <TableCell colSpan={6} align="center">
                    ... Keine TherapieRxs gefunden
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>

          {/* Dialog for displaying notes */}
          <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Notes Details</DialogTitle>
            <DialogContent dividers>
              {selectedNotes.map((note: any, index) => (
                <div key={index}>
                  <Typography variant="body1" gutterBottom>
                    <span style={{ fontWeight: "bold" }}>
                      Note {index + 1}:
                    </span>{" "}
                    {note.text}
                  </Typography>
                  <ul style={{ paddingLeft: "20px" }}>
                    {note.bulletPoints &&
                      note.bulletPoints.map((bullet: any, bulletIndex: any) => (
                        <li key={bulletIndex}>{bullet}</li>
                      ))}
                  </ul>
                </div>
              ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    );
  }
);

export default RxSeriesRowView;
