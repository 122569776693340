import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  IconButton,
  Typography,
  Grid,
  CardHeader,
  Tooltip,
  Avatar,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextArea from '../../../atoms/TextArea';
import { NotesContext } from '../../../stores/Notes/notes.provider';
import useStore from '../../../helpers/useStore';
import { NoteDto } from '../../../api-client';
import { UserContext } from '../../../stores/User/User.provider';
import { observer } from "mobx-react";

interface NotesProps {
  notes: NoteDto[];
  setNotes: (index: number, updatedCard: NoteDto) => void;
}

const Notes: React.FC<NotesProps> = observer(({ notes, setNotes }) => {
  const NotesStore = useStore(NotesContext);
  const { deleteNotebyId,fetchNotes,setFetchNotes } = NotesStore;
  const UserStore = useStore(UserContext);
  const { user } = UserStore;
  const [cards, setCards] = useState(notes);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [editedContent, setEditedContent] = useState('');

  useEffect(() => {
    setCards(notes);
  }, [notes]);

  const handleAddCard = () => {
    const newCard: NoteDto = {
      text: '',
    };
    setCards([...cards, newCard]);
  };

  const handleEditCard = (index: number) => {
    setEditingIndex(index);
    setEditedContent(cards[index].text || '');
  };

  const handleSaveCard = (index: number) => {
    const updatedCard = {
      ...cards[index],
      text: editedContent,
    };
    setNotes(index, updatedCard);
    setEditingIndex(null);
  };

  const handleCancelEdit = () => {
    setEditingIndex(null);
  };

  const handleDeleteCard = async (index: number) => {
    const noteToDelete = cards[index];
    const updatedCards = [...cards];
    updatedCards.splice(index, 1);
    setCards(updatedCards);
  
    await deleteNotebyId(noteToDelete.id as string);
    // if (setFetchNotes) {
    //   setFetchNotes(!fetchNotes);
    // }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader
            title="Notizen"
            action={
              <Tooltip title="Notizen hinzufügen">
                <IconButton onClick={handleAddCard}>
                  <AddIcon />
                </IconButton>
              </Tooltip>
            }
          />
        </Card>
      </Grid>
      {cards?.map((card, index) => (
        <Grid item xs={12} key={card.id || index}>
          <Card
            style={{
              border: '2px solid #007bff',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CardContent style={{ flex: 1 }}>
              {editingIndex === index ? (
                <TextArea
                  rows={6}
                  cols={100}
                  value={editedContent}
                  onChange={(e: any) => setEditedContent(e.target.value)}
                  style={{ width: '100%' }}
                />
              ) : (
                <Tooltip title={card.text || 'Bitte klicken Sie, um eine Notiz zu schreiben'}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    style={{ fontWeight: 'bold', cursor: 'pointer', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis',width:"250px" }}
                    onClick={() => handleEditCard(index)}
                  >
                    {card.text || 'Bitte klicken Sie, um eine Notiz zu schreiben'}
                  </Typography>
                </Tooltip>
              )}
            </CardContent>
            <div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto', padding: 8 }}>
              <Tooltip title={card.updatedByUser ? card.updatedByUser.lastName : card.createdByUser?.lastName}>
                <Avatar
                  sx={{ width: 40, height: 35, marginRight: '8px' }}
                >
                  {card.updatedByUser ? card.updatedByUser.nickName : card.createdByUser?.nickName}
                </Avatar>
              </Tooltip>
              {editingIndex === index ? (
                <>
                  <Tooltip title="Notiz speichern">
                    <IconButton onClick={() => handleSaveCard(index)}>
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bearbeiten abbrechen">
                    <IconButton onClick={handleCancelEdit}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                </>
              ) : (
                <>
                  <Tooltip title="Notiz bearbeiten">
                    <IconButton onClick={() => handleEditCard(index)}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Notiz löschen">
                    <IconButton onClick={() => handleDeleteCard(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
});

export default Notes;
