import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { observer } from "mobx-react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

interface ValidationCardRxInfoProps {
  patientData: any;
  selectedRx: any;
}

interface ValidationResult {
  label: string;
  validity: string;
  message: string;
}

const theme = createTheme({
  typography: {
    body2: {
      fontSize: "0.75rem", // Adjust the size as needed
    },
  },
});

const ValidationCardRxInfoPage: FC<ValidationCardRxInfoProps> = observer(
  ({ patientData, selectedRx }) => {
    const getPersonalValidationStatus = (result: ValidationResult) => {
      if (result.validity === "Valid") {
        return {
          icon: <CheckCircleIcon sx={{ color: "success.main" }} />,
          message: result.message,
        };
      } else if (result.validity === "Not Valid") {
        return { icon: <ErrorIcon sx={{ color: "error.main" }} />, message: result.message };
      } else {
        return { icon: <WarningIcon sx={{ color: "warning.main" }} />, message: result.message };
      }
    };

    if (!selectedRx.type?.id || selectedRx?.type.id !== "Kasse") {
      return (
        <Grid item xs={8} sm={4} sx={{ padding: 2, marginLeft: 8, marginTop: 1 }}>
          <Paper
            elevation={3}
            sx={{ borderRadius: 2, bgcolor: "#f8f4f4", color: "text.primary", padding: 2 }}
          >
            <List>
              <ListItem>
                <ListItemIcon>
                  <WarningIcon style={{ color: "orange" }} />
                </ListItemIcon>
                <ListItemText
                  sx={{ fontWeight: "bold" }}
                  primary="Diese Validierungskarte ist nur für den Rezept-typ Kasse verfügbar."
                />
              </ListItem>
            </List>
          </Paper>
        </Grid>
      );
    }

    const extractValidationResults = (validationObject: any) => {
      return Object.keys(validationObject).map((key) => ({
        label: validationObject[key].label,
        result: validationObject[key],
      }));
    };

    const patientValidationResults = patientData?.validationResults
      ? extractValidationResults(patientData.validationResults)
      : [];
    const rxValidationResults = selectedRx?.rxValidation
      ? extractValidationResults(selectedRx.rxValidation)
      : [];
    const validationResultsAll = [...patientValidationResults, ...rxValidationResults];
    const validationResults = validationResultsAll.filter(({ result }) => result);

    validationResults.sort((a, b) => {
      if (a.result.validity === "Not Valid") return -1;
      if (b.result.validity === "Not Valid") return 1;
      if (a.result.validity === "Partially Valid") return -1;
      if (b.result.validity === "Partially Valid") return 1;
      return 0;
    });

    return (
      <List sx={{ paddingTop: 1 }}>
        {validationResults.map(({ label, result }, index) => (
          <Tooltip title={result.message} placement="right" arrow>
            <ListItem dense sx={{ padding: "2px 1px" }} key={index}>
              <ListItemIcon sx={{ minWidth: "1px", marginRight: "1px" }}>
                {getPersonalValidationStatus(result).icon}
              </ListItemIcon>

              <ListItemText primary={label} primaryTypographyProps={{ variant: "body2" }} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    );
  }
);

export default ValidationCardRxInfoPage;
