import { WeeklyWorkDayDto, WorkPlanDto } from "../../../../api-client";
import { useEffect, useState } from "react";
import moment from "moment";
import useStore from "../../../../helpers/useStore";
import { UserContext } from "../../../../stores/User/User.provider";
import notificationStore from "../../../../stores/Notification/notificationStore";
import DataTable from "../../../../molecules/DataTable";
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import Controls from "../../../../atoms/Controls";
import DeleteConfirmationDialog from "./DeleteConfirmationDialog";
import { Delete, Edit, Add, Save, Clear, ChevronRight } from "@mui/icons-material";
import FlatCard from "../../../../atoms/FlatCard";

interface WeeklyWorkDaysProps {
  user: any;
  workPlanId?: any;
  key: any;
  onItemClick?: (workDay: WeeklyWorkDayDto) => void;
  onItemChange: () => void;
}

interface FormValues {
  dayOfWeek: number;
  startTime: string;
  endTime: string;
}

const WeeklyWorkDays: React.FC<WeeklyWorkDaysProps> = ({
  user,
  workPlanId,
  key,
  onItemClick,
  onItemChange,
}) => {
  const UserStore = useStore(UserContext);
  const {
    getWeeklyWorkDaysByWorkPlan,
    createWeeklyWorkDay,
    deleteWeeklyWorkDay,
    updateWeeklyWorkDay,
  } = UserStore;
  const [weeklyWorkDays, setWeeklyWorkDays] = useState<WeeklyWorkDayDto[]>([]);
  const [isAddingRow, setIsAddingRow] = useState(false);
  const [editingRowId, setEditingRowId] = useState<number | null>(null);
  const [formValues, setFormValues] = useState<FormValues>({
    dayOfWeek: 0,
    startTime: "",
    endTime: "",
  });
  const [selectedWeeklyWorkDay, setSelectedWeeklyWorkDay] = useState<WeeklyWorkDayDto>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const workdayColumns = [
    { id: "id", label: "Id", minWidth: 50 },
    { id: "dayOfWeek", label: "Wochentag", minWidth: 50 },
    { id: "startTime", label: "Begin", minWidth: 50 },
    { id: "endTime", label: "Ende", minWidth: 80 },
    { id: "type", label: "Typ", minWidth: 80 },
    {
      id: "actions",
      label: "Aktionen",
      minWidth: 50,
    },
  ];

  const daysOfWeek = [
    { label: "Montag", value: "1" },
    { label: "Dienstag", value: "2" },
    { label: "Mittwoch", value: "3" },
    { label: "Donnerstag", value: "4" },
    { label: "Freitag", value: "5" },
    { label: "Samstag", value: "6" },
    { label: "Sonntag", value: "7" },
  ];

  const fetchWeeklyWorkDays = async (
    workPlanId: number
  ): Promise<WeeklyWorkDayDto[] | undefined> => {
    try {
      if (!workPlanId) return;
      const weeklyWorkDays = await getWeeklyWorkDaysByWorkPlan(user.id, workPlanId);
      weeklyWorkDays.forEach((workDay: WeeklyWorkDayDto) => {
        workDay.startTime = moment(workDay.startTime).format("HH:mm");
        workDay.endTime = moment(workDay.endTime).format("HH:mm");
      });
      setWeeklyWorkDays(weeklyWorkDays);
      console.log("weeklyWorkDays", weeklyWorkDays);
      return weeklyWorkDays;
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("error", "Fehler beim Abrufen der Arbeitszeiten", error);
    }
  };

  useEffect(() => {
    fetchWeeklyWorkDays(workPlanId);
  }, [workPlanId, key]);

  const handleRowClick = async (workDay: WeeklyWorkDayDto) => {
    setSelectedWeeklyWorkDay(workDay);
    console.log("workPlan clicked", workDay);
    return onItemClick && onItemClick(workDay);
  };

  const addNewRow = () => {
    setIsAddingRow(true);
    // setWeeklyWorkDays([...weeklyWorkDays, {
    //   id: "",
    //   dayOfWeek: 1,
    //   startTime: "",
    //   endTime: "",
    // }]);
  };

  const handleAddWeeklyWorkDay = async () => {
    try {
      const weeklyWorkDay: WeeklyWorkDayDto = {
        workPlan: { id: workPlanId },
        dayOfWeek: formValues.dayOfWeek,
        startTime: formValues.startTime,
        endTime: formValues.endTime,
        employeeId: user.id,
      };
      const newWeeklyWorkDay = await createWeeklyWorkDay(weeklyWorkDay);
      if (newWeeklyWorkDay) {
        notificationStore.showMessage("Arbeitszeiten erfolgreich hinzugefügt", "success");
        newWeeklyWorkDay.startTime = moment(newWeeklyWorkDay.startTime).format("HH:mm");
        newWeeklyWorkDay.endTime = moment(newWeeklyWorkDay.endTime).format("HH:mm");
        setWeeklyWorkDays([...weeklyWorkDays, newWeeklyWorkDay]);
        setIsAddingRow(false);
        setFormValues({ dayOfWeek: 1, startTime: "", endTime: "" });
        return onItemChange();
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("Fehler beim Hinzufügen der Arbeitszeiten", "error", error);
    }
  };

  const handleDeleteRow = () => {
    setIsDialogOpen(true);
  };

  const handleEditClick = (id: number, weeklyWorkDay: WeeklyWorkDayDto) => {
    setEditingRowId(id);
    const values = {
      dayOfWeek: weeklyWorkDay.dayOfWeek!,
      startTime: weeklyWorkDay.startTime!,
      endTime: weeklyWorkDay.endTime!,
    };
    setFormValues(values);
  };

  const handleCancelClick = () => {
    // Reset editing state
    setIsAddingRow(false);
    setEditingRowId(null);
    setFormValues({ dayOfWeek: 1, startTime: "", endTime: "" });
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleConfirmDelete = async (id: number) => {
    try {
      const deletedWorkPlan = await deleteWeeklyWorkDay(id);
      if (deletedWorkPlan) {
        const updatedWorkDays = weeklyWorkDays.filter(
          (workDay: WeeklyWorkDayDto) => workDay.id !== id
        );
        setWeeklyWorkDays(updatedWorkDays);
        setIsAddingRow(false);
        return onItemChange();
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("error", "Fehler beim Löschen der Arbeitszeiten", error);
    } finally {
      setIsDialogOpen(false);
    }
  };

  const handleUpdateWeeklyWorkDay = async () => {
    try {
      const today = new Date();
      const startTimeHour = parseInt(formValues.startTime.split(":")[0]);
      const startTimeMinute = parseInt(formValues.startTime.split(":")[1]);
      const endTimeHour = parseInt(formValues.endTime.split(":")[0]);
      const endTimeMinute = parseInt(formValues.endTime.split(":")[1]);
      const startTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        startTimeHour,
        startTimeMinute
      );
      const endTime = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        endTimeHour,
        endTimeMinute
      );

      const updatedWeeklyWorkDay: WeeklyWorkDayDto = {
        id: editingRowId!,
        dayOfWeek: formValues.dayOfWeek,
        startTime: startTime.toISOString(),
        endTime: endTime.toISOString(),
        employeeId: user.id,
      };

      console.log("updatedWeeklyWorkDay", updatedWeeklyWorkDay);

      const updatedWorkDay = await updateWeeklyWorkDay(updatedWeeklyWorkDay);
      if (updatedWorkDay) {
        console.log("updatedWorkDay", updatedWorkDay);
        notificationStore.showMessage("success", "Arbeitszeiten erfolgreich aktualisiert");
        updatedWorkDay.startTime = moment(updatedWorkDay.startTime).format("HH:mm");
        updatedWorkDay.endTime = moment(updatedWorkDay.endTime).format("HH:mm");
        const updatedWorkDays = weeklyWorkDays.map((workDay: WeeklyWorkDayDto) =>
          workDay.id === editingRowId ? updatedWorkDay : workDay
        );
        setWeeklyWorkDays(updatedWorkDays);
        setEditingRowId(null);
        return onItemChange();
      }
    } catch (error) {
      console.error(error);
      notificationStore.showMessage("error", "Fehler beim Aktualisieren der Arbeitszeiten", error);
    }
  };

  return (
    // <DataTable
    //   columns={workdayColumns}
    //   data={weeklyWorkDays}
    //   onRowClick={() => {}}
    //   onAddClick={() => {}}
    // />
    <>
      <FlatCard title="Arbeitstag">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {workdayColumns.map((column) => (
                  <TableCell key={column.id}>{column.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {weeklyWorkDays.map((workDay: WeeklyWorkDayDto, index: number) => (
                <TableRow
                  key={workDay.id}
                  hover
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(workDay)}
                  selected={selectedWeeklyWorkDay?.id === workDay.id}
                >
                  <TableCell>{workDay.id}</TableCell>
                  {editingRowId === workDay.id ? (
                    <>
                      <TableCell>
                        <Controls.Select
                          label="Wochentag"
                          value={formValues.dayOfWeek.toString()}
                          onChange={(e: any) =>
                            setFormValues({ ...formValues, dayOfWeek: e.target.value })
                          }
                          showOptionLabel
                          options={daysOfWeek}
                          style={{ width: "150px" }} // Adjust the width as needed
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          ampm={false}
                          label="ab"
                          minTime={new Date(0, 0, 0, 7)}
                          maxTime={new Date(0, 0, 0, 20)}
                          defaultValue={new Date().setHours(
                            parseInt(workDay.startTime?.split(":")[0]!),
                            parseInt(workDay.startTime?.split(":")[1]!)
                          )}
                          onChange={(date: any) =>
                            setFormValues({
                              ...formValues,
                              startTime:
                                date.getHours().toString().padStart(2, "0") +
                                ":" +
                                date.getMinutes().toString().padStart(2, "0"),
                            })
                          }
                          sx={{ width: "150px" }}
                        />
                      </TableCell>
                      <TableCell>
                        <TimePicker
                          ampm={false}
                          label="bis"
                          minTime={new Date(0, 0, 0, 7)}
                          maxTime={new Date(0, 0, 0, 20)}
                          defaultValue={new Date().setHours(
                            parseInt(workDay.endTime?.split(":")[0]!),
                            parseInt(workDay.endTime?.split(":")[1]!)
                          )}
                          onChange={(date: any) =>
                            setFormValues({
                              ...formValues,
                              endTime:
                                date.getHours().toString().padStart(2, "0") +
                                ":" +
                                date.getMinutes().toString().padStart(2, "0"),
                            })
                          }
                          sx={{ width: "150px" }}
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>
                        {
                          daysOfWeek.find((day) => day.value === workDay.dayOfWeek?.toString())
                            ?.label
                        }
                      </TableCell>
                      <TableCell>{workDay.startTime}</TableCell>
                      <TableCell>{workDay.endTime}</TableCell>
                      <TableCell>{workDay.isBreak ? "Pause" : "Arbeitszeit"}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {editingRowId === workDay.id ? (
                      <Grid container spacing={2}>
                        <Grid item>
                          <Button onClick={handleUpdateWeeklyWorkDay}>
                            <Save />
                            Speichern
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={handleCancelClick}>
                            <Clear />
                            Abbrechen
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        {/* <Grid item>
                    <Button onClick={() => {}}>Wählen</Button>
                  </Grid> */}
                        <Grid item>
                          <Button onClick={() => handleEditClick(workDay.id!, workDay)}>
                            <Edit />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button onClick={handleDeleteRow}>
                            <Delete />
                          </Button>
                        </Grid>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {/* New Row for Adding */}
              {isAddingRow && (
                <TableRow>
                  <TableCell>*</TableCell>
                  <TableCell>
                    <Controls.Select
                      label="Wochentag"
                      value={formValues.dayOfWeek}
                      onChange={(e: any) =>
                        setFormValues({ ...formValues, dayOfWeek: e.target.value })
                      }
                      showOptionLabel
                      options={daysOfWeek}
                      style={{ width: "150px" }} // Adjust the width as needed
                    />
                  </TableCell>
                  <TableCell>
                    <TimePicker
                      ampm={false}
                      label="ab"
                      minTime={new Date(0, 0, 0, 7)}
                      maxTime={new Date(0, 0, 0, 20)}
                      onChange={(date: any) => setFormValues({ ...formValues, startTime: date })}
                      sx={{ width: "150px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <TimePicker
                      ampm={false}
                      label="bis"
                      minTime={new Date(0, 0, 0, 7)}
                      maxTime={new Date(0, 0, 0, 20)}
                      onChange={(date: any) => setFormValues({ ...formValues, endTime: date })}
                      sx={{ width: "150px" }}
                    />
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Button onClick={handleAddWeeklyWorkDay}>
                          <Save />
                          Speichern
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button variant="outlined" onClick={handleCancelClick}>
                          <Clear />
                          Abbrechen
                        </Button>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* Button for Adding New Row */}
        {!isAddingRow && (
          <Button onClick={addNewRow} sx={{ mt: 2 }}>
            <Add />
            Neu
          </Button>
        )}
      </FlatCard>
      <DeleteConfirmationDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        id={selectedWeeklyWorkDay?.id!}
      />
    </>
  );
};

export default WeeklyWorkDays;
