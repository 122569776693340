import React, { useEffect, useState } from 'react';
import { Drawer, Typography, IconButton, Divider, Grid, Tab, Box } from "@mui/material";
import { AppointmentDto, PatientDto, TimeSlotDto } from '../../api-client';
import PatientOverview from '../../components/PatientOverview/PatientOverview';
import { DrawerHeader } from '../../components/Appbar';
import CloseIcon from '@mui/icons-material/Close';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { mobiliTheme } from '../../themes/mobiliTheme';
import TerminView from '../../components/PatientOverview/RxInfo/TerminView';
import { TerminViewConstants } from '../../components/PatientOverview/TerminView';
import { TherapyFrequencyExt } from './Calendar.type';
import { observer } from 'mobx-react';

interface CalendarDrawerProps {
  open: boolean;
  patient: PatientDto;
  appointments: AppointmentDto[];
  drawerWidth: number;
  selectedTab?: string;
  onClose: () => void;
}


const CalendarDrawer: React.FC<CalendarDrawerProps> = observer(({ open, patient, appointments, onClose, drawerWidth, selectedTab }) => {

  const [tabValue, setTabValue] = useState('');

  useEffect(() => {
    if (appointments.length > 0) {
      setTabValue(selectedTab ?? appointments[0].timeSlots![0].therapyRx?.rx?.id!);
    }
  }
  , [appointments]);

  const groupTimeSlotsByRx = (appointments: AppointmentDto[]) => {
    const groupedTimeslots: Record<string, TimeSlotDto[]> = {};

    appointments.forEach(appointment => {
      appointment.timeSlots?.forEach(timeSlot => {
        const key = timeSlot.therapyRx?.rx?.id!;
        if (!groupedTimeslots[key]) {
          groupedTimeslots[key] = [];
        }
        groupedTimeslots[key].push(timeSlot);
      });
    });
    
    console.log('Grouped Timeslots:', groupedTimeslots);
    return groupedTimeslots;
  };

  const groupedTimeslots = groupTimeSlotsByRx(appointments);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  }

  return (
    <Drawer 
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: `${mobiliTheme.topLevelBackground}`,
          marginLeft: 5, // 40px is the width of the side menu
          marginTop: 8, // 64px is the height of the appbar
        },
      }}
      variant="persistent"
    >
      <Grid padding={1} >
        {patient && (
          <Typography>
            <DrawerHeader sx={{minHeight: '0 !important'}}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </DrawerHeader>
            <PatientOverview 
              patientData={patient} 
              onRowClick={() => {}} 
              setPatientData={() => {}} 
              handleData={() => {}}
            />
            <Box marginTop={1} marginBottom={8}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList onChange={handleChange} variant='scrollable' scrollButtons='auto'>
                    {groupedTimeslots && Object.keys(groupedTimeslots).map((key, index) => (
                      <Tab value={key} key={index} label={`${groupedTimeslots[key][0].therapyRx?.rx?.rxNumber} (${(groupedTimeslots[key][0].therapyRx?.frequency as TherapyFrequencyExt)?.text}x)`} />
                    ))}
                  </TabList>
                </Box>
                {groupedTimeslots && Object.keys(groupedTimeslots).map((key, index) => (
                  <TabPanel value={key} sx={{ backgroundColor: mobiliTheme.cardBackground }}>
                    <TerminView
                      appointments={appointments.filter(app => app.timeSlots?.find(slot => slot.therapyRx?.rx?.id === key))}
                      setDataRefreshFlag={undefined}
                      dataRefreshFlag={undefined}
                      patientId={patient.id}
                      blockedData={[]}
                      setblockedData={undefined}
                      patientAvailabilityData={[]}
                      setPatientAvailability={undefined}
                      isView={TerminViewConstants.TERMIN_VIEW}
                    />
                  </TabPanel>
                ))}
              </TabContext>
            </Box>
            <Divider />
          </Typography>
        )}
      </Grid>
    </Drawer>
  );
})

export default CalendarDrawer;
