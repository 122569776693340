// SnackbarComponent.js
import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import notificationStore from '../stores/Notification/notificationStore';
import { observer } from 'mobx-react';
import { Typography, IconButton, Collapse, styled, Box } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

const SnackbarComponent = observer(() => {
  const { message, type, error, stackTrace, statusCode, timestamp } = notificationStore;
  const [expanded, setExpanded] = useState(false);
  const handleClose = () => {
    notificationStore.showMessage("", ""); // Clear the message when the Snackbar is closed
  };

  const getSeverity = () => {
    // Map notification types to MuiAlert severity
    switch (type) {
      case 'success':
        return { severity: 'success', color: 'success' }; // Green color for success
      case 'error':
        return { severity: 'error', color: 'error' }; // Red color for error
      case 'warning':
        return { severity: 'warning', color: 'warning' }; // Orange color for warning
      case 'info':
        return { severity: 'info', color: 'info' }; // Blue color for info (default)
      default:
        return { severity: '', color: '' }; // Default to no severity and no background color
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { severity, color } = getSeverity();
  console.log("severity",severity,message);

  return (
    <Snackbar
      open={!!message} // Check if there is a message to display
      autoHideDuration={severity === 'error' ? null : 3000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={severity as any} // Use the determined severity based on the type
        style={{ backgroundColor: color }} // Set the background color
      >
      <Typography>
        {message} &nbsp;
        {error && <>
          <Button
            variant='outlined'
            color='inherit'
            aria-expanded={expanded}
            aria-label="show more"
            onClick={handleExpandClick}
          >
            Details
          </Button>
          <Collapse in={expanded}>
            <Typography>
              Status Code: {statusCode} <br />
              DateTime: {timestamp} <br />
              {stackTrace}
            </Typography>
          </Collapse>
        </>}
      </Typography>

      </MuiAlert>
    </Snackbar>
  );
});

export default SnackbarComponent;
