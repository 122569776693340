import React, { useState, useEffect, useRef } from "react";
import { CalendarMonth, ReceiptLong, ImportContacts, Receipt, EditOutlined } from "@mui/icons-material";
import { Box, Button, Card, Grid, Tab, Tabs, Toolbar } from "@mui/material";
import { PatientsContext, PatientsProvider } from "../../../stores/Patients/patients.provider";
import PatientPage from "../../PatientDataEntryForm/PatientPage";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import useStore from "../../../helpers/useStore";
import RxOverView from "../../PatientOverview/RxInfo/RxOverView";
import RxPage from "./../../RxPage";
import { useObserver, observer } from "mobx-react";
import ScheduleOverview from "./../../ScheduleOverview/ScheduleOverview";
import PatientOverview from "./../../PatientOverview/PatientOverview";
import { PatientDto } from "../../../api-client";
import { RxStoreProvider } from "../../../stores/Rx/rx.provider";
import { DoctorProvider } from "../../../stores/Doctor/doctor.provider";
import { AppointmentProvider } from "../../../stores/Appointment/appointment.provider";
import { NotesProvider } from "../../../stores/Notes/notes.provider";

interface PatientControlsProps {
  patientId: number;
  selectedRxId: string;
  rxNumber: string;
}

const PatientControls = observer(({ patientId, selectedRxId, rxNumber }: PatientControlsProps) => {
  const [state, setState] = useState({
    value: 0,
    tab: "new-person",
  });

  const { getPatient ,searchPatient} = useStore(PatientsContext);

  const [selectedSubTab, setSelectedSubTab] = useState<number>(1);
  const [selectedRow, setSelectedRow] = useState<any | null>({ id: selectedRxId, rxNumber: rxNumber });
  const [patient, setPatientData] = useState<PatientDto|null>(null);
  const { masterTablesData } = useStore(MasterTablesContext);
  console.log("masterinrx", masterTablesData);

  const fetchData = async (patient: any) => {
    console.log("FETCHDATA back in patient controls", patient);
    try {
      const fetchedData = await getPatient(patientId);
      const response = await searchPatient({id:patientId});
      if (!response) {
        throw new Error(`No data found for patient ID ${patientId}`);
      }

      const formattedValues = {
        ...response,
        payer: response.payer,
        smsDesired: response?.smsDesired,
        rxs:response.rxs?.filter((rx:any)=>rx.deletedAt==null)
        // Set other properties from the response object as needed
      };

      // Update the patient data for the specific patient
      setPatientData(formattedValues);

      
      console.log("patient loaded", fetchedData);

    } catch (error) {
      console.error(`Error fetching data for patient ${patient.id}:`, error);
    }
  };

  // use effect to fetch data when loading component
  useEffect(() => {
    fetchData(patient);
  }, []);


  const updatePatientData = (updatedData: any) => {
    setPatientData(updatedData);
  };

  const [patientSubTabs, setPatientSubTabs] = useState<Array<any>>([]);

  const openPatientSubTab = (
    patientIndex: number,
    rowData: any // Pass additional data from handleRowClick
  ) => {
    setPatientSubTabs((prevTabs) => {
      const updatedSubTabs = [...prevTabs];
      if (!updatedSubTabs[patientIndex]) {
        updatedSubTabs[patientIndex] = [];
      }

      // Check if a sub-tab already exists for the patient
      if (updatedSubTabs[patientIndex].length === 0) {
        updatedSubTabs[patientIndex].push(rowData);
      } else {
        // If a sub-tab already exists, update its content with the received data
        updatedSubTabs[patientIndex][0] = rowData;
      }

      return updatedSubTabs;
    });
  };

  // Function to handle row click in the "Rx Overview" table

  const handleRowClick = (data: any) => {
    console.log("row was clicked", data);
      setSelectedSubTab(3); // Set the selected sub-tab to "Rx Overview"
      setSelectedRow(data);
  };

  const handleSubTabChange = (event: any, newValue: number) => {
    setSelectedSubTab(newValue);
  };

  return patient ? (
    <RxStoreProvider>
      <DoctorProvider>
        <AppointmentProvider>
          <NotesProvider>
            <Grid container spacing={0} sx={{ marginTop: "20px !important" }}>
            <Grid item xs={9} sm={9}>
<Card sx={{border:"2px splid #ccc",minHeight:"100vh",maxWidth:"100%"}}>
<Tabs
                  orientation="horizontal" // Display tabs vertically
                  value={selectedSubTab}
                  onChange={handleSubTabChange}
                  aria-label="sub-tabs"
                 
                >
                  <Tab
                       iconPosition="start" 
                    label={
                      <span
                        style={{
                          color: "black",
                          fontWeight: selectedSubTab === 1 ? "bold" : "normal",
                          fontSize: "12px",
                        }}
                      >
                       Rezepte
                      </span>
                    }
                    // component={Link}
                    // to={onSubTabClick(patientData[index].id,1)}
                    
                    icon={
                      <ImportContacts
                        sx={{
                          fontWeight: selectedSubTab === 1? "bold" : "normal",
                        }}
                      />
                    }
                    value={1}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      // backgroundColor:
                      //   selectedSubTab === 0 ? "primary.light" : "secondary.dark",
                      borderBottom: "2px solid secondary.dark",
                      borderRadius: "10px", 
                      // borderTopLeftRadius: "10px", // Apply border radius to top-left corner
                      // borderBottomLeftRadius: "10px", // Apply border radius to top-right corner
                      margin: "6px",
                    }}
                  />
                     <Tab
                    label="Termine"
                    // component={Link}
                    // to={onSubTabClick(patientData[index].id,2)}
                    icon={
                      <CalendarMonth
                        sx={{
                          fontWeight: selectedSubTab === 2 ? "bold" : "normal",
                        }}
                      />
                    }
                    value={2}
                    sx={{
                      // backgroundColor:
                      //   selectedSubTab === 2 ? "secondary.light" : "secondary.dark",
                      // borderBottom: "2px solid #7c4dff",
                      // borderTopLeftRadius: "10px", // Apply border radius to top-left corner
                      // borderTopRightRadius: "10px", // Apply border radius to top-right corner
                      margin: "6px",
                    }}
                  />
                      <Tab
                    label="Bill"
                    // component={Link}
                    // to={onSubTabClick(patientData[index].id,3)}
                    icon={
                      <CalendarMonth
                        sx={{
                          fontWeight: selectedSubTab === 3 ? "bold" : "normal",
                        }}
                      />
                    }
                    value={3}
                    sx={{
                      // backgroundColor:
                      //   selectedSubTab === 2 ? "secondary.light" : "secondary.dark",
                      // borderBottom: "2px solid #7c4dff",
                      // borderTopLeftRadius: "10px", // Apply border radius to top-left corner
                      // borderTopRightRadius: "10px", // Apply border radius to top-right corner
                      margin: "6px",
                    }}
                  />
               
               <Grid></Grid>
              
                </Tabs>
                    {(() => {
                      switch (selectedSubTab) {
                        case 1:
                          console.log("ABCe", patient);
                          return (
                            <RxOverView
                              patientData={patient}
                              openPatientTab={(row: any) => handleRowClick(row)}
                              setPatientData={setPatientData}
                              handleData={fetchData}
                              masterData={masterTablesData}
                              noNavigate />);
                         
        
                              case 2:
                                return (
                                  <ScheduleOverview
                                    masterData={masterTablesData}
                                    patientData={patient.id} />
                                );

                        case 3:
                          return;
                      
                        // case 4:
                        //   console.log("ABCe", patient,index,patientData,patientData[index]);
                        //   return (
                        //     <RxOverView
                        //      patientData={patientData[index]}
                        //       openPatientTab={(row: any) => handleRowClick(row)}
                        //       setPatientData={(row: any) =>
                        //         setPatientsData(row)
                        //       }
                        //       handleData={fetchData}
                        //     />
                        //   );
                    
                         
                      }
                    })()}
                    </Card>
                    </Grid>
            </Grid>
          </NotesProvider>
        </AppointmentProvider>
      </DoctorProvider>
    </RxStoreProvider>
  ) : (
    <div>Loading...</div>
  );
});

export default PatientControls;
