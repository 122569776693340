import Input from "./Input";
import RadioGroup from "./RadioGroup";
import Select from "./Select";
import Checkbox from "./Checkbox";
// import DatePicker from "./DatePicker";
import Button from "./Button";
import DatePicker from "./DatePicker";
import TextArea from "./TextArea";
// import MultiCheckboxGroup from "./MultiCheckBoxGroup";
import DateRangePicker from "./DateRangePicker";
import { Chip } from "@mui/material";


const Controls = {
    Input,
    RadioGroup,
    TextArea,
    Select,
    Checkbox,
    DatePicker,
    DateRangePicker,
    Chip,
    // MultiCheckboxGroup,
    Button

}

export default Controls;