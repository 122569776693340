import { ItemsContext } from "../../stores/Items.provider";
import { useEffect, useState } from "react";
import { useObserver } from "mobx-react";
import useStore from "../../helpers/useStore";
import {Box} from "@mui/material";
import { SystemInfo } from "../SystemInfo";
import React from "react";

const ItemMenu = () => {
  const ItemsStore = useStore(ItemsContext);
  const { getAllItems } = ItemsStore;
  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllItems();

        setItems(response);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  return useObserver(() => {
    
    //console.log("UserStr in Items: ", myUserStr);
    return (
      <>
          <Box
            display="flex"
            justifyContent="center"
            minHeight="100vh"
          >
            <SystemInfo />
          </Box>
      </>
    );
  });
};

export default ItemMenu;
