import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Controls from "../../../atoms/Controls";
import { observer } from "mobx-react";
import useStore from "../../../helpers/useStore";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { UserContext } from "../../../stores/User/User.provider";
import { UserDto } from "../../../api-client";
import notificationStore from "../../../stores/Notification/notificationStore";

interface BasicUserInfoProps {
  userData: any;
  onSelectUser: (user: any) => void;
}

const BasicUserInfo: React.FC<BasicUserInfoProps> = observer(({ userData,onSelectUser }) => {
  const MasterTablesStore = useStore(MasterTablesContext);
  const { masterTablesData } = MasterTablesStore;

  console.log("masterTablesData roles", masterTablesData.length);
  const [loading, setLoading] = useState(false);

  const UserStore = useStore(UserContext);
  const { saveUserInfo,getAllUsers } = UserStore;

  const locationsOptions = masterTablesData
    .filter((obj: any) => "location" in obj)
    .map((obj: any) => obj.location);
  const rolesOptions = masterTablesData
    .filter((obj: any) => "role" in obj)
    .map((obj: any) => obj.role);
  const initialFormValues: UserDto = {
    id: userData.id,
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    nickName: userData.nickName || "",
    email: userData.email || "",
    location:  userData.location?.id || userData.location,
    therapistLocation: userData.therapistLocation?.id || userData.therapistLocation ,
    roles: userData.roles?.map((role: any) => role.id) || [],
  };
  const [formState, setFormState] = useState<UserDto>(initialFormValues);

  const handleInputChange = (fieldName: string, value: any) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      [fieldName]: value,
    }));
  };

  const handleCheckboxChange = (role: any) => {
    const { roles } = formState;
    const updatedRoles = roles
      ? roles.includes(role.id)
        ? roles.filter((selectedRole: any) => selectedRole !== role.id)
        : [...roles, role.id]
      : [role.id];

    setFormState((prevFormState) => ({
      ...prevFormState,
      roles: updatedRoles,
    }));
  };
  const [allUsersData,setAllUsersData]=useState<UserDto[]>([])
  useEffect(() => {
    const fetchData = async () => {
      const response=await getAllUsers();
      setAllUsersData(response);
    };
    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      console.log("formState", formState);

      setLoading(true);
      const existingUserWithUserId = allUsersData.find(
        (user) =>
          formState.email &&
          user.email?.toLowerCase() === formState.email?.toLowerCase() &&
          user.id !== formState.id
      );
      // Check if any user already has the same nickName
      const existingUserWithNickName = allUsersData.find(
        (user) =>
          formState.nickName &&
          formState.nickName &&
          user.nickName?.toLowerCase() === formState.nickName?.toLowerCase() &&
          user.id !== formState.id
      );

      if (existingUserWithNickName) {
        // Display a warning for the same nickName
        notificationStore.showMessage("Kürzel bereits vorhanden", "warning");
        return;
      }

      if (existingUserWithUserId) {
        // Display a warning for the same userId
        notificationStore.showMessage(
          "Benutzer-ID/Mail existiert bereits",
          "warning"
        );
        return;
      } else {
        const locationData = locationsOptions[0]?.find(
          (location: any) => location.id.toString() === formState.location
        );
        const rolesData = rolesOptions[0]?.filter((role: any) =>
          formState.roles?.includes(role.id)
        );

        // Create a complete user object with location and roles details
        const completeUserObject = {
          ...formState,
          location: locationData,
          roles: rolesData,
        };

        console.log("Saved User Data:", completeUserObject);
        const response = await saveUserInfo(completeUserObject);

        // Call onSelectUser with the filtered user
        onSelectUser(response);
      }
    } catch (error) {
      console.error("Error saving user data:", error);
    } finally {
      // Set loading back to false when the API call is complete
      setLoading(false);
    }
    //    onSelectUser(response)
  };

  const handleCancel = () => {
    // Implement cancel logic if needed
    setFormState(initialFormValues);
    console.log("Cancelled");
  };
  const isFormChanged = () => {
    // Compare formState with initialFormValues to check for changes
    return !(
      formState.firstName === initialFormValues.firstName &&
      formState.lastName === initialFormValues.lastName &&
      formState.nickName === initialFormValues.nickName &&
      formState.email === initialFormValues.email &&
      formState.location === initialFormValues.location &&
      formState.therapistLocation === initialFormValues.therapistLocation &&
      JSON.stringify(formState.roles) === JSON.stringify(initialFormValues.roles)
    );
  };
  return (
    <Box>
      <Typography variant="h5" sx={{ marginBottom: "20px" }}>
        Kontaktdaten und Rollen
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={20} md={4}>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              label="Vorname"
              value={formState.firstName}
              onChange={(e: any) =>
                handleInputChange("firstName", e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              label="Nachname"
              value={formState.lastName}
              onChange={(e: any) =>
                handleInputChange("lastName", e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              label="Kürzel"
              value={formState.nickName}
              onChange={(e: any) =>
                handleInputChange("nickName", e.target.value)
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Input
              label="Mail"
              value={formState.email}
              onChange={(e: any) => handleInputChange("email", e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Select
              label="Standort"
              multiple
              value={formState.location}
              onChange={(e: any) =>
                handleInputChange("location", e.target.value)
              }
              options={locationsOptions[0]?.map((option: any) => ({
                label: option.id.toString(),
                value: option.id.toString(),
              }))}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controls.Select
              label="Einsatzort"
              multiple
              value={formState.therapistLocation}
              onChange={(e: any) =>
                handleInputChange("therapistLocation", e.target.value)
              }
              options={locationsOptions[0]?.map((option: any) => ({
                label: option.id.toString(),
                value: option.id.toString(),
              }))}
            />
          </Grid>
        </Grid>
        <Grid item xs={10} sm={8}>
          <FormControl component="fieldset">
            <Typography variant="h6" align="left">
              Roles
            </Typography>
            <FormGroup>
              {rolesOptions[0].map((role: any) => (
                <FormControlLabel
                  key={role.id}
                  control={
                    <Checkbox
                      checked={formState.roles?.includes(role.id)}
                      onChange={() => handleCheckboxChange(role)}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      {role.name} (
                      <span style={{ fontSize: "0.8rem" }}>
                        {role.description}
                      </span>
                      )
                    </Typography>
                  }
                />
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
    
        <Grid item xs={12} sm={8}display="flex" justifyContent="flex-end">
          {/* Save button */}
          <Button color="primary" onClick={handleSave} disabled={loading || !isFormChanged()}>
            Speichern
          </Button>
        </Grid>
        {/* <Grid item xs={12} sm={8}>          
          <Button variant="contained" onClick={handleCancel}>
            Reset
          </Button>
        </Grid> */}
      </Grid>
      <Modal
        open={loading}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <CircularProgress size={60} />
        </div>
      </Modal>
    </Box>
  );
});

export default BasicUserInfo;
