import { Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from "@mui/material";
import { FC } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { observer } from "mobx-react";

interface ValidationCardProps {
  patientData: any;
  selectedRx: any;
}

interface ValidationResult {
  label: string;
  validity: string;
  message: string;
}

const ValidationCard: FC<ValidationCardProps> = observer(({ patientData, selectedRx }) => {
  const getPersonalValidationStatus = (result: ValidationResult) => {
    if (result.validity === "Valid") {
      return { icon: <CheckCircleIcon sx={{ color: "success.main" }} />, message: result.message };
    } else if (result.validity === "Not Valid") {
      return { icon: <ErrorIcon sx={{ color: "error.main" }} />, message: result.message };
    } else {
      return { icon: <WarningIcon sx={{ color: "warning.main" }} />, message: result.message };
    }
  };

  if (!selectedRx.type?.id || selectedRx?.type.id !== "Kasse") {
    return (
      
          <List>
            <ListItem>
              <ListItemIcon>
                <WarningIcon style={{ color: "orange" }} />
              </ListItemIcon>
              <ListItemText
                sx={{ fontWeight: "bold" }}
                primary="Diese Validierungskarte ist nur für den Rezept-typ Kasse verfügbar."
              />
            </ListItem>
          </List>
      
    );
  }

  const extractValidationResults = (validationObject: any) => {
    return Object.keys(validationObject).map((key) => ({
      label: validationObject[key].label,
      result: validationObject[key],
    }));
  };

  const patientValidationResults = patientData?.validationResults ? extractValidationResults(patientData.validationResults) : [];
  const rxValidationResults = selectedRx?.rxValidation ? extractValidationResults(selectedRx.rxValidation) : [];

  const validationResultsAll = [...patientValidationResults, ...rxValidationResults];

  const validationResults = validationResultsAll.filter(({ result }) => result);

  validationResults.sort((a, b) => {
    if (a.result.validity === "Not Valid") return -1;
    if (b.result.validity === "Not Valid") return 1;
    if (a.result.validity === "Partially Valid") return -1;
    if (b.result.validity === "Partially Valid") return 1;
    return 0;
  });

  return (
    <List>
      {validationResults.map(({ label, result }, index) => (
        <ListItem dense sx={{ padding: "2px 1px" }} key={index}>
          <ListItemIcon sx={{ minWidth: "1px", marginRight: "1px" }}>
            {getPersonalValidationStatus(result).icon}
          </ListItemIcon>
          <ListItemText
            primary={label}
            secondary={getPersonalValidationStatus(result).message}
            primaryTypographyProps={{ variant: "body2" }}
          />
        </ListItem>
      ))}
    </List>
  );
});

//   return (
   
//         <List>
//           {patientData?.validationResults.isValidName && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(patientData.validationResults.isValidName).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Patientenname"
//                 secondary={
//                   getPersonalValidationStatus(patientData.validationResults.isValidName).message
//                 }
//               />
//             </ListItem>
//           )}
//           {patientData?.validationResults.isValidDob && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(patientData.validationResults.isValidDob).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Geburtsdatum"
//                 secondary={
//                   getPersonalValidationStatus(patientData.validationResults.isValidDob).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.insuranceNameValidationResult && (
//             <ListItem>
//               <ListItemIcon>
//                 {
//                   getPersonalValidationStatus(selectedRx.rxValidation.insuranceNameValidationResult)
//                     .icon
//                 }
//               </ListItemIcon>
//               <ListItemText
//                 primary="Kostenträgerkennung und Versichertennummer"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.insuranceNameValidationResult)
//                     .message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.therapyValidations && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.therapyValidations).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Heilmittel"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.therapyValidations).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.rxAmountValidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.rxAmountValidation).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Therapiebetrag"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.rxAmountValidation).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.icdValidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.icdValidation).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="ICD Codes"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.icdValidation).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.therapyFrequencyValidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {
//                   getPersonalValidationStatus(selectedRx.rxValidation.therapyFrequencyValidation)
//                     .icon
//                 }
//               </ListItemIcon>
//               <ListItemText
//                 primary="Heilmittel Frequenz"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.therapyFrequencyValidation)
//                     .message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.initialDateVAlidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.initialDateVAlidation).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Datum der Erstbehandlung"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.initialDateVAlidation).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.insuranceStatus && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.insuranceStatus).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Versicherungsstatus"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.insuranceStatus).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.visitTypeValidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.visitTypeValidation).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Behandlungsort"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.visitTypeValidation).message
//                 }
//               />
//             </ListItem>
//           )}
//           {selectedRx?.rxValidation.signatureValidation && (
//             <ListItem>
//               <ListItemIcon>
//                 {getPersonalValidationStatus(selectedRx.rxValidation.signatureValidation).icon}
//               </ListItemIcon>
//               <ListItemText
//                 primary="Arztunterschrift vorhanden"
//                 secondary={
//                   getPersonalValidationStatus(selectedRx.rxValidation.signatureValidation).message
//                 }
//               />
//             </ListItem>
//           )}
//         </List>
//   );
// });

export default ValidationCard;
