import {
  List,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemText,
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import LanIcon from "@mui/icons-material/Lan";
import StorageIcon from "@mui/icons-material/Storage";
import PersonIcon from "@mui/icons-material/Person";
import useStore from "../helpers/useStore";
import { UserContext } from "../stores/User/User.provider";
import { ItemsContext } from "../stores/Items.provider";
import { useEffect, useState } from "react";
import { useObserver } from "mobx-react";
import { AuthContext } from "../stores/Auth/auth.provider";
import { observer } from "mobx-react";
import React from "react";

export const SystemInfo = observer(() => {
  const UserStore = useStore(UserContext);
  const ItemsStore = useStore(ItemsContext);
  const { getAllItems } = ItemsStore;
  const [items, setItems] = useState([]);
  const AuthStore = useStore(AuthContext);

  const token = AuthStore.authToken;

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(token || "");
    // Optionally, you can close the modal after copying
    handleClose();
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllItems();

        setItems(response);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();
  }, []);

  const { user,  getRoles, listPermissions } = UserStore;

  return (
    <>

      <List sx={{ width: "100%", maxWidth: 360 }}>
        <ListItem>
          {/*                                             API */}
          <ListItemAvatar>
            <Avatar>
              <LanIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="API"
            secondary={process.env.REACT_APP_API_URL}
          />
          <Button onClick={handleClickOpen}>Token</Button>
        </ListItem>
        <Divider />
        <ListItem>
          {/*                                             DATABASE */}
          <ListItemAvatar>
            <Avatar>
              <StorageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Database"
            secondary={items.map((item: any) => item.description).join(", ")}
          />
        </ListItem>
        <Divider />
        <ListItem>
          {/*                                             USER  */}
          <ListItemAvatar>
            <Avatar>
              <PersonIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="User"
            secondary={user?.firstName + " " + user?.lastName}
          />
          {!user && (
            <Typography >
              loading ...
            </Typography>
          )}
        </ListItem>
        <Divider variant="inset" />
        <List>
          <ListItem sx={{ pl: 9 }}>
            <ListItemText primary="Roles" secondary={getRoles().join(", ")} />
          </ListItem>
          <ListItem sx={{ pl: 9 }}>
            <ListItemText
              primary="Permissions"
              secondary={listPermissions().join(", ")}
            />
          </ListItem>
        </List>
      </List>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Your Token</DialogTitle>
        <DialogContent>
          <DialogContentText>{token}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={copyToClipboard} color="primary">
            Copy to Clipboard
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
