import { useLocalStore } from 'mobx-react';
import React, { createContext, ReactNode } from 'react';
import { Configuration, RxApi, TicketsApi } from '../../api-client';
import useStore from '../../helpers/useStore';
import { AuthContext } from '../Auth/auth.provider';
import TicketsStore from './ticketsStore';


interface TicketsProviderProps {
  children: ReactNode;
}

export const TicketsStoreContext = createContext<TicketsStore | null>(null);
TicketsStoreContext.displayName = 'TicketsStoreContext';

export const TicketsStoreProvider = ({ children }: TicketsProviderProps) => {
const basePath=process.env.REACT_APP_API_URL
const AuthenticationStore = useStore(AuthContext);
const { authToken } = AuthenticationStore;
const config = new Configuration({
  basePath: basePath,
  accessToken: authToken || "",
});
  const apiClient = new TicketsApi(config);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const store = useLocalStore(
    () => new TicketsStore(apiClient,AuthenticationStore)
  );

  store.apiClient = apiClient;

  return (
    <TicketsStoreContext.Provider value={store}>
      {children}
    </TicketsStoreContext.Provider>
  );
};