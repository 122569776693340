import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import useStore from "../helpers/useStore";
import { AppointmentContext } from "../stores/Appointment/appointment.provider";
import React, { useEffect } from "react";
import { DateTimeRange, DateTimeRangePicker } from "../atoms/DateTimeRange";
import { AppointmentDto,TimeSlotDto } from "../api-client";

interface Props {
  patientId: number;
  appointment: AppointmentDto;
  isOpen: boolean;
  onClose: (actionPerformed: boolean) => void;
}

export const CancelAppointmentDialog: React.FC<Props> = ({patientId, appointment, isOpen, onClose }) => {
  const AppointmentStore = useStore(AppointmentContext);
  const [reason, setReason] = React.useState<string | undefined>(undefined);
  const [savingChanges, setSavingChanges] = React.useState(false);
  const [unavailabiltyRange, setUnavailabiltyRange] = React.useState<DateTimeRange>({
    startDate: new Date(appointment.start as string),
    endDate: new Date(appointment.end as string),
  });
  const { cancelAppointment,saveBlockedSlotForPatient } = AppointmentStore;

  const [whoCancelled, setWhoCancelled] = React.useState<"patient" | "therapist">("patient");

  const handleChangeWhoCancelled = (
    event: React.MouseEvent<HTMLElement>,
    value: "patient" | "therapist"
  ) => {
    setWhoCancelled(value);
  };

  const handleAppointmentCancellation = async () => {
    try {
      await cancelAppointment(appointment.id as string, whoCancelled == "therapist", reason);

      if (whoCancelled == "patient") {
        // post a patient unavailabilty to the api which has the same start and end time as the appointment
        console.log("Unavailability range:", unavailabiltyRange);
        // convert range to TimeSlotDto
        const timeSlot:TimeSlotDto = {start:unavailabiltyRange.startDate.toISOString(),
          end:unavailabiltyRange.endDate.toISOString(), type:"PatientUnvailable"};
        await saveBlockedSlotForPatient(patientId, [timeSlot]);
      }
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      onClose(true);
      setSavingChanges(false);
    } catch (error) {
      console.error("Error marking as attended/unattended:", error);
    }
  };

  const handleOk = () => {
    setSavingChanges(true);
    handleAppointmentCancellation();
  };

  const optionsForPatient = ["nicht erschienen", "kurzfristig abgesagt", "langfristig abgesagt"];
  const optionsForTherapist = ["Therapeut krank", "Therapeut kindkrank", "Therapeut im Urlaub"];
  return (
    <Dialog
      open={isOpen}
      onClose={() => onClose(false)}
      sx={{ "& .MuiDialog-paper": { width: "30%", maxWidth: "none" } }}
    >
      <DialogTitle>Termin stornieren</DialogTitle>
      <DialogContent>
        <Typography>Stornierung eingeleitet durch ...</Typography>
        <ToggleButtonGroup value={whoCancelled} onChange={handleChangeWhoCancelled} exclusive>
          <ToggleButton value="patient">Patient</ToggleButton>
          <ToggleButton value="therapist">Therapeut</ToggleButton>
        </ToggleButtonGroup>

        {whoCancelled == "patient" && (
          <>
            <Autocomplete
              freeSolo // This prop allows users to input values not specified in the options
              options={optionsForPatient}
              value={reason}
              onChange={(event, newValue) => {
                setReason(newValue ? newValue : undefined);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Begründung"
                  variant="outlined"
                  fullWidth
                  margin="dense"
                />
              )}
            />
            <Typography>Der Patient ist für die folgende Zeitspanne nicht verfügbar</Typography>
            <DateTimeRangePicker range={unavailabiltyRange} onChange={setUnavailabiltyRange} />
          </>
        )}

        {whoCancelled == "therapist" && (
          <Autocomplete
            freeSolo // This prop allows users to input values not specified in the options
            options={optionsForTherapist}
            value={reason}
            onChange={(event, newValue) => {
              setReason(newValue ? newValue : undefined);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Begründung"
                variant="outlined"
                fullWidth
                margin="dense"
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        {savingChanges ? (
          <CircularProgress size={24} />
        ) : (
          <>
            <Button onClick={() => onClose(false)} variant="text">
              Abbrechen
            </Button>
            <Button disabled={!whoCancelled} onClick={handleOk}>
              Bestätigen
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
