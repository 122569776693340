// import React, { useState } from "react";
// import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Tab, Tabs, TextField, Typography } from "@mui/material";
// import { Close } from "@mui/icons-material";
// import Controls from "../../../atoms/Controls";
// import { observer } from "mobx-react";
// import useStore from "../../../helpers/useStore";
// import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
// import { UserContext } from "../../../stores/User/User.provider";
// import { UserDto } from "../../../api-client";

// interface PermissionsAssignmentProps {
//     userData: any;
//     onSelectUser: (user: any) => void;
//   }

//   const PermissionAssignment: React.FC<PermissionsAssignmentProps> = observer(({ userData,onSelectUser }) => {

//     const MasterTablesStore = useStore(MasterTablesContext);
//     const { masterTablesData } = MasterTablesStore;
//     const UserStore = useStore(UserContext);
//     const { saveUserInfo } = UserStore;
//     const [formState, setFormState] = useState<UserDto>(userData);

//     let permissionOptions: any[] ;
//     permissionOptions= masterTablesData
//     .filter((obj: any) => "permission" in obj )
//     .map((obj: any) => obj.permission);
//     const handleCheckboxChange = async (permissionId: string) => {
//       const permissionIndex = userData.permissions
//         ? userData.permissions.findIndex((p: any) => p.id === permissionId)
//         : -1;

//       if (permissionIndex !== -1) {
//         // Remove the permission from the array
//         userData.permissions.splice(permissionIndex, 1);
//       } else {
//         // If the permission is not found, add it to the array with isUserPermission set to true
//         userData.permissions = [
//           ...(userData.permissions || []),
//           { id: permissionId, isUserPermission: true },
//         ];
//       }

//       setFormState({ ...userData });

//       try {
//         // Call the API (replace with your actual API endpoint)
//         const response = await saveUserInfo({ ...userData });
//         onSelectUser(response);

//         // Handle the API response as needed
//         // console.log("API Response:", response);
//       } catch (error) {
//         // Handle API error
//         console.error("API Error:", error);
//       }
//     };

// //     const handleCheckboxChange = async (permissionId: string) => {
// //         // Log the state before the change
// //         console.log("Before change:", userData.permissions,permissionId);
// //     //     const permissionObject = permissionOptions[0]?.filter((permission: any) =>
// //     //   permission.id===permissionId
// //     // );
// //     // permissionObject.isUserPermission=true;

// //  // Find the permission object in userData.permissions
// //  const permissionIndex = userData.permissions
// //  ? userData.permissions.findIndex((p: any) => p.id === permissionId)
// //  : -1;

// // if (permissionIndex !== -1) {
// //  // Toggle isUserPermission in the found permission object
// //  userData.permissions[permissionIndex].isUserPermission = !userData.permissions[permissionIndex]
// //    .isUserPermission;
// // } else {
// //  // If the permission is not found, add it to the array with isUserPermission set to true
// //  userData.permissions = [
// //    ...(userData.permissions || []),
// //    { id: permissionId, isUserPermission: true },
// //  ];
// // }
// // setFormState({ ...userData });

// //         try {
// //           // Call the API (replace with your actual API endpoint)
// //           const response = await saveUserInfo({ ...userData });
// //           onSelectUser(response);

// //           // Handle the API response as needed
// //         //   console.log("API Response:", response);
// //         } catch (error) {
// //           // Handle API error
// //           console.error("API Error:", error);
// //         }
// //       };

//   console.log('userData',masterTablesData,userData)
//     return (
//         <Box>
//         <Typography variant="h5" sx={{ marginBottom: "20px" }}>
//           Permissions Assignments
//         </Typography>

//         <Grid container spacing={1}>
//           {permissionOptions[0]?.map((permission: any) => (
//             <Grid key={permission.id} item xs={12} sm={4}>
//               <FormControl component="fieldset" fullWidth>
//                 <Box
//                   border="1px solid #ddd"
//                   borderRadius="1px"
//                 //   padding="1px"
//                 display="flex"

//                   alignItems="center"
//                 >

//                 <Checkbox
//                  key={permission.id}  // Add a unique key
//                  checked={formState.permissions?.some((p: any) => p.id === permission.id)}
//                   disabled={userData.permissions?.some((p: any) => p.id === permission.id && !p.isUserPermission)}
//                   onChange={() => handleCheckboxChange(permission.id)}
//                 />

//                   {/* <Box flex="1"> */}
//                     <Typography variant="body2">
//                       {permission.name} (<span style={{ fontSize: "0.8rem" }}>{permission.description}</span>)
//                     </Typography>
//                   {/* </Box> */}

//                 </Box>
//               </FormControl>

//             </Grid>
//           ))}
//         </Grid>
//       </Box>
//     );

// });

// export default PermissionAssignment;
import React, { useState } from "react";
import { Box, Checkbox, FormControl, Typography, Grid } from "@mui/material";
import { observer } from "mobx-react";
import useStore from "../../../helpers/useStore";
import { MasterTablesContext } from "../../../stores/MasterTables/MasterTables.provider";
import { UserContext } from "../../../stores/User/User.provider";
import { UserDto } from "../../../api-client";

interface PermissionsAssignmentProps {
  userData: any;
  onSelectUser: (user: any) => void;
}

const PermissionAssignment: React.FC<PermissionsAssignmentProps> = observer(
  ({ userData, onSelectUser }) => {
    const MasterTablesStore = useStore(MasterTablesContext);
    const { masterTablesData } = MasterTablesStore;
    const UserStore = useStore(UserContext);
    const { saveUserInfo } = UserStore;
    const [checkedPermissions, setCheckedPermissions] = useState<string[]>(
      userData.permissions?.map((p: any) => p.id) || []
    );

    const permissionOptions: any[] = masterTablesData
      .filter((obj: any) => "permission" in obj)
      .map((obj: any) => obj.permission);

    const handleCheckboxChange = async (permissionId: string) => {
      //Check if permission exists
      const isChecked = checkedPermissions.includes(permissionId);
      let updatedUserPermissions: any[] = [];
      //Filter user permissions that is unchecked
      updatedUserPermissions = isChecked
        ? checkedPermissions.filter((id) => !id.includes(permissionId))
        : [...checkedPermissions, permissionId];
      setCheckedPermissions(updatedUserPermissions);
      //When any userpermisssion unchecked
      if (isChecked) {
        updatedUserPermissions = updatedUserPermissions.map((permissionId) => {
          const existingPermission = userData.permissions.find(
            (p: any) => p.id === permissionId
          );
          return {
            id: permissionId,
            name: permissionId,
            isUserPermission: existingPermission
              ? existingPermission.isUserPermission
              : false,
          };
        });
      }
//When new user permission added
      else  {
        const existingPermissionIds =
          userData.permissions?.map((p: any) => p.id) || [];
        const newPermissions = updatedUserPermissions.filter(
          (id: any) => !existingPermissionIds.includes(id)
        );

        updatedUserPermissions = userData.permissions
          ? userData.permissions.filter((p: any) =>
              existingPermissionIds.includes(p.id)
            )
          : [];

        if (newPermissions.length > 0) {
          updatedUserPermissions.push(
            ...newPermissions.map((id: any) => ({
              id,
              isUserPermission: true,
            }))
          );
        }
      }
      userData.permissions = updatedUserPermissions;
      console.log("userData.permissions", userData.permissions);

      try {
        const response = await saveUserInfo({ ...userData });
        onSelectUser(response);
      } catch (error) {
        console.error("API Error:", error);
      }
    };

    return (
      <Box>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          Permissions Assignments
        </Typography>

        <Grid container spacing={1}>
          {permissionOptions[0]?.map((permission: any) => (
            <Grid key={permission.id} item xs={12} sm={4}>
              <FormControl component="fieldset" fullWidth>
                <Box
                  border="1px solid #ddd"
                  borderRadius="1px"
                  display="flex"
                  alignItems="center"
                >
                  <Checkbox
                    checked={checkedPermissions.includes(permission.id)}
                    disabled={userData.permissions?.some(
                      (p: any) => p.id === permission.id && !p.isUserPermission
                    )}
                    onChange={() => handleCheckboxChange(permission.id)}
                  />
                  <Typography variant="body2">
                    {permission.name} (
                    <span style={{ fontSize: "0.8rem" }}>
                      {permission.description}
                    </span>
                    )
                  </Typography>
                </Box>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  }
);

export default PermissionAssignment;
